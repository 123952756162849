import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import * as Settings from './Settings';
import { useAuth } from '../../lib/useAuth'

const Index = () => {
	const auth = useAuth()
	const [show, setShow] = useState(null)
	const location = useLocation()

	const sections = [
		{ key: 'configuration', icon: 'cog', name: 'Settings', linkPath: '/settings' },
		((auth.user.canConnectMailbox() || auth.account.subscribeableMailboxes().length > 0) ? { key: 'mailboxes', icon: 'inbox-in', name: 'Mailboxes', linkPath: '/settings/mailboxes' } : null),
		(auth.user.isAdmin() ? { key: 'users', icon: 'users', name: 'Users', linkPath: '/settings/users' } : null),
		(auth.user.isOwner() && auth.account.isLive() ? { key: 'billing', icon: 'credit-card', name: 'Billing', linkPath: '/settings/billing' } : null)
	].filter(Boolean)

	useEffect(() => {
		let match = location.pathname.match(/\/settings\/(mailboxes|users|billing)\/?/i);
		let section = match ? match[1] : 'configuration'

		setShow(section)
	}, [])

	const switchTo = (panel) => {
		setShow(panel.key)

//		props.router.push({ pathname: panel.linkPath });
	}

	return (
		<div className="main-content-panel">
			<div className="main medium no-sidebar">
				<div className="top-menu desktop-only">
					<ul>
						{sections.length > 1 && sections.map(section => {
							return (
								<li key={section.key} onClick={switchTo.bind(null, section)} className={show === section.key ? 'active' : ''}>
									<i className={"fa fa-lg fa-" + section.icon} />
									{section.name}
								</li>
							);
						})}

					</ul>
				</div>

				<div className="mobile-only">
					<div id="header">
						<div>
							<span className="header-page-icon"><i className="far fa-cog" /></span>
							<h1>Configuration</h1>
							<div className="text-button red" onClick={auth.signOut}><i className="fa fa-sign-out" /> Sign Out</div>
						</div>
					</div>
					<div className="subheader-nav">
						<ul className="sections">
							{sections.length > 1 && sections.map(section => {
								return (
									<li key={section.key} onClick={switchTo.bind(null, section.key)}>
										<span className={"console-button" + (show === section.key ? ' active' : '')}><i className={`fal fa-${section.icon}`} />{section.name}</span>
									</li>
								);
							})}
						</ul>
					</div>
				</div>
				<div className="main-content settings">
					<div>
						{(() => {
							switch (show) {
								case "users":
									return <Settings.Users />;
								case "mailboxes":
									return <Settings.Mailboxes/>;
								case "billing":
									return <Settings.Billing />;
								default:
									return <Settings.Profile />;
							}
						})()}
						</div>
				</div>
			</div>
		</div>
	)
}

export default Index