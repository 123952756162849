import Resource from '../lib/Resource';
import { ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';

export default class Draft extends Resource {
	displaySubject() {
		return this.subject || '(No subject)';
	}

	setContentStateFromHtml(html) {
		const blocksFromHTML = htmlToDraft(html);
		this.content_state = ContentState.createFromBlockArray(
			blocksFromHTML.contentBlocks,
			blocksFromHTML.entityMap
		);
	}

	contentState() {
		if (this.content_state) {
			let content_state = this.content_state;
			while (typeof content_state === 'string') {
				content_state = JSON.parse(content_state);
			}

			return content_state;
		}
	}

	getRecipientsOfType(type) {
		return this[`${type}_recipients`] || []
	}

	nonEmpty() {
		if (this.subject && this.subject !== "") {
			return true;
		} else if (this.content_state) {
			let contentState = JSON.parse(this.content_state);
			contentState.blocks.forEach(block => {
				if (block.text !== "") {
					return true;
				}
			})
		}

		return false;
	}
}

Draft.modelName = 'Draft';
Draft.fields = [
	'id', 'subject', 'content_state', 'body', 'to_recipient_ids', 'cc_recipient_ids', 'reference_message_id', 'mailbox_id',
	'bcc_recipient_ids', 'sendable', 'attachments', { reminder: 'DraftReminder' }, 'include_previous_in_reply', 'thread_id',
	'draft_type', 'to_non_contact_email_addresses', 'cc_non_contact_email_addresses', 'bcc_non_contact_email_addresses',
	{ to_recipients: ['Contact'] }, { cc_recipients: ['Contact'] }, { bcc_recipients: ['Contact'] }

];
Draft.editableFields = Draft.fields.filter(field => !['to_recipients', 'cc_recipients', 'bcc_recipients'].includes(field)).concat(['reminder'])
Draft.url = '/drafts';