import React from 'react';
import { Link } from 'react-router-dom';

const FileResultRow = ({ record }) => {
	return (
		<Link to={"/files/" + record.id}>
			{record.file_name}
		</Link>
	)
}

export default FileResultRow