import Resource from '../lib/Resource';
import { ShareableRecord } from '../lib/ShareableRecord';
import { AbstractContact } from '../lib/AbstractContact';

export default class Contact extends ShareableRecord(AbstractContact(Resource)) {
	displayName(emailAddress) {
		if (this.name) {
			return this.name;
		} else if (emailAddress !== undefined) {
			return emailAddress;
		} else {
			return this.email_addresses[0].value;
		}
	}
}

Contact.fields = [
	'id', 'name', 'email_address', 'background', 'task_count', 'global', 'private_interactor_ids', { shares: ['Share'] }, 'user_id',
	'numbers', 'interaction_histories', 'enriched', 'pinned', { employments: ['Employment'] }, { latest_threads: ['Thread'] }, 'mailbox_ids',
	{ files: 'File' }, { avatar: 'Avatar' }, { tasks: ['Task'] }, { collaborators: ['Contact'] }, { locations: ['Location'] },
	{ websites: ['Website'] }, { social_profiles: ['SocialProfile'] }, { latest_files: ['File'] }, { comments: ['Comment'] },
	{ email_addresses: ['EmailAddress'] }, 'has_duplicates'
];
Contact.editableFields = [
	'name', 'background', 'websites', 'locations', 'employments', 'social_profiles', 'avatar', 'email_addresses'
]
Contact.modelName = 'Contact';