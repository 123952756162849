import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../../lib/useAuth';
import Avatar from '../contacts/contact/Avatar';

const Row = (props) => {
	const [task, setTask] = useState(props.task)
	const [truncateDescription, setTruncateDescription] = useState(true)
	const auth = useAuth()
	const location = useLocation()

	const milestoneName = () => {
		return (task.hasMilestone() && !props.hideMilestone ? <Link to={"/milestones/" + task.milestone.id} className="milestone">{task.milestone.name}</Link> : null)
	}

	const dueDate = () => {
		return (task.hasDueDate() ? <span className="due-date">{task.dueDate().toFormat('LLLL d')}</span> : null);
	}

	const completedAt = () => {
		return (task.isComplete() ? <span className="completed-at">{task.completedAt().toFormat('LLLL d')}</span> : null);
	}

	const associatedRecord = () => {
		if (!props.showAssociated || !task.hasAssociatedRecord()) {
			return null;
		} else if (task.contact.exists()) {
			return <Link to={`/contacts/${task.contact.id}`} className="contact record">{task.contact.displayName()}</Link>
		} else if (task.organization.exists()) {
			return <Link to={`/organizations/${task.organization.id}`} className="organization record">{task.organization.displayName()}</Link>
		} else if (task.file.exists()) {
			return <Link to={`/files/${task.file.id}`} className="file record">{task.file.displayName()}</Link>
		}
	}

	const description = () => {
		let maxLength = 70;

		if (!task.description) {
			return null;
		} else if (task.description.length > maxLength && truncateDescription) {
			return (
				<div className="description">
					{task.description.substring(0, maxLength).trimRight()}&hellip;
					<i className="fas fa-chevron-right expand-truncated" onClick={() => setTruncateDescription(false)} />
				</div>
			);
		} else {
			return (
				<div className="description">
					{task.description}
				</div>
			);
		}
	}

	const responsible = () => {
		if (task.isAssigned()) {
			return (
				<div className="borderless badged-users inline-block">
					{task.assignees(auth.user.account).map(user => {
						return (
							<div className="user">
								<div className="avatar-container"><Avatar contact={user} inline={true} /></div>
								<div className="name">{user.displayName()}</div>
							</div>
						);
					})}
				</div>
			);
		}
	}

	const meta = () => {
		if (task.hasDueDate() || task.isComplete() || task.isAssigned()
			|| (task.hasMilestone() && !props.hideMilestone)
			|| (task.hasAssociatedRecord() && props.showAssociated)) {
			return (
				<div className="meta">
					{false && responsible()}
					{dueDate()}
					{completedAt()}
					{milestoneName()}
					{associatedRecord()}
				</div>
			);
		}
	}

	return (
		<div className={['task-row', task.category(), (task.isComplete() ? 'complete' : null)].join(' ')}>
			<div className="checkbox" onClick={props.toggler.bind(null, task)}></div>
			<div className="assignment">
				<Link className="highlight-on-hover" to={`/tasks/${task.id}`} state={{ modal: true, returnTo: location.pathname, backgroundLocation: location }} data={{ toggler: props.toggler, containerUpdater: (task) => setTask(task), resource: task }}><span>{task.assignment}</span></Link>
				{meta()}
			</div>
			{description()}
		</div>
	)
}

export default Row


//					<HeaderAction linkTo={{ pathname: "/organizations/new", state: { modal: true, returnTo: location.pathname, backgroundLocation: location } }} icon="plus" />
