import React, { useState, useEffect } from 'react';
import HeaderAction from './HeaderAction';

const HeaderActions = (props) => {
	const [showActionDropDown, setShowActionDropDown] = useState(false)
	const [children, setChildren] = useState(null)

	useEffect(() => {
		loadChildrenFromProps(props);
	}, [props])

	const loadChildrenFromProps = props => {
		let newChildren = React.Children.toArray(props.children);

		if (props.includeFilterToggle) {
			newChildren.push(<HeaderAction icon="filter" className={props.showFilter ? "on" : ""} onClick={props.toggleFilter} />);
		}

		setChildren(newChildren)
	}

	const toggleActionDropDown = () => {
		setShowActionDropDown(!showActionDropDown)
	}

	const actionDropDown = () => {
		return (
			<div className={"action-drop-down" + (showActionDropDown ? "" : " hidden")}>
				<ul>
					{children.map(child => {
						return <li>{child}</li>
					})}
				</ul>
			</div>
		);
	}

	const showDropDown = () => {
		return window.isMobile && !props.uncollapsible && children && children.length > 1;
	}

	return (
		<span className="header-actions">
			{
				showDropDown()
				? <HeaderAction icon="ellipsis-h" onClick={toggleActionDropDown} />
				: children ? children.map((child, i) => <React.Fragment key={i}>{child}</React.Fragment>) : null
			}
			{showDropDown() ? actionDropDown() : null}
		</span>
	)
}

HeaderActions.displayName = 'HeaderActions'

export default HeaderActions