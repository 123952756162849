import React from 'react';
import ContactList from './ContactList';
import Commentary from '../../commentary/Index';
import Table from '../../contacts/Table';

const Workspace = ({ organization }) => {
	const domain = () => {
		if (organization.domain) {
			let external_domain = organization.domain;
			if (!external_domain.match(/^http/i)) { external_domain = `http://${external_domain}` }

			return (
				<a href={external_domain} className="organizations__domain">
					{organization.domain}
				</a>
			);
		}
	}

	return (
		<>
			<Commentary record={organization} />

			<div className="structure right-panel">
				<div className="main-panel">
					<div className="description">
						{organization.description}
					</div>
				</div>
				<div className="info-panel">
					{domain()}
					<ContactList organization={organization} />
				</div>
			</div>
		</>
	)
}

export default Workspace