export default class Plan {
	details = {
		solo: {
			code: 'solo',
			name: 'Solo',
			user_price: 24.00,
			description: 'Create a single-user account to take control of your personal or work email.'
		},
		team: {
			code: 'team',
			name: 'Team',
			user_price: 29.00,
			description: 'Create an account for your team, so you can all work better together.'
		}
	}

	constructor(code) {
		this.code = code
	}

	get(field) {
		return this.details[this.code][field]
	}
}