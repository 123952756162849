import { useState, useEffect } from 'react';
import { useNotificationListHandler } from '../../lib/useNotificationListHandler'
import AsyncFetcher from '../../lib/AsyncFetcher';
import { Helmet } from 'react-helmet';
import Slice from './Slice'
import Loading from '../common/Loading';
import { useCollectionLoader } from '../../lib/hooks';

const Index = () => {
//	const [isFiltering, startFiltering] = useTransition()
	const [activeFilter, setActiveFilter] = useState(null)
	const [waiting, setWaiting] = useState(false)
	const [counts, setCounts] = useState(null)
	const [newCounts, setNewCounts] = useState(null)
	const [allCounts, setAllCounts] = useState(null)
	const [notifications, setNotifications] = useState(null)
	const [previousFilter, setPreviousFilter] = useState(null)
	const fetcher = new AsyncFetcher(localStorage.token)
	const [handleMarkAsRead, handleDismiss, handleDismissAll] = useNotificationListHandler()
	const [collection, collectionFilter, loading] = useCollectionLoader('NotificationRepository', {}, () => {})
	const filters = [
		{ key: null, event: null, icon: 'bell', name: 'All notifications'},
		{ key: 'comments', event: 'comment_added', icon: 'comments', name: 'Comments written' },
		{ key: 'tasks', event: 'task_assigned', icon: 'check-circle', name: 'Tasks assigned' },
		{ key: 'milestones', event: 'milestone_shared', icon: 'map-signs', name: 'Milestones shared' },
		{ key: 'files', event: 'file_shared', icon: 'file-alt', name: 'Files shared' },
		{ key: 'threads', event: 'thread_shared', icon: 'envelope', name: 'Threads shared' },
		{ key: 'contacts', event: 'contact_shared', icon: 'users', name: 'Contacts shared' },
	];

	useEffect(() => {
		const loadNotifications = async () => {
			const json = await fetcher.get('/notifications/snapshot')

			setNewCounts(json.notification_snapshot.new_counts)
			setAllCounts(json.notification_snapshot.all_counts)
			setCounts(json.notification_snapshot.all_counts)
		}

		loadNotifications()
	}, [])

	useEffect(() => {
		if (collection) {
			setNotifications(collection.records)
		}
	}, [collection])


	const filterToType = (type) => {
		collectionFilter({ slice: type })

		if (activeFilter && type && type !== activeFilter) {
			markAsRead({ slice: type });
		}

		setPreviousFilter(activeFilter)
		setActiveFilter(type)

	}

	const dismissSlice = (slice) => {
		handleDismiss({ slice: slice })
	}

	const newNotificationsCount = () => {
		if (newCounts) {
			return Object.values(newCounts).reduce((total, count) => total + count, 0)
		}
	}

	const allNotificationsCount = () => {
		if (allCounts) {
			return Object.values(allCounts).reduce((total, count) => total + count, 0);
		}
	}

	const newCountFor = type => {
		if (newCounts) {
			let count = numberOfNotificationsByEvent(type);
			if (count && count > 0) {
				return <span className="event-count">{count}</span>;
			}
		}
	}

	const numberOfNotificationsByEvent = type => {
		if (newCounts) {
			return type === 'all' || type === null ? newNotificationsCount() : newCounts[type];
		}
	}

	const markAsRead = (read) => {
		handleMarkAsRead(read)

		if (read.slice) {
			let updatedNewCounts = { ...newCounts }
			updatedNewCounts[read.slice] = 0;
			setNewCounts(updatedNewCounts)
		}
	}

	return (
		<div className="main medium headingless notification-index">
			<div className="subheader-info mobile-only">
				<div className="styled-select">
					<select onChange={(e) => filterToType(e.target.value === "" ? null : e.target.value)}>
						{filters.map(filter => {
							return (
								<option value={filter.event || ""}>
									{filter.name}
									{numberOfNotificationsByEvent(filter.event) > 0 ? ` (${numberOfNotificationsByEvent(filter.event)} new)` : ""}
								</option>
							);
						})}
					</select>
				</div>
			</div>
			<Helmet title="Your notifications" />
			<div className="main-content">
				<div className="structure left-panel">
					<div className="info-panel">
						<div className="notification-filters">
							{filters.map(filter => {
								return (
									<div onClick={() => filterToType(filter.event)} className={(loading ? 'filtering' : '') + (counts && counts[filter.event] > 0 ? '' : ' empty') + (activeFilter === filter.event ? ' active' : '')}>
										<i className={`fal fa-${filter.icon}`} />
										{filter.name}
										{newCountFor(filter.event)}
									</div>
								)
							})}
						</div>
					</div>
					<div className="main-panel notification-panel">
						{
							collection && newCounts
							? <Slice
									collection={collection}
									sliceKey={collection.slice_key}
									newCount={newCounts[collection.slice_key] || 0}
									allCount={allCounts[collection.slice_key] || 0}
									dismiss={handleDismiss}
								/>
							: <Loading inline={true} />
						}
					</div>
				</div>
			</div>
		</div>
	)
}

//export default CollectionLoader(CurrentUserAccessor(NotificationListHandler(Index)), 'NotificationRepository');

export default Index