import React from 'react';
import createReactClass from 'create-react-class';
import moment from 'moment';
import { transform, isEqual, isObject, isArray, get, set } from 'lodash';
import * as Repositories from '../repositories';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const Routable = (Component) =>  {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();
		return (
			<Component
				{...props}
				router={{ location, navigate, params }}
			/>
		);
	}
	return ComponentWithRouterProp;
}

export var getPolymorphicAttributes = function(object) {
	if (!object) { return {}; }

	return { record_id: object.id, record_type: object.constructor.name.toLowerCase() };
}

export const ButtonIcon = (props) => {
	return (
		<a className="tooltip-bottom" data-tooltip={props.tooltip}>
			<span className="fa-stack fa-1x">
				<i className="fa fa-circle fa-stack-2x" />
				<i className={"fa fa-stack-1x fa-" + props.icon} style={{ color: "white" }} />
			</span>
		</a>
	)
}

export const StackedIcon = (props) => {
	let weight = props.weight || 'fa';

	return (
		<span {...Object.assign({ ref: props.inputRef }, props, { className: "fa-stack fa-lg " + (props.className) })}>
			<i className={"fa fa fa-stack-2x fa-circle"}></i>
			<i className={weight + " fa-stack-1x fa-" + props.icon} style={{ color: "white" }} />
		</span>
	)
}

export var autoLink = function(text, options) {
	if (!text) { return text; }

	var callback, k, linkAttributes, pattern, v;

	// eslint-disable-next-line
	pattern = /(^|[\s\n]|<[A-Za-z]*\/?>)((?:https?|ftp):\/\/[\-A-Z0-9+\u0026\u2019@#\/%?=()~_|!:,.;]*[\-A-Z0-9+\u0026@#\/%=~()_|])/gi;
	if (!(Object.keys(options).length > 0)) {
		return text.replace(pattern, "$1<a href='$2'>$2</a>");
	}
	callback = options["callback"];
	linkAttributes = ((function() {
		var results;
		results = [];
		for (k in options) {
			v = options[k];
			if (k !== 'callback') {
				results.push(" " + k + "='" + v + "'");
			}
		}
		return results;
	})()).join('');
	return text.replace(pattern, function(match, space, url) {
		var link;
		link = (typeof callback === "function" ? callback(url) : void 0) || ("<a href='" + url + "'" + linkAttributes + ">" + url + "</a>");
		return "" + space + link;
	});
}

export var NewTaskFormLoader = {
	getInitialState: function() {
		return {
			showNewTaskForm: false
		}
	},
	toggleNewTaskForm: function() {
		this.setState({ showNewTaskForm: !this.state.showNewTaskForm });
	}
};

export const ProgressDonut = (props) => {
	let percentage = (props.percentage <= 1 ? props.percentage * 100 : props.percentage)
	let degrees = Math.round(360 * (percentage / 100));

	return (
		<div className="progress-indicator" title={Math.round(percentage) + "% complete"}>
			<div className={"pie-wrapper" + (percentage > 50 ? " over-50" : "")}>
				<div className="pie">
					<div className="left-side half-circle" style={{ transform: 'rotate(' + degrees + 'deg)' }}></div>
					<div className="right-side half-circle"></div>
				</div>
				<div className="shadow"></div>
			</div>
		</div>
	)
}

export var formattedRelativeDate = (input, relativeTo, includeTime, asHeading) => {
	let date = moment(input);
	relativeTo = moment(relativeTo);

	if (date.isSame(relativeTo, 'day')) {
		return (includeTime && date._i.includes(':') ? (asHeading ? '' : 'at ') + date.format('h:mma') : 'today');
	} else if (date.isSame(relativeTo.subtract(1, 'days'))) {
		return (includeTime ? 'yesterday at ' + date.format('h:mma') : 'yesterday');
	} else if (date.isSame(relativeTo.subtract(2, 'days'))) {
		return 'two days ago';
	} else if (date.isAfter(relativeTo.subtract(4, 'days'))) {
		return (asHeading ? '' : 'on ') + date.format('dddd');
	} else {
		return (asHeading ? '' : 'on ') + date.format('dddd, MMMM D')
	}
}

export var changes = (object, base, keep) => {
	if (!keep) { keep = []; }

	let newObject = transform(object, function(result, value, key) {
		if (!isEqual(value, base[key]) || keep.includes(key)) {
			let newValue = (isObject(value) && isObject(base[key])) ? changes(value, base[key], keep) : value;

			result[key] = (isArray(newValue) ? newValue.filter(Boolean) : newValue);
		}
	});


	keep.forEach(field => {
		if (get(base, field) && !get(newObject, field)) {
			set(newObject, field, get(base, field))
		}
	});

	return newObject;
}

export var timeAwareDate = (date, withTime) => {
	let addTime = (format) => `${format} [at] h:mma`;

	return date.calendar(null, {
		sameDay: addTime('[Today]'),
		nextDay: addTime('[Tomorrow]'),
		nextWeek: addTime('dddd'),
		lastDay: addTime('[Yesterday]'),
		lastWeek: addTime('[Last] dddd'),
		sameElse: (now) => {
			if (date.isAfter(now.subtract(3, 'months'))) {
				return addTime('MMMM D');
			} else if (date.isAfter(now.subtract(12, 'months'))) {
				return 'MMMM D';
			} else {
				return 'MMMM D, YYYY';
			}
		}
	});
}

export var getRepositoryFor = (object) => {
	return new Repositories[`${object.constructor.modelName}Repository`]();
}