import { useEffect } from 'react';

const ClickHandler = ({ children, onClick }) => {
	useEffect(() => {
		document.addEventListener('mousedown', onClick)

		return () => document.removeEventListener('mouseup', onClick)
	}, [])

	return children
}

export default ClickHandler