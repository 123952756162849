import { useState, useEffect } from 'react';
import { Form } from '../../lib/forms/Form';
import { TextInput } from '../../lib/forms/TextInput';
import { HiddenField } from '../../lib/forms/HiddenField';
import { Label } from '../../lib/forms/Label';

const EmailAddressForm = (props) => {
	const [discovering, setDiscovering] = useState(false)
	const [valid, setValid] = useState(null)

	useEffect(() => {
		if (props) {
			if (props.error) {
				setDiscovering(false)
				setValid(false)
			}	else {
				setValid(
					props.discovery
					&& props.discovery.email_address
					&& props.discovery.validations().email_address.test.test(props.discovery.email_address)
				)
			}
		}
	}, [props])

	const checkValidity = (input, e) => {
		setValid(props.discovery.validations().email_address.test.test(e.target.value))
	}

	const markAsDiscovering = () => {
		setDiscovering(true)
	}

	const indicator = () => {
		if (discovering) {
			return <i className="fa fa-spinner fa-spin fa-3x" style={{ color: "#e0cd22" }} />;
		} else if (valid) {
			return <i className="fa fa-chevron-circle-right fa-3x" onClick={props.discover} style={{ color: "#8cbf7e" }} />
		} else {
			return <i className="fa fa-circle-thin fa-3x" style={{ color: "#efefef" }} />
		}
	}

	const errorMessage = () => {
		if (props.error) {
			return <div className="error message">{props.error.message}</div>
		} else { return null; }
	}

	return (
		<div className="main-form green centered">
			{errorMessage()}
			<Form repository={props.repo} beforeSubmit={markAsDiscovering} object={props.discovery} onSave={props.handleDiscovery} onError={props.handleDiscoveryError} className="center">
				<HiddenField name="invitation_code" />
				<Label attribute="email_address" className="large strong center">What&#8217;s your email address?</Label>
				<TextInput className="large full center" name="email_address" changeHandler={checkValidity} autoFocus />
				<button type="submit" className="with-icon" style={{ marginTop: "20px" }}>
					{indicator()}
				</button>
			</Form>
		</div>
	)
}

export default EmailAddressForm