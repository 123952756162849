import { isArray, isString, isFunction } from 'lodash';

export default class PermissionsEvaluator {
	passes(restrictions, user) {
		restrictions = isArray(restrictions) ? restrictions : [restrictions];
		let context = { currentUser: user, account: user.account }
		var blocks = 0;

		restrictions.forEach(function(restriction) {
			if (isString(restriction)) {
				var split = restriction.split('.'), obj = split[0], check = split[1];

				if (!context[obj][check]()) {
					blocks += 1;
				}
			} else if (isFunction(restriction)) {
				if (!restriction()) {
					blocks += 1;
				}
			}
		});

		return blocks === 0;
	}
}