import ProxyCollection from '../lib/ProxyCollection';
import TaskRepository from '../repositories/TaskRepository';
import { TaskCollectionUtilities } from '../lib/utilities/TaskCollectionUtilities';
import moment from 'moment';

export const AbstractContact = (superclass) => class extends superclass {
  displayName(defaultEmailAddress) {
    if (this.name) {
      return this.name;
    } else if (defaultEmailAddress) {
      return defaultEmailAddress;
    } else {
      return this.primaryEmailAddress();
    }
  }

  getType(user) {
    if (this.isGlobal()) {
      return 'global';
    } else if (this.user_id === user.id) {
      return 'personal';
    } else {
      return 'shared';
    }
  }

  isGlobal() {
    return this.global;
  }

  hasName() {
    return !!(this.name || this.email_address);
  }

  hasAvatar() {
    return (this.avatar && Object.keys(this.avatar.getAttributes()).length > 0);
  }

  hasEmployment() {
    return (this.employments && (this.employments[0].organization || this.employments[0].title));
  }

  hasBackground() {
    return (this.background && this.background !== '');
  }

  displayShortName() {
    return this.firstName() || this.name || this.email_address;
  }

  firstName() {
    if (this.name) { return this.name.split(' ')[0]; }

    return null;
  }

  taskCollection() {
    if (!this._taskCollection) {
      this._taskCollection = Object.assign(new ProxyCollection(this.tasks || [], new TaskRepository()), TaskCollectionUtilities);
    }

    return this._taskCollection;
  }

  hasSocialProfiles() {
    return this.social_profiles && this.social_profiles.length > 0;
  }

  getInitials() {
    if (this.name) {
      let name = this.name.replace(/^[a-z]{2,3}\./i, "").trim().replace(/(.*)[[js]r[.]|[i]{1,3}]?$/i, "$1");
      let matches = name.match(new RegExp(/\b\w/g));

      if (matches && matches.length < 4) {
        return matches.join('');
      }
    } else {
      return ''
    }
  }

  employmentInfo() {
    if (this.employments && this.employments.length > 0 && (this.employments[0].title || this.employments[0].organization)) {
      return this.employments[0];
    } else {
      return null;
    }
  }

  emailAddressValues() {
    let addresses = this.email_addresses ? this.email_addresses.map(address => address.value) : [];
    if (this.email_address) {
      addresses = addresses.concat(this.email_address);
    }

    return addresses.map(address => address.toLowerCase());
  }

  primaryEmailAddress() {
    if (this.email_addresses) {
      let primaryEmailAddress = this.email_addresses.find(email_address => email_address.primary)
      if (primaryEmailAddress) {
        primaryEmailAddress = primaryEmailAddress.value
      }

      return primaryEmailAddress;
    }
  }

  primaryLocation() {
    return (this.locations || [])[0];
  }

  primaryEmployment() {
    return (this.employments || [])[0];
  }

  hasMultipleMailboxInteractions() {
    return this.interaction_histories.length > 1;
  }

  hasMailboxInteractions() {
    return this.interaction_histories && this.interaction_histories.length > 0;
  }

  globalInteractionHistory(user) {
    if (!this.hasMailboxInteractions()) {
      return null;
    }

    if (!this.globalHistories) { this.globalHistories = {} }
    if (!this.globalHistories[user.id]) {
      this.globalHistories[user.id] = { file_count: 0, thread_count: 0, task_count: 0, monthly_messages: {}, first_contact: null, last_contact: null }

      this.interaction_histories.forEach(history => {
        if (history.file_count) { this.globalHistories[user.id].file_count += history.file_count }
        if (history.thread_count) { this.globalHistories[user.id].thread_count += history.thread_count }
        if (history.task_count) { this.globalHistories[user.id].task_count += history.task_count }

        if (this.globalHistories[user.id].first_contact == null || moment(history.first_contact).isBefore(moment(this.globalHistories[user.id].first_contact))) {
          this.globalHistories[user.id].first_contact = history.first_contact;
        }

        if (this.globalHistories[user.id].last_contact == null || moment(history.last_contact).isAfter(moment(this.globalHistories[user.id].last_contact))) {
          this.globalHistories[user.id].last_contact = history.last_contact;
        }

        if (history.monthly_messages) {
          Object.keys(history.monthly_messages).forEach(month => {
            if (!this.globalHistories[user.id].monthly_messages[month]) { this.globalHistories[user.id].monthly_messages[month] = 0 }

            this.globalHistories[user.id].monthly_messages[month] += history.monthly_messages[month];
          })
        }
      })
    }

    return this.globalHistories[user.id];
  }

  interactionHistoryForMailboxId(id) {
    return this.interaction_histories.find(history => history.mailbox_id === id);
  }

  interactionHistoryForUser(user) {
    if (user.scopeFor('contacts') === 'personal') {
      return this.interactionHistoryForMailboxId(user.primaryMailboxId())
    } else {
      return this.globalInteractionHistory(user);
    }
  }

  privateInteractorCount() {
    return this.private_interactor_ids.length;
  }
};