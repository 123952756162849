import React from 'react';
import pluralize from 'pluralize';
import moment from 'moment';

const Stats = ({ milestone }) => {
	let data = [];
	if (milestone.due) {
		data.push(<span key={milestone.id + '.stats.due-date'} className="due-date">{moment(milestone.due).format('dddd, MMMM D')}</span>);
	} else {
		data.push(<span key={milestone.id + '.stats.due-date'} className="no-due-date">No due date</span>);
	}
	data.push(<span key={milestone.id + '.stats.incomplete-count'} className="incomplete-task-count">{pluralize('incomplete task', milestone.incompleteTaskCount(), true)}</span>)

	return data;
}

export default Stats