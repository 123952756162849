import useFormInput from './useFormInput'

export const PasswordInput = (props) => {
	const formInput = useFormInput(props)

	return (
		<div>
			<input type="password"
				{...props}
				id={props.name}
				onChange={(e) => formInput.handleChange(props.name, e)}
				className={formInput.classes()}
			/>
		</div>
	)
}
