import React from 'react';
import { useAuth } from '../../lib/useAuth';
import { formattedRelativeDate } from '../../lib/Utilities';
import Avatar from '../contacts/contact/Avatar';

const List = (props) => {
	const auth = useAuth()

	if (props.record.comments.length === 0) { return null; }

	return (
		<div className="comments">
			{props.record.comments.map(comment => {
				let user = auth.account.getUserById(comment.user_id);
				return (
					<div className="comment-wrapper" key={comment.id}>
						<Avatar contact={user} key={`avatar.${user.id}`} />
						<div className="comment">
							<div className="poster" key="poster">
								Posted by <strong>{user.displayName()}</strong> {formattedRelativeDate(comment.created_at, Date.now(), true)}
							</div>
							<div className="text" key="text">
								{comment.text}
							</div>
						</div>
					</div>
				);
			})}
		</div>
	)
}

export default List


/*
<div className="comment">
	<div className="poster">
		<span className="date">{formattedRelativeDate(comment.created_at)}</span>
		<strong>{props.account.getUserById(comment.user_id).displayName()}</strong>
	</div>
	<div className="text">{comment.text}</div>
</div>
*/