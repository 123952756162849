import React from 'react';

const SearchInput = ({ updateQuery, autoFocus }) => {
	const inputChange = (e) => {
		updateQuery(e.target.value)
	}

	return (
		<input type="text" onChange={inputChange} autoFocus={autoFocus} />
	)
}

export default SearchInput