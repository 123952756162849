import { useState, useEffect } from 'react'
import * as Repositories from '../repositories'
import { useLocation } from 'react-router-dom'

const useCollectionLoader = (repositoryClass, defaultFilters, onCompletion) => {
	const [loading, setLoading] = useState(true)
	const [loaded, setLoaded] = useState(false)
	const [category, setCategory] = useState()
	const [collection, setCollection] = useState(null)
	const [sliceKey, setSliceKey] = useState(null)
	const [nextFilters, setNextFilters] = useState({})
	const repository = new Repositories[repositoryClass]
	const location = useLocation()

	useEffect(() => {
		setSliceKey(getSliceKeyFromPath(location.pathname))
	}, [location])

	useEffect(() => {
		loadCollection()
	}, [sliceKey])

	const getCategoryFilter = () => {
		return (category && category !== 'undefined' ? { category: category } : {})
	}

	const sliceFilter = () => {
		return (sliceKey ? { slice: sliceKey } : {});
	}

	const loadCollection = () => {
		let params = Object.assign({}, location.query, defaultFilters, sliceFilter())
		setLoading(true)

		repository.getCollection(params).then((collection) => {
			setLoading(false)
			setLoaded(true)
			setCollection(collection)

			if (onCompletion) { onCompletion() }
		});
	}

	const filter = async (filters) => {
		if (filters && filters.slice === null) { delete filters.slice }
//		delete filters.category

		let nextFilters = (sliceKey ? Object.assign({ slice: sliceKey }, filters/*, getCategoryFilter()*/) : Object.assign(filters, getCategoryFilter()));

		setLoading(true)
		setNextFilters(nextFilters)

		let collection = await repository.asyncGetCollection(nextFilters)

		setLoading(false)
		setLoaded(true)
		setCollection(collection)
		setNextFilters({})

		return collection
	}

	const getSliceKeyFromPath = (path) => {
		if (repository.sliceKeys().length === 0) { return null; }

		let match = path.match(new RegExp(`/(files|email|contacts|email/mailing-lists|tasks|organizations)/(${repository.sliceKeys().join('|')})`));
		return match ? match[2] : null
	}

	return [collection, filter, loading ? {
		slice: (sliceKey ? { key: sliceKey, ...repository.sliceFilters[sliceKey] } : null),
		loading: true
	} : { loading: false }]
}

export default useCollectionLoader