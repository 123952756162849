import { useState, useEffect } from 'react';
import Loading from '../common/Loading';
import ScopedFilterForm from './ScopedFilterForm'
import Table from './Table'
import Pagination from '../common/Pagination';
import NoResults from '../common//NoResults';
import useInPlaceFilter from '../../lib/useInPlaceFilter'

const fileFilters = {
	query: (file, query) => {
		if (query === '') {
			return true;
		} else {
			return (file.name || '').toLowerCase().includes(query.toLowerCase()) || (file.file_name || '').toLowerCase().includes(query.toLowerCase());
		}
	},
	type: (file, type) => { return file.typeCategory() === (type.slice(-1) === 's' ? type.slice(0, -1) : type) },
	sender: (file, email_address) => {
		return file.sender_email_addresses.includes(email_address)
	}
}

const ScopedFiles = (props) => {
	const parentRecord = props.contact || props.organization
	const [processingSearch, setProcessingSearch] = useState(false)
	const [collection, collectionFilters] = useInPlaceFilter(parentRecord, 'FileRepository', fileFilters)

	const table = () => {
		if (collection.records.length > 0) {
			return <Table files={collection.records} />
		} else if (collectionFilters.activeFilters === []) {
			return <NoResults message={`You have not exchanged any files with ${parentRecord.displayName()}`} />;
		} else {
			return <NoResults message="There are no files matching your filter criteria" />;
		}
	}

	if (collection) {
		return (
			<div className="panel">
				<ScopedFilterForm activeFilters={collectionFilters.activeFilters} processingFilters={collectionFilters.processingFilters} toggleFilter={collectionFilters.toggleFilter} search={collectionFilters.search} />
				<div>
					{table()}
					<Pagination collection={collection} filter={/*this.filter*/() => {}} maintainUrl={true} />
				</div>
			</div>
		)
	} else {
		return <Loading inline={true} />
	}
}

export default ScopedFiles