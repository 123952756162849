import { useState, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import Resource from './Resource';
import * as Repositories from '../repositories';

export const useResourceLoader = (repositoryClass, lookup = null) => {
	const [loading, setLoading] = useState(true)
	const [loaded, setLoaded] = useState(false)
	const [updating, setUpdating] = useState(false)
	const [record, setRecord] = useState(null)
	const location = useLocation()
	const { id } = useParams()
	const repository = new Repositories[repositoryClass]()

	useEffect(() => {
		if (id) {
			loadResource()
		}
	}, [id])

	const loadResource = async (props, forceLoad) => {
		var load = null;
//		if (!forceLoad && (props.resource || (props.location && props.location.state && props.location.state['resource'] instanceof Resource))) {
	if (!forceLoad && (location && location.state && location.state['resource'] instanceof Resource)) {
			load = Promise.resolve(location.data['resource']);
		} else {
			if (lookup) {
				load = lookup(repository, props.params);
			} else {
				load = await repository.find(id);
			}
		}

		setRecord(load)
	}

	const updateResource = (newRecord) => {
		setRecord(newRecord)
	}

	return record
}