import React from 'react';

const Numbers = ({ contact }) => {
	if (!contact.numbers) { return null; }

	return (
		<div className="numbers">
			{contact.numbers.map(function(number) {
				return (
					<div className="phone-number">
						{number.value}
						<span className="etc inline">{number.kind}</span>
					</div>
				);
			})}
		</div>
	)
}

export default Numbers