import { useAuth } from '../../../lib/useAuth'
import { Link } from 'react-router-dom'
import Avatar from './Avatar'
import Employments from './Employments'

const Widget = ({ contact }) => {
	const auth = useAuth()

	const more = () => {
		if (contact.hasBackground() || contact.hasEmployment()) {
			return (
				<div className="more">
					<Employments contact={contact} />
					{contact.hasBackground() ? <div className="background">{contact.background}</div> : null}
				</div>
			);
		}
	}

	return (
		<div key={contact.id} className="link-exposer contact-widget">
			<Link className="contact-heading" to={"/contacts/" + contact.id}>
				<div className="avatar-container">
					<Avatar contact={contact} inline={true} />
				</div>
				<div className="name">
					<strong>{contact.name}</strong>
					<div className="etc">{contact.primaryEmailAddress()}</div>
				</div>
			</Link>
			<div className="contact-stats">
				<div className="stat"><i className="fal fa-envelope" /> {contact.interactionHistoryForMailboxId(auth.user.primaryMailboxId()).thread_count || 0}</div>
				<div className="stat"><i className="fal fa-file-alt" /> {contact.interactionHistoryForMailboxId(auth.user.primaryMailboxId()).file_count || 0}</div>
				<div className="stat"><i className="fal fa-check-circle" /> {contact.task_count || 0}</div>
			</div>
			{more()}
		</div>
	)
}

export default Widget
