import { useState } from 'react'
import Row from './Row'

const ListByDate = ({ collection }) => {
	const [counter, setCounter] = useState(0)

	const specs = {
		overdue: { category: 'urgent', name: 'Overdue', icon: 'exclamation-circle' },
		today: { category: 'urgent', name: 'Due today', icon: 'clock' },
		tomorrow: { category: 'upcoming', name: 'Due tomorrow', icon: 'coffee' },
		laterThisWeek: { category: 'later', name: 'Due later this week', icon: 'ellipsis-h' },
		nextWeek: { category: 'later', name: 'Due next week', icon: 'calendar-alt' },
		later: { category: 'later', name: 'Due later (or no due date)', icon: 'circle' },
	}

	const toggler = (task) => {
		collection.toggleTaskCompletion(task);
		setCounter(counter + 1)
	}

	return (
		<div>
			{Object.keys(specs).map(key => {
				let tasks = collection.tasksDue(key);
				if (tasks.length === 0) { return null; }

				return (
					<div key={key} className={["new-task-list task-group", key, specs[key].category].join(' ')}>
						<h2><i className={"fa fa-" + specs[key].icon} />{specs[key].name}</h2>
						{collection.tasksDue(key).map(task => {
							return <Row key={task.id} task={task} toggler={toggler} showAssociated={true} />;
						})}
					</div>
				);
			})}
		</div>
	)
}

export default ListByDate