import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import ApiError from '../ApiError'
import FormContext from '../../lib/FormContext'
import { changes, getRepositoryFor } from '../../lib/Utilities'

export const Form = (props) => {
	const [valid, setValid] = useState(null)
	const [objectValid, setObjectValid] = useState(null)
	const [changedFields, setChangedFields] = useState([])
	const [submitting, setSubmitting] = useState(null)
	const [originalAttributes, setOriginalAttributes] = useState({})
	const [formFields, setFormFields] = useState([])
	const [counter, setCounter] = useState(1)
	const navigate = useNavigate()

	useEffect(() => {
		setOriginalAttributes(props.object.asJson)
		setObjectValid(props.object.valid())
	}, [])

	useEffect(() => {
		setObjectValid(props.object.valid())
	}, [props])

	const getRepository = () => {
		return props.repository || getRepositoryFor(props.object)
	}

	const registerFormField = (name) => {
		if (!formFields.includes(name)) {
			setFormFields(formFields.concat(name))
		}
	}

	const getFormFields = () => {
		return formFields;
	}

	const valueFor = (name) => {
		if (name !== undefined) {
			if (name.indexOf('.') > -1) {
				var association;
				var parent = props.object;
				var levels = name.split('.')

				for (var i = 0; i < (levels.length - 1); i++) {
					let manyAssociationCheck = levels[i].match(/(.*)\[(.*)\]/)
					if (manyAssociationCheck) {
						association = props.object.getOrBuildAssociation(manyAssociationCheck[1], manyAssociationCheck[2]);
					} else {
						association = parent[levels[i]];
					}

					parent = association;
				}

				if (association) {
					return association.get(levels[levels.length - 1]);
				}
			} else {
				return props.object[name];
			}
		}
	}

	const getUpdates = () => {
		return changes(
			Object.values(props.object.asJson())[0],
			Object.values(originalAttributes),
			props.object.identifiableByFields().concat(getFormFields())
		);
	}

	const inputChangeHandler = (name, e) => {
		let value = (e && e.target ? e.target.value : e);
		let parts = name.split('.');

		if (parts.length > 1) {
			let i = 0;
			let parent = props.object;

			while (i < (parts.length - 1)) {
				let manyAssociationCheck = parts[i].match(/(.*)\[(.*)\]/)
				if (manyAssociationCheck) {
					let association = manyAssociationCheck[1];
					let key = manyAssociationCheck[2];
					parent = props.object.getOrBuildAssociation(association, key);
				} else {
					if (!parent.get(parts[i])) {
						parent.getOrBuildAssociation(parts[i])
					}

					parent = parent.get(parts[i]);
				}

				i++;
			}

			parent.set(parts[parts.length - 1], value)
		} else {
			props.object.set(name, value);
		}

		var previousValidity = objectValid;

		if (!changedFields.includes(name)) { setChangedFields(changedFields.concat(name)) }

		setObjectValid(props.object.valid())

		if (props.object.valid() !== previousValidity) {
			validityChange();
		}
	}

	const forceUpdate = () => {
		setCounter(counter + 1)
	}

	const validityChange = () => {
		if (props.onValidityChange) {
			props.onValidityChange(props.object);
		}
	}

	const submitHandler = (e) => {
		e.preventDefault();

		if (props.delete) {
			doDelete()
		} else {
			save();
		}
	}

	const doDelete = () => {
		setSubmitting(true)

		getRepository().delete(props.object).then(res => {
			if (props.onDelete) {
				props.onDelete();
			}
		});
	}

	const save = () => {
		let object = props.object;

		object.recordAttemptedSave();
		if (object.invalid()) {
			setValid(false)

			return false;
		}

		if (props.beforeSubmit) {
			let beforeSubmitResponse = props.beforeSubmit(object);

			if (typeof beforeSubmitResponse === 'object'
				&& beforeSubmitResponse.constructor.modelName === object.constructor.modelName) {
				object = beforeSubmitResponse;
			}
		}

		setSubmitting(true)

		let saved = getRepository().save(object, props.saveUrl, getUpdates(), props.metaData).then(object => {
			setSubmitting(false)

			if (props.onSave) {
				props.onSave(object);
			}

			if (props.redirectOnSave) {
				if (props.redirectOnSave === true) {
					if (props.redirectUrl) {
						navigate({ pathname: props.redirectUrl, state: { resource: object } });
					} else {
						navigate({ pathname: object.getPath(), state: { resource: object } });
					}
				} else {
					navigate(props.redirectOnSave);
				}
			}
		});

		saved.catch(error => {
			setSubmitting(false)

			if (error instanceof ApiError) {
				object.setResponseError(error);
				forceUpdate()
			}

			if (props.onError) { props.onError(error, object) }
		});
	}

	return (
		<FormContext.Provider value={{
			object: props.object,
			registerFormField: registerFormField,
			inputChangeHandler: inputChangeHandler,
			valueFor: valueFor,
			submitting: submitting
		}}>
			<form onSubmit={submitHandler} className={props.className}>
				{props.children}
			</form>
		</FormContext.Provider>
	)
}

/*

getChildContext = () => {
	return {
		object: this.props.object,
		registerFormField: this.registerFormField,
		inputChangeHandler: this.inputChangeHandler,
		valueFor: this.valueFor,
		submitting: this.state.submitting
	}
}
*/