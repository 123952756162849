import { useEffect, useContext } from 'react'
import FormContext from '../FormContext'

export const Submit = (props) => {
	const context = useContext(FormContext)

	if (context.submitting) {
		return <i className="fa fa-spinner fa-spin" />;
	} else {
		return <input type="submit" className={props.className || ""} value={props.button} {...props} disabled={props.disabled} />;
	}
}