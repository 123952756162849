import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuth, useCollectionLoader } from '../../lib/hooks'
import SubscriptionColorPicker from '../mailboxes/SubscriptionColorPicker';
import MailboxSubscription from '../../resources/user/MailboxSubscription';
import { Loading } from '../common/Common';

const InitialMailboxSubscriptionPicker = ({ finalize, user }) => {
	const [subscriptions, setSubscriptions] = useState([])
	const [mailboxes, setMailboxes] = useState([])
	const [collection, filter] = useCollectionLoader('MailboxRepository', {}, () => {})

	useEffect(() => {
		if (collection) {
			setMailboxes(collection.records)
		}
	}, [collection])

	const toggle = (mailbox) => {
		if (subscriptions.find(sub => sub.mailbox_id === mailbox.id)) {
			setSubscriptions([...subscriptions.filter(sub => sub.mailbox_id !== mailbox.id)])
		} else {
			setSubscriptions([...subscriptions, new MailboxSubscription({ mailbox_id: mailbox.id })])
		}
	}

	const subscribedToMailbox = (mailbox) => {
		return !!subscriptions.find(sub => sub.mailbox_id === mailbox.id);
	}

	const updateSubscription = (subscription) => {
		let otherSubscriptions = subscriptions.filter(existing => existing.mailbox_id !== subscription.mailbox_id);

		setSubscriptions([...otherSubscriptions, ...subscription])
	}

	const subscribableMailboxes = () => {
		return (
			<>
				{mailboxes.map(mailbox => {
					return (
						<div className={subscribedToMailbox(mailbox) ? "active" : ""}>
							<div className="mailbox-picker" onClick={() => toggle(mailbox)}></div>
							<div className="mailbox-info">
								<strong>{mailbox.name}</strong>
								<div className="etc">{mailbox.email_address}</div>
								{
									subscribedToMailbox(mailbox)
									? <SubscriptionColorPicker subscription={subscriptions.find(sub => sub.mailbox_id === mailbox.id)} mailbox={mailbox} user={user} onColorSelection={updateSubscription} />
									: null
								}
							</div>
						</div>
					);
				})}
			</>
		)
	}

	const noAvailableMailboxes = () => {
		return (
			<>
				<p className="error"><strong>There are no mailboxes to subscribe to, and you do not have permission to connect your own mailbox.</strong></p>
				<p className="etc normal-size">Please check with your administrator to add mailboxes, but you can continue to view your team&#8217;s shared contacts, organizations, and emails.</p>
			</>
		)
	}

	const form = () => {
		return (
			<>
				{mailboxes && mailboxes.length > 0 ? subscribableMailboxes() : noAvailableMailboxes()}

				<div className="submit" style={{ margin: "20px 0 -10px 0" }}><span className="medium green rounded button" style={{ margin: "0 auto" }} onClick={() => finalize(subscriptions)}>Get started &rarr;</span></div>
			</>
		)
	}

	return (
		<div>
			<div className="welcome-new-user">
				<p>Choose the mailboxes you&#8217;d like to subscribe to. You&#8217;ll have access to all of their emails, files, and contacts.</p>
			</div>

			<div className="initial-mailbox-subscriptions">
				{mailboxes ? form() : <Loading inline={true} />}
			</div>
		</div>
	)
}

export default InitialMailboxSubscriptionPicker