import React, { useState, useEffect} from 'react'
import { Helmet } from 'react-helmet'
import { useResourceLoader } from '../../../lib/useResourceLoader'
import { Link } from 'react-router-dom';
import { Loading } from '../../common/Common';

const Share = () => {
	const contact = useResourceLoader('ContactRepository')

	const body = () => {
		return (
			<div>
				test
			</div>
		)
	}

	return (
		<div className="main small standalone">
			<Helmet title="Share" />
			<div className="subheading">
				<i className="fa fa-rss" />
				<h2>Share this contact</h2>
				{contact ? <Link to={`/contacts/${contact.id}`} className="close"><i className="fa fa-times" /></Link> : null}
			</div>
			<div className="main-content">
				{contact ? body() : <Loading inline />}
			</div>
		</div>
	)
}

export default Share