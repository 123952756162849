import Resource from '../lib/Resource';
import { find } from 'lodash';
import Fetcher from '../lib/Fetcher';

export default class Account extends Resource {
	multiUser() {
		return this.users.length > 1;
	}

	upToDate() {
		// hack
		return true;

//		return parseInt(this.version) >= parseInt(localStorage.accountVersion);
	}

	getOwnerOf(record) {
		return this.getUserById(record.user_id);
	}

	getUserById(id) {
		return find(this.users, function(user) { return user.id === id });
	}

	getUsersById(ids) {
		if (!ids || ids.length === 0) { return []; }

		var self = this;
		return ids.map(function(id) {
			return self.users.find(function(user) { return user.id === id });
		});
	}

	// hack
	plan() {
		return {
			multi_user: true
		}
	}

	isLive() {
		return this.status === 'live';
	}

	subscribeableMailboxes(user) {
		return []; // TODO
	}

	mailboxesNotSubscribedByUser(user) {
		return this.mailboxes.filter(mailbox => !user.mailbox_subscriptions.map(sub => sub.mailbox_id).includes(mailbox.id));
	}
}

Account.load = function(id, expand = [], callback) {
	var account = new Account();

	var fetcher = new Fetcher(localStorage.token, account);
	fetcher.get(this.url + '.json', { expand: expand }, callback);
}

Account.fields = ['id', 'billing_token', 'status', 'payment_token', 'owner', 'organization',
	{ mailboxes: ['Mailbox'] }, { users: ['User'] }, { billing_account: 'BillingAccount' }
]
//Account.hasMany = ['User'];
Account.url = '/account';
Account.modelName = 'Account';