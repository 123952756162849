import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import { useAuth, useResourceLoader, useRouter } from '../../lib/hooks'
import { Link } from 'react-router-dom';
import Fetcher from '../../lib/Fetcher';
import Form from './Form';
import { useHotkeys } from 'react-hotkeys-hook'

const Edit = () => {
	const [reclassifying, setReclassifying] = useState(false)
	const [contact, setContact] = useState(null)
	const resource = useResourceLoader('ContactRepository')
	const auth = useAuth()
	const router = useRouter()
	const navigate = useNavigate()

	useEffect(() => {
		if (resource) setContact(resource)
	}, [resource])

	const goBack = () => {
		if (router) {
			navigate(`/contacts/${router.query.id}`)
		}
	}
	useHotkeys('esc', () => navigate(`/contacts/${router.query.id}`))

	const privacyNote = () => {
		if (auth.account.multiUser()) {
			let explanation;
			if (contact.global) {
				explanation = <span>This is a <strong>global contact</strong>.  You can choose to mark any information you add as private; otherwise, it will be available to your colleagues.</span>;
			} else {
				explanation = <span>This is a <strong>private contact</strong>.  Nobody else will see the information you&#8217;re adding or editing.</span>;
			}

			return (
				<div className="privacy-note">
					<i className={"fal fa-" + (contact.type === 'global' ? 'globe' : 'lock')} />
					{explanation}
				</div>
			)
		}
	}

	const markAsMailingList = () => {
		this.setState({ reclassifying: true }, () => {
			new Fetcher(localStorage.token).process('POST', `/contacts/${contact.id}/reclassify`).then(() => {
				navigate(`/mailing-lists/${contact.id}`);
			})
		})
	}

	return (
		<div className="main small standalone">
			{contact ? <Helmet title={`Edit ${contact.displayName()}`} /> : null}
			<div className="subheading">
				<i className="fa fa-pencil" />
				<h2>Edit this contact</h2>
				{/*
					this.state.reclassifying
					?  <i className="fa fa-spinner fa-spin" style={{ lineHeight: "34px" }} />
					: <span className="inline action white" style={{ marginRight: "10px" }} onClick={this.markAsMailingList}><i className="fa fa-mail-bulk" />Mark as mailing list</span>
				*/}
				{contact ? <Link to={'/contacts/' + contact.id} className="close"><i className="fa fa-times" /></Link> : null}
			</div>
			{contact ? privacyNote() : null}
			<div className="main-content">
				<div className="main-form gray full-width new-form standalone">
					{contact ? <Form contact={contact} router={router} /> : null}
				</div>
			</div>
		</div>
	)
}

export default Edit