import { createContext } from 'react';

const FormContext = createContext({
	object: null,
	inputChangeHandler: () => {},
	submitHandler: () => {},
	registerFormField: () => {},
	valueFor: () => {},
	submitting: false
});

export default FormContext