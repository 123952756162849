import { useState } from 'react';
import Form from './Form';
import { useLocation } from 'react-router-dom';
import Task from '../../resources/Task';

const New = (props) => {
	const location = useLocation()
	const [saved, setSaved] = useState(false)

/*
	let onSave = (task) => {
		setSaved(true).then(() => {
			let onSave = props.onSave || (context.router.location && context.router.location.state && context.router.location.state.onSave);
			if (onSave) {
				onSave(task);
			}
		});
	}
*/
	const savedMessage = (
		<div className="task-created">
			<i className="fal fa-check-circle" />
			<h5>You&#8217;ve added this task!</h5>
			<div>
				{props.hide ? <><span className="gray pseudobutton" onClick={props.hide}>close</span> or </> : null}<span className="gray pseudobutton" onClick={() => setSaved(false)}>add another task</span>
			</div>
		</div>
	)

	return (
		<div className="main mini headingless">
			{saved ? savedMessage : <Form record={props.record} defaultParams={location.state && location.state.taskParams ? location.state.taskParams : {}} />}
		</div>
	);
}

export default New;