import { useState, useEffect, useRef } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { Loading } from '../common/Common'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PDFPreview = ({ file }) => {
	const [loaded, setLoaded] = useState(false)
	const [numPages, setNumPages] = useState(null)
	const [pageNumber, setPageNumber] = useState(1)
	const [windowWidth, setWindowWidth] = useState(null)
	const pdfWrapper = useRef(null)

	useEffect(() => {
		const handleResize = () => setWindowWidth(window.innerWidth)

		window.addEventListener('resize', handleResize)

		return () => window.removeEventListener('resize')
	}, [])

	const onDocumentLoadSuccess = ({ numPages }) => {
		setLoaded(true)
		setNumPages(numPages)
	}

	const goToPreviousPage = () => {
		if (pageNumber != 1) {
			setPageNumber(pageNumber - 1)
		}
	}

	const goToNextPage = () => {
		if (pageNumber != numPages) {
			setPageNumber(pageNumber + 1)
		}
	}

	const paginator = () => {
		return (
			<div className="paginator">
				<i className={"fa fa-chevron-left previous" + (pageNumber == 1 ? " inactive" : "")} onClick={goToPreviousPage} />
				<span>Page {pageNumber} of {numPages}</span>
				<i className={"fa fa-chevron-right next" + (pageNumber == numPages ? " inactive" : "")} onClick={goToNextPage} />
			</div>
		)
	}

	const width = pdfWrapper && pdfWrapper.current ? Math.min(pdfWrapper.current.offsetWidth - 40, 1200) : 800
	return (
		<div className="pdf">
			<div className="pdf-wrapper" ref={pdfWrapper}>
				{numPages > 1 ? paginator() : null}

				<Document file={file.downloadUrl()} onLoadSuccess={onDocumentLoadSuccess} loading={<Loading inline />}>
					<Page pageNumber={pageNumber} width={width} />
				</Document>
			</div>
		</div>
	)
}

export default PDFPreview