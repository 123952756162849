import Repository from '../lib/Repository';
import Organization from '../resources/Organization';

export default class OrganizationRepository extends Repository {
	entityClass = Organization;
	baseUrl = '/organizations';
	sliceFilters = {
		pinned: { icon: 'thumbtack', name: 'Pinned organizations', color: '#ccc' },
		latest: { icon: 'comment', name: 'Organizations you&#8217;ve interacted with in the last month', color: '#ccc' },
		lapsed: { icon: 'unlink', name: 'Organizations you haven&#8217;t spoken to recently', color: '#ccc' },
		tasked: { icon: 'check-circle', name: 'Organizations with oustanding tasks', color: '#ccc' },
		enriched: { icon: 'plug', name: 'Organizations with enriched data', color: '#ccc' }
	}
}