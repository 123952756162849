import React from 'react'
import { Link } from 'react-router-dom'

const Employments = ({ contact }) => {
	if (!contact.employments) { return null }

	return (
		<div className="employments">
			{contact.employments.map(function(employment) {
				let display
				if (employment.hasTitle() && employment.hasOrganization()) {
					display = <span>{employment.title} at <Link to={`/organizations/${employment.organization.id}`}>{employment.organization.name}</Link></span>
				} else if (employment.hasTitle()) {
					display = employment.title
				} else {
					display = <Link to={`/organizations/${employment.organization.id}`}>{employment.organization.name}</Link>
				}

				return (
					<div key={`employment.${employment.id}`} className="employment">
						{display}
					</div>
				)
			})}
		</div>
	)
}

export default Employments