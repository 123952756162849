import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../lib/hooks'

const CommentNotification = (props) => {
	const auth = useAuth()
	const [truncate, setTruncate] = useState(true)

	const commentText = () => {
		let maxLength = 120;

		if (props.notification.payload.comment.text.length > maxLength && truncate) {
			return (
				<div className="comment-text">
					{props.notification.payload.comment.text.substring(0, maxLength).trimRight()}&hellip;
					<i className="fas fa-chevron-right expand-truncated" onClick={() => setTruncate(false)} />
				</div>
			);
		} else {
			return (
				<div className="comment-text">
					{props.notification.payload.comment.text}
				</div>
			);
		}
	}

	const payload = props.notification.payload;

	return (
		<>
			<div className="icon"><i className="fal fa-comment" /></div>
			<div className="body">
				<h4><strong>{auth.account.getUserById(props.notification.prompting_user_id).displayName()}</strong> commented on <strong><Link to={`/${payload.record_type}s/${payload.id}`}>{payload.name || payload.file_name}</Link></strong>.</h4>
				{commentText()}
			</div>
		</>
	)
}

export default CommentNotification