import { useEffect } from 'react'
import useFormInput from './useFormInput'

export const BooleanToggle = (props) => {
	const formInput = useFormInput(props)

	useEffect(() => {

	})

	const turnOn = () => {
		formInput.handleChange(props.name, true);
	}

	const turnOff = () => {
		formInput.handleChange(props.name, false);
	}

	var defaultValue = formInput.valueFor(props.name);
	if ((defaultValue === null || defaultValue === undefined) && props.defaultValue) { defaultValue = props.defaultValue; }

	return (
		<div className="toggler">
			<span className={"on" + (defaultValue ? " active" : "")} onClick={turnOn}>Yes</span>
			<span className={"off" + (defaultValue ? "" : " active")} onClick={turnOff}>No</span>
		</div>
	)
}