import { useState, useEffect } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { useResourceLoader, useAuth, useRouter } from '../../lib/hooks'
import FileRepository from '../../repositories/FileRepository';
import { Form } from '../../lib/forms/Form';
import { TextArea } from '../../lib/forms/TextArea';
import { TextInput } from '../../lib/forms/TextInput';
import { useHotkeys } from 'react-hotkeys-hook'

const Edit = () => {
	const [file, setFile] = useState(null)
	const resource = useResourceLoader('FileRepository')
	const location = useLocation()
	const auth = useAuth()
	const navigate = useNavigate()
	const router = useRouter()

	const goBack = () => {
		if (router) {
			navigate(`/organizations/${router.query.id}`)
		}
	}
	useHotkeys('esc', () => navigate(`/files/${router.query.id}`))

	useEffect(() => {
		if (resource) setFile(resource)
	}, [resource])

	const form = () => {
		return (
			<Form repository={new FileRepository()} object={file} redirectOnSave={true} router={router}>
				<div className="options">
					<div className="option title">
						<TextInput name="name" className="large full" placeholder="File name" defaultValue={file.file_name} autoFocus />
					</div>
					<div className="option with-icon">
						<i className="fa fa-info option-icon" />
						<TextArea name="description" className="full four-lines" placeholder="More information about this file" />
					</div>
				</div>
				<div className="submit">
					<input type="submit" value="Save this File" className="rounded medium green" />
				</div>
			</Form>
		);
	}
	return (
		<div className="main mini headingless">
			<div className="subheading gradient">
				<i className="fa fa-pencil" />
				<h2>Edit this file</h2>
			</div>
			<div className="main-content">
				<div className="main-form gray full-width new-form standalone">
					{file ? form() : null}
				</div>
			</div>
		</div>
	)
}

export default Edit


