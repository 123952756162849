import React from 'react';
import { Link } from 'react-router-dom';

const ContactList = ({ organization }) => {
	return (
		<div>
			{organization.getSortedContacts().map(contact => {
				return (
					<Link key={contact.id} className="org-contact" to={`/contacts/${contact.id}`}>
						<strong>{contact.name}</strong>
						<div>{contact.title}</div>
						<div className="etc">{contact.email_address}</div>
					</Link>
				);
			})}
		</div>
	)
}

export default ContactList