import React, { useState, useEffect } from 'react';

const Header = (props) => {
	const [showFilter, setShowFilter] = useState(null)

	useEffect(() => {
		setShowFilter(props.collection && props.collection.isFiltered())
	}, [props])

	const toggleFilter = () => {
		setShowFilter(!showFilter)
	}

	const filterFromChildren = (children) => {
		let filter = children.find(child => child.type.displayName === 'HeaderFilter');
		if (filter && props.collection) {
			return React.cloneElement(children.find(child => child.type.displayName === 'HeaderFilter'), { key: `filterWrapper.${props.collection.slice_key}`, collection: props.collection, visible: showFilter, children: filter.props.children })
		}
	}
	let children = React.Children.toArray(props.children);

	let actions = children.find(child => child.type && child.type.displayName === 'HeaderActions');
	if (actions) {
		if (actions.props.includeFilterToggle) {
			actions = React.cloneElement(actions, { toggleFilter: toggleFilter, showFilter: showFilter });
		}
	}

	let icon = children.find(child => child.type && child.type.displayName === 'PageIcon');
	if (icon) {
		if (props.breadcrumb) {
			icon = React.cloneElement(icon, { withBreadcrumb: true });
		}
	}

	let tabs = children.find(child => child.type && child.type.displayName === 'HeaderSearch');
	if (tabs) {
		tabs = React.cloneElement(tabs, props.getCategory ? { setCategory: props.setCategory, category: props.getCategory() } : {})
	}

	return (
		<div id="header" className={props.classNames || ""}>
			<div>
				{children.find(child => child.type && child.type.displayName === 'Avatar') || icon}
				<h1>
					{props.breadcrumb ? <div className="breadcrumb">{props.breadcrumb}</div> : null}
					{props.title}
				</h1>
				{props.append}
				{actions}
				{tabs}
				{children.find(child => child.type && child.type.displayName === 'HeaderTabs')}
				{children.find(child => child.type && child.type.displayName === 'HeaderStats')}
			</div>
			{filterFromChildren(children)}
		</div>
	)
}

export default Header