import { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import { useToggle } from '../../lib/useToggle'
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Loading } from '../common/Common'
import { ProgressDonut } from '../../lib/Utilities.js';
import Row from '../tasks/Row';
import NewTaskForm from '../tasks/InlineForm';
import OrderedList from '../tasks/OrderedList';
import moment from 'moment';
import pluralize from 'pluralize';
import { Header, PageIcon, HeaderActions, HeaderAction } from '../common/header';
import { useResourceLoader } from '../../lib/useResourceLoader'
import { useHotkeys } from 'react-hotkeys-hook'

const View = (props) => {
	const [showCompleted, setShowCompleted] = useState(false)
	const location = useLocation()
	const navigate = useNavigate()

	useHotkeys('p', () => { props.toggle('pin') })
	useHotkeys('e', () => { navigate(`/milestones/${props.milestone.id}/edit`) })

	const noResults = () => {
		return (
			<div className="no-results">
				<i className="fal fa-circle" />
				<h2>You don&#8217;t have any tasks to complete.</h2>
				<p>Great work!  You can add more tasks above if you&#8217;d like&mdash;or just take a breather.</p>
			</div>
		);
	}

	const description = () => {
		if (!props.milestone.description) { return null; }

		return <div className="milestone-description" style={{ marginBottom: "10px" }}>{props.milestone.description}</div>;
	}

	const completedTasks = () => {
		let tasks = props.taskCollection.subset('completeTasks').tasksSortedByCompletionDate()

		if (tasks.length > 0) {
			return (
				<div className="task-group complete-tasks">
					<h2>
						<i className="fa fa-check-circle" />You have completed {pluralize('task', tasks.length, true)} in this milestone
						<div className={"show-all" + (showCompleted ? ' hidden' : '')} onClick={() => setShowCompleted(true)}><i className="fa fa-plus" />see all</div>
					</h2>
					<div className={"new-task-list" + (showCompleted ? '' : ' hidden')}>
						<TransitionGroup>
							{tasks.map(task => {
								return (
									<CSSTransition key={task.id} classNames="task-list" timeout={{ enter: 2000, exit: 700 }}>
									 <Row task={task} toggler={props.toggleTaskCompletion} hideMilestone={true} />
									</CSSTransition>
								)
							})}
						</TransitionGroup>
					</div>
				</div>
			);
		}
	}

	return (
		<div className={"main full milestone" + (props.milestone.archived ? " archived" : "")}>
			<Helmet title={props.milestone.name} />
			<Header
				breadcrumb={<><strong>Milestone</strong>{props.milestone.due ? <span className="due-date">{moment(props.milestone.due).format('dddd, MMMM D')}</span> : null}</>}
				title={props.milestone.name}
			>
				<PageIcon><ProgressDonut percentage={props.milestone.progress()} /></PageIcon>
				<HeaderActions>
					<HeaderAction onClick={() => props.toggle('pin')} icon="thumbtack" className={props.milestone.pinned ? "pinned" : ""} title={props.milestone.pinned ? "Unpin this milestone" : "Pin this milestone"} />
					<HeaderAction icon="pencil" title="Edit this milestone" linkTo={{ pathname: "/milestones/" + props.milestone.id + "/edit", state: { resource: props.milestone, modal: true, returnTo: location.pathname } }} />
					{
						props.milestone.archived
						? <HeaderAction icon="undo" className="on" title="Un-archive this milestone" onClick={() => props.toggleArchive()} />
						: <HeaderAction icon="lock-alt" className="red" title="Archive this milestone" onClick={() => props.toggleArchive()} />
					}
				</HeaderActions>
			</Header>
			<div className="main-content milestone-list">
				{description()}
				<NewTaskForm milestone={props.milestone} onSave={props.addToMilestone} />
				{props.taskCollection.incompleteTasks().length > 0
					? <OrderedList collection={props.taskCollection.subset('incompleteTasks')} toggler={props.toggleTaskCompletion} showAssociated={true} hideMilestones={true} />
					: noResults()
				}
				{completedTasks()}
			</div>
		</div>
	)

}

export default View