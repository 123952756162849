import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../lib/hooks'
import MailboxSubscriptionRepository from '../../../repositories/MailboxSubscriptionRepository';
import MailboxSubscription from '../../../resources/user/MailboxSubscription';
import SubscriptionColorPicker from '../../mailboxes/SubscriptionColorPicker';
import pluralize from 'pluralize';

const Row = (props) => {
	const [subscriptionColor, setSubscriptionColor] = useState(null)
	const auth = useAuth()

	useEffect(() => {
		setSubscriptionColor(subscription() ? subscription().color : null)
	}, [])

	const isPersonal = () => {
		return auth.user.email_address === props.mailbox.email_address;
	}

	const mailboxIcon = () => {
		if (props.mailbox.isPrimaryFor(auth.user)) {
			return <i className="fa fa-badge-check primary type" title="Primary mailbox" />;
		} else if (props.mailbox.shared) {
			return <i className="fa fa-rss shared type" title="Shared mailbox" />;
		} else {
			return <i className="fa fa-lock private type" title="Private mailbox" />;
		}
	}

	const subscription = () => {
		if (props.subscribed) {
			return props.subscription
		}
	}

	const status = () => {
		if (!props.mailbox.authorized) {
			return <strong className="sync-state">Not connected</strong>;
//		} else { /* TODO: show subscriber count if we want to */
//			return <strong className="sync-state">{pluralize('subscriber', props.mailbox.subscription_count, true)}</strong>;
		}
	}

	const actions = () => {
		if (isPersonal()) {
			return <div key="actions"></div>;
		} else if (!props.mailbox.authorized) {
			return <span key="connect" className="inline white action" onClick={props.showConnection.bind(null, props.mailbox)}><i className="fal fa-plug" />Connect</span>
		} else {
			return (
				<span key="actions" className="inline white action" onClick={() => props.toggleSubscription(props.mailbox)}>
					{
						props.subscribed
						? <><i className="fal fa-times" /> Unsubscribe</>
						: <><i className="fal fa-check-circle" /> Subscribe</>
					}
				</span>
			)
		}
	}

	const adminActions = () => {
		if (auth.user.isAdmin()) {// && !isPersonal()) {
			let shareAction;
			if (auth.user.account.multiUser()) {
				shareAction = props.mailbox.shared
					? <Link key="lock" to={`/settings/mailboxes/${props.mailbox.id}/lock`} className="edit"><i className="fa fa-lock" /></Link>
					: <Link key="unlock" to={`/settings/mailboxes/${props.mailbox.id}/unlock`} className="green"><i className="fa fa-rss" /></Link>
			}


			return (
				<div key="admin-actions" className="admin-actions">
					{shareAction}
					<Link key="delete" to={`/settings/mailboxes/${props.mailbox.id}/delete`} className="delete"><i className="fa fa-trash" /></Link>
				</div>
			);
		} else { return null; }
	}

	return (
		<div className={"mailbox-row" + (isPersonal() ? " personal" : "") + (props.subscribed && props.mailbox.authorized ? " subscribed" : "")}>
			{mailboxIcon()}
			<div className="name">
				<strong>{props.mailbox.name}</strong>
				<div className="etc normal-size" style={{ color: "#777" }}>{props.mailbox.email_address}</div>
				<div className="status">{status()}</div>
			</div>
			{(subscription() && !isPersonal() ? <SubscriptionColorPicker subscription={subscription()} user={auth.user} onColorSelection={(subscription) => new MailboxSubscriptionRepository(auth.user).save(subscription)} /> : null)}
			<div className="actions">
				{actions()}
				{adminActions()}
			</div>
		</div>
	)
}

export default Row