import { useState } from 'react';
import { Helmet } from 'react-helmet';
import Contact from '../../resources/Contact';
import { useAuth, useRouter } from '../../lib/hooks';
import { Link } from 'react-router-dom';
import Form from './Form';

const New = () => {
	let newContact = new Contact({ locations: [], websites: [], employments: [], social_profiles: [] })
	newContact.getOrBuildAssociation('email_addresses');
	const [contact, setContact] = useState(newContact)
	const auth = useAuth()
	const router = useRouter()

	const privacyNote = () => {
		if (auth.account.multiUser()) {
			let explanation;
			if (contact.type === 'global') {
				explanation = <span>This is a <strong>global contact</strong>.  You can choose to mark any information you add as private; otherwise, it will be available to your colleagues.</span>;
			} else {
				explanation = <span>This is a <strong>private contact</strong>.  Nobody else will see the information you&#8217;re adding or editing.</span>;
			}

			return (
				<div className="privacy-note">
					<i className={"fal fa-" + (contact.type === 'global' ? 'globe' : 'lock')} />
					{explanation}
				</div>
			)
		}
	}

	return (
		<div className="main small standalone">
			<Helmet title={"Create a new contact"} />
			<div className="subheading">
				<i className="fa fa-plus" />
				<h2>Add a new contact</h2>
			</div>
			{privacyNote()}
			<div className="main-content">
				<div className="main-form gray full-width new-form standalone">
					<Form contact={contact} router={router} />
				</div>
			</div>
		</div>
	)
}

export default New