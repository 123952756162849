import React from 'react';
import _ from 'lodash';
import { Filterable } from '../../lib/Filterable';
import ContactRepository from '../../repositories/ContactRepository';
import Avatar from '../contacts/contact/Avatar';

class RecipientList extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showOptions: true,
			recipients: props.email[`${this.props.recipientType}_recipients`] || []
		}
		this.filter = this.props.filter;

		this.filter.setSearchFields(['name', 'email_address'])
		this.filter.setQueryableCollection(new ContactRepository());
		this.props.onSelect(this.selectListOption);
	}

	componentWillReceiveProps(props) {
		if (props.topContacts && props.topContacts !== [] && !this.filter.hasOptionsLoaded()) {
			this.filter.loadOptions(props.topContacts);
		}
	}

	selectListOption = (contact) => {
		let recipients = this.state.recipients;

		if (recipients.filter(recipient => { return recipient.id === contact.id && recipient.email_address === contact.email_address }).length === 0) {
			recipients.push(contact);

			let email = this.props.email;
			email.set(`${this.props.recipientType}_recipients`, recipients)
			email.set(`${this.props.recipientType}_recipient_ids`, recipients.map(recipient => recipient.id))

			this.setState({ recipients: recipients }, () => { this.props.updater(email) });
		}
	}


	matches = () => {
		if (this.props.filterMatches.length === 0) { return null; }

		return (
			<div className="content-block white" onMouseDown={this.mouseDownHandler} onMouseUp={this.mouseUpHandler}>
				<ul className="table">
					{_.map(this.props.filterMatches, (contact) => {
						let name;
						if (contact.hasName()) {
							name = <div><strong>{contact.displayName()}</strong> <div className="etc">{contact.email_address}</div></div>
						} else {
							name = <div><strong>{contact.primaryEmailAddress()}</strong></div>
						}

						return (
							<li key={"contact." + contact.id} onClick={this.selectListOption.bind(null, contact)}>
								<div className={"block pointer" + (this.props.active === contact ? " active" : "")}>
									<Avatar contact={contact} />
									{name}
								</div>
							</li>
						);
					})}
				</ul>
			</div>
		);
	}

	remove = (recipient) => {
		let recipients = this.state.recipients;
		_.remove(recipients, function(current) {
			return current === recipient;
		});

		this.setState({ recipients: recipients });
	}

	recipients = () => {
		if (this.props.email.getRecipientsOfType(this.props.recipientType).length === 0) {
			return false;
		} else {
			return (
				<div className="recipient-list">
					{_.map(this.state.recipients, (to) => {
						return (
							<div className="recipient" title={to.email_address} key={`email.${this.props.recipientType}.to.${to.email_address}`}>
								{(to.avatar_url ? <div className="tiny-avatar"><img src={to.avatar_url} alt="Contact avatar" /></div> : false)}
								<div className="avatar-container"><Avatar contact={to} inline={true} size="small" /></div>
								<span><strong>{to.name || to.email_address}</strong></span>
								<div className="remove" onClick={this.remove.bind(null, to)}><i className="fa fa-times" /></div>
							</div>
						);
					})}
				</div>
			);
		}
	}

	render() {
		return (
			<div style={{ display: "table" }}>
				{this.recipients()}
				<div className="recipient-options list-filter-container">
					<input type="text" autoFocus onFocus={() => { this.setState({ showOptions: true })}} onBlur={this.toggle} onChange={this.props.updateQuery} onKeyDown={this.props.onKeyDown} ref="filterInput" />
					{this.matches()}
				</div>
			</div>
		);
	}
}

export default Filterable(RecipientList);