export const MilestoneCollectionUtilities = {
	pinnedMilestones() {
		return this.records.filter(milestone => { return milestone.pinned && !milestone.archived })
	},

	unpinnedMilestones() {
		return this.records.filter(milestone => { return !milestone.pinned && !milestone.archived })
	},

	archivedMilestones() {
		return this.records.filter(milestone => { return milestone.archived })
	}
}