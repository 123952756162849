import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { Modalize } from '../../lib/Modalize';
import { Shareable } from '../../lib/Shareable';
import ShareSheet from '../sharing/ShareSheet';
import * as FileUtilities from '../../lib/utilities/FileUtilities';
import Preview from './Preview';
import AttachmentMessages from './AttachmentMessages';
import OrderedList from '../tasks/OrderedList.js';
import NewTaskForm from '../tasks/New';
import Commentary from '../commentary/Index';
import { Header, PageIcon, HeaderActions, HeaderAction } from '../common/header';
import { useHotkeys } from 'react-hotkeys-hook'

const SenderCell = ({ file, currentUser }) => {
	const role = file.getRoleOf(currentUser);

	const icon = () => {
		switch (role) {
			case 'sender':
				return <div className="icons"><i className="fal fa-reply" /></div>;
			case 'recipient':
				return <div className="icons"><i className="fal fa-share" /></div>;
			default:
				return <div className="icons"><i className="fal fa-share" /><i className="fal fa-reply" /></div>;
		}
	}

	const value = () => {
		let participant;
		switch (role) {
			case 'sender':
				if (file.recipient_email_addresses.length > 1) { return file.recipient_email_addresses.length + ' recipients'; }

				participant = file.getParticipantByEmailAddress(file.recipient_email_addresses[0])
				return <Link to={'/contacts/' + participant.id}>{participant.displayName()}</Link>;
			case 'recipient':
				if (file.sender_email_addresses.length > 1) { return file.sender_email_addresses.length + ' people'; }

				participant = file.getParticipantByEmailAddress(file.sender_email_addresses[0])
				if (participant) {
					return <Link to={'/contacts/' + participant.id}>{participant.displayName()}</Link>;
				} else {
					return <span>{file.getParticipantNameFromEmailAddress(file.sender_email_addresses[0])}</span>
				}
			default:
				return file.getParticipantCount() + ' people';
		}
	}

	const label = () => {
		return { sender: 'Sent to', recipient: 'Received from', both: 'Sent and received by' }[role];
	}

	return (
		<div>
			{icon()}
			<div className="info-label">{label()}</div>
			<div className="info-value"><span>{value()}</span></div>
		</div>
	)
}

const View = (props) => {
	const [file, setFile] = useState(props.file)
	const location = useLocation()
	const navigate = useNavigate()
	useHotkeys('e', () => { navigate(`/files/${props.file.id}/edit`) })
	useHotkeys('p', () => { props.toggle('pin') })

	const description = () => {
		if (file.description) {
			return (
				<div className="file description">
					{file.description}
				</div>
			);
		} else { return null; }
	}

	const download = (e) => {
		e.preventDefault();

		window.open(file.downloadUrl(), "_blank");
	}

	const toggleTask = (task) => {
		file.taskCollection().toggleTaskCompletion(task);

		setFile(file) // need to forceUpdate
	}

	const tasks = () => {
		if (file.taskCollection().tasks().length === 0) { return null; }

		return (
			<div className="file-tasks">
				<h2><i className="fa fa-check-circle" />Tasks related to this file</h2>
				<OrderedList collection={file.taskCollection()} toggler={toggleTask} location={props.location} />
			</div>
		);
	}

	const addTask = (task) => {
		let updatedFile = file;
		updatedFile.taskCollection().addTask(task);
		setFile(updatedFile)
	}

	return (
		<div className="main full view-file">
			<Helmet title={file.displayName()} />
			<Header title={file.displayName()}>
				<PageIcon icon="file-alt" />
				<HeaderActions>
					{
						props.currentUser.account.multiUser()
						? (
							<HeaderAction icon="rss" title="Share this file" className={(file.shared ? 'shared' : '')}>
								<Modalize popUnderClasses="top right">
									<ShareSheet record={file} />
								</Modalize>
							</HeaderAction>
						)
						: null
					}
					<HeaderAction icon="check-circle" title="Add a task">
						<Modalize popUnderClasses="top right">
							<NewTaskForm record={file} onSave={addTask} />
						</Modalize>
					</HeaderAction>
					<HeaderAction onClick={() => props.toggle('pin')} icon="thumbtack" className={file.pinned ? "pinned" : ""} title={file.pinned ? "Unpin this file" : "Pin this file"} />
					<HeaderAction linkTo={{ pathname: "/files/" + file.id + "/edit", state: { resource: file, modal: true, returnTo: "/files/" + file.id, backgroundLocation: location } }} icon="pencil" title="Edit this file" />
				</HeaderActions>
			</Header>
			{description()}
			<div className="record-stats-bar blue">
				<div>
					<SenderCell file={file} currentUser={props.currentUser} />
					<div>
						<div className="icons"><i className="fal fa-calendar-alt" /></div>
						<div className="info-label">{file.attachment_count > 1 ? 'Last sent on' : 'Sent on'}</div>
						<div className="info-value"><span>{moment(file.last_sent_at).format('MMMM D, YYYY')}</span></div>
					</div>
					<div>
						<div className="icons"><i className="fal fa-file" /></div>
						<div className="info-label">File type</div>
						<div className="info-value file-type"><span>{FileUtilities.fileType(file)}</span></div>
					</div>
					<div>
						<div className="icons"><i className="fal fa-hdd" /></div>
						<div className="info-label">File Size</div>
						<div className="info-value">{file.lastFileSize() ? <span>{FileUtilities.bytesToHumanSize(file.lastFileSize())}</span> : <span>&mdash;</span>}</div>
					</div>
				</div>
			</div>
			<div id="content" className="file">
				{tasks()}

				<AttachmentMessages currentUser={props.currentUser} file={file} />

				<div style={{ marginTop: "20px" }}><Preview file={file} download={download} /></div>

				<Commentary record={file} />
			</div>
		</div>
	)
}

export default View