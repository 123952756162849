import React from 'react';
import { useResourceLoader } from '../../lib/useResourceLoader'
import Loading from '../common/Loading';
import View from './View';

const ViewContainer = () => {
	const resource = useResourceLoader('MailingListRepository')

	return (
		<div>
			{resource ? <View list={resource} /> : <Loading />}
		</div>
	)
}

export default ViewContainer