import React from 'react';
import Agenda from './Agenda';
import Notifications from './Notifications';
import { useAuth } from '../../lib/useAuth'

const DashboardSidebar = (props) => {
	const auth = useAuth()

	return (
		<div id="agenda">
			{
				auth.account.multiUser() && auth.user.notification_inbox.latest_notifications.length > 0 && !window.isMobile
				? <Notifications />
				: null
			}

			<Agenda agenda={props.agenda} />
		</div>
	)
}

export default DashboardSidebar