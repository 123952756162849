import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Avatar from './contact/Avatar';
import Timeline from '../../lib/Timeline';
import Fetcher from '../../lib/Fetcher';
import { formattedRelativeDate } from '../../lib/Utilities';
import { useDefaultLens } from '../../lib/hooks'
import { useRouter } from '../../lib/useRouter';
import Table from './Table';
import { NoResults, Loading } from '../common/Common'
import Contact from '../../resources/Contact';
import StubList from './StubList';
import moment from 'moment';
import { Header, PageIcon, HeaderSearch } from '../common/header';

const TaskedContact = (props) => {
	const [toggling, setToggling] = useState(null)
	const [task, setTask] = useState(null)

	const toggleCompletion = (task) => {
		setToggling(task)

		task.toggleCompletion(task => {
			setToggling(false)
			setTask(task)
		})
	}

	return (
		<div>
			<div className="tasked-contact">
				<div className="avatar-container">
					<Avatar contact={props.contact} inline={true} />
				</div>
				<div className="name">
					<strong><Link to={`/contacts/${props.contact.id}`} className="name">{props.contact.displayName()}</Link></strong>
					{props.contact.name ? <div className="etc">{props.contact.primaryEmailAddress()}</div> : null}
				</div>
			</div>
			<div className="file-tasks task-list">
				{props.contact.tasks.map(task => {
					return (
						<div key={task.id} className={"task-row task" + (toggling === task ? " toggling" : "") + (task.isComplete() ? " complete" : "")}>
							<div className="checkbox with-circle" onClick={toggleCompletion.bind(null, task)}></div>
							<div className="task-assignment">{task.assignment}</div>
						</div>
					);
				})}
			</div>
		</div>
	)
}

const Index = () => {
	useDefaultLens('contacts')
	const [snapshot, setSnapshot] = useState(null)
	const [timeline, setTimeline] = useState(null)
	const [mobileView, setMobileView] = useState('pinned')
	const router = useRouter()
	const sections = [
		{ key: 'pinned', icon: 'thumbtack', name: 'Pinned' },
		{ key: 'tasked', icon: 'check', name: 'With Tasks' },
		{ key: 'latest', icon: 'clock', name: 'Latest' }
	]

	useEffect(() => {
		new Fetcher(localStorage.token).get('/contacts/snapshot').then(json => {
			let result = {};
			['pinned', 'latest', 'tasked'].forEach(type => { result[type] = json.contact_snapshot[type].map(attrs => new Contact(attrs)) })

			setSnapshot(result)
			setTimeline(timelineFromRecent(result.latest))
		})
	}, [])

	const timelineFromRecent = (contacts) => {
		return new Timeline(
			contacts.filter(contact => contact.interaction_histories.length > 0),
			(contact) => contact.interaction_histories[0].last_contact
		);
	}

	const pinnedContacts = () => {
		if (snapshot.pinned.length > 0) {
			return (
				<>
					<h3 className="with-icon-before pinned" style={{ marginTop: "0" }}>
						{snapshot.pinned.length > 8 ? <Link to="/contacts/pinned" className="see-all">see all</Link> : null}
						Your pinned contacts ({snapshot.pinned.length})
					</h3>
					<Table contacts={snapshot.pinned} router={router} location={router.location} />
				</>
			);
		}
	}

	const taskedContacts = () => {
		if (snapshot.tasked.length > 0) {
			return (
				<div>
					<h3 className="with-icon-before tasks">
						{snapshot.tasked.length > 8 ? <Link to="/contacts/tasked" className="see-all">see all</Link> : null}
						Contacts with incomplete tasks ({snapshot.tasked.length})
					</h3>
					<div className="tasked-contacts">
						{snapshot.tasked.map(contact => <TaskedContact key={contact.id} contact={contact} />)}
					</div>
				</div>
			);
		}
	}

	const loadedView = () => {
		return (
			<div className="structure contacts">
				<div className={"main-panel" + (mobileView !== 'snapshot' ? ' hide-on-mobile' : '')}>
					{snapshot.pinned.length + snapshot.tasked.length === 0
						? <NoResults message={<span>You don&#8217;t have any pinned contacts or contacts with outstanding tasks</span>} subMessage={<span>When you do, we&#8217;ll show them here!</span>} />
						: <>{pinnedContacts()}{taskedContacts()}</>
					}
				</div>

				<div className={"info-panel" + (mobileView !== 'latest' ? ' hide-on-mobile' : '')}>
					<div className="recent-contacts">
						<h3><i className="fal fa-history" />Recently in contact</h3>
						{Object.keys(timeline.objectsByDate()).map(date => {
							return (
								<div key={date}>
									<div className="file-timeline-date">{formattedRelativeDate(date, moment(), true, true)}</div>
									<StubList contacts={timeline.objectsByDate()[date]} />
								</div>
							);
						})}
					</div>
				</div>
			</div>
		)
	}

	const rowsInSection = (section) => {
		return <StubList contacts={snapshot[section]} />;
	}

	let search = <HeaderSearch scope="contacts" router={router} placeholder="Search by name, email, or employment" />;

	return (
		<div className="main full">
			<Helmet title="Your contacts" />
			<Header title="Manage Your Contacts">
				<PageIcon icon="users" toggleSidebarOnMobile />
				{window.isMobile ? null : search}
			</Header>
			<div className="subheader-search mobile-only">{search}</div>
			<div className="subheader-nav mobile-only">
				<ul className="sections">
					{sections.map(section => {
						return (
							<li key={section.key}>
								<span className={"console-button" + (mobileView === section.key ? ' active' : '')} onClick={() => setMobileView(section.key)}>
									<i className={"fa fa-" + section.icon} />
									{section.name}
								</span>
							</li>
						)
					})}
				</ul>
			</div>
			<div className="main-content contacts">
				{snapshot ? loadedView() : <Loading inline={true} />}
			</div>
		</div>
	)
}

export default Index