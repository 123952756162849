import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useDefaultLens, useFilterableCollection, useCollectionLoader } from '../../lib/hooks'
import NoResults from '../common/NoResults';
import Loading from '../common/Loading';
import Pagination from '../common/Pagination';
import Table from './Table';
import FileFilterForm from './FileFilterForm';
import MailboxScoper from '../common/MailboxScoper'
import { Header, PageIcon, HeaderActions, HeaderFilter } from '../common/header';

const List = () =>  {
	useDefaultLens('files')
	const [counter, setCounter] = useState(1)
	const [collection, filter, nextCollection] = useCollectionLoader('FileRepository', { scope: localStorage.scope || 'subscribed' }, () => {})
	const [sort, sortForm] = useFilterableCollection(collection, filter, {
		last_sent_at: { icon: 'calendar-alt', name: 'Last Sent' },
		name: { icon: 'font', name: 'Name' },
		attachment_count: { icon: 'sync-alt', name: 'Frequency Sent' },
		size: { icon: 'hdd', name: 'File Size' }
	})
	const location = useLocation()

	const header = () => {
		if (false /* TODO: not sure what this used to be */) {
			return (
				<h1 className="with-breadcrumb">
					<div className="breadcrumb"></div>
					<span className="page-icon"><i className={`fa fa-${collection.currentSlice().icon}`} /></span>
				</h1>
			);
		} else {
			return (
				<h1>
					<span className="page-icon"><i className="far fa-file-alt" /></span>Manage Your Files
				</h1>
			);
		}
	}

	const loaded = () => {
		if (collection.records.length > 0) {
			return <>
				<Table files={collection.records}  />
				<Pagination collection={collection} filter={filter} />
			</>
		} else {
			return <NoResults collection={collection} subMessage={<p>You can <Link to={"/files"} className="gray pseudobutton">clear filters</Link> or try a less restrictive search.</p>} />;
		}
	}

	const getDisplaySlice = () => {
		if (nextCollection.loading && nextCollection.slice) {
			return nextCollection.slice
		} else if (collection && collection.hasSlice()) {
			return collection.currentSlice()
		}
	}

	return (
		<div>
			<MailboxScoper onUpdate={(newScope) => filter({ scope: newScope })} />
			<div className="main full">
				<Helmet title={getDisplaySlice() ? getDisplaySlice().name : 'Your file list'} />
				<Header
					collection={collection}
					breadcrumb={collection && collection.hasSlice() ? 'Manage Your Files' : null}
					title={getDisplaySlice() ? <span dangerouslySetInnerHTML={{ __html: getDisplaySlice().name }}></span> : 'Manage Your Files'}
				>
					<PageIcon icon={getDisplaySlice()? getDisplaySlice().icon : "file-alt"} toggleSidebarOnMobile />
					<HeaderActions includeFilterToggle />
					<HeaderFilter>
						<FileFilterForm collection={collection} updater={filter} />
					</HeaderFilter>
				</Header>
				{collection && sortForm ? sortForm() : null}
				<div className="main-content file-list">
					{nextCollection.loading ? <Loading inline /> : loaded()}
				</div>
			</div>
		</div>
	)
}

export default List


/*
export default MailboxScoper(
	CollectionLoader(
		FilterableCollection(
			{
				last_sent_at: { icon: 'calendar-alt', name: 'Last Sent' },
				name: { icon: 'font', name: 'Name' },
				attachment_count: { icon: 'sync-alt', name: 'Frequency Sent' },
				size: { icon: 'hdd', name: 'File Size' }
			}
		), 'FileRepository'
	)
);
*/