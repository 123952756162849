import React, { useState } from 'react';
import { useAuth } from '../../lib/useAuth';
import Comment from '../../resources/Comment';
import Avatar from '../contacts/contact/Avatar';
import CommentRepository from '../../repositories/CommentRepository';
import { Form } from '../../lib/forms/Form';
import { TextArea } from '../../lib/forms/TextArea';

const New = (props) => {
	const [saving, setSaving] = useState(false)
	const [showSubmitButton, setShowSubmitButton] = useState(false)
	const [comment, setComment] = useState(new Comment())
	const auth = useAuth()

	const handleCommentBoxChange = (field, e) => {
		setShowSubmitButton(e.target.value.length > 0)
	}

	const submitArea = () => {
		return (
			<div className="submit">
				{saving ? <i className="fa fa-spin fa-spinner" /> : <input type="submit" value="Add your comment" className="medium rounded green button" />}
			</div>
		)
	}
	return (
		<div className="new-comment">
			<Avatar contact={auth.user} />
			<Form object={comment} repository={new CommentRepository(props.record)} beforeSubmit={() => setSaving(true)} onSave={(comment) => { setSaving(false); props.addCommentToList(comment) }}>
				<TextArea name="text" className="full" placeholder="Your comments or notes" autoFocus changeHandler={handleCommentBoxChange} />
				{showSubmitButton ? submitArea() : null}
			</Form>
		</div>
	)
}

export default New