import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useRouter } from '../../lib/useRouter';
import { useAuth } from '../../lib/useAuth';
import NewMailboxForm from './mailboxes/New';
import MailboxList from './mailboxes/List';
import Connection from '../mailboxes/Connection';
import MailboxRepository from '../../repositories/MailboxRepository';

const Mailboxes = () => {
	const [mailboxes, setMailboxes] = useState([])
	const [loadingMailbox, setLoadingMailbox] = useState(false)
	const [activeMailbox, setActiveMailbox] = useState(null)
	const mailboxRepository = new MailboxRepository();
	const router = useRouter()
	const auth = useAuth()

	useEffect(() => {
		loadMailboxFromOauthRedirect();
	}, [])

	const loadMailboxFromOauthRedirect = () => {
		if (router.query.state && router.query.code) {
			setLoadingMailbox(true)

			mailboxRepository.find(router.query.state).then(mailbox => {
				mailboxRepository.save(mailbox, `/mailboxes/${mailbox.id}/connect`, { oauth_code: router.query.code }).then(mailbox => {
					setLoadingMailbox(false)
					setActiveMailbox(mailbox)

					if (mailbox.integration && mailbox.integration.authorized) {
						// props.loadUser();
					}
				})
			})
		}
	}

	const showConnection = (current) => {
		mailboxRepository.find(current.id).then(mailbox => {
			setActiveMailbox(mailbox)
		})
	}

	const mainView = () => {
		if (loadingMailbox) {
			return (
				<div className="loading" style={{ marginBottom: "20px" }}>
					<i className="fa fa-spinner fa-pulse" />
				</div>
			);
		}
		if (activeMailbox) {
			if (activeMailbox.authorized) {
				return (
					<div className="mailbox-connected">
						<i className="fa fa-plug" />
						<div className="main-content">
							<h2>{activeMailbox.email_address} has been connected</h2>
							<p className="description">We&#8217;ll start importing your emails, files, and contacts right away.</p>
						</div>
					</div>
				);
			} else {
				return <div className="new-mailbox-connection-settings"><Connection mailbox={activeMailbox} /></div>;
			}
		} else {
			return <NewMailboxForm user={auth.user} showConnection={showConnection} router={router} />;
		}
	}


	return (
		<div>
			<Helmet title="Manage your mailboxes" />
			<h3><i className="fa fa-inbox-in" />Manage your mailboxes</h3>
			<p className="settings description">Connect other private or shared email accounts so you have everything in one place.</p>

			{mainView()}

			{mailboxes ? <MailboxList showConnection={showConnection} /> : null}
		</div>
	)
}

export default Mailboxes