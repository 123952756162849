import React from 'react';
import { TextInput } from '../../../../lib/forms/TextInput';
import { Select } from '../../../../lib/forms/Select';
import { CountryList } from '../../../../lib/utilities/CountryList';
import { HiddenField } from '../../../../lib/forms/HiddenField';

const LocationInput = (props) => {
	return (
		<div className="field-section">
			<HiddenField name={`locations[${props.location.lookupKey()}].id`} />
			<div className="remove">
				<i className="fal fa-times-circle" onClick={props.remove.bind(null, 'locations', props.location)} />
			</div>
			<div className="location fields">
				<table>
					<tbody>
						<tr>
							<td width="80px"><TextInput name={`locations[${props.location.lookupKey()}].street_number`} className="full centered" placeholder="Number" /></td>
							<td><TextInput name={`locations[${props.location.lookupKey()}].street`} className="full" placeholder="Street" /></td>
							<td width="150px"><TextInput name={`locations[${props.location.lookupKey()}].apartment`} className="full" placeholder="Apartment/Line 2" /></td>
						</tr>
					</tbody>
				</table>
				<table>
					<tbody>
						<tr>
							<td width="30"><TextInput name={`locations[${props.location.lookupKey()}].city`} className="full" placeholder="City" /></td>
							<td width="30%"><TextInput name={`locations[${props.location.lookupKey()}].state`} className="full" placeholder="State or province" /></td>
							<td width="40%">
								<div className="styled-select">
									<Select name={`locations[${props.location.lookupKey()}].country_code`} defaultValue="">
										<option value=""></option>
										{Object.keys(CountryList).map(code => {
											return <option key={code} value={code}>{CountryList[code]}</option>;
										})}
									</Select>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	)
}

export default LocationInput