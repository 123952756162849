import React from 'react';
import { useResourceLoader } from '../../lib/useResourceLoader';
import Loading from '../common/Loading';
import ThreadViewContainer from '../threads/ViewContainer';

const ViewContainer = () => {
	const thread = useResourceLoader('ThreadRepository', (repository, params) => repository.findByMessageId(params.messageId))

	return thread ? <ThreadViewContainer resource={thread} /> : <Loading />
}

export default ViewContainer