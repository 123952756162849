import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Table from './Table'
import { useCollectionLoader, useDefaultLens, useFilterableCollection, useAddressBookScoper } from '../../lib/hooks'
import { NoResults, Loading } from '../common/Common'
import Pagination from '../common/Pagination'
import { Header, PageIcon, HeaderActions, HeaderAction } from '../common/header'

const List = () => {
	useDefaultLens('contacts', '/contacts/list')
	const location = useLocation()
	const [loading, setLoading] = useState(false)
	const [scope, scopeUpdated, addressBookScopeTabs] = useAddressBookScoper()
	const [collection, filter, nextCollection] = useCollectionLoader('ContactRepository', { scope: scope })
	const [sort, sortForm] = useFilterableCollection(collection, filter, {
		priority: { icon: 'bolt', name: 'Priority' },
		thread_count: { icon: 'hashtag', name: 'Interactions' },
		last_contact: { icon: 'clock', name: 'Last Contact' },
	})

	useEffect(() => {
		if (collection) {
			let filterResponse = filter({ scope: scope })

			scopeUpdated()
		}
	}, [scope])

	const results = () => {
		return collection.records.length > 0
			? <div><Table contacts={collection.records} /><Pagination collection={collection} filter={filter} /></div>
			: <NoResults collection={collection} />
	}

	const getDisplaySlice = () => {
		if (nextCollection.loading && nextCollection.slice) {
			return nextCollection.slice
		} else if (collection && collection.hasSlice()) {
			return collection.currentSlice()
		}
	}

	return (
		<div>
			{addressBookScopeTabs}
			<div className="main full">
				<Helmet title={getDisplaySlice() ? getDisplaySlice().name.replace('&#8217;', "'") : 'Your contact list'} />
				<Header
					breadcrumb={getDisplaySlice() ? 'Manage Your Contacts' : null}
					title={collection && collection.hasSlice() ? <span dangerouslySetInnerHTML={{ __html: getDisplaySlice().name }}></span> : 'Manage Your Contacts'}
				>
					<PageIcon icon={getDisplaySlice() ? getDisplaySlice().icon : "users"} toggleSidebarOnMobile={true} />
					<HeaderActions>
						<HeaderAction linkTo={{ pathname: "/contacts/new", state: { modal: true, returnTo: location.pathname, backgroundLocation: location } }} icon="plus" />
					</HeaderActions>
				</Header>
				{collection && collection.records.length > 0 ? sortForm() : null}
				<div className="main-content">
					{nextCollection.loading ? <Loading inline /> : results()}
				</div>
			</div>
		</div>
	)
}


/*
export default AddressBookScoper(
	CollectionLoader(
		FilterableCollection(
			{
				priority: { icon: 'bolt', name: 'Priority' },
				thread_count: { icon: 'hashtag', name: 'Interactions' },
				last_contact: { icon: 'clock', name: 'Last Contact' },
			}
		), 'ContactRepository'
	)
);
*/

export default List