import React from 'react';
import { useOutletContext, NavLink, useLocation } from 'react-router-dom';
import useCategoryVisibilityToggle from '../../lib/useCategoryVisibilityToggle'
import { useAuth } from '../../lib/useAuth'

const ThreadSidebar = () => {
	const auth = useAuth()
	const location = useLocation()

	return (
		<div>
			<h2><i className="fal fa-envelope" />Inbox &amp; Emails</h2>

			<NavLink to={{ pathname: "/drafts/new", state: { modal: true, returnTo: location.pathname } }} className="main-action-button">Compose an Email</NavLink>

			<div className="sub-panel">
				<div className="main-links">
					<NavLink to="/email" end>
						<i className="far fa-list-alt" /><span className="title">Email</span>
					</NavLink>
					<NavLink to="/mailing-lists">
						<i className="far fa-newspaper" /><span className="title">Mailing Lists</span>
					</NavLink>
					<NavLink to="/email/search">
						<i className="fa fa-search" />
						<span className="title">Search Your Inbox</span>
					</NavLink>
				</div>

				<h3 className="section-title">
					Smart email filters
				</h3>
				<div className="sub">
					<NavLink to="/email/today">
						<i className="fal fa-sun" /><span className="title">Today&#8217;s emails</span>
					</NavLink>
					<NavLink to="/email/workweek">
						<i className="fal fa-calendar-alt" /><span className="title">From this workweek</span>
					</NavLink>
					<NavLink to="/email/unreplied">
						<i className="fal fa-ellipsis-h-alt" /><span className="title">Awaiting your reply</span>
					</NavLink>
					<NavLink to="/email/awaiting">
						<i className="fal fa-hourglass-half" /><span className="title">Awaiting their reply</span>
					</NavLink>
					<NavLink to="/email/flagged">
						<i className="fal fa-flag" /><span className="title">Flagged for follow-up</span>
					</NavLink>
					{
						auth.account.multiUser()
						? (<NavLink to="/email/shared">
								<i className="fal fa-rss" /><span className="title">Shared with you</span>
							</NavLink>)
						: null
					}
					<NavLink to="/email/pinned-contacts">
						<i className="fal fa-thumbtack" /><span className="title">From pinned contacts</span>
					</NavLink>
					<NavLink to="/email/snoozed">
						<i className="fal fa-alarm-clock" /><span className="title">Snoozed</span>
					</NavLink>
				</div>
			</div>
		</div>
	)
}

export default ThreadSidebar