import { useLocation } from 'react-router-dom'
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Row from './Row';

const OrderedList = (props) => {
	const location = useLocation()

	return (
		<div className="tasks-with-indicators new-task-list">
			<TransitionGroup>
				{props.collection.tasksSortedByDueDate().map(task => {
					return (
						<CSSTransition key={task.id} classNames="task-list" timeout={{ enter: 2000, exit: 700 }}>
							<Row task={task} toggler={props.toggler} showAssociated={props.showAssociated} location={location} hideMilestone={props.hideMilestones} />
						</CSSTransition>
					)
				})}
			</TransitionGroup>
		</div>
	)
}

export default OrderedList