import { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../lib/useAuth'
import { convertToRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import _ from 'lodash';
import AttachmentList from './AttachmentList';
import RecipientList from './RecipientList';
import ContactRepository from '../../repositories/ContactRepository';
import Dropzone from 'react-dropzone';
import DraftRepository from '../../repositories/DraftRepository';
import { Loading } from '../common/Common'
import { TextInput } from '../../lib/forms/TextInput';
import { BooleanToggle } from '../../lib/forms/BooleanToggle';
import { Label } from '../../lib/forms/Label';
import { Select } from '../../lib/forms/Select';
import { RichEditor } from '../../lib/forms/RichEditor';
import { Submit } from '../../lib/forms/Submit';
import { Form } from '../../lib/forms/Form';

const DraftForm = (props) => {
	const auth = useAuth()
	const timer = null
	const lastSavedDraft = null
	const repository = new DraftRepository()
	const [email, setEmail] = useState(null)
	const [files, setFiles] = useState([])
	const [sending, setSending] = useState(false)
	const [pendingAttachments, setPendingAttachments] = useState([])
	const [showUnsendableNotice, setShowUnsendableNotice] = useState(false)
	const [topContacts, setTopContacts] = useState([])
	const [showCc, setShowCc] = useState(false)
	const [showBcc, setShowBcc] = useState(false)
	const [showReminder, setShowReminder] = useState()
	const formRef = useRef()
	const dropZone = useRef()

	useEffect(() => {
		if (props && props.email) {
			setEmail(props.email)
			setShowReminder(props.email.reminder)
//			launchAutoSaver()
			preloadTopContacts()
		}
	}, [props])

	const isPartOfThread = () => {
		return !!props.referenceMessage;
	}

	const isReply =() => {
		return isPartOfThread() && props.type === 'reply';
	}

	const isForward = () => {
		return isPartOfThread() && props.type === 'forward';
	}
/*
	componentWillUnmount() {
	  clearTimeout(this.timer);
	}

	launchAutoSaver = () => {
		this.lastSavedDraft = email.toJson();

		this.timer = setInterval(() => {
			if (this.lastSavedDraft !== email.toJson()) {
				this.saveDraft();
			}
		}, (5 * 1000));
	}
*/
	const preloadTopContacts = () => {
		new ContactRepository().getCollection({ slice: 'top', limit: 50 }).then((collection) => {
			setTopContacts(collection.records)
		});
	}

	const sendable = () => {
		return hasSomeInput() && hasRecipients() && !attachmentUploadsPending();
	}

	const hasSomeInput = () => {
		return email.subject || (email.body && email.body.replace(/<\/?[^>]+(>|$)/g, '').length > 0)
	}

	const hasRecipients = () => {
		return ((email.to_recipients || []).length
			+ (email.cc_recipients || []).length
			+ (email.bcc_recipients || []).length)
			> 0
	}

	const attachmentUploadsPending = () => {
		return !!(email.attachments || []).find(attachment => attachment.status !== 'complete');
	}

	const notSendableNotice = () => {
		if (sendable()) { return null; }

		return (
			<div className="not-sendable">
				<h5><i className="fa fa-exclamation-circle" />We can&#8217;t send this email just yet:</h5>
				<ul>
					{!hasRecipients() ? <li>Please choose a <strong>recipient for this email</strong></li> : null}
					{!hasSomeInput() ? <li>Please enter a <strong>subject or body</strong></li> : null}
					{attachmentUploadsPending() ? <li>We&#8127;re <strong>still uploading an attachment</strong></li> : null}
				</ul>
			</div>
		)
	}

	const saveDraft = () => {
		return true
/*
		let emailToSave = email;

		if (email.nonEmpty()) {
			repository.save(emailToSave).then(savedEmail => {
				lastSavedDraft = emailToSave.toJson();

				if (!email.id) {
					let email = email;
					email.set('id', savedEmail.id);
					setEmail(email)
				}
			});
		}*/
	}

	const attach = (file) => {
		var email = email;

		if (_.filter(email.uploads, function(existing) {
			return existing.id === file.upload.id
		}).length === 0) {
			email.uploads.push(file.upload);

			setEmail(email)
		}
	}

	const updateEmail = (newEmail) => {
		setEmail(newEmail)
	}

	const unattach = (file) => {
		let newEmail = email;

		_.remove(newEmail.uploads, function(existing) {
			return file.upload.id === existing.id;
		});
		let newFiles = files;
		_.remove(newFiles, function(existing) {
			return newFiles['name'] === existing['name'] && newFiles['size'] === existing['size'];
		})

		setEmail(newEmail)
		setFiles(newFiles)
	}

	const attachments = () => {
//		console.log('here', this.allAttachments(), this.state)
		if (allAttachments().length > 0) {
			return (
				<tr>
					<td width="20%" className="top-aligned"><Label className="standalone">Attachments</Label></td>
					<td>
						<AttachmentList key={`attachments.${allAttachments().length}`} files={allAttachments()} updater={updateEmail} addAttachment={addAttachment} />
					</td>
				</tr>
			);
		}
	}

	const allAttachments = () => {
		return (email.attachments || []).concat(pendingAttachments);
	}

	const selectFile = () => {
//		this.refs.dropZone.open();
	}

	const addAttachments = (newFiles) => {
		if (!(newFiles instanceof Array)) { newFiles = [newFiles] }
		newFiles.forEach(newFile => { addAttachment(newFile) });
	}

	const addAttachment = (newFile) => {
		let email = email;
		let uploadedAttachments = (email.attachments || []).filter(file => { return file.preview !== newFile.preview });
		let pendingAttachments = pendingAttachments.filter(file => { return file.preview !== newFile.preview });
//console.log(pendingAttachments, uploadedAttachments)
		if (newFile.status === 'complete') {
			uploadedAttachments.push(newFile);
			email.set('attachments', uploadedAttachments);
			this.setState({ email: email, pendingAttachments: pendingAttachments });
		} else {
			pendingAttachments.push(newFile);
			this.setState({ pendingAttachments: pendingAttachments }, () => { console.log(this.state) });
		}
//console.log(uploadedAttachments, pendingAttachments, this.allAttachments())
		console.log(this.state)
	}

	const onEditorChange = (editorState) => {
		let newEmail = email;
		let content = editorState.getCurrentContent()
		newEmail.set('content_state', JSON.stringify(convertToRaw(content)));
		newEmail.set('body', stateToHTML(content));

		setEmail(newEmail)
	}

	const markForSending = (email) => {
		email.set('sendable', true);
		this.setState({ email: email, sending: true });

		if (email.reminder && !isNaN(parseInt(email.reminder.grace_period_in_days))) {
			email.reminder.grace_period_in_days = parseInt(email.reminder.grace_period_in_days);
		}

		clearTimeout(this.timer);

		return email;
	}

	const afterSending = (email) => {
		email.sendable = false;

		setTimeout(() => {
			props.history.push(props.returnTo || "/");
		}, 2000)
	}

	const sendingNotification = () => {
		return (
			<div className="email-sent">
				<i className="fal fa-paper-plane" />
				<h2>All set&mdash;your email is on its way!</h2>
			</div>
		);
	}

	const checkSendability = (e) => {
		if (!this.sendable()) {
			e.preventDefault();

			this.setState({ showUnsendableNotice: true });
		}
	}

	const otherRecipients = (type) => {
		return (
			<tr>
				<td width="20%">
					<Label className="standalone">{type.toUpperCase()} to</Label>
				</td>
				<td width="80%">
					<RecipientList email={email} topContacts={topContacts} recipientType={type} updater={this.updateEmail} />
				</td>
			</tr>
		);
	}

	const reminderFields = () => {
		return (
			<tr className={showReminder ? "" : "hidden"}>
				{isPartOfThread() ? null : <td width="20%" className="top-aligned"><Label>Set a reminder</Label></td>}
				<td colSpan={isPartOfThread() ? "2" : ""} className="reminder-options">
					<div>
						<i className="fal fa-alarm-clock" />
						<span>Remind me to follow up</span>
						<div className="styled-select small">
							<Select name="reminder.grace_period_in_days" defaultValue="3">
								<option value="1">tomorrow</option>
								<option value="2">in 2 days</option>
								<option value="3">in 3 days</option>
								<option value="5">in 5 days</option>
								<option value="7">in a week</option>
								<option value="14">in two weeks</option>
							</Select>
						</div>
						<span>if there&#8217;s no response</span>
					</div>
					<div>
						<i className="fal fa-check-circle" />
						<span>With the task</span>
						<TextInput name="reminder.task_assignment" className="small" defaultValue="Follow up on this unanswered email" onFocus={e => e.target.select()} />
					</div>
				</td>
			</tr>
		)
	}

	const setReminder = () => {
		let newEmail = email;
		newEmail.getOrBuildAssociation('reminder');
		newEmail.reminder.set('task_assignment', 'Follow up on this unanswered email');
		newEmail.reminder.set('grace_period_in_days', 3);

		setEmail(newEmail)
		setShowReminder(true)
	}

	const subject = () => {
		if (!isPartOfThread()) {
			return (
				<tr>
					<td width="20%" style={{ background: "#fafafa" }}><Label className="standalone" attribute="subject">Subject</Label></td>
					<td width="80%" style={{ background: "#fafafa" }}>
						<TextInput name="subject" className="full bold" />
					</td>
				</tr>
			);
		}
	}

	const includeRepliesOption = () => {
		if (isReply()) {
			return (
				<tr>
					<td colSpan="2" className="include-replies-option">
						<BooleanToggle name="include_previous_in_reply" /> <Label for="include_previous_in_reply">Include earlier emails below your reply</Label>
					</td>
				</tr>
			);
		}
	}

	if (email === null) {
		return <Loading inline />
	} else if (sending) {
		return sendingNotification();
	} else if (email.sent) {
//		return sentNotification();
	}
//			<Dropzone ref={dropZone} onDrop={addAttachments} disableClick={true} className="drop-zone" activeClassName="drop-zone active">

	return (
		<Form ref={formRef} object={email} repository={repository} beforeSubmit={markForSending} afterSubmit={afterSending} key={email.id}>
				<table className="clean gray left middle">
					<tbody>
						<tr style={{ display: (auth.user.getMailboxes().length === 1 ? 'none' : 'auto') }}>
							<td width="20%" style={{ background: "#f2f2f2" }}><Label className="standalone" attribute="mailbox_id">From mailbox</Label></td>
							<td width="80%" style={{ background: "#f2f2f2" }}>
								<div className="styled-select">
									<Select name="mailbox_id" defaultValue={(auth.user.primaryMailbox() || auth.user.getMailboxes()[0]).id}>
										{auth.user.getMailboxes().map(mailbox => {
											let name = mailbox.displayName();
											if (mailbox.name) { name = `${name} (${mailbox.email_address})` }
											return <option value={mailbox.id}>{name}</option>
										})}
									</Select>
								</div>
							</td>
						</tr>
						<tr>
							<td width="20%">
								<Label className="standalone">Send to</Label>
								<div className="cc">
									<span className={showCc ? "hidden" : ""} onClick={() => setShowCc(true)}><i className="fa fa-plus" />CC</span>
									<span className={showBcc ? "hidden" : ""} onClick={() => setShowBcc(true)}><i className="fa fa-plus" />BCC</span>
								</div>
							</td>
							<td width="80%">
								<RecipientList key={email.to_recipients} email={email} topContacts={topContacts} recipientType="to" updater={updateEmail} />
							</td>
						</tr>
						{showCc ? otherRecipients('cc') : null}
						{showBcc ? otherRecipients('bcc') : null}
						{subject()}
						{false && attachments()}
						<tr>
							<td colSpan="2">
								<RichEditor key="richeditor" selectFile={selectFile} onEditorChange={onEditorChange} defaultValue={email.contentState()} />
								<div id="rich-editor"></div>
								{isForward() ? <p className="etc" style={{ marginTop: "5px" }}>Enter any text you&#8217;d like to add; we&#8217;ll automatically insert the forwarded email below it.</p> : null}
							</td>
						</tr>
						{includeRepliesOption()}
						{reminderFields()}
					</tbody>
				</table>
				{!sendable() && showUnsendableNotice ? notSendableNotice() : false}

				<div className="submit-options">
					<div className="set-reminder">
						<span className={"toggle-reminder" + (showReminder ? " hidden" : "")} onClick={setReminder}><i className="fal fa-alarm-clock" />Remind me&hellip;</span>
					</div>
					<div className="submit-button">
						<Submit name="send" value="Send this Email" onClick={checkSendability} className="green medium" />
					</div>
				</div>
		</Form>
	)
}

export default DraftForm