import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import User from '../../resources/User';
import UserForm from '../users/Form'
import { Link } from 'react-router-dom';
import { useAuth } from '../../lib/useAuth'
import { useGatekeeper } from '../../lib/useGatekeeper'

const Users = () => {
//	const [users, setUsers] = useState(null)
	const [showNewUserForm, setShowNewUserForm] = useState(false)
	const [newUser, setNewUser] = useState(new User({ can_connect_email_account: true }))
	const [otherUsers, setOtherUsers] = useState([])
	const auth = useAuth()
	const [permitted, component] = useGatekeeper(['currentUser.isAdmin'])

	useEffect(() => {
		setOtherUsers(auth.account.users.filter(user => user.id !== auth.user.id && !user.deleted))
	}, [])


	if (!permitted) { return component }

	const addNewUser = (user) => {
		let users = otherUsers;
		users.push(user);

		setOtherUsers(users)
		setShowNewUserForm(false)
	}

	const userList = () => {
		return (
			<div className="user-list">
				{otherUsers.map(function(user) {
					let userControls;
					if (auth.user.isAdmin() && !user.isOwner()) {
						userControls = (
							<div className="user-controls">
								<Link to={{ pathname: `/users/${user.id}/edit`, state: { modal: true, returnTo: '/settings/users' } }} className="inline action white" style={{ marginRight: "10px" }}><i className="fa fa-pencil" />Edit</Link>
								<Link to={{ pathname: `/users/${user.id}/delete`, state: { modal: true, returnTo: '/settings/users' } }} className="inline action white delete"><i className="fa fa-trash" />Delete</Link>
							</div>
						)
					}

					return (
						<div className="user-row" key={user.id}>
							<div className="name">
								<strong className="user-name">{user.first_name} {user.last_name}</strong>
								<div className="etc normal-size" style={{ color: "#555" }}>{user.email_address}</div>
								{user.hasBeenActivated() ? null : <div className="unactivated"><i className="fa fa-clock" />awaiting activation</div>}
							</div>
							<div className="role">
								{user.isOwner() || user.isAdmin() ? <span className="badge"><i className={user.isOwner() ? "fa fa-database" : "fa fa-key"} />{user.roleName()}</span> : null}
							</div>
							{userControls}
						</div>
					);
				})}
			</div>
		);
	}

	return (
		<div>
			<Helmet title="Manage user accounts" />
			<h3><i className="fa fa-users" />Manage your team &amp; colleagues</h3>
			<p className="settings description">Create accounts for your colleagues and team members so you can coordinate more effectively than ever.</p>
			<div className="main-form gray full-width" style={{ marginTop: "0" }}>
			{showNewUserForm ? <UserForm user={newUser} addNewUser={addNewUser} /> : <div className="centered"><strong className="rounded medium green button" onClick={() => setShowNewUserForm(true)}><i className="fa fa-plus" />Add a new user</strong></div>}
			</div>

			{otherUsers && otherUsers.length > 0 ? userList() : null}
		</div>
	)
}

export default Users