import { useState } from 'react';
import Fetcher from '../../lib/Fetcher';

const emailAddressValid = (emailAddress) => {
	return /^[^@]+@[^@]+\.[^@]+$/i.test(emailAddress);
}

const WaitlistForm = ({ hasInvalidInvitation }) => {
	const [submissionAttempted, setSubmissionAttempted] = useState(false)
	const [emailAddress, setEmailAddress] = useState("")
	const [valid, setValid] = useState(false)
	const [added, setAdded] = useState(false)
	const [submitting, setSubmitting] = useState(false)

	const onSubmit = (e) => {
		e.preventDefault();

		setSubmissionAttempted(true)

		if (emailAddressValid(emailAddress)) {
			setSubmitting(true)

			return new Fetcher().post('/waitlistees', { email_address: emailAddress }).then(json => {
				setAdded(true)
			})
		} else {
			setValid(false)
		}
	}

	const updateInput = e => {
		setEmailAddress(e.target.value)
		setValid(emailAddressValid(e.target.value))
	}

	const invalidInvitationNotice = () => {
		return (
			<div className="error">
				<p><strong>You have an invalid activation code.</strong></p>
				<p>It may have been redeemed already, or you might have copied it incorrectly from your invitation.  You can always add your email to the waiting list below.</p>
			</div>
		);
	}

	const body = () => {
		if (added) {
			return (
				<div className="empty-congrats">
					<i className="fa fa-check" />
					<h2>You&#8217;re on the list!</h2>
					<p>We&#8217;ll send an invitation as soon as we can.</p>
				</div>
			);
		} else {
			return (
				<div>
					<div className="welcome-new-user">
						<p><strong>Thanks for your interest in Carom!</strong></p>
						<p>We&#8217;ve just launched what we think is the best way to manage your contacts, track interactions, and stay on the same page as your colleagues.</p>
						<p className="strong">We&#8217;re opening our doors slowly for now.  If you enter your email address below, we&#8217;ll invite you to create an account as soon as we can.</p>
					</div>
					{hasInvalidInvitation ? invalidInvitationNotice() : null}
					<form onSubmit={onSubmit}>
						<input type="text" name="email_address" className={"large full centered" + (submissionAttempted && !valid ? " error" : "")} placeholder="Your email address" autoFocus onChange={updateInput} />
						<div className="etc centered" style={{ margin: "5px 10px" }}>Just to send your invitation&mdash;we&#8217;ll never email you otherwise</div>
						<div className="submit">
							{
								submitting
								? <i className="fa fa-spinner fa-spin" />
								: <input type="submit" className="medium green rounded" value="Add me to the waiting list" />
							}
						</div>
					</form>
				</div>
			);
		}
	}

	return (
		<div className="main-content gray">
			{body()}
		</div>
	)
}


export default WaitlistForm