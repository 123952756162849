import React from 'react';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Carom from './app/components/Carom';
import './App.scss';

import * as Sentry from '@sentry/react';
Sentry.init({
	dsn: 'https://d722fa0dc4db4744b0bc8b13bfbb5a7d@o123044.ingest.sentry.io/270894'
});

var mobileType = window.mobileType = {
	Android: function() {
		return navigator.userAgent.match(/Android/i);
	},
	BlackBerry: function() {
		return navigator.userAgent.match(/BlackBerry/i);
	},
	iOS: function() {
		return navigator.userAgent.match(/iPhone|iPad|iPod/i);
	},
	Opera: function() {
		return navigator.userAgent.match(/Opera Mini/i);
	},
	Windows: function() {
		return navigator.userAgent.match(/IEMobile/i);
	},
	any: function() {
		return (mobileType.Android() || mobileType.BlackBerry() || mobileType.iOS() || mobileType.Opera() || mobileType.Windows()) || false;
	}
};

window.isMobile = mobileType.any() && window.innerWidth < 500;
// React Router

// TODO
/*
var onRouteUpdate = function() {
	if (localStorage.reloadUiAfterUpdate) {
		localStorage.removeItem('reloadUiAfterUpdate');

		window.location.reload(true);
	}
}
*/

const root = ReactDOM.createRoot(
	document.getElementById("root")
);

root.render(
	<>
		<BrowserRouter>
			<Routes>
				<Route path="*" element={<Carom />} />
			</Routes>
		</BrowserRouter>
	</>
);

//const div = document.getElementById('root');
//ReactDOM.render(Routes, div);
