import React from 'react';
import { TextInput } from '../../../../lib/forms/TextInput';
import { HiddenField } from '../../../../lib/forms/HiddenField';

 const WebsiteInput = ({ website, remove }) => {
	let main;
	if (website.id) {
		main = (
			<div className="fields">
				<strong>{website.title || '(No webpage title)'}</strong>
				<div className="etc">{website.url}</div>
			</div>
		);
	} else {
		main = (
			<div className="fields">
				<TextInput name={`websites[${website.lookupKey()}].url`} className="full" placeholder="URL for this contact's website (e.g., http://www.example.com)" />
			</div>
		);
	}

	return (
		<div className="field-section">
			<HiddenField name={`websites[${website.lookupKey()}].id`} />
			<div className="remove">
				<i className="fal fa-times-circle" onClick={remove.bind(null, 'websites', website)} />
			</div>
			{main}
		</div>
	)
}

export default WebsiteInput