import Repository from '../lib/Repository';
import Contact from '../resources/Contact';

export default class ContactRepository extends Repository {
	entityClass = Contact;
	baseUrl = '/contacts';
	sliceFilters = {
		pinned: { icon: 'thumbtack', name: 'Pinned contacts', color: '#ccc' },
		latest: { icon: 'comment', name: 'Contacts you&#8217;ve interacted with in the last month', color: '#ccc' },
		lapsed: { icon: 'unlink', name: 'Contacts you haven&#8217;t spoken to recently', color: '#ccc' },
		tasked: { icon: 'check-circle', name: 'Contacts with oustanding tasks', color: '#ccc' },
		enriched: { icon: 'plug', name: 'Contacts with enriched data', color: '#ccc' },
		shared: { icon: 'rss', name: 'Contacts shared with you', color: '#ccc' }
	}

//	filterFields = ['file_name', 'first_sent_before', 'last_sent_after'];
//	sortOptions = ['file_name', 'size', 'attachment_count', 'last_sent_at'];
//	defaultSort = 'size';
//	defaultOrder = 'asc';
}