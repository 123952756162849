import _ from 'lodash';
import Search from '../resources/Search';
//import { map, remove } from 'lodash';
//import ReactDOM from 'react-dom';
//const OutsideClickHandler = window.OutsideClickHandler;
//const Tag = window.Tag;

export default class ListFilter {
	preloadedOptions = [];

	setSearchFields(fields) {
		this.searchFields = fields;
	}

	loadOptions(options) {
		if (!this.preloadedOptions) { this.preloadedOptions = []; }
		let existingIds = this.preloadedOptions.map(option => option.id)

		options.forEach(option => {
			if (!existingIds.includes(option.id)) {
				option.searchable_field = this.searchFields.map(k => option[k]).join(' ');
				this.preloadedOptions.push(option);
			}
		});
	}

	resetOptions(options) {
		this.preloadedOptions = [];
		this.loadOptions(options);
	}

	removeOption(option) {
		this.preloadedOptions = this.preloadedOptions.filter(existing => option !== existing);
	}

	addOption(option) {
		this.preloadedOptions.push(option);
	}

	hasOptionsLoaded() {
		return this.preloadedOptions && this.preloadedOptions.length > 0;
	}

	setQueryableCollection(collection, preload) {
		this.queryableCollection = collection;

		if (preload) {
			this.queryableCollection.getCollection({ limit: 250 }).then(collection => {
				if (collection.records) {
					this.loadOptions(collection.records);

					if (collection.pages === 1) {
						this._isFullyPreloaded = true;
					}
				}
			})
		}
	}

	hasQueryableCollection() {
		return !!this.queryableCollection;
	}

	isFullyPreloaded() {
		return !!this._isFullyPreloaded;
	}

	queryRemote(query) {
		if (this.hasQueryableCollection) {
			let search = new Search({});
			search.setResource(this.queryableCollection.getBaseUrl().substring(1))
			search.setQuery(query);

			return search.execute().then((search) => {
				let results = Object.keys(search.results).map(k => search.results[k].records).flat();

				this.loadOptions(results);
				return this.queryPreloaded(query);
			})
		}
	}

	queryPreloaded(query) {
		let matches = this.preloadedOptions;

		if (query.length === 0) {
			if (this.previewOnEmpty) {
				_.remove(matches, function(match) {
						return !match.id
					})

				return matches;
			} else {
				return [];
			}
		} else {
			let regex = new RegExp(query, "i");
			// Add a default
			_.remove(matches, function(match) {
				return !match.id
			});
			let exact = matches.filter(function(match) {
				return match.searchable_field === query;
			})[0];

			if (!exact && this.allowNew === true) {
				/*matches.unshift(new Tag({ name: query, searchable_field: query }));*/
			}

			matches = matches.filter(function(match) {
				return match.searchable_field.match(regex);
			});

/*
			var self = this;

			this.setState({ matches: matches}, function() {
				if (self.state.matches.length > 0 && !self.hasActiveRow()) {
					self.initializeActiveRow();
				} else {
					self.initializeActiveRow();
//					self.clearActiveRow();
				}
			});
*/

			return matches;
		}
	}
}