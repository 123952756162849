import { useState } from 'react';
import { LocalTime, Locations, Websites, Numbers, Avatar, SocialProfiles, Employments } from '../Contact'
import { Link } from 'react-router-dom';
import Commentary from '../../commentary/Index';
import * as FileUtilities from '../../../lib/utilities/FileUtilities';
import OrderedList from '../../tasks/OrderedList.js';
import ThreadTable from '../../threads/Table';
import FileTable from '../../files/Table';

const Workspace = ({ contact, panelSwitcher }) => {
	const [counter, setCounter] = useState(0)

	const collaborators = () => {
		return null;

		// eslint-disable-next-line
		if (contact.collaborators.length === 0) { return null; }

		return (
			<div className="priority-contacts">
				<h3 className="with-icon-before">Top collaborators <span className="inline etc">People with the most shared email interactions with {contact.displayShortName()}</span></h3>
				{contact.collaborators.map(contact => {
					return (
						<Link to={`/contacts/${contact.id}`} className="contact">
							<Avatar contact={contact} inline={true} />
							<div className="name"><span>{contact.displayName()}</span></div>
						</Link>
					);
				})}
				<div style={{ clear: 'both' }}></div>
			</div>
		);
	}

	const tasks = () => {
		if (contact.taskCollection().tasks().length === 0) { return null; }

		return (
			<div className="contact-tasks">
				<h2><i className="fa fa-check-circle" />Tasks related to this contact</h2>
				<OrderedList collection={contact.taskCollection()} toggler={toggleTask}  />
			</div>
		);
	}

	const toggleTask = (task) => {
		contact.taskCollection().toggleTaskCompletion(task);
		setCounter(counter + 1)
	}

	const fileDisplay = (files) => {
		if (window.isMobile) {
			return <FileTable files={files} />
		} else {
			return (
				<>
					{files.map(file => {
						return (
							<Link to={"/files/" + file.id} className="recent-file">
								<div className={"file-icon " + FileUtilities.fileType(file)}>{FileUtilities.fileIcon(file)}</div>
								<div className="file-name">{file.file_name}</div>
								<div className="etc minor" style={{ color: "#aaa" }}>
									{file.attachments[0].thread.subject}
								</div>
							</Link>
						);
					})}
				</>
			);
		}
	}

	const recentFiles = () => {
		if (!contact.latest_files || contact.latest_files.length === 0) { return null; }

		return (
			<div className="recent-files">
				<h3 className="with-icon-before">
					Recent files
					<Link to={`/contacts/${contact.id}/files`} onClick={panelSwitcher.bind(null, 'files')} className="see-all">see all</Link>
				</h3>
				{fileDisplay(contact.latest_files)}
				<div style={{ clear: 'both' }}></div>
			</div>
		);
	}

	const recentThreads = () => {
		if (!contact.latest_threads || contact.latest_threads.length === 0) { return null; }

		return (
			<div className="recent-threads">
				<h3 className="with-icon-before">
					Latest threads
					<Link to={`/contacts/${contact.id}/emails`} onClick={panelSwitcher.bind(null, 'emails')} className="see-all">see all</Link>
				</h3>
				<ThreadTable threads={contact.latest_threads} />
			</div>
		);
	}

	const emptyCardSlot = () => {
		return (
			<div className="empty-card-slot">
				<i className="fal fa-circle" />
			</div>
		);
	}

	const infoCard = () => {
		return (
			<div className="contact-info-card">
				<div className="card-body">
					{contact.background ? <div className="bio">{contact.background}</div> : null}
					<LocalTime contact={contact} />
					<Employments contact={contact} />
					<div className="contact-info">
						<div className="email-addresses">
							{contact.email_addresses.map(email_address => {
								return <div key={`email.${email_address.id}`} className="email-address">{email_address.value}</div>
							})}
						</div>
						<Numbers contact={contact} />
						<Locations contact={contact} />
						<Websites contact={contact} />
						<SocialProfiles contact={contact} />
					</div>
				</div>
			</div>
		);
	}

	return (
		<div className="panel">
			{tasks()}

			{infoCard()}

			{collaborators()}

			{recentFiles()}

			{recentThreads()}

			<Commentary record={contact} />
		</div>
	)
}

export default Workspace