import React, { useState } from 'react';
import Mailbox from '../../resources/Mailbox';

const SubscriptionColorPicker = ({ subscription, onColorSelection }) => {
  const [subscriptionColor, setSubscriptionColor] = useState(subscription ? subscription.color : null);

  const chooseHighlight = color => {
    setSubscriptionColor(color);
    let updatedSubscription = subscription;
    updatedSubscription.set('color', color);
    onColorSelection(updatedSubscription);
  };

  return (
    <div className="highlight-colors">
      {Mailbox.colors.map(color => {
        return (
          <i
            key={color}
            className={`fa fa-${subscriptionColor === color ? 'check-circle' : 'circle'}`}
            onClick={() => chooseHighlight(color)}
            style={{ color: color }}
          />
        );
      })}
    </div>
  );
};

export default SubscriptionColorPicker;
