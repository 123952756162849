import React from 'react';
import { Link } from 'react-router-dom';

const Row = ({ organization }) => {
	return (
		<Link className="block record-row" to={{ pathname: "/organizations/" + organization.id, state: { resource: organization } }}>
			<div className="container">
				<div className="row-logo">
					{
						organization.logo.exists() && false
						? <img src={organization.logo.source_url} alt="Organization logo" />
						: <i className="fa fa-building" />
					}
				</div>
				<div className="main-cell">
					<h4 className="organization-name">{organization.pinned ? <i className="fa fa-thumbtack" /> : null}{organization.name}</h4>
				</div>
				<div className="stats-container">
					<dl className="org-stats">
						<span>
							<dt>Contacts</dt>
							<dd>{organization.contact_count.toLocaleString()}</dd>
						</span>
						<span>
							<dt>Emails</dt>
							<dd>{organization.thread_count.toLocaleString()}</dd>
						</span>
						<span>
							<dt>Files</dt>
							<dd>{organization.file_count.toLocaleString()}</dd>
						</span>
					</dl>
				</div>
			</div>
		</Link>
	)
}

export default Row