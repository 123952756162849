import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../lib/hooks'
import MailboxRepository from '../../../repositories/MailboxRepository'
import Row from './Row'
import MailboxSubscriptionRepository from '../../../repositories/MailboxSubscriptionRepository';
import MailboxSubscription from '../../../resources/user/MailboxSubscription';
import SubscriptionColorPicker from '../../mailboxes/SubscriptionColorPicker';
import pluralize from 'pluralize';

const List = (props) => {
	const auth = useAuth()
	const [subscriptions, setSubscriptions] = useState(auth.user.getMailboxSubscriptions())
	const mailboxRepository = new MailboxRepository()
	const mailboxSubscriptionRepository = new MailboxSubscriptionRepository(auth.user)
	const [mailboxes, setMailboxes] = useState([])
	const [primaryMailbox, setPrimaryMailbox] = useState(null)
	const [otherSubscribedMailboxes, setOtherSubscribedMailboxes] = useState([])
	const [nonSubscribedMailboxes, setNonSubscribedMailboxes] = useState([])

	useEffect(() => {
		loadAccountMailboxes()
	}, [])

	const loadAccountMailboxes = async () => {
		let collection = await mailboxRepository.asyncGetCollection()
		setMailboxes(collection.records)
	}

/*
	useEffect(() => {
		updateMailboxes()
	}, [mailboxes])

	const updateMailboxes = async () => {
		const u = await auth.loadUser()
console.log('in here')
		setPrimaryMailbox(mailboxes.find(mailbox => mailbox.email_address === auth.user.email_address))
		setOtherSubscribedMailboxes([...mailboxes.filter(mailbox => auth.user.subscribedMailboxIds().includes(mailbox.id) && mailbox.email_address !== auth.user.email_address)])
		setNonSubscribedMailboxes([...mailboxes.filter(mailbox => !auth.user.subscribedMailboxIds().includes(mailbox.id))])
	}
*/
	const toggleSubscription = (mailbox) => {
		const existingSubscription = subscriptions.find(sub => sub.mailbox_id === mailbox.id)

		if (existingSubscription) {
			mailboxSubscriptionRepository.delete(existingSubscription)
			setSubscriptions(subscriptions.filter(sub => sub.mailbox_id !== mailbox.id))
		} else {
			mailboxSubscriptionRepository.save(new MailboxSubscription({ mailbox_id: mailbox.id })).then((newSub) => {
			setSubscriptions([...subscriptions, newSub])
			})

		}


/*
		let existingSubscription = auth.user.mailbox_subscriptions.find(sub => sub.mailbox_id === mailbox.id);
		let mailboxSubscriptionRepository = new MailboxSubscriptionRepository(auth.user);
		mailbox.subscription_count += (existingSubscription ? -1 : 1)
		mailboxes[mailboxes.findIndex(existing => mailbox.id === existing.id)] = mailbox;

		if (existingSubscription) {
			auth.user.mailbox_subscriptions.splice(auth.user.mailbox_subscriptions.indexOf(existingSubscription), 1)
			mailboxSubscriptionRepository.delete(existingSubscription);
		} else {
			mailboxSubscriptionRepository.save(new MailboxSubscription({ mailbox_id: mailbox.id })).then(sub => {
				auth.user.mailbox_subscriptions.push(sub);
			})
		}

		updateMailboxes()
*/
	}

	const showPrimaryMailbox = () => {
		if (mailboxes) {
			const primary = mailboxes.find(mailbox => mailbox.email_address === auth.user.email_address)

			if (primary) {
				return <Row key="primary" mailbox={primary} />
			}
		}
	}

	const showSecondarySubscribedMailboxes = () => {
		if (mailboxes) {
			const subscribed = mailboxes.filter(mailbox => subscriptions.map(sub => sub.mailbox_id).includes(mailbox.id) && mailbox.email_address !== auth.user.email_address)

			if (subscribed) {
				return subscribed.map(mailbox => <Row key={mailbox.id} subscribed={true} subscription={subscriptions.find(sub => sub.mailbox_id === mailbox.id)} mailbox={mailbox} toggleSubscription={toggleSubscription} showConnection={props.showConnection} />)
			}
		}
	}

	const showSubscribableMailboxes = () => {
		if (mailboxes) {
			const subscribable = mailboxes.filter(mailbox => !subscriptions.map(sub => sub.mailbox_id).includes(mailbox.id))

			if (subscribable && subscribable.length > 0) {
				return (
					<div>
						<h2><i className="fa fa-inbox" />Other mailboxes you can subscribe to</h2>
						{subscribable.map(mailbox => <Row key={mailbox.id} mailbox={mailbox} subscribed={false} toggleSubscription={toggleSubscription} showConnection={props.showConnection} />)}
					</div>
				)
			}
		}
	}

	if (mailboxes !== null) {
		return (
			<>
				<div className="mailbox-list">
					{showPrimaryMailbox()}
					{showSecondarySubscribedMailboxes()}
				</div>
				<div className="mailbox-list">
					{showSubscribableMailboxes()}
				</div>
			</>
		)
	}
}

export default List