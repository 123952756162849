import Resource from '../../lib/Resource';

export default class SocialProfile extends Resource {
	networkName() {
		switch (this.network) {
			case 'linkedin':
				return 'LinkedIn';
			case 'angellist':
				return 'AngelList';
			case 'github':
				return 'GitHub';
			case 'twitter':
				return 'Twitter';
			case 'facebook':
				return 'Facebook';
			default:
				return null;
		}
	}

	link() {
		switch (this.network) {
			case 'twitter':
				return 'https://twitter.com/' + this.handle;
			case 'facebook':
				return 'https://facebook.com/' + this.handle;
			case 'angellist':
				return 'https://angel.co/' + this.handle;
			case 'linkedin':
				return 'https://linkedin.com/' + this.handle;
			case 'github':
				return 'https://github.com/' + this.handle;
			default:
				return null;
		}
	}
}

SocialProfile.modelName = 'SocialProfile';
SocialProfile.fields = ['id', 'network', 'handle', 'data', 'deleted', 'private'];