import { useState } from 'react';

export const useFilterableCollection = (collection, filter, sortOptions) =>  {
	const [showFilterForm, setShowFilterForm] = useState(false)
	const [updating, setUpdating] = useState(null)
	const toggleFilterForm = (collection, filter) => {
		setShowFilterForm(!showFilterForm)
	}

	const sort = async (sort) => {
		setUpdating(sort)

		if (collection.getFilter('sort') === sort) {
			await filter({ sort: sort, order: (collection.getFilter('order') === 'asc' ? 'desc' : 'asc') });
		} else {
			await filter({ sort: sort });
		}

		setUpdating(null)
	}

	const sortForm = () => {
		return (
			<div className="table sort narrow">
				<div className="row">
					<div className="cell" style={{ width: "70%" }}>
						<ul className="sections">
							{Object.keys(sortOptions).map((key) => {
								let classNames = [
									(collection.getFilter('sort') === key ? "active" : ""),
									(updating == key ? "updating" : "")
								]

								return (
									<li key={key}>
										<a className={classNames.join(' ')} onClick={() => sort(key)}>
											<i className={"fal fa-" + sortOptions[key]['icon']} />
											{sortOptions[key]['name']}
											<i className={"fa sort fa-caret-" + (collection.getFilter('order') === 'desc' ? 'down' : 'up')} />
											<i className="fa fa-spinner fa-pulse" />
										</a>
									</li>
									)
							})}
						</ul>
					</div>
					<div className="cell filtered-result-count" style={{ width: "30%" }}>
						{(() => {
							if (!collection.isEmpty()) {
								return (
									<div className="match-count">
										Showing {collection.firstResultNumber()} to {collection.lastResultNumber()} of <strong>{collection.total_results.toLocaleString()}</strong>
									</div>
								)
							}
						})()}
					</div>
				</div>
			</div>
		);
	}

	return [sort, sortForm]
}

// 									(this.props.updating && this.props.nextFilters['sort'] === key ? "updating" : "")



/*	render() {
		return <CollectionViewer
			{...this.props}
			{...this.state}
			filter={this.props.filter}
			toggleFilterForm={this.toggleFilterForm}
			showFilterForm={this.state.showFilterForm}
			sortForm={this.sortForm}
			sortOptions={sortOptions}
		/>;
	}
}
*/