import React, { useState, useRef } from 'react'
import { useAuth } from '../../lib/useAuth'
import { Toggleable } from '../../lib/Toggleable'
import { useToggle } from '../../lib/useToggle'
import { Modalize } from '../../lib/Modalize'
import { mailboxIndicatorClass } from '../common/mailboxIndicator'
import moment from 'moment'
import SnoozeForm from './SnoozeForm'
import pluralize from 'pluralize'
import RecordRow from '../common/RecordRow'
import { timeAwareDate } from '../../lib/Utilities'
import { decode } from 'html-entities'

const Row = (props) => {
	const auth = useAuth()
	const [thread, setThread] = useState(props.thread)
//	const [snoozeButton, setSnoozeButton] = useState(null)
	const [showSnooze, setShowSnooze] = useState(false)
	const [showSnoozeInProgress, setShowSnoozeInProgress] = useState(false)
	const [markAsSnoozed, setMarkAsSnoozed] = useState(false)
	const [snoozeToggleInProgress, setSnoozeToggleInProgress] = useState(false)
	const toggle = useToggle(thread, setThread)
	const snoozeButton = useRef(null)

	const indicators = () => {
		let indicators = []

		if (thread.isSharedWith(auth.user)) {
			indicators.push('rss')
		}
		if (!thread.read) {
			indicators.push('circle')
		}
		if (thread.has_attachments) {
			indicators.push('paperclip')
		}
		if (thread.flagged) {
			indicators.push('flag')
		}

		return (
			<span className="indicators" key={`thread.${thread.id}.indicators`}>
				{indicators.map(icon => <i key={icon} className={"fa fa-" + icon} />)}
			</span>
		)
	}

	const toggleFlagButton = () => {
		return
	}

	const toggleUnreadButton = () => {
		return thread.read ? <i className="fal fa-envelope" title="Mark as unread" onClick={() => toggle('read')} /> : <i className="fal fa-envelope-open" title="Mark as read" onClick={() => toggle('read')} />
	}

	const toggleSnoozeForm = (e) => {
		if (e) { e.preventDefault() }

		if (!showSnoozeInProgress) {
			setShowSnooze(!showSnooze)
		}
		setShowSnoozeInProgress(false)
	}

	const recordSnooze = (snoozedThread) => {
		let updatedThread = thread
		updatedThread.set('snooze_until', snoozedThread.snooze_until)

		setThread(new thread.constructor(updatedThread))
	}

	const recordSnoozeToggleMouseDown = (e) => {
		if (showSnooze) {
			setShowSnoozeInProgress(true)
		}
	}

	const unsnooze = () => {
		thread.unsnooze()
		let unsnoozedThread = thread
		unsnoozedThread.snooze_until = null

		setThread(new thread.constructor(unsnoozedThread))
	}

	const doMarkAsSnoozed = () => {
		setShowSnooze(false)
		setMarkAsSnoozed(true)
	}

	const getControls = () => {
		return [
			{
				icon: (thread.flagged ? 'fa fa-flag' :  'fal fa-flag'),
				shortTitle: (thread.flagged ? 'Unflag' : 'Flag'),
				longTitle: `${thread.flagged ? 'Unflag' : 'Flag'} this thread`,
				onClick: (e) => { toggle('flag') }
			},
			{
				icon: (thread.read ? 'fal fa-envelope' : 'fal fa-envelope-open'),
				shortTitle: (thread.read ? 'Mark Unread' : 'Mark Read'),
				longTitle: (thread.read ? 'Mark as Unread' : 'Mark as Read'),
				onClick: (e) => { toggle('read') }
			},
			{
				icon: 'fal fa-alarm-clock',
				shortTitle: 'Snooze',
				additionalClasses: ("snooze-button" + (showSnooze ? ' active' : '')),
				longTitle: 'Snooze this thread',
				onMouseDown: recordSnoozeToggleMouseDown,
				persistControls: showSnooze,
				onClick: (e) => { toggleSnoozeForm() },
				payload: (buttonRef) => <Modalize getRefPoint={() => buttonRef} popUnderClasses="headingless white snooze right side" toggle={toggleSnoozeForm} show={showSnooze}><SnoozeForm thread={thread} beforeSnooze={doMarkAsSnoozed} onSnooze={recordSnooze} /></Modalize>
			},
			{
				icon: 'fal fa-box-check',
				shortTitle: 'Resolve',
				longTitle: 'Resolve this thread',
				onClick: (e) => { toggle('resolution') }
			},
		].filter(Boolean)
	}

	const snippet = () => {
		let snippet = decode(thread.snippet)
		return (window.isMobile ? snippet.slice(0, 100) : snippet)
	}

	let date = moment(thread.last_sent_at)
	let snoozeDate = thread.snooze_until ? moment(thread.snooze_until) : null

	return (
		<div style={{ position: 'relative' }} key={`thread_row.${thread.id}.${thread.snooze_until}`}>
			<RecordRow
				key={thread.id}
				link={{ pathname: "/email/" + thread.id }}
				additionalClasses={"email-row" + (thread.read ? '' : ' unread') + (thread.resolved ? ' resolved' : '') + (thread.isSnoozed() ? ' snoozed' : '') + mailboxIndicatorClass(thread, auth.user)}
				controls={getControls()}
			>
				<div className="badges">
					<div className="resolved-badge">Resolved <i className="fa fa-undo" onClick={(e) => { e.stopPropagation(); toggle('resolution') }} /></div>
					<div className="snoozed-badge">Snoozed <i className="fa fa-undo" onClick={(e) => { e.stopPropagation(); unsnooze() }} /></div>
				</div>
				<div className="row-body" key={`thread.${thread.id}.row-body`}>
					<div className="date">
						{thread.snooze_until ? <div className="snooze-info"><i className="far fa-alarm-clock" />{snoozeDate.format('MMMM D')} at {snoozeDate.format('ha')}</div> : null}
						<strong className="minor">{timeAwareDate(date, true)}</strong>
					</div>
					<div key={`thread.${thread.id}.main`}>
						{indicators()}
						<div className="participants comma-section" key={`thread.${thread.id}.participants`}>
							{thread.participants.filter(participant => !participant.emailAddressValues().includes(auth.user.email_address)).map(participant => {
								return <span key={participant.id} className="post-comma">{participant.displayName()}</span>
							})}
						</div>
						<div className="subject" key={`thread.${thread.id}.subject`}>
							{thread.displaySubject()} <span className="message-count" title={`${pluralize('messages', thread.message_count, true)} in this thread`}>{thread.message_count}</span>
						</div>
						<div className="etc">{snippet()}</div>
					</div>
				</div>
			</RecordRow>
		</div>
	)
}

export default Row