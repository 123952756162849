import { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Avatar from './contact/Avatar'
import { Modalize } from '../../lib/Modalize'
import NewTask from '../tasks/New'
import { useToggle, useAuth } from '../../lib/hooks'
import OutsideClickHandler from '../common/OutsideClickHandler'
import MessageTimeline from './contact/MessageTimeline'
import RecordRow from '../common/RecordRow'

const Row = (props) => {
	const [contact, setContact] = useState(null)
	const [showNewTaskForm, setShowNewTaskForm] = useState(false)
	const [interactionHistory, setInteractionHistory] = useState(null)
	const toggle = useToggle(contact, setContact)
	const auth = useAuth()
	const navigate = useNavigate()
	const location = useLocation()

	useEffect(() => {
		setContact(props.contact)

		if (auth.user.scopeFor('contacts') === 'personal') {
			setInteractionHistory(props.contact.interactionHistoryForMailboxId(auth.user.primaryMailboxId()))
		} else {
			setInteractionHistory(props.contact.globalInteractionHistory(auth.user))
		}
	}, [])

	const employment = () => {
		if (contact.employmentInfo()) {
			if (contact.primaryEmployment().title && contact.primaryEmployment().organization && contact.primaryEmployment().organization.name) {
				return (
					<div className="employment-info"><strong>{contact.employmentInfo().title}</strong> at <strong>{contact.employmentInfo().organization.name}</strong></div>
				);
			} else if (contact.primaryEmployment().title || contact.primaryEmployment().organization) {
				return (
					<div className="employment-info"><strong>{contact.primaryEmployment().title || contact.primaryEmployment().organization.title}</strong></div>
				);
			}
		}
	}

	const createEmailDraft = () => {
		navigate({ pathname: "/drafts/new/", state: { contact: contact, modal: true, returnTo: location.pathname }});
	}

	const getControls = () => {
		return [
			{
				icon: 'fal fa-check-circle',
				longTitle: 'Create a task related to this contact',
				shortTitle: 'Add Task',
				persistControls: showNewTaskForm,
				onClick: () => setShowNewTaskForm(!showNewTaskForm),
				payload: (buttonRef) => <Modalize getRefPoint={() => buttonRef} popUnderClasses="new-task right side white" toggle={setShowNewTaskForm} show={showNewTaskForm}><NewTask record={contact} /></Modalize>
			},
			{
				icon: "fal fa-thumbtack" + (contact.pinned ? " active" : ""),
				longTitle: (contact.pinned ? 'Unpin this contact' : 'Pin this contact'),
				shortTitle: (contact.pinned ? 'Unpin' : 'Pin'),
				onClick: () => toggle('pin')
			},
			{
				icon: 'fal fa-paper-plane',
				longTitle: 'Email this contact',
				shortTitle: 'Email',
				onClick: createEmailDraft
			}
		];
	}

	const contactTimeline = () => {
		if (contact.interactionHistoryForUser(auth.user)) {
			return (
				<MessageTimeline
					messagesByMonth={contact.interactionHistoryForUser(auth.user).monthly_messages}
					style={{ data: { fill: '#fafafa', stroke: '#f2f2f2' } }}
				/>
			)
		}
	}

	const rightCell = () => {
		if (contact.interactionHistoryForUser(auth.user)) {
			return (
				<div className="stats">
					<div className="stat"><i className="fal fa-envelope" /> {contact.interactionHistoryForUser(auth.user).thread_count || 0}</div>
					<div className="stat"><i className="fal fa-file-alt" /> {contact.interactionHistoryForUser(auth.user).file_count || 0}</div>
					<div className="stat"><i className="fal fa-check" /> {contact.task_count}</div>
				</div>
			);
		}
	}

	if (contact) {
		return (
			<div style={{ position: "relative" }}>
				<RecordRow
					link={{ pathname: "/contacts/" + contact.id, state: { resource: contact } }}
					controls={getControls()}
				>
					{contactTimeline()}
					<div className="container">
						<div className="avatar-container"><Avatar contact={contact} inline={true} /></div>
						<div className="main-cell">
							<h4 className="contact-name">{contact.pinned ? <i className="fa fa-thumbtack" /> : null}{contact.displayName()}</h4>
							{employment()}
							<div className="etc">
								{contact.name !== contact.primaryEmailAddress() ? contact.primaryEmailAddress() : null}
							</div>
						</div>
						{rightCell()}
					</div>

				</RecordRow>
			</div>
		)
	}
}

export default Row

//export default Toggleable(CurrentUserAccessor(Row), 'contact');