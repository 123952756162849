import React from 'react';
import SearchInput from './SearchInput';
import Results from './Results';
import { LiveSearch } from '../../lib/LiveSearch';

class SidebarSearch extends React.Component {
	componentDidMount = () => {
	  window.addEventListener('mousedown', this.pageClick, false);
	}

	componentWillUnmount = () => {
	  window.removeEventListener('mousedown', this.pageClick);
	}

	mouseDownHandler = () => {
	  this.localMouseDown = true;
	}

	mouseUpHandler = () => {
	  this.localMouseDown = false;
	}

	pageClick = () => {
		if (!this.localMouseDown) {
			this.props.formToggle();
		}
	}

	render() {
		return (
			<div className="sidebar-search side bottom" onMouseDown={this.mouseDownHandler} onMouseUp={this.mouseUpHandler}>
				<Results search={this.props.search} />
				<div className={"input" + (this.props.processing ? " processing" : '')}>
					<SearchInput search={this.props.search} updateQuery={this.props.updateQuery} autoFocus />
					<i className="fa fa-spinner fa-pulse fa-3x fa-fw" />
				</div>
			</div>
		);
	}
}

export default LiveSearch(SidebarSearch);