import { useState, useEffect } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom'
import { useHotkeys } from 'react-hotkeys-hook'

export const Modal = () => {
	const navigate = useNavigate();
	const location = useLocation()
	let localMouseDown;
	useHotkeys('esc', () => dismiss())

	useEffect(() => {
		window.addEventListener('mousedown', pageClick, false)

		return () => window.removeEventListener('mousedown', pageClick)
	}, [])

	const pageClick = (e) => {
		if (!localMouseDown) {
			dismiss()
		}
	}

	const mouseDownHandler = () => {
		localMouseDown = true
	}

	const mouseUpHandler = () => {
		localMouseDown = false
	}

	const dismiss = () => {
		navigate(location.state?.returnTo || location.state?.backgroundLocation || '/')
	}

	return (
		<div className="layer with-modal">
			<div className="layer-shade"></div>
			<div onMouseDown={mouseDownHandler} onMouseUp={mouseUpHandler}>
				<Outlet />
			</div>
		</div>
	)
}