import Repository from '../lib/Repository';
import File from '../resources/File';

export default class FileRepository extends Repository {
	findByAttachmentId(attachmentId) {
		return this.fetcher.get('/attachments/' + attachmentId).then(json => {
			return this.entityFromJson({ file: json.attachment.file });
		});
	}

	entityClass = File;
	baseUrl = '/files';
	filterFields = ['query', 'participant', 'first_sent_before', 'last_sent_after'];
	sortOptions = ['name', 'size', 'attachment_count', 'last_sent_at'];
	defaultSort = 'last_sent_at';
	defaultOrder = 'desc';
	sliceFilters = {
		images: { icon: 'file-image', name: 'Photos and images', color: '#ccc' },
		documents: { icon: 'file-alt', name: 'Microsoft Word and text documents', color: '#ccc' },
		spreadsheets: { icon: 'file-excel', name: 'Spreadsheets and CSV files', color: '#ccc' },
		presentations: { icon: 'file-powerpoint', name: 'PowerPoint and Keynote presentations', color: '#ccc' },
		pdfs: { icon: 'file-pdf', name: 'PDF documents', color: '#ccc' },
		other: { icon: 'file', name: 'Non-traditional or uncommon file types', color: '#ccc' },
		'from-you': { icon: 'reply', name: 'Files you have sent to others', color: '#ccc' },
		'to-you': { icon: 'share', name: 'Files others have sent to you', color: '#ccc' },
		'pinned-contacts': { icon: 'user-circle', name: 'Files exchanged with pinned contacts', color: '#ccc' },
		pinned: { icon: 'thumbtack', name: 'Pinned files', color: '#ccc' },
		tasked: { icon: 'check-circle', name: 'Files with oustanding tasks', color: '#ccc' },
		shared: { icon: 'rss', name: 'Shared with you' }
	};
}