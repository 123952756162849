import Resource from '../lib/Resource';
import { difference, filter } from 'lodash';
import moment from 'moment';
import Fetcher from '../lib/Fetcher';
import { ShareableRecord } from '../lib/ShareableRecord';

export default class Thread extends ShareableRecord(Resource) {
	getMessages() {
		this.messages.map(message => message.loadParticipantsFromThread(this));

		return this.messages;
	}

	displaySubject() {
		return this.subject || '(No subject)';
	}

	getParticipantByEmailAddress(emailAddress) {
		return this.participants.find(participant => participant.emailAddressValues().includes(emailAddress.toLowerCase()))
	}

	replies() {
		return this.messages.slice(1, this.messages.length);
	}

	readReplies() {
		return difference(this.messages, this.unreadReplies());
	}

	isSnoozed() {
		let snoozedAt = moment(this.snooze_until);

		return snoozedAt.isValid() && snoozedAt.isAfter(moment());
	}

	unsnooze() {
		return new Fetcher(localStorage.token).delete(`/threads/${this.id}/snooze`);
	}

	unreadReplies() {
		if (this._unreadReplies) { return this._unreadReplies; }

		if (this.last_read_at) {
			var read_at = this.last_read_at;
			this._unreadReplies = filter(this.messages, function(message) {
				return message.sent_at > read_at;
			});
		} else {
			this._unreadReplies = this.replies();
		}

		return this._unreadReplies;
	}

	getParticipantCount() {
		return this.participants.length;
	}

	getAttachments() {
		return [].concat.apply([], this.messages.map(message => message.attachments || []));
	}

	getAttachmentCount() {
		return this.getAttachments().length;
	}
}

Thread.fields = [
	'id', 'thread_id', 'subject', 'snippet',  'first_sent_at', 'last_sent_at', { messages: ['Message'] }, 'priority', 'message_count',
	'has_attachments', { participants: ['Contact'] }, 'read_at', 'flagged', 'read', 'resolved', 'snooze_until',
	'mailbox_id', { shares: ['Share'] }, 'participant_names'
];
Thread.modelName = 'Thread';

//export default Object.assign(Thread, mixin);