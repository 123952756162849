import { useState } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import User from '../../resources/User';

const BillingForm = (props) => {
	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [password, setPassword] = useState('')
	const [hasEnteredPassword, setHasEnteredPassword] = useState(false)
	const [passwordValid, setPasswordValid] = useState(null)
	const [organization, setOrganization] = useState('')
	const [processing, setProcessing] = useState(false)
	const [cardComplete, setCardComplete] = useState(false)
	const [error, setError] = useState(null)
	const stripe = useStripe()
	const elements = useElements()

	const handleSubmit = (ev) => {
		ev.preventDefault();

		setProcessing(true)
		setError(null)

		stripe.createToken(elements.getElement('card'), { name: [firstName, lastName].join(' ') }).then(({token}) => {
			props.accountCreator(token, firstName, lastName, organization, password).catch(error => {
				setError(error)
				setProcessing(false)
			});
		});
	}

	const updatePassword = (value) => {
		setPassword(value)

		if (hasEnteredPassword) {
			checkPasswordValidity();
		}
	}

	const monitorCardCompleteness = (card) => {
		if (card.complete !== cardComplete) {
			setCardComplete(card.complete)
		}
	}

	const checkPasswordValidity = () => {
		setPasswordValid(User.validations.password.test.test(password))
	}

	const isReady = () => {
		return cardComplete && firstName !== '' && lastName !== '' && passwordValid;
	}

	const submitButton = () => {
		if (processing) {
			return <i className="fa fa-spinner fa-spin" />;
		} else if (isReady()) {
			return <input type="submit" value="Process payment and get started" className="large green rounded iconless action" />;
		} else {
			return <i className="fa fa-circle-thin" />;
		}
	}

	const planOverview = () => {
		return (
			<div className="plan-reminder">
				You&#8217;re signing up for the <strong>Basic</strong> plan for <strong>$49/month</strong>
				<div className="etc normal-size">
					<p>Your plan includes unlimited emails, contacts, and files, plus 100 contact enrichments per month.  You can add additional mailboxes ($29/month) and users ($19/month) later.</p>
					<p><strong className="minor inline-highlight"><span>To thank you for being a beta user, your first month (one user and mailbox) is on us&mdash;at no charge to you.</span></strong></p>
				</div>
			</div>
		);
	}

	return (
		<div>
			{planOverview()}
			<form className="credit-card-form" onSubmit={handleSubmit}>
				<h4>We just need a bit more to get you started&hellip;</h4>
				<div className="card">
					{
						error
						? <div className="invalid-record">{error.message}</div>
						: null
					}
					<label>What&#8217;s your name?</label>
					<table>
						<tbody>
							<tr>
								<td width="50%">
									<input type="text" style={{ width: 'calc(100% - 3px)' }} className="full" placeholder="First name" onChange={(e) => setFirstName(e.target.value)} autoFocus />
								</td>
								<td width="50%">
									<input type="text" style={{ width: 'calc(100% - 3px)', marginLeft: '3px' }} className="full" placeholder="Last name" onChange={(e) => setLastName(e.target.value)} />
								</td>
							</tr>
						</tbody>
					</table>
					<label>What&#8217;s your organization&#8217;s name?</label>
					<input type="text" className="full" onChange={(e) => setOrganization(e.target.value)} />
					<label>
						Choose a secure password
						<p className="etc">8+ characters, including letters, numbers, special characters</p>
					</label>
					<input type="password" className={"full" + (passwordValid === false ? " error" : "")} onChange={(e) => updatePassword(e.target.value)} onBlur={() => { setHasEnteredPassword(true); checkPasswordValidity() }} />
					<label>Enter your credit card information</label>
					<CardElement onChange={monitorCardCompleteness} style={{
						base: {
							fontWeight: 400,
							fontFamily: "'Whitney SSm A', 'Whitney SSm B', 'helvetica neue', 'helvetica', 'sans-serif'",
							fontSize: '15px'
						}
					}} />
				</div>
				<div className="centered submit-payment" style={{ margin: "10px 0 -10px 0" }}>
					{submitButton()}
				</div>
			</form>
		</div>
	)
}

export default BillingForm