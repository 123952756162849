import { useState, useEffect } from 'react';
import { pull } from 'lodash';
import moment from 'moment';
import Reply from './Reply';

const Replies = ({ thread }) => {
	const [clusteredReplies, setClusteredReplies] = useState([])
	const [expandedReplies, setExpandedReplies] = useState([])

	useEffect(() => {
		let expanded, clustered = [];
		var keys = Object.keys(thread.replies()).map(key => parseInt(key))
		var numUnread = thread.unreadReplies().length;

		if (thread.readReplies().length > 2) {
			clustered = keys.slice(0, numUnread - 1);
		}

		setClusteredReplies(clustered)
		setExpandedReplies(keys.filter(key => !clustered.includes(key)))
	}, [])

	const getClusteredReplies = () => {
		return thread.replies().slice(0, clusteredReplies.length)
	}

	const getUnclusteredReplies = () => {
		return thread.replies().slice(clusteredReplies.length);
	}

	const toggleExpansion = (message) => {
		let newExpandedReplies = expandedReplies;
		let index = thread.replies().indexOf(message);
		expandedReplies.includes(index) ? pull(newExpandedReplies, index) : newExpandedReplies.push(index)

		setExpandedReplies(newExpandedReplies)
	}

	const uncluster = () => {
		setClusteredReplies([])
	}

	const cluster = () => {
		if (getClusteredReplies().length === 0) { return null; }

		return (
			<div className="reply-cluster">
				<div className="uncluster" onClick={uncluster}><strong>{getClusteredReplies().length} replies</strong> between {moment(getClusteredReplies()[0].sent_at).format('MMMM D')} and {moment(getClusteredReplies()[getClusteredReplies().length - 1].sent_at).format('MMMM D')}</div>
			</div>
		);
	}

	return (
		<div>
			{clusteredReplies.length > 0 ? cluster() : null}
			{getUnclusteredReplies().map((message, unreadIndex) => {
				let index = (unreadIndex + clusteredReplies.length);
				let nextIsCollapsed = getUnclusteredReplies()[index + 1] && !expandedReplies.includes(index + 1)
				return <Reply message={message} collapsed={!expandedReplies.includes(index)} toggleExpansion={toggleExpansion} downloadFile={() => {}} nextIsCollapsed={nextIsCollapsed} />
			})}
		</div>
	)
}

export default Replies