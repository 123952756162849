import { useState, useEffect } from 'react'
import { useLocation, Link } from 'react-router-dom'
import EmailDiscoveryRepository from '../../repositories/EmailDiscoveryRepository'
import Mailbox from '../../resources/Mailbox'
import WaitlistForm from '../accounts/WaitlistForm'
import Loading from '../common/Loading'
import * as Mailboxes from './Mailboxes'
import EmailDiscovery from '../../resources/EmailDiscovery'
import Fetcher from '../../lib/Fetcher'

const New = () => {
	const [processing, setProcessing] = useState(false)
	const [error, setError] = useState(null)
	const [authenticated, setAuthenticated] = useState(false)
	const [discovery, setDiscovery] = useState(new EmailDiscovery({}))
	const [mailbox, setMailbox] = useState(new Mailbox({}))
	const [discovering, setDiscovering] = useState(false)
	const [plan, setPlan] = useState('solo')
	const [throttled, setThrottled] = useState(null)
	const [hasInvalidInvitation, setHasInvalidInvitation] = useState(null)
	const [validInvitationCode, setValidInvitationCode] = useState(false)
	const [ready, setReady] = useState(false)
	const fetcher = new Fetcher()
	const location = useLocation()
	const repo = new EmailDiscoveryRepository()
	const invitationCode = new URLSearchParams(location.search).get('invitation')

	useEffect(() => {
		fetcher.get('/accounts/throttle_check').then(throttleJson => {
			setReady(!throttleJson.throttled || !invitationCode) // we aren't throttled OR we need to check our invitation first
			setThrottled(throttleJson.throttled)

			if (invitationCode) {
				fetcher.get(`/waitlistees/${invitationCode}`).then(invitationJson => {
					if (invitationJson.waitlistee) {
						let newDiscovery = discovery;
						newDiscovery.email_address = invitationJson.waitlistee.email_address;
						newDiscovery.invitation_code = invitationJson.waitlistee.invitation_code;

						setDiscovery(newDiscovery)
						setValidInvitationCode(!!invitationJson.waitlistee.invitation_code)
						setReady(true)
					}
				}).catch(() => {
					setReady(true)
					setHasInvalidInvitation(true)
				})
			}
		});
	}, [])


	const handleDiscovery = (discovery) => {
		setDiscovery(discovery)
		setMailbox(discovery.mailbox)
		setError(null)
	}

	const handleDiscoveryError = (error, discovery) => {
		setError(error)
		setDiscovering(false)
	}

	const mailboxForm = () => {
		let form;
		let klass = "main-content";

		if (discovery.discoverable == null) {
			form = <Mailboxes.EmailAddressForm invitationCode={validInvitationCode} repo={repo} error={error} discovery={discovery} handleDiscovery={handleDiscovery} handleDiscoveryError={handleDiscoveryError} />;
			klass += " gray"
		} else {
			form = <Mailboxes.Connection discovery={discovery} />;
		}

		return <div className={klass}>{form}</div>;
	}

	const launched = () => {
		if (validInvitationCode || !throttled) {
			return mailboxForm();
		} else {
			return <WaitlistForm validInvitationCode={validInvitationCode} hasInvalidInvitation={hasInvalidInvitation} />;
		}
	}

	return (
		<div id="main" className="mini standalone">
			<div className="subheading">
				<i className="fa fa-plus-circle" />
				<h2>Sign up for Carom</h2>
				<span className="side-button"><Link to="/sessions" className="header-side-link">sign in<span className="desktop-only"> to your account</span></Link></span>
			</div>

			{ready ? launched() : <Loading inline={true} iconOnly={true} />}
		</div>
	)
}

export default New