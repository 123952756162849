import { useState } from 'react';
import Row from '../tasks/Row';
import Stats from './Stats';
import MilestoneWidgetTaskForm from './MilestoneWidgetTaskForm';
import { Link } from 'react-router-dom';
import { ProgressDonut } from '../../lib/Utilities.js';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

const Widget = (props) => {
	const [showNewTaskForm, setShowNewTaskForm] = useState(false)
	const [counter, setCounter] = useState(1)

	const toggler = (task) => {
		props.milestone.taskCollection().toggleTaskCompletion(task)
		setCounter(counter + 1)
	}

	const tasks = () => {
		if (props.milestone.tasks.length + props.milestone.taskCollection().incompleteTasks().length === 0) {
			return (
				<div className="no-tasks">
					<i className="fal fa-circle" />
					<strong>There aren&#8217;t any incomplete tasks in this milestone. Congrats!</strong>
				</div>
			);
		} else {
			return (
				<div className="milestone-tasks">
					<TransitionGroup>
						{props.milestone.taskCollection().tasksSortedByDueDate().map(task => {
							return (
								<CSSTransition key={task.id} classNames="task-list" timeout={{ enter: 2000, exit: 700 }}>
									<Row task={task} toggler={toggler} location={props.location} hideMilestone={true} />
								</CSSTransition>
							)
						})}
					</TransitionGroup>
				</div>
			);
		}
	}

	const addToMilestone = (task) => {
		props.milestone.taskCollection().addTask(task)
//		forceUpdate();
	}

	const toggleNewTaskForm = () => {
		setShowNewTaskForm(!showNewTaskForm)
	}

	const newTaskForm = () => {
		if (showNewTaskForm) {
			return <MilestoneWidgetTaskForm milestone={props.milestone} addToMilestone={addToMilestone} toggleForm={toggleNewTaskForm} />
		}
	}

	return (
		<div className="milestone-widget">
			<h4>
				<ProgressDonut percentage={props.milestone.progress()} />
				<i className={"fa fa-plus" + (showNewTaskForm ? ' active' : '')} onClick={toggleNewTaskForm} />
				<i className="fa fa-thumbtack" onClick={props.togglePin.bind(this, props.milestone)} />
				<Link to={{ pathname: "/milestones/" + props.milestone.id, state: { resource: props.milestone, modal: true, returnTo: "/milestones" } }}><span>{props.milestone.name}</span></Link>
			</h4>
			{
				props.milestone.tasks.length > 0
				? <div className="milestone-stats"><Stats milestone={props.milestone} /></div>
				: null
			}
			{newTaskForm()}
			{tasks()}
		</div>
	)
}

export default Widget