import { useState } from 'react';
import MailboxRepository from '../../repositories/MailboxRepository';
import { Form } from '../../lib/forms/Form';
import { TextInput } from '../../lib/forms/TextInput';
import { Label } from '../../lib/forms/Label';
import { BooleanToggle } from '../../lib/forms/BooleanToggle';
import { Submit } from '../../lib/forms/Submit';
import { PasswordInput } from '../../lib/forms/PasswordInput';
import { includes } from 'lodash';

const MicrosoftConnection = ({ connectUrl, showImapSettings }) => {
	return (
		<div style={{ marginTop: "20px" }}>
			<a href={connectUrl}  className="large action iconless" target="popup">Connect with <img src="/images/logos/outlook.png" alt="Outlook logo" className="connect-icon" /></a>
			<div className="etc normal-size" style={{ marginTop: "10px" }}>or <span className="underline pointer" onClick={showImapSettings}>enter IMAP settings manually</span></div>
		</div>
	)
}

const GoogleConnection = ({ connectUrl, showImapSettings }) => {
	return (
		<div style={{ marginTop: "20px" }}>
			<a href={connectUrl} className="large action iconless">Connect via your <img src="/images/logos/gmail-icon.png" alt="Gmail icon" className="connect-icon" /> <strong>Google</strong> account</a>
			<div className="etc normal-size" style={{ marginTop: "10px" }}>or <span className="underline pointer" onClick={showImapSettings}>enter IMAP settings manually</span></div>
		</div>
	)
}

const AccountConnection = (props) => {
	const oauthable = ['microsoft', 'google']
	const [showImapSettings, setShowImapSettings] = useState(false)
	const [mailbox, setMailbox] = useState(props.mailbox || (props.discovery ? props.discovery.mailbox : null))
	const [connectUrl, setConnectUrl] = useState((props.mailbox || props.discovery) ? (props.mailbox || props.discovery).connect_url : null)

	const imapSettingsForm = () => {
		return (
			<div className="imap-settings with-heading">
				<Form object={mailbox} repository={new MailboxRepository()}>
					<div style={{ margin: "-10px -10px 20px -10px", padding: "10px 20px", background: "#efefef" }}>
						{(() => {
							if (mailbox.hasConnectionSettings()) {
								return (
									<>
										<strong>Here&#8217;s our best guess about your email configuration.</strong>
										<br />Please make sure these settings are correct so we can connect to your inbox.
									</>
								);
							} else {
								return <strong>Please enter your email connection settings.</strong>;
							}
						})()}
					</div>
					<table className="clean gray left middle">
						<tbody>
							<tr>
								<td width="30%" className="right"><Label className="standalone" attribute="configuration.imap.username">Account Username</Label></td>
								<td width="70%">
									<TextInput name="configuration.imap.username" defaultValue={mailbox.hasConnectionSettings() ? mailbox.configuration.imap.username : null} className="large full" autoFocus />
								</td>
							</tr>
							<tr>
								<td width="30%" className="right"><Label className="standalone" attribute="configuration.imap.server">Server Address</Label></td>
								<td width="70%">
									<TextInput name="configuration.imap.server" defaultValue={mailbox.hasConnectionSettings() ? mailbox.configuration.imap.server : null} className="large full" />
								</td>
							</tr>
							<tr>
								<td width="30%" className="right"><Label className="standalone" attribute="configuration.imap.password">Email Password</Label></td>
								<td width="70%">
									<PasswordInput name="configuration.imap.password" className="large full" />
								</td>
							</tr>
							<tr>
								<td width="30%" className="right"><Label className="standalone" attribute="configuration.imap.port">Port</Label></td>
								<td width="70%">
									<TextInput name="configuration.imap.port" defaultValue={mailbox.hasConnectionSettings() ? mailbox.configuration.imap.port : null} className="large centered" style={{ width: "50px" }} />
								</td>
							</tr>
							<tr>
								<td width="30%" className="right"><Label className="standalone" attribute="configuration.imap.use_ssl">Use <acronym title="Secure Sockets Layer">SSL</acronym></Label></td>
								<td width="70%">
									<BooleanToggle name="configuration.imap.use_ssl" defaultValue={mailbox.hasConnectionSettings() ? mailbox.configuration.imap.use_ssl : null} />
								</td>
							</tr>
						</tbody>
					</table>
					<div style={{ marginTop: "10px" }}>
						<Submit value="Connect your email account" className="green rounded medium" />
					</div>
				</Form>
			</div>
		);
	}

	const connectionForm = () => {
		if (!showImapSettings && includes(oauthable, mailbox.configuration.provider)) {
			switch (mailbox.configuration.provider) {
				case 'microsoft':
					return <MicrosoftConnection account={props.account} mailbox={mailbox} showImapSettings={() => setShowImapSettings(true)} connectUrl={connectUrl} />
				case 'google':
					return <GoogleConnection account={props.account} mailbox={mailbox} connectUrl={connectUrl} showImapSettings={() => setShowImapSettings(true)} />
				default:
				return null;
			}
		} else {
			return imapSettingsForm();
		}
	}

	const errorMessage = () => {
		if (mailbox.unableToAuthorize()) {
			return (
				<div className="error left">
					<p><strong>We were unable to connect to this mailbox.</strong></p>
					{
						!showImapSettings && includes(oauthable, mailbox.configuration.provider)
						? <p>Please re-authorize through your mail provider or enter your IMAP settings manually.</p>
						: <p>Please double-check your IMAP settings below.</p>
					}
				</div>
			);
		}
	}

	return (
		<div className="centered">
			<div className="green" style={{ fontSize: "20px", fontWeight: "600", marginBottom: "25px", marginTop: "25px" }}>{mailbox.email_address}</div>
			<div className="connect-account">
				{errorMessage()}
				{connectionForm()}
			</div>
		</div>
	)
}

export default AccountConnection