import { useState, useEffect, useRef } from 'react';
import { EditorState, RichUtils, convertFromRaw, ContentState } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createImagePlugin from '@draft-js-plugins/image';

// Custom overrides for "code" style.
const styleMap = {
	CODE: {
		backgroundColor: 'rgba(0, 0, 0, 0.05)',
		fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
		fontSize: 16,
		padding: 2,
	},
};

function getBlockStyle(block) {
	switch (block.getType()) {
		case 'blockquote': return 'RichEditor-blockquote';
		default: return null;
	}
}

// eslint-disable-next-line
const BLOCK_TYPES = [
	{label: 'H1', style: 'header-one'},
	{label: 'H2', style: 'header-two'},
	{label: 'Blockquote', style: 'blockquote'},
	{label: 'UL', style: 'unordered-list-item'},
	{label: 'OL', style: 'ordered-list-item'},
	{label: 'Code Block', style: 'code-block'},
];
/*
const BlockStyleControls = (props) => {
	const {editorState} = props;
	const selection = editorState.getSelection();
	const blockType = editorState
		.getCurrentContent()
		.getBlockForKey(selection.getStartKey())
		.getType();

	return (
		<div className="RichEditor-controls">
			{BLOCK_TYPES.map((type) =>
				<StyleButton
					active={type.style === blockType}
					label={type.label}
					onToggle={props.onToggle}
					style={type.style}
				/>
			)}
		</div>
	);
};
*/
var BLOCK_STYLES = [
	{icon: 'list', style: 'unordered-list-item'},
	{icon: 'list-ol', style: 'ordered-list-item'}
];

var INLINE_STYLES = [
	{icon: 'bold', style: 'BOLD'},
	{icon: 'italic', style: 'ITALIC'},
	{icon: 'underline', style: 'UNDERLINE'}
];

const InlineStyleControls = (props) => {
	console.log(props)
	var currentStyle = props.editorState.getCurrentInlineStyle();

	return (
		<div className="editor-controls">
			<div className="group">
				{INLINE_STYLES.map(type =>
					<i key={"button." + type.style} className={"far fa-" + type.icon + (currentStyle.has(type.style) ? " active" : "")} onClick={props.onClickInlineStyle.bind(null, type.style)} />
				)}
			</div>
			<div className="group">
				{BLOCK_STYLES.map(type =>
					<i key={"button." + type.style}  className={"far fa-" + type.icon + (currentStyle.has(type.style) ? " active" : "")} onClick={props.onClickBlockType.bind(null, type.style)} />
				)}
			</div>

			{/*<span className="attach" onClick={props.selectFile}><i className="far fa-paperclip" />Attach a File</span>*/}
		</div>
	);
};


export const RichEditor = (props) => {
	const [editorState, setEditorState] = useState(null)
	const editor = useRef(null);

	useEffect(() => {
		if (props.defaultValue && Object.keys(props.defaultValue).length > 0) {
			setEditorState(EditorState.createWithContent(props.defaultValue instanceof ContentState ? props.defaultValue : convertFromRaw(props.defaultValue)))
		} else {
			setEditorState(EditorState.createEmpty())
		}
	}, [])


//		this.focus = () => this.refs.editor.focus();

	const onChange = function(newEditorState) {
		setEditorState(editorState)
		props.onEditorChange(newEditorState);

	}

	const handleKeyCommand = (command) => {
		const newState = RichUtils.handleKeyCommand(editorState, command);
		if (newState) {
			onChange(newState);
			return true;
		}
		return false;
	}

	const toggleBlockType = (blockType) => {
		onChange(
			RichUtils.toggleBlockType(
				editorState,
				blockType
			)
		);
	}

	const toggleInlineStyle = (inlineStyle) => {
		this.onChange(
			RichUtils.toggleInlineStyle(
				editorState,
				inlineStyle
			)
		);
	}

	if (editorState) {
		return (
			<div>
				<InlineStyleControls
					editorState={editorState}
					onClickInlineStyle={toggleInlineStyle}
					onClickBlockType={toggleBlockType}
					addAttachment={props.addAttachment}
				/>
				<div>
					<Editor
						blockStyleFn={getBlockStyle}
						customStyleMap={styleMap}
						editorState={editorState}
						handleKeyCommand={handleKeyCommand}
						onChange={onChange}
						value={"test value"}
						ref={editor}
						spellCheck={true}
						plugins={[createImagePlugin()]}
					/>
				</div>
			</div>
		)
	}
}

