import { useState } from 'react'
import useFormInput from './useFormInput'

export const IconToggle = (props) => {
	const formInput = useFormInput(props)
	const [value, setValue] = useState(formInput.valueFor(props.name))

	const toggle = () => {
		let newValue = (["true", true, 1].includes(formInput.valueFor(props.name)) ? null : true);

		setValue(newValue)
		formInput.handleChange(props.name, newValue)
	}

	return (
		<span onClick={toggle} title={props.title} className={"toggle-icon " + props.name + (["true", true, 1].includes(value)  ? "" : " off")}>
			{props.children}
		</span>
	)
}
