import { useState } from 'react';
import { useAuth } from '../../lib/useAuth'
import { Helmet } from 'react-helmet';
import SecondFactorRepository from '../../repositories/SecondFactorRepository';
import SecondFactor from '../../resources/SecondFactor';
import { Form } from '../../lib/forms/Form';
import { TextInput } from '../../lib/forms/TextInput';
import { Submit } from '../../lib/forms/Submit';

const MultiFactorAuthentication = () => {
	const [secondFactor, setSecondFactor] = useState(new SecondFactor({}))
	const [confirmationCode, setConfirmationCode] = useState(null)
	const [confirmationError, setConfirmationError] = useState(false)
	const auth = useAuth()

	const updateConfirmationCode = (_, e) => {
		setConfirmationCode(e.target.value)
		setConfirmationError(false)
	}

	const onSave = (factor) => {
		setSecondFactor(factor)
		auth.loadUser()
	}

	const verificationForm = () => {
		return (
			<div>
				<p className="minor strong centered">Use your authentication app to scan the QR code, then enter the code from your app to verify your setup.</p>
				<div className="qr-code-parent">
					<img src={secondFactor.qr_code} />
					{confirmationError ? <div className="error" style={{ margin: "10px -20px -20px -20px", borderRadius: 0 }}>You entered an incorrect or outdated code.</div> : null}
					<label for="confirmation_code">Enter your 6-digit confirmation code</label>
					<TextInput name="confirmation_code" id="confirmation_code" type="text" maxLength="6" autoFocus changeHandler={updateConfirmationCode} />
					{
						confirmationCode && confirmationCode.length == 6
						? <div className="submit" style={{ margin: "-10px 0" }}><Submit value="Confirm your second factor" className="large green" /></div>
						: null
					}
				</div>
			</div>
		)
	}

	const creationForm = () => {
		return (
			<div>
				<p className="minor strong" style={{ fontSize: "15px", lineHeight: "19px" }}>Two-factor authentication provides extra security for your account by requiring not just your password, but also a one-time code available on your phone.</p>
				<p>You can use apps like Google Authenticator, Microsoft Authenticator, and Authy to generate these one-time codes for future sign-ins.</p>
				<div className="submit" style={{ margin: 0 }}>
					<Submit value="Enable two-factor authentication" className="large green" />
				</div>
			</div>
		)
	}

	const enabledMessage = () => {
		return (
			<div className="mfa-enabled">
				<i className="fa fa-check" />
				<h2>Two-factor authentication is enabled</h2>
				<div className="backup-codes">
					<h3>Your backup codes</h3>
					<p>Please save these one-time-use backup codes and keep them safe.  If you lose access to your authenticator app, you can use these codes instead of your password to sign in.</p>
					<ul>
						{secondFactor.backup_codes.map(backup_code => <li>{backup_code}</li>)}
					</ul>
				</div>
			</div>
		)
	}

	const secondFactorAlreadyRequired = () => {
		return (
			<div className="mfa-enabled">
				<i className="fa fa-check" />
				<h2>Two-factor authentication is enabled</h2>
				<p>You can disable two-factor authentication from your settings page.</p>
			</div>
		)
	}

	const main = () => {
		if (!secondFactor.id && auth.user.requiresTwoFactorAuthentication()) {
			return secondFactorAlreadyRequired();
		} else if (secondFactor.id) {
			if (secondFactor.qr_code) {
				return verificationForm();
			} else if (secondFactor.enabled_at) {
				return enabledMessage();
			}
		} else {
			return creationForm();
		}
	}

	return (
		<div className="main mini">
			<Helmet title="Enable two-factor authentication" />
			<div className="subheading">
				<i className="fa fa-key" />
				<h2>Enable two-factor authentication</h2>
			</div>
			<div className="main-content gray">
				<Form object={secondFactor} repository={new SecondFactorRepository(auth.user)} onSave={onSave} onError={() => setConfirmationError(true)}>
					{main()}
				</Form>
			</div>
		</div>

	)
}

export default MultiFactorAuthentication