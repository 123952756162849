import React from 'react';
import Search from '../resources/Search';

export const LiveSearch = (SearchContainer) => class extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			search: new Search({ limit: 6 }),
			processing: false,
			loaded: false
		}
	}

	updateQuery = (query) => {
		let search = this.state.search;
		search.setQuery(query);
		this.setState({ search: search });

		this.queueDelayedSearch();
	}

	executeSearch() {
		this.setState({ processing: true }, () => {
			let search = this.state.search;
			search.execute().then((search) => {
				this.setState({ search: search, processing: false, loaded: true})
			})
		})
	}

	queueDelayedSearch = () => {
		this.clearTimer();
		this.timer = setTimeout(() => {
			this.executeSearch();
		}, 1000);
	}

	clearTimer() {
		clearTimeout(this.timer);
	}

	componentWillUnmount() {
		this.clearTimer();
	}

	render() {
		return <SearchContainer {...this.state} {...this.props} updateQuery={this.updateQuery} />;
	}
}