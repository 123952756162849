import { useState, useEffect } from 'react';
import Widgets from './Widgets';
import Row from './Row';
import { NoResults } from '../common/Common';

const MobileIndex = (props) => {
	const [activeSection, setActiveSection] = useState(null)

	useEffect(() => {
		setActiveSection(getDefaultActiveSection())
	}, [])

	const getDefaultActiveSection = () => {
		if (props.visibleTypes.includes('pinnedMilestones')) {
			return 'pinnedMilestones';
		} else if (props.visibleTypes.includes('unpinnedMilestones')) {
			return 'unpinnedMilestones';
		} else {
			return 'archivedMilestones';
		}
	}

	const list = (milestones) => {
		return (
			<ul className="table milestone-table">
				{milestones.map(milestone => {
					return <Row key={milestone.id} milestone={milestone} togglePin={props.togglePin} />;
				})}
			</ul>
			);
		}

	let nav;
	let types = { pinnedMilestones: 'Pinned', unpinnedMilestones: 'Other', archivedMilestones: 'Archived' }
	nav = (
		<div className="subheader-nav mobile-only">
			<ul className="sections">
				{Object.keys(types).map(k => {
					if (props.visibleTypes.includes(k) || true) {
						return <li><span className={"console-button" + (activeSection === k ? ' active' : '')} onClick={() => this.setState({ activeSection: k })}>{types[k]} <span className="count">{props[k].length}</span></span></li>
					} else { return null; }
				})}
			</ul>
		</div>
	);

	let activeSectionDisplay;
	if (props[activeSection].length === 0) {
		activeSectionDisplay = <NoResults
			message={<span>You don&#8217;t have any {types[activeSection].toLowerCase()} milestones</span>}
			subMessage="You can use the tabs above to view other milestones"
		/>
	} else {
		activeSectionDisplay = activeSection === 'pinnedMilestones'
			? <Widgets milestones={props.pinnedMilestones} togglePin={props.togglePin} location={props.location} />
			: this.list(props[activeSection])
	}

	return (
		<div>
			{nav}
			{activeSectionDisplay}
		</div>
	)
}

export default MobileIndex