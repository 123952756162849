import React, { useState, useEffect, useRef } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useAuth } from '../../lib/useAuth'
import { Filterable } from '../../lib/Filterable';
import AsyncFetcher from '../../lib/AsyncFetcher';
import Avatar from '../contacts/contact/Avatar';
import Loading from '../common/Loading';
import { useLiveFilter } from '../../lib/useLiveFilter';
import Share from '../../resources/Share';
import ShareRepository from '../../repositories/ShareRepository';
import { Form } from '../../lib/forms/Form';
import { BooleanToggle } from '../../lib/forms/BooleanToggle';
import { TextArea } from '../../lib/forms/TextArea';
import pluralize from 'pluralize';

const ShareSheet = (props) => {
	const auth = useAuth()
	const repo = new ShareRepository(props.record)
	const fetcher = new AsyncFetcher(localStorage.token)
	const [addSharees, setAddSharees] = useState(!props.record.shared)
	const [currentShareRecipientIds, setCurrentShareRecipientIds] = useState([])
	const [showOptions, setShowOptions] = useState(true)
	const [chosenUsers, setChosenUsers] = useState([])
	const [showNote, setShowNote] = useState(false)
	const [saving, setSaving] = useState(false)
	const [record, setRecord] = useState(props.record)
	const [share, setShare] = useState(new Share({}))
	const [loaded, setLoaded] = useState(false)
	const [type, setType] = useState('shared')
	const [globalizing, setGlobalizing] = useState(false)
	const filterInput = useRef(null)
	const [filter, filterMatches, updateQuery, removeOption] = useLiveFilter(['first_name', 'last_name', 'email_address'])
/*	const [test] = useLiveFilter(
		props.account.users.filter(user => {
			return (user.id !== props.currentUser.id && !currentShareRecipientIds.includes(user.id))
		})
	)*/

	useEffect(() => {
		const loadExistingShares = async () => {
			let json = await fetcher.get(`/${pluralize(props.record.constructor.modelName.toLowerCase())}/${props.record.id}/shares/recipients`)
			setLoaded(true)

			setCurrentShareRecipientIds(json.share_recipients.map(recipient => recipient.user_id))
		}

		loadExistingShares()

		filter.loadOptions(
			auth.account.users.filter(user => {
				return (user.id !== auth.user.id && !currentShareRecipientIds.includes(user.id))
			})
		)
	}, [])


//		loadExistingShares();
/*
	const loadExistingShares = () => {
		fetcher.get(`/${pluralize(props.record.constructor.modelName.toLowerCase())}/${props.record.id}/shares/recipients`).then(resultJson => {
			props.filter.setSearchFields(['first_name', 'last_name', 'email_address'])
			props.onSelect(selectListOption);

			setFilterOptions();

			setState({
				loaded: true,
				currentShareRecipientIds: resultJson.share_recipients.map(recipient => recipient.user_id)
			})
		});
	}
*/
	const setFilterOptions = () => {
		props.filter.loadOptions(
			props.account.users.filter(user => {
				return (user.id !== props.currentUser.id && !currentShareRecipientIds.includes(user.id))
			})
		);
	}

	const selectListOption = (user) => {
		if (user) {
			let newChosenUsers = [...chosenUsers, user]
			setChosenUsers(newChosenUsers)

			setShare(new Share(Object.assign(share, { user_ids: newChosenUsers.map(user => user.id) })))

			removeOption(user);
		}
	}

	const matches = () => {
		if (filterMatches.length === 0) { return null; }

		// TODO
		let mouseDownHandler, mouseUpHandler
		//  onMouseDown={mouseDownHandler} onMouseUp={mouseUpHandler}

		return (
			<div className="content-block white">
				<ul className="table user-filter-list">
					{filterMatches.map(user => {
						return (
							<li key={"user." + user.id} onClick={() => selectListOption(user)}>
								<div className={"block pointer" + (props.active === user ? " active" : "")}>
									<div className="avatar-container"><Avatar contact={user} inline={true} /></div>
									<div className="name">{user.displayName()}</div>
								</div>
							</li>
						);
					})}
				</ul>
			</div>
		);
	}

	const unshare = (user) => {
		fetcher.delete(`${repo.baseUrl(record)}/recipients`, { user_id: user.id });

		setCurrentShareRecipientIds([...currentShareRecipientIds.filter(recipientId => (recipientId != user.id))])

		setFilterOptions()
	}

	const removeChosen = (user) => {
		filter.addOption(user);

		setChosenUsers([...chosenUsers.filter(chosen => chosen.id !== user.id)])
	}

	const shareeRow = (user, unshareFunction) => {
		return (
			<CSSTransition key={`sharee.${user.id}`} classNames="notifications" timeout={{ enter: 2000, exit: 800 }}>
				<div className="sharee">
					<div className="avatar-container"><Avatar contact={user} inline={true} /></div>
					<div className="name"><strong>{user.displayName()}</strong></div>
					<div className="unshare"><i className="fa fa-times-circle" onClick={() => unshareFunction(user)} /></div>
				</div>
			</CSSTransition>
		);
	}

	const existingShares = () => {
		if (currentShareRecipientIds.length > 0) {//record.isShared()) {
			return (
				<div className="existing-shares">
					<h5>Currently shared with {pluralize('other user', currentShareRecipientIds.length, true)}</h5>
					<div className="sharees">
						<TransitionGroup>
							{currentShareRecipientIds.map(userId => {
								let user = auth.account.getUserById(userId);

								return shareeRow(user, (user) => unshare(user));
							})}
						</TransitionGroup>
					</div>
				</div>
			);
		}
	}

	const onShare = () => {
		record.shares = (record.shares || []).concat(new Share({
			recipients: chosenUsers
		}))


		setAddSharees(false)
		setRecord(Object.create(Object.assign(record, { shares: [...record.shares, new Share({ recipients: chosenUsers })] })))
		setCurrentShareRecipientIds([...currentShareRecipientIds, ...chosenUsers.map(user => user.id)])
		setChosenUsers([])
		setSaving(false)
/*
		let record = record;
		record.shares = (record.shares || []).concat(new Share({
			recipients: chosenUsers
		}))

		setState({
			addSharees: false,
			record: record,
			currentShareRecipientIds: currentShareRecipientIds.concat(chosenUsers.map(user => user.id)),
			chosenUsers: [],
			saving: false
		})
*/
	}

	const submitOptions = () => {
		if (chosenUsers.length === 0) { return null; }

		return (
			<div className="submit-with-options">
				<Form object={share} repository={repo} beforeSubmit={() => setSaving(true)} onSave={onShare}>
					{showNote ? <TextArea name="note" placeholder="Let your colleagues know what you're sharing and why" /> : null}
					{showNote ? null : <span className="text-button add-note" title="Let your colleagues know what you're sharing and why" onClick={() => setShowNote(true)}>Add a note&hellip;</span>}
					<input type="submit" className="tiny rounded green button" value={saving ? "Saving..." : "Share"} />
				</Form>
			</div>
		);

	}

	const addShareesForm = () => {
		if (addSharees) {
			let blankSlate = (
				<div className="no-chosen-users">
					<i className="fa fa-share" /> Choose a user to share with
				</div>
			)

			return (
				<div className="add-sharee-section">
					<div className="input">
						<input type="text" placeholder="Search by user name" onFocus={() => setShowOptions(true)} onBlur={() => {}} onChange={updateQuery} onKeyDown={props.onKeyDown} ref={filterInput} autoFocus />
						{showOptions ? matches() : null}
					</div>
					{chosenUsers.length === 0 ? blankSlate : null}
					<div className="chosen-users sharees">
						{chosenUsers.map(user => {
							return shareeRow(user, (user) => removeChosen(user));
						})}
					</div>
					{submitOptions()}
				</div>
			);
		}
	}

	const shareType = () => {
		let shareAssociatedInput
		if (props.record.constructor.name === 'Contact') {
			shareAssociatedInput = (
				<div className="share-associated">
					<label>Include emails and files</label>
					<BooleanToggle />
				</div>
			)
		}

		if (props.globalizable && !props.record.global) {
			return (
				<>
					{shareAssociatedInput}
					<div className="share-type">
						<div><span className={(type !== 'global' ? 'active' : '')} onClick={() => setType('shared')}>Choose colleagues</span></div>
						<div><span className={(type === 'global' ? 'active' : '')} onClick={() => setType('global')}>Share with everyone</span></div>
					</div>
				</>
			);
		}
	}

	const globalForm = () => {
		return (
			<div className="global-info">
				<h3>Do you want to make this contact visible to everyone?</h3>
				<p>Emails and files you&#8217;ve exchanged and private tasks you&#8217;ve created will still be private, but your colleagues will see this person&#8217;s contact information and will be able to add comments.</p>
				<div className="submit">
					{
						globalizing
						? <i className="fa fa-spinner fa-spin" />
						: <span className="tiny red button" onClick={globalize}>Share with everyone</span>
					}
				</div>
			</div>
		);
	}

	const globalize = async () => {
		let json = fetcher.post(`/contacts/${record.id}/globalize`)
/*
		setState({ globalizing: true }, () => {
			fetcher.post(`/contacts/${record.id}/globalize`).then(json => {
				let record = record;
				record.global = true;
				props.updateRecord(record);
				props.toggle();
			});
		});
*/
	}

	const main = () => {
		if (type === 'shared') {
			return (
				<>
					{addShareesForm()}
					<div key={currentShareRecipientIds}>{existingShares()}</div>
				</>
			);
		} else {
			return globalForm();
		}
	}

	return (
		<div className="share-sheet" onClick={e => e.stopPropagation()}>
			<div className="heading">
				<i className="fa fa-rss" />Share with other users
				{addSharees ? null : <span className="add-sharees text-button" onClick={() => setAddSharees(true)}>add</span>}
			</div>

			{shareType()}

			{loaded ? main() : <div style={{ padding: "0 10px" }}><Loading inline={true} /></div>}
		</div>
	)
}


export default ShareSheet