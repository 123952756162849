import React from 'react';

const mailboxColor = function(record, user) {
	if (user.mailbox_subscriptions) {
		let subscription = user.mailbox_subscriptions.find(sub => sub.mailbox_id === record.mailbox_id);
		if (subscription && subscription.color) {
			return subscription.color;
		}
	}
}

export const mailboxIndicator = function(record, user) {
	let color = mailboxColor(record, user);

	if (color) {
		return <i className="fa fa-circle mailbox-indicator-dot" style={{ color: color }} />;
	}
}

export const mailboxIndicatorClass = function(record, user) {
	let color = mailboxColor(record, user);

	if (color) {
		return ` mailbox-indicator ${color} `;
	} else {
		return '';
	}
}