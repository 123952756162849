import React from 'react';
import _ from 'lodash';
import ListFilter from './ListFilter';

export var Filterable = (Component) => class extends React.Component {
	state = {
		query: '',
		active: null,
		preloadedListOptions: [],
		listOptions: [],
		showOptions: false,
		filterMatches: [],
		selected: []
	}

	constructor(props) {
		super(props);

		this.filter = new ListFilter();
	}

	onSelect = (callback) => {
		this.onSelectCallback = callback;
	}

	componentDidMount = () => {
		window.addEventListener('mousedown', this.pageClick, false);
	}
	componentWillUnmount = () => {
		window.removeEventListener('mousedown', this.pageClick);
	}
	mouseDownHandler = () => {
		this.localMouseDown = true;
	}
	mouseUpHandler = () => {
		this.localMouseDown = false;
	}

	updateQuery = (e) => {
		let query = e.target.value;
		let filterMatches = this.filter.queryPreloaded(query);
		let active = this.state.active === null ? filterMatches[0] : this.state.active;

		this.setState({ query, filterMatches, active }, () => {
			if (this.filter.hasQueryableCollection()) {
				this.queueDelayedRemoteSearch()
			}
		});
	}

	queueDelayedRemoteSearch = () => {
		this.clearTimer();

		this.timer = setTimeout(() => {
			if (!this.filter.isFullyPreloaded() && this.state.query !== "") {
				this.filter.queryRemote(this.state.query).then(records => {
					if (records) {
						this.setState({ filterMatches: records });
					}
				})
			}
		}, 1000);
	}

	clearTimer() {
		clearTimeout(this.timer);
	}

	componentWillUnmount() {
		this.clearTimer();
	}

	pageClick = (e) => {
		if (!this.localMouseDown) {
			this.setState({ showOptions: false });
		}
	}

	onKeyDown = (e) => {
		if (e.key === 'ArrowUp') {
			e.preventDefault();
			this.moveUp();
		} else if (e.key === 'ArrowDown') {
			e.preventDefault();
			this.moveDown();
		} else if (e.key === 'Enter') {
			e.preventDefault();
			this.selectActive();
		}
	}

	initializeActiveRow = (location = 'top') => {
		var active = this.state.filterMatches[(location === 'bottom' ? this.state.filterMatches.length - 1 : 0)];

		this.setState({ active: active })
	}

	clearActiveRow = () => {
		this.setState({ active: null })
	}

	moveUp = () => {
		if (this.hasActiveRow()) {
			var newKey = this.state.filterMatches.indexOf(this.state.active) - 1;

			this.setState({ active: this.state.filterMatches.hasOwnProperty(newKey) ? this.state.filterMatches[newKey] : this.state.filterMatches[this.state.filterMatches.length - 1] });
		} else {
			this.initializeActiveRow('bottom');
		}
	}

	moveDown = () => {
		if (this.hasActiveRow()) {
			var newKey = this.state.filterMatches.indexOf(this.state.active) + 1;

			this.setState({ active: this.state.filterMatches.hasOwnProperty(newKey) ? this.state.filterMatches[newKey] : this.state.filterMatches[0] });
		} else {
			this.initializeActiveRow();
		}
	}

	selectActive = () => {
		this.selectListOption(this.state.active)
		this.clearInput()
	}

	selectItem = (item) => {
		this.selectListOption(item);
		this.clearInput()

//		ReactDOM.findDOMNode(this.refs.filterInput).focus();
	}

	removeSelected = (item) => {
		var selected = this.state.selected;
		_.remove(selected, function(current) {
			return current === item;
		});

//		if (this.props.updateHandler) { this.props.updateHandler(selectedUsers); }

		this.setState({ selected: selected });
	}

	clearInput = () => {
		this.setState({ query: '', filterMatches: [] });
//		ReactDOM.findDOMNode(this.refs.filterInput).value = "";
	}

	hasActiveRow = () => {
		return (this.state.active != null);
	}

	selectListOption = (option) => {
		this.setState({ query: '', active: null }, () => {
			if (this.onSelectCallback) {
				this.onSelectCallback(option);
			}
		});
	}

	render() {
		return <Component
			{...this.props}
			{...this.state}
			filter={this.filter}
			updateQuery={this.updateQuery}
			onKeyDown={this.onKeyDown}
			selectItem={this.selectItem}
			onSelect={this.onSelect}
			removeSelected={this.removeSelected}
		/>;
	}
}