export default class ViewHistory {
	length = 10

	constructor(user) {
		this.user = user;
	}

	addRecord(record, type) {
		let history = this.getStoredHistory();
		let list = (history[type] || []);

		list = list.filter(existing => { return existing.id !== record.id })

		if (list.length > 6) { list.pop(); }
		list.unshift(this.serializeRecord(record, type));

		history[type] = list;
		localStorage.setItem('viewHistory', JSON.stringify(history));
	}

	getLatest(type) {
		return this.getStoredHistory()[type] || [];
	}

	getStoredHistory() {
		return JSON.parse(localStorage.getItem('viewHistory')) || { contacts: [], threads: [], files: [], organizations: [] };
	}

	serializeRecord(record, type) {
		switch (type) {
			case 'contacts':
				return {
					id: record.id,
					name: record.displayName(),
					email_address: record.email_address,
					avatar: record.avatar
				};
			case 'organizations':
				return {
					id: record.id,
					name: record.name
				};
			case 'threads':
				return {
					id: record.id,
					subject: record.displaySubject()
				};
			case 'files':
				return {
					id: record.id,
					name: record.displayName()
				};
			default:
				return {};
		}
	}
}