import React from 'react';
import { Link } from 'react-router-dom';

const ThreadResultRow = ({ record }) => {
	return (
		<Link to={"/email/" + record.id}>
			{record.subject}
		</Link>
	)
}

export default ThreadResultRow