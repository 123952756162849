import { useState } from 'react'
import { Loading } from '../common/Common'

const ImagePreview = ({ file }) => {
	const [loaded, setLoaded] = useState(false)

	return (
		<div className="image">
			<img
				src={file.downloadUrl()}
				style={{ display: (loaded ? "block" : "none") }}
				onLoad={() => setLoaded(true)}
			/>

			<div style={{ display: (loaded ? "none" : "block") }}>
				<Loading inline />
			</div>
		</div>
	)
}

export default ImagePreview