import { useState, useEffect } from 'react'

const ScopedFilterForm = ({ activeFilters, processingFilters, toggleFilter, search }) => {
	const [showSearch, setShowSearch] = useState(false)

	const toggleSearch = () => {
		if (showSearch) {
			resetAfterSearch()
		} else {
			setShowSearch(true)
		}
	}

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			search(e.target.value);
		}
	}

	const resetAfterSearch = () => {
		setShowSearch(false)
//		props.updateRecords(props.initialCollection.records)
	}

	return (
		<div className="panel">
			<div className="panel-filters">
				<i className="fa fa-filter intro-icon" />
				<p>Filter these emails</p>
				<div className={"filter-buttons" + (showSearch ? ' show-search' : '')}>
					<div className={"search-toggle" + (showSearch ? ' on' : '')} onClick={toggleSearch}><i className="fa fa-search" /></div>
					<span className={"search-input-container" + (processingFilters.includes('query') ? ' processing' : '')}>
						<input className="search-input small" type="text" disabled={processingFilters.includes('query')} placeholder="Search these threads" ref={input => input && input.focus()} onKeyUp={handleKeyPress} />
					</span>
					<div className={"unread" + (activeFilters.includes('unread') ? ' on' : '') + (processingFilters.includes('unread') ? ' processing' : '')} onClick={() => { toggleFilter('unread') }}>Unread</div>
					<div className={"flagged" + (activeFilters.includes('flagged') ? ' on' : '') + (processingFilters.includes('flagged') ? ' processing' : '')} onClick={() => { toggleFilter('flagged') }}>Flagged</div>
					<div className={"with-attachments" + (activeFilters.includes('has_attachment') ? ' on' : '') + (processingFilters.includes('has_attachment') ? ' processing' : '')} onClick={() => { toggleFilter('has_attachment') }}>Has attachments</div>
					<div className={"unresolved" + (activeFilters.includes('unresolved') ? ' on' : '') + (processingFilters.includes('unresolved') ? ' processing' : '')} onClick={() => { toggleFilter('unresolved') }}>Unresolved</div>
				</div>
			</div>
		</div>
	)
}

export default ScopedFilterForm