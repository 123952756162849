import { isString } from 'lodash';
import RecordValidation from './RecordValidation';
import { isArray, map, snakeCase } from 'lodash';

export default class Resource {
	attributes = {};
	saveAttempted = false;
	dynamicResource = require('../resources/DynamicResourceLoader').default;

	constructor(attrs) {
		if (attrs !== undefined) { this.loadAttributes(attrs); }
		this.validator = new RecordValidation(this.validations(), this);
	}

	fields() {
		return this.constructor.fields;
	}

	fieldKeys() {
		return this.fields().map(field => { return (typeof field === 'string' ? field : Object.keys(field)[0]) })
	}

	associationTypeByKey(key) {
		var type;
		if (this.fieldKeys().includes(key)) {
			this.fields().forEach(field => {
				if (Object.keys(field)[0] === key) { type = Object.values(field)[0] }
			})
		}

		return type;
	}

	setResponseError(error) {
		this.responseError = error;
	}

	getResponseError() {
		return this.responseError;
	}

	hasResponseError() {
		return !!this.getResponseError();
	}

	identifiableByFields() {
		return this.constructor.identifiableBy;
	}

	getOrBuildAssociation(association, key, attributes) {
		let type = this.associationTypeByKey(association);
		if (!attributes) { attributes = {} }

		if (isArray(type)) {
			if (!isArray(this[association])) {
				this[association] = [];
			}

			let child = this[association].find(record => key && record.lookupKey().toString() === key.toString());
			if (!child) {
				let klass = this.dynamicResource(type[0]);
				child = new klass(Object.assign(attributes, { childKey: key || Date.now() }));
				this[association].push(child)
			}

			return child;
		} else {
			if (!this[association]) {
				let klass = this.dynamicResource(type);
				this[association] = new klass(attributes);
			}
		}

		return this[association]
	}

	lookupKey() {
		return this.id || this.childKey
	}

	getAttributes(editableOnly) {
		let response = {};
		let fields = editableOnly && this.constructor.editableFields ? this.constructor.editableFields : this.fieldKeys();
		if (this.constructor.identifiableBy) { fields = fields.concat(this.identifiableByFields()); }

		fields.forEach(field => {
			if (this[field] !== undefined && this[field] !== null) {
				if (typeof this[field] === 'object' && this[field].getAttributes) {
					response[field] = this[field].getAttributes(editableOnly);
				} else if (isArray(this[field])) {
					response[field] = this[field].map(child => (child.getAttributes ? child.getAttributes(editableOnly) : child));
				} else {
					response[field] = this[field];
				}
			}
		});

		return response;
	}

	attributeChanged(attr) {
		return this.originalAttributes[attr] !== this.get(attr);
	}

	loadAttributes(attrs) {
		this.originalAttributes = attrs;

		if (attrs) {
			for (let field of this.fields().concat('childKey')) {
				if (isString(field) && attrs.hasOwnProperty(field)) {
					this.set(field, attrs[field]);
				} else {
					let [key, klass] = Object.entries(field)[0];
					if (attrs.hasOwnProperty(key)) {
						if (isArray(klass)) {
							klass = this.dynamicResource(klass[0]);
							this.set(key, map(attrs[key], function(subAttrs) { return new klass(subAttrs) }));
						} else {
							klass = this.dynamicResource(klass);
							this.set(key, new klass(attrs[key]));
						}
					}
				}
			}
		}
	}

	set(k, v) {
		this[k] = v;
		if (this.fieldKeys().includes(k)) {
			this.attributes[k] = v;
		}
	}

	get(k) {
		return this[k];
	}

	toJson() {
		return JSON.stringify(this.getAttributes());
	}

	asJson(excludeRoot) {
		let json = {};

		if (!excludeRoot) {
			json[snakeCase(this.constructor.modelName)] = this.getAttributes(!!this.constructor.editableFields);
		}

		return json;
	}

	valid() {
		return this.validator.process(this);
	}

	exists() {
		return !!this.id;
	}

	invalid() {
		return !this.valid();
	}

	invalidFields() {
		return this.validator.getInvalidFields(this)
	}

	recordAttemptedSave() {
		this.saveAttempted = true;
	}

	saveHasBeenAttempted() {
		return this.saveAttempted;
	}

	fieldIsInvalid(field) {
		return this.validator.getInvalidFields(this).includes(field);
	}

	validations() {
		if (this.constructor.validations != null && Object.keys(this.constructor.validations).length > 0) {
			return this.constructor.validations;
		} else {
			return [];
		}
	}

	getPath() {
		return '/' + this.constructor.modelName.toLowerCase() + 's/' + this.id;
	}
}

Resource.identifiableBy = ['id'];