import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../lib/useAuth'
import { useResourceLoader } from '../../lib/useResourceLoader'
import { Helmet } from 'react-helmet';
import { Form } from '../../lib/forms/Form';
import { Loading } from '../common/Common';
import { Submit } from '../../lib/forms/Submit';
import { CurrentUserAccessor } from '../../lib/CurrentUserAccessor';

const Lock = () => {
	const [mailbox, setMailbox] = useState(null)
	const auth = useAuth()
	const resource = useResourceLoader('MailboxRepository')
	const navigate = useNavigate()

	useEffect(() => {
		if (resource) {
			setMailbox(resource)
		}
	}, [resource])


	const updateMailboxes = (mailbox) => {
		navigate('/settings/mailboxes')
/*
		TODO
		loadUser().then(() => {
			router.push({ pathname: '/settings/mailboxes' });
		});
*/
	}

	const main = () => {
		return (
			<>
				<Helmet title={`Lock ${mailbox.name}`} />
				<div className="subheading">
					<i className="fa fa-lock edit" />
					<div className="combined-heading">
						<h2>Lock this mailbox</h2>
						<h3>{mailbox.name} ({mailbox.email_address})</h3>
					</div>
				</div>
				<div className="main-content gray bordered">
					<Form object={mailbox} saveUrl={`/mailboxes/${mailbox.id}/lock`} onSave={updateMailboxes}>
						<p>This is a shared mailbox.  If you lock it, nobody else will be able to view the emails or files it includes.  Contacts that have already been extracted from this mailbox will continue to be available to all users.</p>
						<p>You or another administrator can always choose to unlock this mailbox later.</p>
						<div className="submit">
							<Submit name="send" value="Lock this mailbox" className="rounded medium red" />
						</div>
					</Form>
				</div>
			</>
		)
	}

	return (
		<div className="main small standalone">
			{mailbox ? main() : <Loading inline={true} />}
		</div>
	)
}

export default Lock