import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useAuth } from '../../lib/useAuth';
import { Loading } from '../common/Common';
import ImportStatus from './ImportStatus';
import DashboardSidebar from './Sidebar';
import Inbox from '../threads/Inbox';
import Agenda from '../../resources/Agenda';
import Notifications from '../notifications/Index';
import Workspace from '../../resources/Workspace';
import AsyncFetcher from '../../lib/AsyncFetcher';
import { Header, PageIcon, HeaderActions, HeaderAction } from '../common/header';

const Index = () => {
	const [loaded, setLoaded] = useState(false)
	const [workspace, setWorkspace] = useState(null)
	const [agenda, setAgenda] = useState(null)
	const [mobileView, setMobileView] = useState('inbox')
	const auth = useAuth()

	useEffect(() => {
		load()
	}, [])

	const load = async () => {
		const fetcher = new AsyncFetcher(localStorage.token)
		const json = await fetcher.get('/dashboard')

		setLoaded(true)
		setAgenda(new Agenda(json.dashboard.agenda))
		setWorkspace(new Workspace(json.dashboard.workspace))
	}

	const loadedView = () => {
		let panels = [
			<Inbox key="inbox" />,
			<DashboardSidebar key="agenda" agenda={agenda} />
		];

		if (window.isMobile) {
			panels.push(<Notifications key="notifications" />);
			return panels.find(panel => panel.key === mobileView);
		} else {
			return panels;
		}
	}

	const countLabel = (count, alertCondition) => {
		if (count === 0) {
			return null;
		} else if (count > 99) {
			count = '99+';
		}

		return <span className={"count" + (alertCondition ? " alert" : "")}>{count}</span>;
	}

	const notificationsLink = () => {
		if (auth.account.multiUser()) {
			return (
				<li>
					<span className={"console-button" + (mobileView === 'notifications' ? ' active' : '')} onClick={() => setMobileView('notifications')}>
						<i className="fal fa-alarm-clock" />
						Notifications
						{countLabel(auth.user.notification_inbox.new_count, auth.user.notification_inbox.hasNewNotificationsSinceLastRead())}
					</span>
				</li>
			)
		}
	}

	return (
		<div className="main-content-panel">
			<div className="main headingless no-sidebar">
				<Helmet title="Your Dashboard" />
				<div className="mobile-only">
					<Header title="Dashboard">
						<PageIcon icon="tachometer" />
						<HeaderActions>
							<HeaderAction icon="cog" linkTo={{ pathname: "/settings" }} />
						</HeaderActions>
					</Header>
					<div className="subheader-nav">
						<ul className="sections">
							<li>
								<span className={"console-button" + (mobileView === 'inbox' ? ' active' : '')} onClick={() => setMobileView('inbox')}>
									<i className="fa fa-inbox" />
									Inbox
									{countLabel(auth.user.unread_open_thread_count)}
								</span>
							</li>
							<li><span className={"console-button" + (mobileView === 'agenda' ? ' active' : '')} onClick={() => setMobileView('agenda')}><i className="fal fa-calendar-check" />Agenda</span></li>
							{notificationsLink()}
						</ul>
					</div>
				</div>

				{
					auth.user.hasPrimaryMailbox() && auth.user.primaryMailbox().sync_state === 'importing'
					? <ImportStatus mailbox={auth.user.primaryMailbox()} />
					: null
				}

				<div className="main-content">
					<div id="dashboard">
						{loaded ? loadedView() : <Loading inline={true} />}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Index