import Repository from '../lib/Repository';
import MailingList from '../resources/MailingList';

export default class MailingListRepository extends Repository {
	entityClass = MailingList;
	baseUrl = '/mailing_lists';
	sliceFilters = ['active', 'inactive'];
//	filterFields = ['file_name', 'first_sent_before', 'last_sent_after'];
//	sortOptions = ['file_name', 'size', 'attachment_count', 'last_sent_at'];
//	defaultSort = 'size';
//	defaultOrder = 'asc';
}