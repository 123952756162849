import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import AsyncFetcher from '../../lib/AsyncFetcher';
import SessionRepository from '../../repositories/SessionRepository';
import { useAuth } from '../../lib/useAuth';
import User from '../../resources/User'
import { SessionController } from '../../lib/SessionController';
import { Loading } from '../common/Common';
import { Form } from '../../lib/forms/Form';
import { PasswordInput } from '../../lib/forms/PasswordInput';
import { TextInput } from '../../lib/forms/TextInput';
import { HiddenField } from '../../lib/forms/HiddenField';
import { Submit } from '../../lib/forms/Submit';
import moment from 'moment';

const ActivateUser = () => {
	const [loaded, setLoaded] = useState(false)
	const [user, setUser] = useState(null)
	const [password, setPassword] = useState(null)
	const [passwordIsValid, setPasswordIsValid] = useState(false)
	const validationRegex = User.prototype.validations()['password']['test']
	const location = useLocation()
	const navigate = useNavigate()
	const params = useParams()
	const fetcher = new AsyncFetcher(localStorage.token)
	const activationCode = params.activation_code
	const auth = useAuth()

	useEffect(() => {
		const requestUser = async () => {
			try {
				let json = await fetcher.get('/users/current', { user: { id: params.id, activation_code: activationCode } })
				let newUser = new User(Object.assign({ activation_code: activationCode }, json.user));
				newUser.configuration.time_zone = moment.tz.guess();

				setLoaded(true)
				setUser(newUser)
			} catch(e) {
				setLoaded(true)
			}
		}

		requestUser()
	}, [])

	const updatePassword = (_, e) => {
		setPassword(e.target.value)
		setPasswordIsValid(validationRegex.test(e.target.value))
	}

	const initiateSession = async (session) => {
		if (session.token) {
			localStorage.setItem('token', session.token.key)

			await auth.loadUser()

			navigate('/')
		}
	}

	const activationForm = () => {
		return (
			<>
				<div className="welcome-new-user">
					<p><strong>Hi, {user.first_name}!  Welcome to Carom.</strong></p>
					<p>You&#8217;re almost ready to access your account.  But first, please create the password you&#8217;ll use to sign in.</p>
				</div>
				<Form object={user} repository={new SessionRepository()} saveUrl={`/users/${user.id}/activate/${user.activation_code}`} onSave={initiateSession}>
					<HiddenField name="activation_code" />
					<HiddenField name="configuration.time_zone" />
					<PasswordInput changeHandler={updatePassword} name="password" className="large full" placeholder="Your password" autoFocus />
					{
						passwordIsValid
							? <div className="submit"><Submit className="medium green rounded" button="Set your password" /></div>
							: <div className="etc centered" style={{ margin: "20px 5px 0 5px" }}>8 characters or more, including at least one uppercase letter, special character, and number</div>
					}
				</Form>
			</>
		);
	}

	const invalidActivationCode = () => {
		return (
			<>
				<div className="welcome-new-user">
					<p className="strong red">
						You&#8217;re probably trying to re-activate an account that&#8217;s already been activated, or you might have copied an activation link incorrectly.
					</p>
				</div>
				<p>If you&#8217;ve already activated your account and created a password, you can <Link to="/sessions">click here to sign in</Link>.</p>
			</>
		);
	}

	const main = () => {
		if (user) {
			return activationForm();
		} else {
			return invalidActivationCode();
		}
	}

	return (
		<div className="main mini standalone">
			<Helmet title="Activate your account" />
			<div className="main-content gray headingless">
				{loaded ? main() : <Loading inline={true} />}
			</div>
		</div>
	)
}

export default ActivateUser;