import { useState, useRef } from 'react'
import ListFilter from './ListFilter';

export const useLiveFilter = (searchFields) => {
	const [query, setQuery] = useState('')
	const [active, setActive] = useState(null)
	const [preloadedListOptions, setPreloadedListOptions] = useState([])
	const [listOptions, setListOptions] = useState([])
	const [showOptions, setShowOptions] = useState(false)
	const [filterMatches, setFilterMatches] = useState([])
	const [selected, setSelected] = useState([])
	const filter = useRef(new ListFilter())

	filter.current.setSearchFields(searchFields)

	const updateQuery = (e) => {
		let newQuery = e.target.value

		setQuery(newQuery)
		setFilterMatches(filter.current.queryPreloaded(newQuery))
		setActive(active === null ? filterMatches[0] : active)
		/*
			if (this.filter.hasQueryableCollection()) {
				this.queueDelayedRemoteSearch()
			}
		});*/
	}

	const removeOption = (option) => {
		filter.current.removeOption(option)

		setFilterMatches(filter.current.queryPreloaded(query))
	}

	return [filter.current, filterMatches, updateQuery, removeOption]
}