import { useState, useEffect, useContext } from 'react'
import FormContext from '../FormContext'

const useFormInput = (props) => {
	const [invalid, setInvalid] = useState(null)
	const context = useContext(FormContext)
	const [counter, setCounter] = useState(1)

	useEffect(() => {
		if (context.registerFormField) { context.registerFormField(props.name); }

		updateValidity()
	})

	const handleChange = (name, e) => {
		if (props.changeHandler) {
			props.changeHandler(name, e)
		}

		let res = context.inputChangeHandler(name, e)
		updateValidity()

		setCounter(counter + 1)
		return res;
	}

	const updateValidity = () => {
		setInvalid(fieldIsInvalid())
	}

	const fieldIsInvalid = () => {
		return context.object && context.object.saveHasBeenAttempted() && context.object.invalid() && context.object.fieldIsInvalid(props.name);
	}

	const classes = () => {
		return (props.className || '') + (invalid ? ' error' : '');
	}

	return {
		handleChange: handleChange,
		classes: classes,
		...context
	}
}

export default useFormInput