import { useState } from 'react';
import Stats from './Stats';
import { Link } from 'react-router-dom';
import { ProgressDonut } from '../../lib/Utilities.js';

const Row = ({ milestone, togglePin }) => {
	const [counter, setCounter] = useState(0)

	const toggler = (e, task) => {
		milestone.taskCollection().toggleTaskCompletion(task);

		setCounter(counter + 1)
	}

	return (
		<li key={milestone.id} className="link-exposer">
			<Link className="block" to={{ pathname: "/milestones/" + milestone.id, state: { resource: milestone, modal: true, returnTo: "/milestones" } }}>
				{milestone.archived ? null : <i className="fa fa-thumbtack" onClick={(e) => { e.preventDefault(); togglePin(milestone) }} />}
				<ProgressDonut percentage={milestone.task_progress} />
				<strong style={{ fontSize: "15px" }}>{milestone.name}</strong>
				<div className="more">{milestone.description}</div>
				<div className="milestone-stats">
					<Stats milestone={milestone} />
				</div>
			</Link>
		</li>
	)
}

export default Row