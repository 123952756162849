import Resource from '../../lib/Resource';

export default class Location extends Resource {
	displayValue() {
		return this.value;
	}
}

Location.modelName = 'Location';
Location.fields = [
	'id', 'street_number', 'street', 'apartment', 'city', 'state', 'zip_code', 'country_code',
	'time_zone', 'deleted', 'private'
];