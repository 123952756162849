import React from 'react';
import StubList from '../../contacts/StubList';

const ParticipantList = ({ thread }) => {
	return (
		<div>
			<h3 className="light-heading">Email Participants ({thread.participants.length})</h3>
			<StubList contacts={thread.participants} />
		</div>
	)
}

export default ParticipantList