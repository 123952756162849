import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useResourceLoader } from '../../lib/useResourceLoader';
import ContactRepository from '../../repositories/ContactRepository';
import * as Repositories from '../../repositories';
import { Link } from 'react-router-dom';
import { Loading, NoResults } from '../common/Common';
import Fetcher from '../../lib/Fetcher';
import { Submit } from '../../lib/forms/Submit';

const Duplicates = (repositorySource) => {
	const [loaded, setLoaded] = useState(false)
	const [processing, setProcessing] = useState(false)
	const [complete, setComplete] = useState(false)
	const [duplicates, setDuplicates] = useState([])
	const [mergeIds, setMergeIds] = useState([])
	const resource = useResourceLoader(repositorySource)

	useEffect(() => {
		if (resource) {
			setLoaded(true)

			new Repositories[repositorySource]().getCollection({}, `/${repositorySource == 'ContactRepository' ? 'contacts' : 'organizations'}/${resource.id}/duplicates`).then(collection => {
				setLoaded(true)
				setDuplicates(collection.records)
			})
		}
	}, [resource])

	const body = () => {
		if (!loaded) {
			return <Loading inline />
		} else if (duplicates.length === 0) {
			return <NoResults message={`This ${resource.constructor.modelName.toLowerCase()} has no suspected duplicates.`} />
		} else if (complete) {
			return <NoResults congrats message={`Your duplicates have been merged successfully.`} />
		} else {
			let duplicateRows = [];
			duplicates.forEach(duplicate => {
				duplicateRows = duplicateRows.concat(duplicateRow(duplicate));
			})

			return (
				<div className="duplicate-section">
					<div className="current-overview">{duplicateRow(resource, true)}</div>
					<p className="instructions">Select the potential duplicates you would like to merge into this record.</p>
					{duplicateRows}
				</div>
			);
		}
	}

	const toggleMerge = id => {
		if (mergeIds.includes(id)) {
			setMergeIds(mergeIds.filter(currentId => currentId !== id))
		} else {
			setMergeIds(mergeIds.concat(id))
		}
	}

	const duplicateFields = (field, dup) => {
		if (dup[field]) {
			if (Array.isArray(dup[field]) && dup[field].length > 0) {
				return <div className={`${field.replace('_', '-')} comma-section`}>{dup[field].map(assoc => <span className="post-comma">{assoc.displayValue()}</span>)}</div>
			}
		}
	}

	const background = (dup) => {
		if (dup.constructor.modelName === 'Contact') {
			return dup.hasBackground() ? <div className="background">{dup.background}</div> : null;
		} else {
			return dup.hasDescription() ? <div className="background">{dup.description}</div> : null;
		}
	}

	const duplicateRow = (dup, isCurrent) => {
		isCurrent = typeof isCurrent !== 'undefined';

		return (
			<div className={`duplicate ${isCurrent ? 'current' : ''}`}>
				<div className="merge hidden">
					<span className="merge-control"><i className="fa fa-code-merge " /></span>
				</div>
				<div className="contact-info">
					<div className="name">{
						isCurrent
						? dup.displayName()
						: <Link to={`/${resource.constructor.modelName.toLowerCase()}s/${dup.id}`}>{dup.displayName()}</Link>
					}</div>
					{background(dup)}
					{['email_addresses', 'websites', 'numbers', 'locations', 'domain'].map(field => duplicateFields(field, dup))}
				</div>
				<div className="primary">
					{
						mergeIds.includes(dup.id)
						? <span className="action green" onClick={toggleMerge.bind(null, dup.id)}><i className="fa fa-code-merge" />merge</span>
						: <span className="action white iconless" onClick={toggleMerge.bind(null, dup.id)}>merge</span>
					}
				</div>
			</div>
		)
	}

	const handleSubmit = () => {
		if (mergeIds.length > 0) {
			setProcessing(true)

			new Fetcher(localStorage.token).post(`/contacts/${resource.id}/merge`, { contact_ids: mergeIds }).then(json => {
				setProcessing(false)
				setComplete(true)
			});
		}
	}

	const submitSection = () => {
		if (mergeIds.length > 0) {
			return (
				<div className="submit" style={{ marginTop: '10px' }}>
					{
						processing
						? <i className="fa fa-spinner fa-spin" />
						: <Submit value="Merge these records" className="rounded medium green" onClick={handleSubmit} />
					}
				</div>
			)
		}
	}

	if (loaded) {
		return (
			<div className="main small standalone">
				{loaded ? <Helmet title={`Potential duplicates: ${resource.displayName()}`} /> : null}
				<div className="subheading">
					<i className="fa fa-clone" />
					<h2>Resolve potential duplicates</h2>
					{loaded ? <Link to={`/${resource.constructor.modelName.toLowerCase()}s/${resource.id}`} className="close"><i className="fa fa-times" /></Link> : null}
				</div>
				<div className="main-content">
					{body()}
					{complete ? null : submitSection()}
				</div>
			</div>
		);
	}
}

export const ContactDuplicates = () => Duplicates('ContactRepository')
export const OrganizationDuplicates = () => Duplicates('OrganizationRepository')