import { useState, useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { useResourceLoader } from '../../lib/useResourceLoader'
import { useAuth } from '../../lib/useAuth'
import { useToggle } from '../../lib/useToggle'
import { useResourceViewRecorder } from '../../lib/useResourceViewRecorder'
import Loading from '../common/Loading'
import View from './View'

const ViewContainer = () => {
	const resource = useResourceLoader('ContactRepository')
	const recordView = useResourceViewRecorder('contacts')
	const location = useLocation()
	const auth = useAuth()
	let match = location.pathname.match(/\/contacts\/[a-z0-9]+\/(.*)\/?/i)
	let section = match && ['emails', 'files'].includes(match[1]) ? match[1] : 'workspace'
	const [active, setActive] = useState(section)
	const [contact, setContact] = useState(null)
	const toggle = useToggle(resource, setContact)

	useEffect(() => {
		if (resource) {
			setContact(resource)
			recordView(resource)
		}
	}, [resource])


/*
	componentWillReceiveProps(nextProps) {
		this.setState({ contact: nextProps.contact }, () => {
			if (!this.state.contact.latest_threads || !this.state.contact.latest_files) {
				nextProps.reload(nextProps);
			}
		});
	}
*/
	const switcher = (activePanel, e) => {
		setActive(activePanel)
	}

/*
	const setMailboxScope = mailbox => {
		setScopedMailbox(mailbox)
	}
*/

	return contact ? <View setMailboxScope={() => {}} switcher={switcher} contact={contact} active={active} toggle={toggle} scopedMailbox={null} /> : <Loading />;
}

export default ViewContainer