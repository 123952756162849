import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const useDefaultLens = (module, value) => {
	const location = useLocation()

	useEffect(() => {
		const updateDefaultLens = () => {
			let update = {}
			update[module] = value || location.pathname
			localStorage.setItem('defaultLens', JSON.stringify(Object.assign({}, JSON.parse(localStorage.getItem('defaultLens')), update)))
		}

		updateDefaultLens()
	})
}