import { useState, useEffect } from 'react';

const useCategoryVisibilityToggle = (category) => {
	const [visible, setVisible] = useState(
		!(localStorage.collapsedSidebarSections ? JSON.parse(localStorage.collapsedSidebarSections) : {})[category]
	)

	useEffect(() => {
		let collapsedSidebarSections = localStorage.collapsedSidebarSections ? JSON.parse(localStorage.collapsedSidebarSections) : {};
		collapsedSidebarSections[category] = !visible;

		localStorage.collapsedSidebarSections = JSON.stringify(collapsedSidebarSections);
	}, [visible])


	const icon = <i className={'fa fa-' + (visible ? 'minus' : 'plus') + '-circle'} onClick={() => setVisible(!visible)} />;

	return [visible, icon]
}

export default useCategoryVisibilityToggle