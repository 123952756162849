import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../lib/useAuth'
import moment from 'moment';

const AttachmentMessage = (props) => {
	const auth = useAuth()

	const displayNameForEmailAddress = (emailAddress) => {
		if (props.file.getParticipantByEmailAddress(emailAddress))
			return props.file.getParticipantByEmailAddress(emailAddress).displayName();
		else if (props.attachment.thread.participant_names[emailAddress]) {
			return props.attachment.thread.participant_names[emailAddress];
		} else {
			return emailAddress;
		}
	}

	let identifier, names;
	if (props.attachment.sentByUser(auth.user)) {
		identifier = 'To';
		names = (
			<span className="comma-section">
				{props.attachment.recipient_email_addresses(emailAddress => {
					return <span key={"recipient." + emailAddress} className="post-comma">{displayNameForEmailAddress(emailAddress)}</span>;
				})}
			</span>
		);
	} else {
		identifier = 'From';
		names = displayNameForEmailAddress(props.attachment.sender_email_address)
	}

	return (
		<div className="attachment-message">
			<div className="sender-icon"><i className={"fa fa-" + (props.attachment.sentByUser(auth.user) ? 'reply' : 'share')} /></div>
			<div className="message-info">
				<div className="sent-at">{moment(props.attachment.sent_at).format('MMMM D, YYYY')}</div>
				<div className="sender">
					{identifier} <strong className="minor">{names}</strong>
				</div>
				<div className="subject"><Link to={'/email/' + props.attachment.thread.id}>{props.attachment.thread.displaySubject()}</Link></div>
				<div className="snippet">{props.attachment.message.snippet}</div>
			</div>
			{props.includeDownloadLink ? <div className="download-icon"><a href={props.attachment.downloadUrl(props.file.id)}><i className="fa fa-cloud-download" /></a></div> : null}
		</div>
	)
}

export default AttachmentMessage