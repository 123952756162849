import { useState } from 'react';
import { useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import { useDefaultLens, useFilterableCollection } from '../../lib/hooks'
import { NoResults, Loading } from '../common/Common'
import Table from './Table';
import Pagination from '../common/Pagination';
import useCollectionLoader from '../../lib/useCollectionLoader'
import { Header, PageIcon, HeaderActions, HeaderAction } from '../common/header';

const List = () => {
	useDefaultLens('organizations', '/organizations/list')
	const [scope, setScope] = useState('personal')
	const location = useLocation()
	const [collection, filter, nextCollection] = useCollectionLoader('OrganizationRepository', {}, () => {})
	const [sort, sortForm] = useFilterableCollection(collection, filter, {
		name: { icon: 'font', name: 'Name' },
		contacts: { icon: 'users', name: 'Contacts' },
		threads: { icon: 'envelope', name: 'Emails' },
		files: { icon: 'file-alt', name: 'Files' }
	})

	const results = () => {
		return collection.records.length > 0
			? <>
				<Table organizations={collection.records} />
				<Pagination collection={collection} filter={filter} />
			</> : <NoResults collection={collection} />;
	}

	const getDisplaySlice = () => {
		if (nextCollection.loading && nextCollection.slice) {
			return nextCollection.slice
		} else if (collection && collection.hasSlice()) {
			return collection.currentSlice()
		}
	}

	return (
		<div className="main full">
			<Helmet title={getDisplaySlice() ? getDisplaySlice().name.replace('&#8217;', "'") : 'Your companies and organizations'} />
			<Header
				breadcrumb={getDisplaySlice() ? 'Companies and Organizations' : null}
				title={getDisplaySlice() ? <span dangerouslySetInnerHTML={{ __html: getDisplaySlice().name }}></span> :<span>Companies and Organizations</span>}
			>
				<PageIcon icon={getDisplaySlice() ? getDisplaySlice().icon : "building"} toggleSidebarOnMobile={true} />
				<HeaderActions>
					<HeaderAction linkTo={{ pathname: "/organizations/new", state: { modal: true, returnTo: location.pathname, backgroundLocation: location } }} icon="plus" />
				</HeaderActions>
			</Header>
			{collection && sortForm ? sortForm() : null}
			<div className="main-content">
				{nextCollection.loading ? <Loading inline /> : results()}
			</div>
		</div>
	)
}

export default List