import React from 'react';
import Row from './Row';

const Table = ({ organizations }) => {
	return (
		<div className="contact-table organization-table">
			{organizations.map((organization) => {
				return <Row key={organization.id} organization={organization} />;
			})}
		</div>
	)
}

export default Table