import { useState, useEffect } from 'react'
import { useRouter } from '../../lib/useRouter'
import { Form } from '../../lib/forms/Form'
import { TextInput } from '../../lib/forms/TextInput'
import { TextArea } from '../../lib/forms/TextArea'
import { Submit } from '../../lib/forms/Submit'
import OrganizationRepository from '../../repositories/OrganizationRepository'
import Dropzone, { useDropzone } from 'react-dropzone'
import FileUploader from '../../lib/FileUploader'

const OrganizationForm = (props) => {
	const [logo, setLogo] = useState(null)
	const [organization, setOrganization] = useState(null)
	const [uploadInProgress, setUploadInProgress] = useState(false)
	const router = useRouter()

	useEffect(() => {
		let org = props.organization
		org.getOrBuildAssociation('logo')

		setOrganization(org)
	}, [])

	const onDrop = files => {
		let org = organization
		setUploadInProgress(true)

		new FileUploader('logos', (logo) => {
			if (logo.status === 'complete') {
				org.logo.loadAttributes({
					source_url: `https://s3.amazonaws.com/carom.logos/${logo.s3_key}`,
					file_name: logo.file_name
				})

				setUploadInProgress(false)
				setLogo(files[0])
				setOrganization(organization)
			}
		}).upload(files[0])
	}
	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

	const dropzoneContainer = () => {
		return (
			<div className="upload-avatar" {...getRootProps()}>
				<input {...getInputProps()} />
				<div className="default-photo">
					{showLogo()}
				</div>
				<div className="instructions-label">
					Drag and drop or click to upload logo
				</div>
			</div>
		)
	}

	const showLogo = () => {
		if (uploadInProgress) {
			return <i className="fal fa-spinner fa-spin" />
		} else if (organization && organization.logo && organization.logo.source_url) {
			return <img src={organization.logo.source_url} alt="Organization logo" />
		} else {
			return <i className="fal fa-building" />
		}
	}

	if (organization) {
		return (
			<Form object={organization} repository={new OrganizationRepository()} redirectOnSave={true} router={router} className="organization-form">
				<div className="name-section">
					<TextInput name="name" className="large full" placeholder="Organization name" autoFocus />
				</div>
				<div className="top-section">
					{dropzoneContainer()}
					<div className="main-info">
						<label htmlFor="description"><i className="fal fa-info-circle" />About this organization</label>
						<TextArea name="description" className="full four-lines" />
					</div>
				</div>
				<hr />
				<label htmlFor="domain"><i className="fal fa-at" />Organization domain</label>
				<div className="etc" style={{ marginBottom: "3px", padding: "0 5px" }}>We&#8217;ll add anyone with an email here to this organization</div>
				<TextInput name="domain" placeholder="Website domain (example.com)" className="full" />

				<div className="submit">
					<Submit button="Save this Organization" className="rounded large green" disabled={uploadInProgress} />
				</div>
			</Form>
		)
	}
}

export default OrganizationForm


/*
<Dropzone ref="drop-zone" onDrop={onDrop} className="upload-avatar" activeClassName="drop-zone active">
	<div className="default-photo">
		{showLogo()}
	</div>
	<div className="instructions-label">
		Drag and drop or click to upload logo
	</div>
</Dropzone>
*/