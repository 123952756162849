import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import ClickHandler from '../components/common/ClickHandler';

export const Modalize = (props) => {
	const modal = useRef(null)

	const handleOutsideClick = e => {
		if (modal && modal.current) {
			if (!modal.current.contains(e.target) && !(props.getRefPoint && props.getRefPoint() && props.getRefPoint().current.contains(e.target))) {
				props.toggle();
			}
		}
	}

	if (!props.show) { return null; }

	if (window.isMobile) {
		let modal = (
			<ClickHandler onClick={handleOutsideClick}>
				<div className="layer">
					<div className="layer-shade" onClick={e => e.preventDefault()}></div>
					<div className="main mini headingless no-sidebar" ref={modal}>
						{React.Children.map(props.children, child => React.cloneElement(child, props))}
					</div>
				</div>
			</ClickHandler>
		);
/* TODO
		return ReactDOM.createPortal(
	    modal,
	    this.context.getModalParent()
	  );
*/
	} else {
		let style = {};

		if (props.getRefPoint && props.getRefPoint() && props.getRefPoint().current) {
			let { offsetTop, offsetLeft, offsetHeight, offsetWidth } = props.getRefPoint().current;
			let position = window.getComputedStyle(props.getRefPoint().current).position

			let leftMargin = (position === "relative" || position === "absolute") ? 0 : offsetLeft;

			// this is ridiculous, the first one is really just for mailbox pickers
			let left, right, translateX;
			if (props.popUnderClasses.includes('center')) {
				left = (leftMargin + (0.5 * offsetWidth)).toString() + "px";
				translateX = "-50%";
			} else if (props.popUnderClasses.includes('right')) {
				right = `calc(100% - ${(props.popUnderClasses.includes('side') ? offsetLeft : (offsetLeft + 63)).toString()}px)`
			} else {
				left = `calc(100% - ${(props.popUnderClasses.includes('side') ? offsetLeft : (offsetLeft + (offsetWidth / 2) + 40)).toString()}px)`
			}

			style = {
				// For a side popunder, top offset aims for the carat to be in the middle of the requesting element
				top: (props.popUnderClasses.includes('side') ? (offsetTop + (offsetHeight / 2) - 21) : (offsetTop + offsetHeight + 3)).toString() + "px",
				left: (left || 'auto'),
				right: (right || 'auto'),
				transform: `translateX(${translateX})`
			}
		}

		return (
			<ClickHandler onClick={handleOutsideClick}>
				<div className={"pop-under-form " + (props.popUnderClasses || '')} style={style} ref={modal}>
					{React.Children.map(props.children, child => React.cloneElement(child, {...props}))}
				</div>
			</ClickHandler>
		)
	}
}