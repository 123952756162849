import { useState } from 'react';
import { useRouter } from '../../lib/useRouter'
import { TextInput } from '../../lib/forms/TextInput';
import { IconToggle } from '../../lib/forms/IconToggle';
import { DatePickerInput } from '../../lib/forms/DatePickerInput';
import { FilterForm } from '../../lib/forms/FilterForm';

const ThreadFilterForm = (props) => {
	const [loading, setLoading] = useState(false)
	const router = useRouter()

	return (
		<div className="filters gray" key={`threads.filter.${props.collection.currentSlice()}`}>
			<FilterForm collection={props.collection} router={router} updater={props.updater} onSubmit={() => setLoading(!loading)} afterSubmit={() => setLoading(!loading)}>
				<div className="table narrow">
					<div className="row">
						<div className="cell p23">
							<label className="small standalone">Subject</label>
							<div className="input-field"><TextInput name="subject" className="full" placeholder="Subject includes..." /></div>
						</div>
						<div className="cell p23">
							<label className="small standalone">Participants</label>
							<div className="input-field"><TextInput name="participant" className="full" placeholder="Email address" /></div>
						</div>
						<div className="cell date-sent">
							<label className="small standalone">Sent after</label>
							<div className="input-field"><DatePickerInput name="last_sent_after" dateFormat="MMM D, YYYY" weekStart="0" /></div>
						</div>
						<div className="cell date-sent">
							<label className="small standalone">Sent before</label>
							<div className="input-field"><DatePickerInput name="first_sent_before" dateFormat="MMM D, YYYY" weekStart="0" /></div>
						</div>

						<div className="centered cell thread extra-options">
							<IconToggle name="unread">
								<i className="fa fa-envelope" title="Unread emails" />
							</IconToggle>
							<IconToggle name="flagged">
								<i className="fa fa-flag" title="Flagged emails" />
							</IconToggle>
							<IconToggle name="has_attachment">
								<i className="fa fa-paperclip" title="Emails with attachments" />
							</IconToggle>
						</div>
						<div className="cell filter-submit">
							<button type="submit" className="invisible-container">
							  <i className={"fa fa-" + (loading ? 'spinner fa-spin' : 'arrow-circle-right')} />
							</button>
						</div>
					</div>
				</div>
			</FilterForm>
		</div>
	)
}

export default ThreadFilterForm