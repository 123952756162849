import React from 'react'
import { Helmet } from 'react-helmet'
import { useDefaultLens } from '../../lib/hooks'
import { Header, PageIcon, HeaderSearch } from '../common/header'

const Index = () => {
	let search = <HeaderSearch scope="organizations" placeholder="Search by name or domain" />

	return (
		<div className="main full">
			<Helmet title="Organizations" />
			<Header title="Manage Your Organizations">
				<PageIcon icon="building" toggleSidebarOnMobile />
				{window.isMobile ? null : search}
			</Header>
			<div className="subheader-search mobile-only">{search}</div>
			<div className="subheader-nav mobile-only">
			</div>
			<div className="main-content organizations">

			</div>
		</div>
	)
}

export default Index