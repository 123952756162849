import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import { Modalize } from '../../lib/Modalize';
import NewTaskForm from '../tasks/New';
import ShareSheet from '../sharing/ShareSheet';
import { Link } from 'react-router-dom';
import { Workspace } from './Contact';
import ScopedThreads from '../threads/ScopedList'
import ScopedFiles from '../files/ScopedList'
import { Enrichable } from '../../lib/Enrichable';
import moment from 'moment';
import { MessageTimeline } from './Contact'
import Avatar from './contact/Avatar';
import ContactOwnershipBar from './contact/OwnershipBar';
import { Header, PageIcon, HeaderActions, HeaderAction } from '../common/header';
import { useAuth } from '../../lib/useAuth'
import { useHotkeys } from 'react-hotkeys-hook'

const View = (props) => {
	const auth = useAuth()
	const location = useLocation()
	const navigate = useNavigate()
	const [contact, setContact] = useState(null)
	const [scope, setScope] = useState(null)
	useHotkeys('e', () => { navigate(`/contacts/${props.contact.id}/edit`) })
	useHotkeys('d', () => { navigate(`/contacts/${props.contact.id}/delete`) })
	useHotkeys('p', () => { props.toggle('pin') })

	const activePanel = () => {
		switch (props.active) {
			case 'emails':
				return <ScopedThreads key={`${props.contact.id}.threads`} panelSwitcher={props.switcher} contact={props.contact} scopedMailbox={props.scopedMailbox} location={location} />;
			case 'files':
				return <ScopedFiles key={`${props.contact.id}.files`} panelSwitcher={props.switcher} contact={props.contact} scopedMailbox={props.scopedMailbox} location={location} router={props.router} />;
			default:
				return <Workspace key={`${props.contact.id}.workspace`} panelSwitcher={props.switcher} contact={props.contact} scopedMailbox={props.scopedMailbox} location={location}  />;
		}
	}

	const addTask = (task) => {
		let c = props.contact;
		c.taskCollection().addTask(task);

		setContact(contact)
	}

	const contactStats = () => {
		if (props.contact.interactionHistoryForUser(auth.user)) {
			return (
				<div className="contact-stats">
					<div>
						<div className="stat-label">First contact</div>
						{moment(props.contact.interactionHistoryForUser(auth.user).first_contact).format('MMMM YYYY')} <i className="fa fa-arrow-right" />
					</div>
					<Link className={props.active === 'emails' ? 'active' : ''} to={`/contacts/${props.contact.id}/emails`} onClick={props.switcher.bind(null, 'emails')}>
						<div className="stat-label"><i className="fal fa-envelope" /> Emails</div>
						<strong>{props.contact.interactionHistoryForUser(auth.user).thread_count || 0}</strong>
					</Link>
					<Link className={props.active === 'files' ? 'active' : ''} to={`/contacts/${props.contact.id}/files`} onClick={props.switcher.bind(null, 'files')}>
						<div className="stat-label"><i className="fal fa-file-alt" /> Files</div>
						<strong>{props.contact.interactionHistoryForUser(auth.user).file_count || 0}</strong>
					</Link>
					<div>
						<div className="stat-label"><i className="fal fa-check-circle" /> Tasks</div>
						<strong>{props.contact.task_count}</strong>
					</div>
					<div>
						<div className="stat-label">Last contact</div>
						<i className="fa fa-arrow-left" /> {moment(props.contact.interactionHistoryForUser(auth.user).last_contact).format('MMMM YYYY')}
					</div>
				</div>
			);
		}
	}

	const noInteractionBar = () => {
		if (!props.contact.hasMailboxInteractions()) {
			let privateInteractors, sharedMailboxes;

			if (props.contact.privateInteractorCount() === 1) {
				privateInteractors = <div className="other-interactors"><strong>{auth.account.getUserById(props.contact.private_interactor_ids[0]).displayName()}</strong> has exchanged emails with this contact.</div>;
			}

			return (
				<div className="no-contact-explanation">
					<h3>You have not interacted with {props.contact.displayName()}</h3>
					{privateInteractors}
					{sharedMailboxes}
				</div>
			);
		}
	}

	const getThreadCount = () => {

	}

	const getFileCount = () => {

	}

	const mobileNav = () => {
		if (!props.contact.hasMailboxInteractions()) { return null; }

		let types = {
			emails: {
				name: 'Emails',
				icon: 'envelope',
				count: props.contact.interactionHistoryForUser(auth.user).thread_count || 0
			},
			files: {
				name: 'Files',
				icon: 'file-alt',
				count: props.contact.interactionHistoryForUser(auth.user).file_count || 0
			},
			/*tasks: {
				name: 'Tasks',
				icon: 'check',
				count: props.task_count
			}*/
		};

		return (
			<div className="subheader-nav" style={{ marginTop: 0 }}>
				<ul className="sections">
					<li key="snapshot"><span className={"console-button" + (props.active === "workspace" ? " active" : "")} onClick={props.switcher.bind(null, "workspace")}><i className="fal fa-bullseye" />Snapshot</span></li>
					{Object.keys(types).map(k => {
						return (
							<li key={k}>
								<span className={"console-button" + (props.active === k ? ' active' : '')} onClick={props.switcher.bind(null, k)}>
									<i className={`fal fa-${types[k].icon}`} />
									{types[k].name}
									{types[k].count && types[k].count > 0 ? <span className="count">{types[k].count}</span> : null}
								</span>
							</li>
						)
					})}
				</ul>
			</div>
		)
	}

	const contactTimes = () => {
		if (props.contact.hasMailboxInteractions()) {
			return (
				<div className="overlay">
					<div className="stat">
						<div className="label">First contact</div>
						<div className="value">{moment(props.contact.interactionHistoryForUser(auth.user).first_contact).format('MMMM YYYY')} <i className="fa fa-arrow-right" /></div>
					</div>
					<div className="spacer"></div>
					<div className="stat">
						<div className="label">Last contact</div>
						<div className="value"><i className="fa fa-arrow-left" /> {moment(props.contact.interactionHistoryForUser(auth.user).last_contact).format('MMMM YYYY')}</div>
					</div>
				</div>
			)
		}
	}

	return (
		<div className="main contact-view">
			<Helmet title={props.contact.displayName()} />
			<Header
				title={<span>{props.contact.displayName()}{props.contact.enriched ? <i className="fa fa-plug enriched-indicator" title="Enriched record" /> : null}</span>}
			>
				{
					props.contact.hasAvatar()
					? <Avatar contact={props.contact} />
					: <PageIcon icon="user-circle" />
				}
				<HeaderActions>
					{
						!props.contact.isGlobal() && auth.account.multiUser()
						? <HeaderAction linkTo={{ pathname: `/contacts/${props.contact.id}/share`, state: { modal: true, backgroundLocation: location, returnTo: location.pathname } }} icon="rss" title="Share this contact" />

						: null
					}
					{props.getEnrichmentActionButton()}
					{
						props.contact.has_duplicates
						? <HeaderAction linkTo={{ pathname: `/contacts/${props.contact.id}/duplicates`, state: { modal: true, backgroundLocation: location, returnTo: location.pathname } }} icon="clone" title="Resolve duplicates" />
						: null
					}
					<HeaderAction icon="check-circle" title="Add a task">
						<Modalize popUnderClasses="top right headingless">
							<NewTaskForm record={props.contact} onSave={addTask} />
						</Modalize>
					</HeaderAction>
					<HeaderAction onClick={() => props.toggle('pin')} icon="thumbtack" className={props.contact.pinned ? "pinned" : ""} title={props.contact.pinned ? "Unpin this contact" : "Pin this contact"} />
					<HeaderAction linkTo={{ pathname: "/drafts/new/", state: { contact: props.contact, modal: true, returnTo: location.pathname } }} icon="paper-plane" title="Email this contact" />
					<HeaderAction linkTo={{ pathname: "/contacts/" + props.contact.id + "/edit", state: { resource: props.contact, modal: true, returnTo: location.pathname, backgroundLocation: location } }} icon="pencil" title="Edit this contact" />
				</HeaderActions>
			</Header>

			<ContactOwnershipBar key={`scope.${props.scopedMailbox}`} contact={props.contact} scopedMailbox={props.scopedMailbox} setMailboxScope={props.setMailboxScope} />

			{mobileNav()}

			<div className="contact-timeline-container">
				<div className={"interaction-overview" + (props.contact.hasMailboxInteractions() ? "" : " uncontacted")}>
					{auth.account.multiUser() ?
						<div className={"contact-type " + props.contact.getType(auth.user)}>
							<strong>{props.contact.getType(auth.user)}</strong> Contact
						</div>
						: null
					}

					{noInteractionBar()}
				</div>

				{contactTimes()}

				{
					props.contact.interactionHistoryForUser(auth.user)
					? <MessageTimeline key={`${props.contact.id}.timeline`} messagesByMonth={props.contact.interactionHistoryForUser(auth.user).monthly_messages} firstSentAt={props.contact.interactionHistoryForUser(auth.user).first_contact} lastSentAt={props.contact.interactionHistoryForUser(auth.user).last_contact} />
					: null
				}
			</div>

			{props.getUnenrichableNotice()}

			<div id="contact" className="main-content">
				{activePanel()}
			</div>
		</div>
	)
}

export default Enrichable(View);