export default class FileUploader {
	constructor(bucket, fileStateUpdater) {
		this.bucket = bucket;
		this.updateFileState = fileStateUpdater;
	}

	upload(file) {
		let formData = new FormData();

		formData.append('file', file);
		formData.append('bucket', this.bucket);

		let upload = { preview: file.preview, file_name: file.name, size: file.size };
		var xhr = new XMLHttpRequest()
		xhr.onload = () => {
			let response = JSON.parse(xhr.responseText);
			if (xhr.status === 200) {
				upload.status = 'complete';
				upload.s3_key = response.file.s3_key;
				upload.content_type = response.file.content_type;
			} else {
				upload.status = 'error';
				upload.error = JSON.parse(xhr.responseText);
			}

			this.updateFileState(upload);
		}
		xhr.onerror = function() {}
		xhr.upload.onprogress = (event) => {
			upload.status = 'uploading';

		  if (event.lengthComputable) {
		    var percent = Math.round((event.loaded / event.total) * 100)
		    upload.progress = percent;

		    this.updateFileState(upload);
		  }
		}

		xhr.open('POST', 'https://77qc2eel1l.execute-api.us-east-1.amazonaws.com/prod/uploadFile');
		xhr.setRequestHeader('Accept', 'application/json');
//		xhr.setRequestHeader('Content-Type', 'multipart/form-data');
		xhr.send(formData);
	}
}