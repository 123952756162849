import { useState, useEffect } from 'react'
import { useAuth } from './useAuth'
import Fetcher from './Fetcher'
import User from '../resources/User'

export const useNotificationListHandler = () =>  {
	const auth = useAuth()
	const [notifications, setNotifications] = useState(null)
	const fetcher = new Fetcher(localStorage.token)

	useEffect(() => {
		setNotifications(auth.user.notification_inbox.latest_notifications)
	}, [])

	const dismiss = (dismissed) => {
		if (dismissed.notification) {
			fetcher.post(`/notifications/dismiss`, { ids: [dismissed.notification.id] })
		} else if (dismissed.notifications) {
			fetcher.post(`/notifications/dismiss`, { ids: dismissed.notifications.map(notification => notification.id) })
		} else {
			fetcher.post(`/notifications/dismiss`, dismissed)
		}
	}

	const markAsRead = (read) => {
		if (read.notification) {
			fetcher.post('/notifications/read', { ids: [read.notification.id] })
		} else if (read.notifications) {
			fetcher.post('/notifications/read', { ids: read.notifications.map(notification => notification.id) })
		} else if (read.ids || read.slice) {
			fetcher.post('/notifications/read', read)
		}
	}

/*
		let user = new User(auth.user);
		user.notification_inbox.set('new_count', user.notification_inbox.new_count - ids.length);
		user.notification_inbox.set('last_viewed_at', new Date().getTime());

		user.notification_inbox.set('latest_notifications', notifications.map(notification => {
			notification.set('new', false);
			return notification;
		}));

		auth.setUser(user)
*/



	const dismissAll = () => {

	}

	return [markAsRead, dismiss, dismissAll]
}

