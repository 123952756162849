import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import OutsideClickHandler from '../common/OutsideClickHandler'
import Fetcher from '../../lib/Fetcher'

const Reclassify = ({ mailingList }) => {
	const [showForm, setShowForm] = useState(false)
	const [saving, setSaving] = useState(false)
	const navigate = useNavigate()

	const form = () => {
		return (
			<OutsideClickHandler onOutsideClick={() => setShowForm(false)}>
				<div className="pop-under-form white headingless right top">
					<div className="contents">
						<p><strong>If we mistakenly classified this contact as a mailing list, you can convert it now.</strong></p>
						<p>You&#8217;ll then be redirected to a standard contact view for {mailingList.displayName()}.</p>
						<div className="centered">
							{saving ? <i className="fa fa-spinner fa-spin" /> : <span className="medium green button" onClick={reclassify}>Convert to contact</span>}
						</div>
					</div>
				</div>
			</OutsideClickHandler>
		);
	}

	const reclassify = () => {
		setSaving(true)

		new Fetcher(localStorage.token).process('POST', `/mailing_lists/${mailingList.id}/reclassify`).then(() => {
			navigate(`/contacts/${mailingList.id}`);
		})
	}

	return (
		<div className="recategorize-mailing-list-container">
			<div className={"recategorize-mailing-list" + (showForm ? ' active' : '')} onClick={() => setShowForm(true)}>Not a mailing list?</div>
			{showForm ? form() : null}
		</div>
	)
}

export default Reclassify