import useFormInput from './useFormInput'

export const SegmentedSelect = (props) => {
	const formInput = useFormInput(props)

	var value = formInput.valueFor(props.name)
	value = (value === undefined ? props.defaultValue : value)

	return (
		<div className={"segmented toggler " + (props.className || '')}>
			{props.children.map(function(option) {
				return <span key={option.props.value} className={value === option.props.value ? "active" : ""} onClick={formInput.handleChange.bind(null, props.name, option.props.value)}>{option.props.children}</span>
			})}
		</div>
	)
}