import React from 'react';
import { Helmet } from 'react-helmet';
import SearchResultForm from '../common/SearchResultForm';
import { useAddressBookScoper } from '../../lib/useAddressBookScoper';
import { useRouter } from '../../lib/useRouter';

const SearchResults = () => {
	const scope = useAddressBookScoper()
	const router = useRouter()

/*
	componentWillReceiveProps(nextProps) {
		if (this.props.scope !== nextProps.scope) {
			this.props.onLoadCompletion();
		}
	}
*/

	return (
		<Helmet title="Search your files" />,
		<SearchResultForm scope={null/*this.props.scope*/} resource="files" query={router.query.q} router={router} />
	);
}

export default SearchResults
//export default AddressBookScoper(SearchResults);