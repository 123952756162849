import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const PageIcon = (props) => {
	const togglePanel = () => {
		// TODO
		// ReactDOM.findDOMNode(this.context.getSidebar()).classList = 'slide-in';
	}

	if (props.toggleSidebarOnMobile && window.isMobile) {
		return <span className={"header-page-icon " + (props.className || "")} onClick={togglePanel}><i className="fa fa-bars" /></span>
	} else if (props.children) {
		return (
			<span className={"header-page-icon " + (props.className || "") + (props.withBreadcrumb ? " with-breadcrumb" : "")}>
				{props.children}
			</span>
		);
	} else if (props.imgSrc) {
		return (
			<span className={"header-page-image " + (props.className || "") + (props.withBreadcrumb ? " with-breadcrumb" : "")}>
				<img src={props.imgSrc} alt="" />
			</span>
		);
	} else {
		return (
			<span className={"header-page-icon " + (props.className || "") + (props.withBreadcrumb ? " with-breadcrumb" : "")}>
				<i className={`far fa-${props.icon}`} />
			</span>
		);
	}
}

PageIcon.displayName = 'PageIcon'

export default PageIcon