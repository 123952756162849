import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Modalize } from '../../lib/Modalize';
import MailboxRepository from '../../repositories/MailboxRepository';
import { useAuth } from '../../lib/useAuth'

const Picker = (props) => {
	const auth = useAuth()
	const [unsubscribedMailboxes, setUnsubscribedMailboxes] = useState(null)

	useEffect(() => {
		new MailboxRepository().getCollection({}).then(collection => {
			setUnsubscribedMailboxes(
				collection.records.filter(mailbox => !props.user.subscribedMailboxIds().includes(mailbox.id))
			)
		})
	}, [])

	const mailboxRow = (mailbox, subscription) => {
		return (
			<div className="mailbox" onClick={props.setScope.bind(null, mailbox.id)}><i className="fa fa-circle" style={{ color: (subscription && subscription.color ? subscription.color : '#ccc') }} />
				<strong>{mailbox.displayName()}</strong>
				{mailbox.name === '' ? '' : <div>{mailbox.email_address}</div>}
			</div>
		);
	}

	const unsubscribedMailboxList = () => {
		if (unsubscribedMailboxes) {
			return (
				<React.Fragment>
					<div className="divider"><i className="fa fa-ellipsis-h" />Other connected mailboxes</div>
					{unsubscribedMailboxes.map(mailbox => mailboxRow(mailbox))}
				</React.Fragment>
			);
		}
	}

	return (
		<Modalize popUnderClasses="top center" show={props.show} toggle={props.toggle} getRefPoint={props.getRefPoint}>
			<div className="content mailbox-picker">
				<div className="divider"><i className="fa fa-check-square" />Your subscribed mailboxes</div>
				{auth.user.mailbox_subscriptions.map(sub => mailboxRow(auth.user.getMailboxById(sub.mailbox_id), sub))}
				{unsubscribedMailboxList()}
			</div>
		</Modalize>
	)
}

const MailboxScoper = ({ onUpdate }) => {
	const [scope, setScope] = useState(localStorage.scope || 'subscribed')
	const [previous, setPrevious] = useState(null)
	const [loading, setLoading] = useState(null)
	const [showMailboxSelector, setShowMailboxSelector] = useState(false)
	const [sidebarRef, setSidebarRef] = useState(null)
	const pickerRefPoint = useRef(null)
	const auth = useAuth()

	const persistScope = (newScope) => {
		localStorage.setItem('scope', newScope);

//		setPrevious(scope)
//		setLoading(newScope)
		setScope(newScope)
		onUpdate(newScope)
	}

	const onLoadCompletion = () => {
		setLoading(null)
		setPrevious(null)
	}

	const toggleMailboxSelection = () => {
		setShowMailboxSelector(!showMailboxSelector)
	}

	const selectedMailbox = () => {
		if (!['personal', 'subscribed', 'all'].includes(scope)) {
			return auth.user.getMailboxes().find(mailbox => mailbox.id === scope);
		}
	}

	const mailboxes = () => {
		let mailboxes = {};

		if (auth.user.getMailboxes().length > 1) {
			if (auth.user.hasPrimaryMailbox()) {
				mailboxes.personal = 'Personal';
			}

			if (auth.user.mailbox_subscriptions.length > 1) {
				mailboxes.subscribed = 'Subscribed';
			}

			/*if (props.user.account.mailboxes.length > props.user.mailbox_subscriptions.length) {
				mailboxes.all = 'All';
			}*/
		}
		if (auth.account.multiUser()) {
			mailboxes.shared = 'Shared'
		}

		return mailboxes;
	}

	if (auth.user.getMailboxes().length > 1) {
		return (
			<div className="scope-tab-container">
				<i className="fa fa-inbox" />
				<div className="scope-tabs">
					{
						Object.keys(mailboxes()).map(m => {
							let active = false;
							if ((m === scope && loading === null) || (previous === m && loading)) {
								active = true;
							}

							return <span
								key={m}
								onClick={() => persistScope(m)}
								className={(active ? 'active' : '') + (loading === m ? ' loading' : '')}
							>{mailboxes()[m]}</span>
						})
					}
					<span ref={pickerRefPoint} className={'single-mailbox ' + (selectedMailbox() && scope === selectedMailbox().id && loading !== scope ? " active" : "") + (selectedMailbox() && loading === selectedMailbox().id ? ' loading' : '')} onClick={toggleMailboxSelection}>
						{selectedMailbox() && !window.isMobile ? selectedMailbox().displayName() : <span>In&hellip;</span>}

						{showMailboxSelector ? <Picker user={auth.user} setScope={persistScope} show={showMailboxSelector} toggle={toggleMailboxSelection} getRefPoint={() => pickerRefPoint} /> : null}
					</span>
				</div>
				{selectedMailbox() && window.isMobile ? <span className="selected-single-mailbox">{selectedMailbox().displayName()}</span> : null}
			</div>
		);

		if (window.isMobile) {
			if (sidebarRef) {
				/*return [
					ReactDOM.createPortal(scoper, ReactDOM.findDOMNode(sidebarRef).querySelector('.scope-container')),
					<Component {...props} scope={scope} onLoadCompletion={onLoadCompletion} />
				];*/
			}
		}
	}

}

export default MailboxScoper