import Repository from '../lib/Repository';
import Thread from '../resources/Thread';

export default class ThreadRepository extends Repository {
	findByMessageId(messageId) {
		return this.fetcher.get('/messages/' + messageId).then(json => {
			return this.entityFromJson({ thread: json.message.thread });
		});
	}

	entityClass = Thread;
	baseUrl = '/threads';
	filterFields = ['subject', 'participant', 'first_sent_before', 'last_sent_after', 'unread', 'flagged', 'has_attachment', 'category'];
	sortOptions = ['message_count', 'last_sent_at'];
	defaultSort = 'last_sent_at';
	defaultOrder = 'desc';
	sliceFilters = {
		today: { icon: 'sun', name: 'Emails you&#8217;ve received today' },
		workweek: { icon: 'calendar-alt', name: 'Emails from this workweek' },
		unreplied: { icon: 'ellipsis-h', name: 'Unresolved emails awaiting your reply' },
		awaiting: { icon: 'hourglass-half', name: 'Unresolved emails awaiting someone else&#8217;s reply' },
		flagged: { icon: 'flag', name: 'Emails you&#8217;ve flagged' },
		'pinned-contacts': { icon: 'thumbtack', name: 'Emails from your pinned contacts' },
		snoozed: { icon: 'alarm-clock', name: 'Emails you&#8217;ve snoozed until later' },
		shared: { icon: 'rss', name: 'Shared with you' },
		'mailing-lists': { icon: 'newspaper', name: 'Emails from mailing lists' }
	};
}