import { useState } from 'react';
import { Helmet } from 'react-helmet';
import Organization from '../../resources/Organization';
import Form from './Form';

const New = () => {
	const [organization, setOrganization] = useState(new Organization({}))

	return (
		<div className="main mini headingless standalone">
			<Helmet title="Add a new organization" />
			<div className="subheading gradient">
				<i className="fa fa-building" />
				<h2>Add a new organization</h2>
			</div>
			<div className="main-content">
				<div className="main-form gray full-width new-form standalone">
					<Form organization={organization} />
				</div>
			</div>
		</div>
	)
}

export default New