import { useState, useEffect } from 'react';
import MilestoneForm from './Form';
import { useResourceLoader } from '../../lib/useResourceLoader'

const Edit = () => {
	const resource = useResourceLoader('MilestoneRepository')
	const [milestone, setMilestone] = useState(null)

	useEffect(() => {
		if (resource) {
			setMilestone(resource)
		}
	}, [resource])

	return (
		<div className="main mini headingless no-sidebar">
			<div className="subheading gradient">
				<i className="fa fa-pencil" />
				<h2>Edit this milestone</h2>
			</div>
			<div className="main-content">
				<div className="main-form full-width new-form standalone">
					{milestone ? <MilestoneForm milestone={milestone} /> : null}
				</div>
			</div>
		</div>
	)
}

export default Edit