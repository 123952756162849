import Resource from '../../lib/Resource';

export default class Avatar extends Resource {
	exists() {
		return !!this.source_url;
	}
}

Avatar.fields = ['source_url', 'downloaded_at', 'content_type', 'file_name', 'file'];
Avatar.belongsTo = [{ polymorphic: true, as: 'record' }];
Avatar.scopedTo = 'record';