import { useState } from 'react';
import { TextInput } from '../../../../lib/forms/TextInput';
import { HiddenField } from '../../../../lib/forms/HiddenField';
import OrganizationSelector from '../OrganizationSelector';

const EmploymentInput = (props) => {
	const [organization, setOrganization] = useState(props.employment.organization)

	const onExistingOrgSelection = (org) => {
		let employment = props.employment;
		employment.organization = org;

		props.update('employments', employment)

		setOrganization(org)
	}

	return (
		<div className="field-section" key={`employments.${props.employment.lookupKey()}`}>
			<div className="remove">
				<i className="fal fa-times-circle" onClick={props.remove.bind(null, 'employments', props.employment)} />
			</div>
			<div className="fields">
				<HiddenField name={`employments[${props.employment.lookupKey()}].id`} />
				<HiddenField name={`employments[${props.employment.lookupKey()}].organization_id`} defaultValue={organization ? organization.id : null} />
				<TextInput name={`employments[${props.employment.lookupKey()}].title`} placeholder="Professional role or title" /> <span className="hide-on-mobile">at</span> <OrganizationSelector currentOrganization={organization} onSelection={onExistingOrgSelection} employmentLookupKey={props.employment.lookupKey()} />
			</div>
		</div>
	)
}

export default EmploymentInput