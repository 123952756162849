import React, { useState } from 'react'
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom'
import * as Search from '../search/Search'
import SidebarNotificationPanel from '../notifications/SidebarPanel'
import moment from 'moment'
import { useAuth } from '../../lib/useAuth'

export const Navigator = (props) => {
	const location = useLocation()
	const navigate = useNavigate()
	const params = useParams()
	const [showSearchForm, setShowSearchForm] = useState(false)
	const [showNotifications, setShowNotifications] = useState(false)
	const auth = useAuth()

	const injectCustomizedDefault = (module, defaultLink, e) => {
		if (localStorage.defaultLens) {
			let lenses = JSON.parse(localStorage.defaultLens)
			if (lenses[module]) {
				e.preventDefault()
				navigate(lenses[module])
			}
		}
	}

	const handleNotificationClick = e => {
		if (auth.user.notification_inbox.latest_notifications && auth.user.notification_inbox.latest_notifications.length > 0) {
			e.preventDefault()
			setShowNotifications(true)
		}
	}

	const notificationIcon = () => {
		if (auth.user.notification_inbox.total > 0) {
			let badgeClass = (auth.user.notification_inbox.hasNewNotificationsSinceLastRead() ? "alert-number" : "warning-number")

			return (
				<NavLink to='/notifications' state={{ modal: true, returnTo: location.pathname }} onClick={() => handleNotificationClick}>
					<i className="fa fa-bell">
						{
							auth.user.notification_inbox.new_count > 0
							? <div className={badgeClass}><span>{auth.user.notification_inbox.new_count > 99 ? "99+" : auth.user.notification_inbox.new_count}</span></div>
							: null
						}
					</i>
					<span className="title">Notifications</span>
				</NavLink>
			)
		}
	}

	return (
		<div id="menu">
			<div className="identifier">
				<div className="call-sign">
					{
						auth.user.avatar && auth.user.avatar.source_url
						? <NavLink to="/settings" className="current-user-avatar"><img src={auth.user.avatar.source_url} alt="Your avatar" /></NavLink>
						: <NavLink to="/settings" className="initials">{auth.user.getInitials()}</NavLink>
					}
				</div>
			</div>

			{showSearchForm ? <Search.SidebarSearch router={props.router} location={location} formToggle={() => setShowSearchForm(!showSearchForm)} /> : null}

			{showNotifications ? <SidebarNotificationPanel router={props.router} location={location} user={auth.user} toggle={() => setShowNotifications(!showNotifications)} /> : null}

			<div className="panel-filter-links top">
				<NavLink to="/">
					<i className="far fa-tachometer">
						{
							auth.user.unread_open_thread_count > 0
							? (
								<div className={moment(auth.user.inbox_last_viewed_at).isAfter(moment(auth.user.last_message_received_at)) ? "alert_number" : "warning-number"}>
									<span>{auth.user.unread_open_thread_count > 99 ? "99+" : auth.user.unread_open_thread_count}</span>
								</div>
							) : null
						}
					</i>
					<span className="title">Overview</span>
				</NavLink>
				<NavLink to="/contacts" onClick={(e) => injectCustomizedDefault('contacts', '/contacts', e)}>
					<i className="fa fa-users" /><span className="title">Contacts</span>
				</NavLink>
				<NavLink to="/organizations" onClick={(e) => injectCustomizedDefault('organizations', '/organizations', e)}>
					<i className="fa fa-building" /><span className="title">Organizations</span>
				</NavLink>
				<NavLink to="/email" onClick={(e) => injectCustomizedDefault('email', '/email', e)}>
					<i className="fa fa-envelope" /><span className="title">Emails</span>
				</NavLink>
				<NavLink to='/files' onClick={(e) => injectCustomizedDefault('files', '/files', e)}>
					<i className="fa fa-file-alt" /><span className="title">Files</span>
				</NavLink>
				<NavLink to='/tasks' onClick={(e) => injectCustomizedDefault('tasks', '/tasks', e)} className={location.pathname.includes('tasks') || location.pathname.includes('milestones') ? "active" : ""}>
					<i className="fa fa-tasks" /><span className="title">Tasks</span>
				</NavLink>
			</div>

			<div className="panel-filter-links bottom">
				{notificationIcon()}
				<a onClick={() => setShowSearchForm(true)}>
					<i className="fa fa-search" style={(showSearchForm ? { color: 'white' } : {})} />
				</a>
				<NavLink to="/settings" state={{ modal: true, returnTo: location.pathname }}>
					<i className="fa fa-cog" /><span className="title">Settings</span>
				</NavLink>
			</div>
		</div>
	)

}
