import Resource from '../lib/Resource';
import moment from 'moment';

export default class NotificationInbox extends Resource {
	hasNewNotificationsSinceLastRead() {
		if (this.lastViewTime() && this.latest_notifications.length > 0 && moment(this.latest_notifications[0].created_at).isAfter(this.lastViewTime())) {
			return true;
		} else {
			return false;
		}
	}

	lastViewTime() {
		return (this.last_viewed_at ? moment(this.last_viewed_at) : null);
	}
}

NotificationInbox.fields = ['new_count', 'total', 'last_viewed_at', { latest_notifications: ['Notification'] }];
NotificationInbox.modelName = 'NotificationInbox';