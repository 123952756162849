import React, { useState } from 'react';
import { useAuth } from '../../lib/useAuth';
import Avatar from './contact/Avatar';
import Widget from './contact/Widget';

const StubList = (props) => {
	const [expanded, setExpanded] = useState(null)
	const auth = useAuth()

	const link = (contact) => {
		let isCurrentUser = contact.emailAddressValues().includes(auth.user.email_address);
		let onClick = (isCurrentUser ? null : () => { setExpanded(contact) })

		return (
			<div key={contact.id} className={"contact-stub-row" + (isCurrentUser ? " unexpandable" : "")} onClick={onClick} >
				<div className="avatar-container">
					<Avatar contact={contact} inline={true} />
				</div>
				<div>
					<div className="name"><strong>{isCurrentUser ? "You" : contact.displayName()}</strong></div>
					{contact.name ? <div className="etc">{contact.primaryEmailAddress()}</div> : null}
				</div>
			</div>
		)
	}

	return (
		<div className="contact-stub-list">
			{props.contacts.map(contact => {
				return (expanded === contact ? <Widget key={contact.id} contact={contact} /> : link(contact));
			})}
		</div>
	)
}

export default StubList