import Resource from '../lib/Resource';
import { ShareableRecord } from '../lib/ShareableRecord';
import { AbstractContact } from '../lib/AbstractContact';

export default class MailingList extends ShareableRecord(AbstractContact(Resource)) {

}

MailingList.fields = [
	'id', 'name', 'email_address', 'background', 'task_count', 'scope', 'private_interactor_ids', { shares: ['Share'] }, 'user_id',
	'numbers', 'interaction_histories', 'enriched', 'pinned', { employments: ['Employment'] }, { latest_threads: ['Thread'] }, 'mailbox_ids',
	{ files: 'File' }, { avatar: 'Avatar' }, { tasks: ['Task'] }, { collaborators: ['Contact'] }, { locations: ['Location'] },
	{ websites: ['Website'] }, { social_profiles: ['SocialProfile'] }, { latest_files: ['File'] }, { comments: ['Comment'] }, 'average_frequency_in_words',
	{ email_addresses: ['EmailAddress'] },
];
MailingList.editableFields = [
	'name', 'background', 'websites', 'locations', 'employments', 'social_profiles', 'avatar', 'email_addresses'
]
MailingList.modelName = 'MailingList';