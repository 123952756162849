import { useEffect, useContext } from 'react'
import FormContext from '../FormContext'
import useFormInput from './useFormInput'

export const TextInput = (props) => {
	const context = useContext(FormContext)
	const formInput = useFormInput(props)

	let defaultValue = context.valueFor(props.name);
	if ((defaultValue === null || defaultValue === undefined) && props.defaultValue) {
		defaultValue = props.defaultValue;
	}

	return (
		<input type="text"
			{...props}
			/*ref={props.inputRef}*/
			defaultValue={defaultValue}
			disabled={(context.isDefaultValue ? context.isDefaultValue(props.name) : false)}
			id={props.name}
			onChange={formInput.handleChange.bind(null, props.name)}
			className={formInput.classes()}
		/>
	)
}