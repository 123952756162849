import { useState, useEffect } from 'react';
import Loading from '../common/Loading';
import ScopedFilterForm from './ScopedFilterForm'
import ThreadRepository from '../../repositories/ThreadRepository';
import Table from './Table'
import Pagination from '../common/Pagination';
import NoResults from '../common//NoResults';
import useInPlaceFilter from '../../lib/useInPlaceFilter'

const threadFilters = {
	unread: (record) => { return !record.read },
	flagged: (record) => { return record.flagged },
	has_attachments: (record) => { return record.has_attachments },
	unresolved: (record) => { return !record.resolved }
}

const ScopedThreads = (props) => {
	const parentRecord = props.contact || props.organization
	const [processingSearch, setProcessingSearch] = useState(false)
	const [collection, collectionFilters] = useInPlaceFilter(parentRecord, 'ThreadRepository', threadFilters)

	const table = () => {
		if (collection.records.length > 0) {
			return <Table threads={collection.records} />
		} else if (collectionFilters.activeFilters === []) {
			return <NoResults message={`You have not exchanged any emails with ${parentRecord.displayName()}`} />;
		} else {
			return <NoResults message="There are no emails matching your filter criteria" />;
		}
	}

	if (collection) {
		return (
			<div className="panel">
				<ScopedFilterForm activeFilters={collectionFilters.activeFilters} processingFilters={collectionFilters.processingFilters} toggleFilter={collectionFilters.toggleFilter} search={collectionFilters.search} />
				<div>
					{table()}
					<Pagination collection={collection} filter={/*this.filter*/() => {}} maintainUrl={true} />
				</div>
			</div>
		)
	} else {
		return <Loading inline={true} />
	}
}

export default ScopedThreads
/*
	componentWillReceiveProps(nextProps) {
		if (nextProps.scopedMailbox && nextProps.scopedMailbox != this.props.scopedMailbox) {
			this.props.toggleFilter('mailbox_id', nextProps.scopedMailbox.id)
		}
	}
*/

/*
	const resetAfterSearch = () => {
		setShowSearch(false)
		props.updateRecords(props.initialCollection.records)
	}

	const search = (query) => {
		setProcessingSearch(true)
		this.props.clearFilters()
		this.props.loadRecords({ query: query }).then(() => { this.setState({ processingSearch: false }) })
	}

}

export default Threads

/*


export default FilterableRecordCollection(Threads, ThreadRepository, {
	unread: (record, _) => { return !record.read; },
	flagged: (record, _) => { return record.flagged; },
	has_attachments: (record, _) => { return record.has_attachments; },
	unresolved: (record, _) => { return !record.resolved; }
});
*/