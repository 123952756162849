import { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom'
import { autoLink } from '../../lib/Utilities';

const MessageBody = ({ message }) => {
	const [iframeHeight, setIframeHeight] = useState('auto')
	const iframeRef = useRef(null)

	useEffect(() => {
		if (iframeRef.current) {
			iframeRef.current.contentDocument.body.innerHTML = message.body.formattedBody(message.attachments);
			let cssLink = iframeRef.current.contentWindow.document.createElement('link');
			cssLink.href = "/css/message-frame.css";
			cssLink.rel = "stylesheet";
			cssLink.type = "text/css";
			iframeRef.current.contentWindow.document.head.appendChild(cssLink);
			resizeMessage()

			let iframe = ReactDOM.findDOMNode(iframeRef.current)
			let imgs = Array.from(iframe.contentWindow.document.getElementsByTagName('img'));

			imgs.forEach(img => {
				img.addEventListener('load', () => resizeMessage())
				img.addEventListener('error', () => resizeMessage())
			})
		}
	}, [iframeRef])
/*
	useEffect(() => {
		Promise.all(Array.from(document.images).filter(img => !img.complete).map(img => new Promise(resolve => { img.onload = img.onerror = resolve; }))).then(() => {
		    resizeMessage()
		});
	}, [])
*/
/*
	Promise.all(Array.from(document.images).filter(img => !img.complete).map(img => new Promise(resolve => { img.onload = img.onerror = resolve; }))).then(() => {
	    console.log('images finished loading');
	});
*/

	const findHighestNode = (nodesList) => {
		var pageHeight = 0;

	    for (var i = nodesList.length - 1; i >= 0; i--) {
	        if (nodesList[i].scrollHeight && nodesList[i].clientHeight) {
	            var elHeight = Math.max(nodesList[i].scrollHeight, nodesList[i].clientHeight);
	            pageHeight = Math.max(elHeight, pageHeight);
	        }
	        if (nodesList[i].childNodes.length) findHighestNode(nodesList[i].childNodes);
	    }

	    return pageHeight;
	}

	const calcHeight = () => {
		return findHighestNode(iframeRef.current.contentWindow.document.body.childNodes)
	}

	const resizeMessage = () => {
		let height = Math.max(
			calcHeight(),
			iframeRef.current.contentWindow.document.documentElement.scrollHeight,
			iframeRef.current.contentWindow.document.body.scrollHeight,
			iframeRef.current.contentWindow.document.body.offsetHeight,
			iframeRef.current.contentWindow.document.documentElement.scrollHeight,
			iframeRef.current.contentWindow.document.documentElement.clientHeight
		)
		setIframeHeight(height + 'px')
	}

	const body = () => {
		if (message.body.isHtml() ) {
			return <iframe title={message.id} className="message-frame" style={{ height: iframeHeight }} sandbox="allow-scripts allow-popups allow-same-origin allow-top-navigation" target="_blank" ref={iframeRef} />;
		} else {
			return (
				<div className={"email-body" + (message.body.isPlainText() ? ' line-break' : '')}>
					<div dangerouslySetInnerHTML={{ __html: autoLink(message.body.formattedBody(), { target: "_blank" }) }} />
				</div>
			);
		}
	}

	return (
		<div>
			{body()}
		</div>
	)
}

export default MessageBody