import _ from 'lodash';

export default class Collection {
	fields = ['per_page', 'page', 'records', 'total_results', 'pages', 'slice_key', 'filters'];
	attributes = {}

	constructor(json, entityClass, repository, filters = {}) {
		for (let field of this.fields) {
			this[field] = this.attributes[field] = json[field]
		}

		this.criteria = Object.assign({}, json.filters, filters, { sort: json.sort, order: json.order });
		this.repository = repository;
		this.loadRecordEntities(entityClass);
	}

	currentFilters() {
		return this.criteria;
	}

	currentSlice() {
		if (this.slice_key === null) {
			return null;
		} else if (Array.isArray(this.repository.sliceFilters)) {
			return this.slice_key;
		} else if (this.repository.sliceFilters[this.slice_key]) {
			return this.repository.sliceFilters[this.slice_key]
		}
	}

	isEmpty() {
		return this.total_results === 0;
	}

	isFiltered() {
		return Object.keys(this.currentFilters()).filter(k => { return !['page', 'sort', 'order', 'slice', 'scope', 'category'].includes(k) }).length > 0;
	}

	getPersistentFilters() {
		return _.omit(this.currentFilters()); // this used to omit slice and scope but I'm not sure why...
	}

	getFilter(key) {
		return this.criteria[key];
	}

	firstResultNumber() {
		return ((this.page || 1) - 1) * this.per_page + 1;
	}

	lastResultNumber() {
		return Math.min(this.total_results, (this.page || 1) * this.per_page);
	}

	setRecords(records) {
		this.records = records
	}

	loadRecordEntities(entityClass) {
		this['records'] = this.attributes['records'] = this.records.map(function(record) {
			return new entityClass(record);
		})
	}

	isComplete() {
		return this.per_page >= this.total_results;
	}

	addFilterCriteria(criteria) {
		var self = this;

		_.forEach(_.pick(criteria, this.repository.getFilterFields()), function(v, k) {
			self.setFilterCriterion(k, v);
		});
	}

	setFilterCriteria(criteria) {
		this.criteria = {};

		this.addFilterCriteria(criteria);
	}

	setFilterCriterion(criterion, value) {
		if (_.includes(this.repository.getFilterFields(), criterion)) {
			this.criteria = _.isUndefined(this.criteria) ? {} : this.criteria;

			if (value && value !== '' && value !== null) {
				this.criteria[criterion] = value;
				if (this[criterion] !== null) {
					this[criterion] = value;
				}
			} else {
				this.criteria = _.omit(this.criteria, criterion);
			}
		}
	}

	hasPersistentFilters() {
		return Object.keys(this.getPersistentFilters()).length > 0;
	}

	getFilterCriterion(criterion) {
		return this.filterCriteria()[criterion];
	}

	filterCriteria() {
		return _.pick(this.currentFilters(), this.repository.getFilterFields());
	}

	slicedFilterCriteria() {
		return _.merge(this.filterCriteria(), this.hasSlice() ? this.slice.filters : {});
	}

	hasSlice() {
		return this.slice_key !== null
	}
}