import React, { useState } from 'react';
import Fetcher from '../../lib/Fetcher';
import { Link } from 'react-router-dom';
import { Submit } from '../../lib/forms/Submit';

const ResetRequest = () => {
	const [requested, setRequested] = useState(false);
	const [emailAddress, setEmailAddress] = useState('');
	const fetcher = new Fetcher(null);

	const updateEmailAddress = (e) => {
		setEmailAddress(e.target.value);
	}

	const requestReset = (e) => {
		e.preventDefault();

		fetcher.post('/password_resets', { email_address: emailAddress }).then(() => {
			setRequested(true);
		});
	}

	const form = () => {
		return (
			<div className="main-form padded">
				<form onSubmit={requestReset}>
					<input onChange={updateEmailAddress} type="text" name="email_address" autoFocus className="full large" placeholder="Your email address" autoCorrect="off" autoCapitalize="off" />
					<div className="submit">
						<Submit name="send" value="Reset your password" className="rounded medium green" />
					</div>
				</form>
			</div>
		);
	}

	const successMessage = () => {
		return (
			<div className="inline-notice">
				<h3>We&#8217;ve received your password reset request</h3>
				<p>You requested a new password for <strong className="minor">{emailAddress}</strong>. </p>

				<p className="etc normal-size">If there is an account with this email address, you&#8217;ll receive an email with instructions on how to reset your password.</p>
			</div>
		);
	}

	return (
		<div className="main mini standalone">
			<div className="subheading">
				<i className="fa fa-key" />
				<h2>Recover your password</h2>
				<span className="side-button"><Link to="/sessions" className="header-side-link">sign in<span className="desktop-only"> to your account</span></Link></span>
			</div>
			<div className="main-content gray">
				{requested ? successMessage() : form()}
			</div>
		</div>
	);
}

export default ResetRequest