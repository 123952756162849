import { useState, useEffect } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { useAuth, useResourceLoader, useToggle } from '../../lib/hooks'
import { Helmet } from 'react-helmet'
import ShareSheet from '../sharing/ShareSheet'
import Loading from '../common/Loading'
import ViewEmail from './ViewEmail'
import ViewSidebar from './ViewSidebar'
import { Shareable } from '../../lib/Shareable'
import { Modalize } from '../../lib/Modalize'
import SnoozeForm from './SnoozeForm'
import { Header, PageIcon, HeaderActions, HeaderAction } from '../common/header'

const ViewContainer = () => {
	const [messagesLoaded, setMessagesLoaded] = useState(false)
	const [mobileViewModule, setMobileViewModule] = useState('email')
	const [thread, setThread] = useState(null)
	const [showSnooze, setShowSnooze] = useState(false)
	const resource = useResourceLoader('ThreadRepository')
	const auth = useAuth()
	const toggle = useToggle(resource, setThread)

	useEffect(() => {
		if (resource) {
			setThread(resource)
		}
	}, [resource])

/*
	componentWillReceiveProps(props) {
		if (props.thread.messages.find(message => { return (!message.body || !message.body.format) })) {
			this.setState({ messagesLoaded: false });
			this.props.reload(props).then(this.setState({ messagesLoaded: true }));
		} else {
			this.setState({ messagesLoaded: true });
		}
	}
*/
	const pageIcon = () => {
		if (thread.resolved) {
			return 'box-check';
		} else if (thread.read) {
			return 'envelope';
		} else {
			return 'envelope-open';
		}
	}

	const actions = () => {
		if (thread) {
			return (
				<HeaderActions>
					{
						auth.account.multiUser()
						? (
							<HeaderAction icon="rss" title="Share this thread" className={(thread.isShared() ? 'shared' : '')}>
								<Modalize popUnderClasses="top right">
									<ShareSheet record={thread} />
								</Modalize>
							</HeaderAction>
						)
						: null
					}
					<HeaderAction onClick={() => toggle('resolution')} className={thread.resolved ? "on" : ""} icon="box-check" title={thread.resolved ? "Mark as unresolved" : "Resolve this thread"} />
					<HeaderAction onClick={() => toggle('read')} icon={(thread.read ? 'envelope' : 'envelope-open')} title={thread.read ? "Mark as unread" : "Mark as read"} />
					<HeaderAction className={showSnooze ? 'active' : (thread.isSnoozed() ? 'snoozed' : '')} icon="alarm-clock" title="Snooze until later">
						<Modalize popUnderClasses="headingless white snooze right top"><SnoozeForm thread={thread} beforeSnooze={() => {}} onSnooze={(newThread) => { console.log(newThread); setThread(newThread) }} /></Modalize>
					</HeaderAction>
					<HeaderAction onClick={() => toggle('flag')} className={thread.flagged ? "red" : ""} icon={(thread.flagged ? 'flag fal' : 'flag')} title={thread.flagged ? "Unflag this thread" : "Flag this thread"} />
				</HeaderActions>
			);
		}
	}

	const mobileView = () => {
		return (
			<div className="main headingless thread">
				<Helmet title={thread.displaySubject()} />
				<Header title={thread.displaySubject()}>
					<PageIcon icon={pageIcon()} />
					{actions()}
				</Header>
				<div className="subheader-nav">
					<ul className="sections">
						<li onClick={() => setMobileViewModule('email')}>
							<span className={"console-button" + (mobileViewModule !== 'sidebar' ? ' active' : '')}>Email Content</span>
						</li>
						<li onClick={() => setMobileViewModule('sidebar')}>
							<span className={"console-button" + (mobileViewModule === 'sidebar' ? ' active' : '')}>Contacts &amp; Files</span>
						</li>
					</ul>
				</div>
				<div className="main-content">
					{
						mobileViewModule === 'sidebar'
						? <ViewSidebar thread={thread} updateThread={() => {}} currentUser={auth.user} />
						: <ViewEmail thread={thread} updateThread={() => {}} currentUser={auth.user} />
					}
				</div>
			</div>
		)
	}

	const desktopView = () => {
		return (
			<div className="main headingless thread">
				<Helmet title={thread.displaySubject()} />
				<div id="content">
					<div className="structure">
						<div className="main-panel">
							<div style={{ position: 'relative' }}>
								<Header title={thread.displaySubject()}>
									<PageIcon icon={pageIcon()} />
									{actions()}
								</Header>
							</div>

							<ViewEmail thread={thread} updateThread={setThread} currentUser={auth.user} />
						</div>
					</div>
				</div>
			</div>
		);
	}

	if (thread) {
		if (window.isMobile) {
			return mobileView()
		} else {
			return desktopView()
		}
	} else {
		return <Loading />
	}
}

export default ViewContainer