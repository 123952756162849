import React from 'react'
import { useNavigate } from 'react-router-dom'
import MilestoneForm from './Form'
import Milestone from '../../resources/Milestone'

const New = () => {
	const navigate = useNavigate()

	return (
		<div className="main mini headingless no-sidebar">
			<div className="subheading gradient">
				<i className="fa fa-map-signs" />
				<h2>Create a new milestone</h2>
				<span onClick={navigate('-1')} className="close"><i className="fa fa-times" /></span>
			</div>
			<div className="main-content">
				<div className="main-form full-width new-form standalone">
					<MilestoneForm milestone={new Milestone({})} />
				</div>
			</div>
		</div>
	)
}

export default New