import { useState, useCallback } from 'react'
import { Link, useLocation } from 'react-router-dom'
import TaskRow from '../tasks/Row'
import { useAuth } from '../../lib/useAuth'

const Agenda = (props) => {
	const auth = useAuth()
	const location = useLocation()
	const [, updateState] = useState();
	const forceUpdate = useCallback(() => updateState({}), []);

	const toggler = (task) => {
		props.agenda.taskCollection().toggleTaskCompletion(task)
		forceUpdate()
	}

	const taskList = (tasks) => {
		if (tasks.length > 0) {
			return (
				<div className="new-task-list agenda-tasks">
					{tasks.map(task => {
						return <TaskRow key={task.id} task={task} toggler={toggler} showAssociated={true} location={location} />
					})}
				</div>
			)
		}
	}

	const tasksGroup = (className, heading, tasks) => {
		if (tasks) {
			return (
				<div className={className}>
					<div className="date-heading with-icon-before"><span>{heading}</span></div>
					{tasks}
				</div>
			)
		}
	}

	const addNewTask = (task) => {
		props.agenda.taskCollection().addTask(task)
		forceUpdate()
	}

	const empty = () => {
		return (
			<div className="no-results centered">
				<i className="fal fa-circle" />
				<h2>Your agenda is empty</h2>
				<p>You have no assigned tasks due this week. You can add upcoming tasks to see them here.</p>
				<Link to="/tasks/new" state={{ modal: true, onSave: addNewTask, returnTo: '/', backgroundLocation: location, taskParams: { assigned_user_ids: [auth.user.id] } }} className="rounded green button">Add a task</Link>
			</div>
		)
	}

	const agenda = () => {
		return [
			tasksGroup('today', 'Due today or overdue', taskList(props.agenda.taskCollection().tasksDue('overdue').concat(props.agenda.taskCollection().tasksDue('today')))),
			tasksGroup('tomorrow', 'Due tomorrow', taskList(props.agenda.taskCollection().tasksDue('tomorrow'))),
			tasksGroup('later', 'Due later', taskList(props.agenda.taskCollection().tasksDue('laterThisWeek')))
		]
	}

	return (
		<div>
			<h2 className="hide-on-mobile"><i className="fa fa-calendar-check" />Your <strong>Agenda</strong></h2>

			{props.agenda.taskCollection().tasksDue('thisWeek').concat(props.agenda.taskCollection().tasksDue('overdue')).length === 0 ? empty() : agenda()}
		</div>
	)
}

export default Agenda