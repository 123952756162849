import Resource from '../lib/Resource';

export default class MessageBody extends Resource {
	isHtml() {
		return this.format === 'html';
	}

	isPlainText() {
		return this.format === 'plain';
	}

	isRichText() {
		return this.format === 'rich'
	}

	formattedBody(attachments) {
		if (this.sanitized_html) {
			let html = this.sanitized_html

			if (attachments) {
				attachments.filter(attachment => attachment.isInlineWithContent()).forEach(attachment => {
					console.log('here')
					html = html.replace(`src="cid:${attachment.reference_id}`, `src="data:${attachment.content_type};base64, ${attachment.content}`)
				})
			}

			return html
		} else if (this.parsed_text) {
			return this.parsed_text;
		} else if (this.rich_text) {
			return this.rich_text;
		}
	}
}

MessageBody.fields = ['format', 'sanitized_html', 'parsed_text', 'rich_text'];
MessageBody.modelName = 'MessageBody';