import Resource from '../../lib/Resource';

export default class Logo extends Resource {
	exists() {
		return !!this.source_url;
	}
}

Logo.fields = ['source_url', 'content_type', 'file_name', 'file'];
Logo.belongsTo = 'Organization';
Logo.scopedTo = 'organization';