import { useState, useEffect } from 'react';
import { useResourceLoader } from '../../lib/useResourceLoader'
import { useAuth } from '../../lib/useAuth'
import { useRouter } from '../../lib/useRouter'
import MilestoneRepository from '../../repositories/MilestoneRepository';
import { Form } from '../../lib/forms/Form';
import { TextInput } from '../../lib/forms/TextInput';
import { TextArea } from '../../lib/forms/TextArea';
import { DatePickerInput } from '../../lib/forms/DatePickerInput';
import { SegmentedSelect } from '../../lib/forms/SegmentedSelect';
import Assigner from '../tasks/Assigner';

const MilestoneForm = (props) => {
	const router = useRouter()
	const auth = useAuth()
	const [milestone, setMilestone] = useState(props.milestone)
	const [showDatePicker, setShowDatePicker] = useState(false)
	const [showCollaboratorPicker, setShowCollaboratorPicker] = useState(false)

	const addTasksToMilestone = (milestone) => {
		milestone.tasks = milestone.tasks;

		return milestone;
	}

	const dueDatePicker = () => {
		if (milestone.due || showDatePicker) {
			return <DatePickerInput autoFocus={showDatePicker} name="due" className="full" placeholder="Due date" dateFormat="MMMM d, yyyy" />;
		} else {
			return <span className="set-due-date text-button" onClick={() => setShowDatePicker(true)}>Add a due date</span>;
		}
	}

	const setCollaborators = (collaborators) => {
		let updatedMilestone = milestone;
		updatedMilestone.collaborator_user_ids = collaborators.map(user => user.id);

		setMilestone(updatedMilestone)
	}

	const collaboratorRow = () => {
		return (
			<div className={"option with-icon" + (showCollaboratorPicker || milestone.isShared() ? "" : " hidden")}>
				<i className="fal fa-users option-icon" />
				<Assigner record={milestone} placeholder="Find users to share with" userLookup={milestone.collaborators.bind(milestone)} account={auth.account} setChosenUsers={setCollaborators} />
			</div>
		);
	}

	const handleVisibilityUpdate = (k, visibility) => {
		setShowCollaboratorPicker(visibility === 'shared')
	}

	const visibilityRow = () => {
		return (
			<div className="option with-icon">
				<i className="fa fa-rss option-icon" />
				<SegmentedSelect name="visibility" defaultValue="private" changeHandler={handleVisibilityUpdate}>
					<option value="private">private</option>
					<option value="public">visible to everyone</option>
					<option value="shared">shared with&hellip;</option>
				</SegmentedSelect>
			</div>
		);
	}

	if (auth && milestone) {
		return (
			<Form className="milestone-form" repository={new MilestoneRepository()} object={milestone} router={router} redirectOnSave={true}>
				<div className="options">
					<div className="option title">
						<TextInput name="name" className="large full" placeholder="Milestone name" autoFocus />
					</div>
					<div className="option with-icon">
						<i className="fal fa-calendar-alt option-icon" />
						{dueDatePicker()}
					</div>
					{auth.account.multiUser() ? visibilityRow() : null}
					{auth.account.multiUser() ? collaboratorRow() : null}
					<div className="option with-icon">
						<i className="fal fa-info option-icon" />
						<TextArea name="description" className="full four-lines" placeholder="More information about this milestone" />
					</div>
				</div>
				<div className="submit">
					<input type="submit" value="Save this Milestone" className="rounded medium green" />
				</div>
			</Form>
		)
	}
}

export default MilestoneForm