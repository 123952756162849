import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useFilterableCollection } from '../../lib/useFilterableCollection.js';
import { NoResults, Loading } from '../common/Common'
import Pagination from '../common/Pagination';
import ThreadFilterForm from './ThreadFilterForm';
import Table from './Table';
import { Header, PageIcon, HeaderActions, HeaderFilter, HeaderTabs } from '../common/header';
import MailboxScoper from '../common/MailboxScoper'
import { useCollectionLoader } from '../../lib/hooks'

const List = () => {
	const [category, setCategory] = useState(localStorage.threadCategory !== 'undefined' ? localStorage.threadCategory : null)
	const defaultFilters = Object.assign((category ? { category: category } : {}), { scope: localStorage.scope || 'subscribed' })
	const [collection, filter, nextCollection] = useCollectionLoader('ThreadRepository', defaultFilters, () => {})
	const location = useLocation()
	const [sort, sortForm] = useFilterableCollection(collection, filter, {
		last_sent_at: { icon: 'calendar-alt', name: 'Last Sent' },
		message_count: { icon: 'reply-all', name: 'Number of Messages' }
	})

	const updateCategory = (newCategory) => {
		if (newCategory === 'undefined' || newCategory === '') {
			setCategory(null)
			localStorage.removeItem('threadCategory')

			const { category, ...newFilters } = defaultFilters
			filter(newFilters)
		} else {
			setCategory(newCategory)
			localStorage.setItem('threadCategory', newCategory)

			filter({ category, ...defaultFilters, ...{ category: newCategory }})
		}
	}

	const list = () => {
		if (collection.total_results === 0) {
			return <NoResults collection={collection} subMessage={<p>You can <Link to={"/email"} className="gray pseudobutton">clear filters</Link> or try a less restrictive search.</p>} />;
		} else {
			return (
				<div>
					<Table threads={collection.records} location={location} />
					<Pagination collection={collection} filter={filter} />
				</div>
			);
		}
	}

	const header = () => {
		if (collection && collection.hasSlice()) {
			return (
				<h1 className="with-breadcrumb">
					<div className="breadcrumb">Emails</div>
					<span className="page-icon"><i className={`fa fa-${getDisplaySlice().icon}`} /></span><span dangerouslySetInnerHTML={{ __html: getDisplaySlice().name }}></span>
				</h1>
			);
		} else {
			return (
				<h1>
					<span className="page-icon"><i className="fa fa-inbox" /></span>Your Inbox
				</h1>
			);
		}
	}

	const getDisplaySlice = () => {
		if (nextCollection.loading && nextCollection.slice) {
			return nextCollection.slice
		} else if (collection && collection.hasSlice()) {
			return collection.currentSlice()
		}
	}

	const categories = () => {
		return (
			<>
				<div className="h1-sidenav">
					<ul className="sections">
						<li><span className={"console-button" + (collection && !category ? " active" : "")} onClick={() => { updateCategory('') }}><i className="fal fa-inbox" />All</span></li>
						<li><span className={"console-button" + (collection && category === "personal" ? " active" : "")} onClick={() => { updateCategory('personal') }}><i className="fal fa-id-card" />Personal</span></li>
						<li><span className={"console-button" + (collection && category === "update" ? " active" : "")} onClick={() => { updateCategory('update') }}><i className="fal fa-newspaper" />Notifications</span></li>
						<li><span className={"console-button" + (collection && category === "promotion" ? " active" : "")} onClick={() => { updateCategory('promotion') }}><i className="fal fa-money-bill-wave" />Promotions</span></li>
					</ul>
				</div>
			</>
		)
	}

	return (
		<div>
			<MailboxScoper onUpdate={(newScope) => filter({ scope: newScope })} />
			<div className="main full">
				<Helmet title={getDisplaySlice() ? getDisplaySlice().name.replace('&#8217;', "'") : 'Your email list'} />
				<Header
					key={"header."/* + (getCategory() ? getCategory() : "")*/}
					collection={collection}
					breadcrumb={getDisplaySlice() ? 'Manage Your Emails' : null}
					title={collection && collection.hasSlice() ? <span dangerouslySetInnerHTML={{ __html: getDisplaySlice().name }}></span> : 'Manage Your Emails'}
				>
					<PageIcon icon={getDisplaySlice() ? getDisplaySlice().icon : "inbox"} toggleSidebarOnMobile />
					<HeaderActions includeFilterToggle />
					<HeaderTabs>{categories()}</HeaderTabs>
					<HeaderFilter>
						<ThreadFilterForm collection={collection} updater={filter} />
					</HeaderFilter>
				</Header>
				{collection && collection.total_results > 0 ? sortForm() : null}
				<div className="main-content">
					{nextCollection.loading ? <Loading inline /> : list()}
				</div>
			</div>
		</div>
	)
}

export default List
/*
export default MailboxScoper(
);
*/