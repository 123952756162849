import { useState, useEffect, useTransition } from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import Loading from '../common/Loading'
import pluralize from 'pluralize'
import NoResults from '../common/NoResults';
import ViewNotification from './View'

const Slice = ({ collection, sliceKey, newCount, allCount, dismiss }) => {
	const [sliceNotifications, setSliceNotifications] = useState(null)
	const eventToResourceMapping = {
		comment_added: 'comment',
		task_assigned: 'task',
		milestone_shared: 'milestone',
		file_shared: 'file',
		thread_shared: 'thread',
		contact_shared: 'contact'
	}

	useEffect(() => {
		if (collection && collection.records)	{
			setSliceNotifications([...collection.records])
		}
	}, [collection])

	const mobileHeading = () => {
/*
		let filter = filters.find(filter => filter.event === sliceKey)
		return (
			<h2 className="section mobile-only">
				<i className={`fa fa-${filter.icon}`} />
				{filters.find(filter => filter.event === sliceKey).name}
			</h2>
		);
*/
	}

	const dismissSlice = slice => {
/*
		let updatedNewCounts = { ...newCounts }
		let updatedAllCounts = { ...allCounts }
		updatedNewCounts[slice] = 0;
		updatedAllCounts[slice] = 0;

		setNotifications([])
		setNewCounts(updatedNewCounts)
		setAllCounts(updatedAllCounts)
*/

/*
		this.setState({ notifications: [], new_counts: new_counts, all_counts: all_counts }, () => {
			this.fetcher.post('/notifications/dismiss', { slice: slice })
		})
*/
	}

	const dismissNotification = (notification) => {
		dismiss({ notification: notification })

		setSliceNotifications([...sliceNotifications.filter(existing => existing.id !== notification.id)])
	}


	const dismissAll = () => {
		dismiss({ slice: sliceKey })

		setSliceNotifications([])
	}

	const sliceHeading = () => {
		let dismissButton = <span className="text-button" onClick={dismissAll}>dismiss all</span>;

		switch (sliceKey) {
			case 'comment_added':
				return <h3 key={`${sliceKey}.heading`}>{pluralize('comment', allCount, true)} written{newCount && newCount > 0 ? <span className="new-count">{newCount} new</span> : null}{dismissButton}</h3>
				break;
			case 'task_assigned':
				return <h3 key={`${sliceKey}.heading`}>{pluralize('task', allCount, true)} assigned{newCount && newCount > 0 ? <span className="new-count">{newCount} new</span> : null}{dismissButton}</h3>
				break;
			case 'milestone_shared':
			case 'thread_shared':
			case 'file_shared':
			case 'contact_shared':
				return <h3 key={`${sliceKey}.heading`}>{pluralize(eventToResourceMapping[sliceKey], allCount, true)} shared{newCount && newCount > 0 ? <span className="new-count">{newCount} new</span> : null}{dismissButton}</h3>
				break;
			default:
				return <h3 key={`${sliceKey}.heading`}>{pluralize('notification', allCount, true)}{newCount > 0 ? <span className="new-count">{newCount} new</span> : null}{dismissButton}</h3>
		}
	}

	const mainView = () => {
		if (!sliceNotifications) {
			return <Loading inline />
		} else if (sliceNotifications.length == 0) {
			return <NoResults message="There are no matching notifications." />
		} else {
			return sliceNotifications.map(notification => {
				return (
					<CSSTransition key={`notification.${notification.id}`} classNames="notifications" timeout={{ enter: 2000, exit: 1500 }}>
						<ViewNotification sliceKey={`notification.${notification.id}`} notification={notification} dismiss={dismissNotification} />
					</CSSTransition>
				)
			})
		}
	}

	return (
		<div key={`${sliceKey}.${sliceNotifications ? sliceNotifications.length : '0'}`}>
			{mobileHeading()}
			{sliceHeading()}
			<TransitionGroup>
				{mainView()}
			</TransitionGroup>
		</div>
	);
}

export default Slice