import React from 'react';

const NoResults = (props) => {
	return (
		<div className={props.congrats ? "empty-congrats" : "no-results"}>
			<i className={props.congrats ? "fa fa-trophy" : "fal fa-circle"} />
			<h2>{props.message ? props.message : 'There are no matching records.'}</h2>
			{props.subMessage !== null ? <p>{props.subMessage}</p>: <p>You can <span className="gray pseudobutton">clear filters</span> or try a less restrictive search.</p>}
		</div>
	)
}

export default NoResults