import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import { useResourceLoader, useAuth, useResourceViewRecorder, useToggle } from '../../lib/hooks';
import Loading from '../common/Loading';
import View from './View';

const ViewContainer = () => {
	const resource = useResourceLoader('FileRepository')
	const auth = useAuth()
	const recordView = useResourceViewRecorder('files')
	const [file, setFile] = useState(null)
	const toggle = useToggle(resource, setFile)

	useEffect(() => {
		if (resource) {
			setFile(resource)
			recordView(resource)
		}
	}, [resource])

	return file ? <View key={file.id} file={file} currentUser={auth.user} toggle={toggle} /> : <Loading />
}


export default ViewContainer