import Repository from '../lib/Repository';
import Task from '../resources/Task';
import { TaskCollectionUtilities } from '../lib/utilities/TaskCollectionUtilities';

export default class TaskRepository extends Repository {
	entityClass = Task;
	baseUrl = '/tasks';
	collectionUtilities = TaskCollectionUtilities;
	sliceFilters = {
		contacts: { category: 'later', name: 'Tasks associated with your contacts', icon: 'fa fa-users' },
		organizations: { category: 'later', name: 'Tasks associated with your organizations', icon: 'fa fa-building' },
		emails: { category: 'later', name: 'Tasks associated with your emails', icon: 'fa fa-envelope' },
		files: { category: 'later', name: 'Tasks associated with your files', icon: 'fa fa-file-alt' },
		overdue: { category: 'urgent', name: 'Overdue tasks', icon: 'fa fa-exclamation-circle' },
		today: { category: 'urgent', name: 'Tasks due today', icon: 'fa fa-clock' },
		tomorrow: { category: 'upcoming', name: 'Due tomorrow', icon: 'fa fa-coffee' },
		'this-week': { category: 'upcoming', name: 'Due this week', icon: 'fa fa-ellipsis-h' },
		'next-week': { category: 'upcoming', name: 'Due next week', icon: 'fa fa-calendar-alt' },
		later: { category: 'later', name: 'Due later (or no due date)', icon: 'far fa-circle' }
	};
}