import { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Search from '../../resources/Search';
import ContactTable from '../contacts/Table';
import FileTable from '../files/Table';
import OrganizationTable from '../organizations/Table';
import ThreadTable from '../threads/Table';
import { NoResults } from '../common/Common'

const SearchResults = (props) => {
	const [processing, setProcessing] = useState(false)
	const [query, setQuery] = useState(props.query || '')
	const [search, setSearch] = useState(new Search({}))
	const [results, setResults] = useState([])
	const [loaded, setLoaded] = useState(false)

	useEffect(() => {
		search.setQuery(props.query)

		if (query) {
			executeSearch()
		}
	}, [props])
/*
	componentDidUpdate(prevProps) {
		if (props.scope && props.scope !== prevProps.scope) {
			let search = search;

			if (search.query) {
				if (search.scope !== props.scope) {
					setState({ search: search, query: search.query }, () => executeSearch());
				}
			}
		}
	}
*/
	const updateQuery = (e) => {
		setQuery(e.target.value)
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		executeSearch();
	}

	const executeSearch = () => {
		setProcessing(true)
		const newSearch = search;
		newSearch.setQuery(query);
		newSearch.setResource(props.resource);
		newSearch.setScope(props.scope);
		newSearch.execute().then((searchResult) => {
			setSearch(searchResult)
			setResults(searchResult.results)
			setProcessing(false)
			setLoaded(true)
			if (props.onLoadCompletion) { props.onLoadCompletion() }
		})
	}

	const searchResults = () => {
		switch (props.resource) {
			case 'files':
				return <FileTable files={results.files.records} router={props.router} location={props.router.location} />;
			case 'threads':
				return <ThreadTable threads={results.threads.records} router={props.router} location={props.router.location} />
			case 'contacts':
				return <ContactTable contacts={results.contacts.records} router={props.router} location={props.router.location} />;
			case 'organizations':
				return <OrganizationTable organizations={results.organizations.records} router={props.router} location={props.router.location} />;
			default:
				return null;
		}
	}

	const resultDisplay = () => {
		return hasResults() ? searchResults() : <NoResults />
	}

	const hasResults = () => {
		return loaded && numResults() > 0
	}

	const numResults = () => {
		return results[props.resource].total_results
	}

	const getRecords = () => {
		return results[props.resource].records
	}

	const enterQueryMessage = () => {
		return (
			<div className="enter-search-query">
				<i className="far fa-search" />
				<h2>Please enter your search query above</h2>
			</div>
		);
	}

	const resultInfo = () => {
		if (hasResults()) {
			return (
				<div className="sub-bar">
					{
						numResults() === 1
						? <span>There is <strong>one</strong> matching {props.resource.slice(0, -1)}.</span>
						: <span>There are <strong>{numResults()}</strong> matching {props.resource}.</span>
					}
				</div>
			);
		}
	}

	const slideInMenu = () => {
		/* TODO
		if (window.isMobile) {
			ReactDOM.findDOMNode(context.getSidebar()).classList = 'slide-in'
		}
		*/
	}

	let searchIcon;
	if (query.length === 0) {
		searchIcon = 'far fa-circle';
	} else if (processing) {
		searchIcon = 'fa fa-spinner fa-spin';
	} else {
		searchIcon = 'fa fa-arrow-circle-right'
	}

	return (
		<div className="main full">
			<div className="search-header">
				<form onSubmit={handleSubmit}>
					<i className="back fa fa-bars" onClick={slideInMenu} />
					<div className="input">
						<input type="text" className="large" onChange={updateQuery} autoFocus defaultValue={query} />
					</div>
					<i className={"submit " + searchIcon} onClick={executeSearch} />
				</form>
			</div>
			{resultInfo()}
			<div id="content">
				{loaded ? resultDisplay() : enterQueryMessage()}
			</div>
		</div>
	)
}

export default SearchResults