import React from 'react';
import Fetcher from './Fetcher';

export var NotificationListHandler = (Component, type) => class extends React.Component {
	state = {
		notifications: this.props.currentUser.notification_inbox.latest_notifications
	};

	componentWillReceiveProps(props) {
		if (props.currentUser.notification_inbox) {
			this.setState({ notifications: props.currentUser.notification_inbox.latest_notifications });
		}
	}

	dismiss = (dismissed) => {
		if (!Array.isArray()) { dismissed = [dismissed] }
		let dismissedIds = dismissed.map(notification => notification.id);

		new Fetcher(localStorage.token).post(`/notifications/dismiss`, { ids: dismissedIds });
	}

	markAsRead = (notifications) => {
		let ids = notifications.filter(notification => notification.new).map(notification => notification.id);

		new Fetcher(localStorage.token).post('/notifications/read', { ids: ids });

		let user = this.props.currentUser;
		user.notification_inbox.set('new_count', user.notification_inbox.new_count - ids.length);
		user.notification_inbox.set('last_viewed_at', new Date().getTime());

		user.notification_inbox.set('latest_notifications', notifications.map(notification => {
			notification.set('new', false);
			return notification;
		}));

		this.props.updateCurrentUser(user);
	}

	render() {
		return <Component {...this.props} notifications={this.state.notifications} dismiss={this.dismiss} dismissAll={this.dismissAll} markAsRead={this.markAsRead} />;
	}
}
