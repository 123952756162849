import Resource from '../lib/Resource';
import { TaskCollectionUtilities } from '../lib/utilities/TaskCollectionUtilities';
import ProxyCollection from '../lib/ProxyCollection';
import TaskRepository from '../repositories/TaskRepository';
import Task from './Task';

export default class Agenda extends Resource {
	taskCollection() {
		if (!this._taskCollection) {
			let tasks = [];
			this.today.tasks.concat(this.tomorrow.tasks).concat(this.later.tasks).concat(this.overdue.tasks).forEach(task => {
				if (task !== 'undefined') {
					tasks.push(new Task(task))
				}
			});

			this._taskCollection = Object.assign(new ProxyCollection(tasks, new TaskRepository()), TaskCollectionUtilities);
		}

		return this._taskCollection;
	}
}

Agenda.fields = ['today', 'tomorrow', 'later', 'overdue'];
Agenda.modelName = 'Agenda';