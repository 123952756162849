import React from 'react';

export var bytesToHumanSize = function(bytes) {
	var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
	if (parseInt(bytes, 10) === 0) return '0 Byte';
	var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);

	return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

/*privateIndicator: function(file) {
	if (file.private) {
		return <i className="fa fa-lock fa-lg lock" />;
	}
}*/

export var fileType = function(file) {
	if (!file.file_name) {
		return "";
	}

	var pieces = file.file_name.toLowerCase().split('.');
	switch (pieces[pieces.length - 1]) {
		case 'pdf':
			return 'PDF';
		case 'csv':
		case 'xls':
		case 'xlsx':
			return 'spreadsheet';
		case 'doc':
		case 'docx':
			return 'document';
		case 'jpg':
		case 'jpeg':
		case 'gif':
		case 'png':
			return 'image';
		case 'ppt':
		case 'keynote':
			return 'presentation';
		case 'zip':
			return 'archive';
		default:
			return (pieces.length > 1 ? `.${pieces[pieces.length - 1]}` : 'unknown');
	}
}

export var fileIcon = function(file) {
	switch (fileType(file)) {
		case 'pdf':
			return <i className="fal fa-file-pdf" />;
		case 'spreadsheet':
			return <i className="fal fa-file-excel" />;
		case 'archive':
			return <i className="fal fa-file-archive" />;
		case 'document':
			return <i className="fal fa-file-alt" />;
		case 'image':
			return <i className="fal fa-file-image" />;
		case 'presentation':
			return <i className="fal fa-file-powerpoint" />;
		default:
			return <i className="fal fa-file" />;
	}
}
/*
preview: function(file) {
	if (this.fileType(file) === 'image') {
		return (
			<div className="file-preview">
				<img src={file.download_url} />
			</div>
		);
	} else {
		return this.fileIcon(file);

		// Not working yet
		return (
			<iframe src={"http://docs.google.com/gview?embedded=true&url=" + encodeURI(file.download_url) }></iframe>
		);
	}
}*/