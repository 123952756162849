import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../lib/hooks'

const MilestoneNotification = (props) => {
	const auth = useAuth()

	return (
		<>
			<div className="icon"><i className="fal fa-map-signs" /></div>
			<div className="body">
				<h4><strong>{auth.account.getUserById(props.notification.prompting_user_id).displayName()}</strong> invited you to the milestone <strong><Link to={`/milestones/${props.notification.payload.id}`}>{props.notification.payload.name}</Link></strong>.</h4>
			</div>
		</>
	)
}

export default MilestoneNotification