import Fetcher from './Fetcher';
import { snakeCase } from 'lodash';

export var useToggle = (record, updateRecord) => {
	const mapping = {
		pin: 'pinned',
		flag: 'flagged',
		read: 'read',
		follow: 'following',
		resolution: 'resolved'
	}

	const toggle = (field) => {
		if (!record) return

		let fetcher = new Fetcher(localStorage.token)
		let path = `/${snakeCase(record.constructor.modelName)}s/${record.id}/${field}`;

//		let updatedRecord = {...record}

		let res;
		if (record[mapping[field]]) {
			record[mapping[field]] = false;
			res = fetcher.delete(path);
		} else {
			record[mapping[field]] = true;
			res = fetcher.post(path);
		}

		updateRecord(new record.constructor(record));

		res.catch(e => {
			record[mapping[field]] = !record[mapping[field]];
			updateRecord(new record.constructor(record));
		})
	}

	return toggle
}