import React from 'react';

const SocialProfiles = ({ contact }	) => {
	if (!contact.numbers) { return null; }

	return (
		<div className="contact-social-profiles">
			{contact.social_profiles.map(function(profile) {
				return (
					<a href={profile.link()} key={`social.${profile.link()}`} className={"contact-social-profile " + profile.network}>{profile.handle}</a>
				);
			})}
		</div>
	)
}

export default SocialProfiles