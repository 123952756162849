import { useState } from 'react'
import { useAuth } from '../../lib/useAuth'
import List from './List'
import New from './New'
import { useHotkeys } from 'react-hotkeys-hook'

const Commentary = (props) => {
	const [addComment, setAddComment] = useState(false)
	const [comments, setComments] = useState(props.record.comments)
	const auth = useAuth()
	useHotkeys('c', () => { setAddComment(true) })

	const addCommentToList = comment => {
		let modifiedComments = comments
		modifiedComments.push(comment)

		setAddComment(false)
		setComments(modifiedComments)
	}

	if (auth.account.multiUser()) {
		return (
			<div id="commentary">
				{props.record.comments.length > 0 ? <h2><i className="fa fa-comments" /> Discussion about this {props.record.constructor.modelName.toLowerCase()}</h2> : null}
				<List record={props.record} />

				{
					addComment
					? <New record={props.record} addCommentToList={addCommentToList} />
					: <div className="centered"><span className="medium rounded green button" onClick={() => setAddComment(true)}>{props.record.comments.length > 0 ? 'Add a comment' : 'Start a discussion'}</span></div>
				}

			</div>
		)
	} else { return null }
}

export default Commentary