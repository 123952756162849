import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import { Workspace } from './Organization';
import ScopedThreads from '../threads/ScopedList'
import ScopedFiles from '../files/ScopedList'
import moment from 'moment';
import { Enrichable } from '../../lib/Enrichable';
import { Header, PageIcon, HeaderActions, HeaderAction } from '../common/header';
import { Modalize } from '../../lib/Modalize';
import NewTaskForm from '../tasks/New';
import { useHotkeys } from 'react-hotkeys-hook'

const View = (props) => {
	const navigate = useNavigate()
	const location = useLocation()
	useHotkeys('e', () => { navigate(`/organizations/${props.organization.id}/edit`) })
	useHotkeys('d', () => { navigate(`/organizations/${props.organization.id}/delete`) })
	useHotkeys('p', () => { props.toggle('pin') })

	const activePanel = () => {
		switch (props.active) {
			case 'emails':
				return <ScopedThreads key={`${props.organization.id}.threads`} panelSwitcher={props.switcher} organization={props.organization} scopedMailbox={props.scopedMailbox} location={props.location} />;
			case 'files':
				return <ScopedFiles key={`${props.organization.id}.files`} panelSwitcher={props.switcher} organization={props.organization} scopedMailbox={props.scopedMailbox} location={props.location} router={props.router} />;
			default:
				return <Workspace key={`${props.organization.id}.workspace`} panelSwitcher={props.switcher} organization={props.organization} scopedMailbox={props.scopedMailbox} location={props.location}  />;
		}
	}

	const mobileNav = () => {
		let types = {
			emails: {
				name: 'Emails',
				icon: 'envelope',
				count:  props.organization.thread_count.toLocaleString()
			},
			files: {
				name: 'Files',
				icon: 'file-alt',
				count:  props.organization.file_count.toLocaleString()
			}
		};

		return (
			<div className="subheader-nav">
				<ul className="sections">
					<li><span className={"console-button" + (props.active === "workspace" ? " active" : "")} onClick={props.switcher.bind(null, "workspace")}><i className="fal fa-bullseye" />Snapshot</span></li>
					{Object.keys(types).map(k => {
						return (
							<li key={k}>
								<span className={"console-button" + (props.active === k ? ' active' : '')} onClick={props.switcher.bind(null, k)}>
									<i className={`fal fa-${types[k].icon}`} />
									{types[k].name}
									{types[k].count && types[k].count > 0 ? <span className="count">{types[k].count}</span> : null}
								</span>
							</li>
						)
					})}
				</ul>
			</div>
		)
	}

	return (
		<div className="main full organization">
			<Helmet title={props.organization.name} />
			<Header
				breadcrumb={<><strong>organization</strong>{props.organization.due ? <span className="due-date">{moment(props.organization.due).format('dddd, MMMM D')}</span> : null}</>}
				title={<span>{props.organization.name}{props.organization.enriched ? <i className="fa fa-plug enriched-indicator" title="Enriched record" /> : null}</span>}
			>
				{
					props.organization.logo.exists()
					? <PageIcon imgSrc={props.organization.logo.source_url} className="logo" />
					: <PageIcon icon="building" />
				}
				<HeaderActions>
					{
						props.organization.has_duplicates
						? <HeaderAction linkTo={{ pathname: `/${props.contact ? 'contacts' : 'organizations'}/${props.organization.id}/duplicates`, state: { organization: props.organization, modal: true, returnTo: props.location.pathname } }} icon="clone" title="Resolve duplicates" />
						: null
					}
					{false && props.getEnrichmentActionButton()}
					<HeaderAction icon="check-circle" title="Add a task">
						<Modalize popUnderClasses="top right headingless">
							{<NewTaskForm record={props.organization} />}
						</Modalize>
					</HeaderAction>
					<HeaderAction onClick={() => props.toggle('pin')} icon="thumbtack" className={props.organization.pinned ? "pinned" : ""} title={props.organization.pinned ? "Unpin this organization" : "Pin this organization"} />
					<HeaderAction icon="pencil" title="Edit this organization" linkTo={{ pathname: "/organizations/" + props.organization.id + "/edit", state: { resource: props.organization, modal: true, backgroundLocation: location } }} />
					<HeaderAction icon="trash" title="Delete this organization" linkTo={{ pathname: "/organizations/" + props.organization.id + "/delete", state: { resource: JSON.stringify(props.organization), modal: true, returnTo: location.pathname, backgroundLocation: location } }} />
				</HeaderActions>
			</Header>

			{mobileNav()}

			<div id="organization" className="main-content">
				{activePanel()}
			</div>
		</div>
	)
}

//export default Enrichable(View);

export default View