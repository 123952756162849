import { useState } from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';
import moment from 'moment';
import MessageBody from './MessageBody';

const FirstMessage = ({ message, toggleExpansion, hideInfo, index }) => {
	const [iframeHeight, setIframeHeight] = useState("auto")

	const resizeMessage = () => {
		var node = ReactDOM.findDOMNode(this.refs.messageBody);
		var document = node.contentWindow.document;
		var cssLink = document.createElement("link")
		cssLink.href = "/assets/message-frame.css";
		cssLink.rel = "stylesheet";
		cssLink.type = "text/css";
		document.body.appendChild(cssLink);

		setIframeHeight(node.contentWindow.document.body.getBoundingClientRect().bottom + node.contentWindow.document.body.getBoundingClientRect().top + "px")
	}

	const info = () => {
		return (
			<div className="reply-header" onClick={() => toggleExpansion(index)}>
				{/*<Contacts.Avatar contact={message.from} inline={inline || false} />*/}
				<div className="sender">
					From <strong><Link to={"/contacts/" + message.from.id}>{message.from.name || message.from.email_address}</Link></strong> on <strong>{moment(message.date).format('MMMM D, YYYY [at] h:mma')}</strong>
				</div>
				<div className="snippet">{message.snippet}</div>
			</div>
		);
	}

	return (
		<div>
			{hideInfo ? null : info()}
			<MessageBody message={message} />
		</div>
	)
}

export default FirstMessage