import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import ViewNotification from '../notifications/View';
import { NotificationListHandler } from '../../lib/NotificationListHandler';
import { CurrentUserAccessor } from '../../lib/CurrentUserAccessor';

const Notifications = (props) => {
	const location = useLocation()

	return (
		<div className="notifications">
			<h2>
				<i className="fa fa-bell" />
				<Link to={{ pathname: "/notifications", state: { modal: true, returnTo: '/' } }}>Notifications</Link>
				<span className="text-button">clear all</span>
			</h2>
			<div className="notifications">
				<TransitionGroup >
					{props.notifications.map(notification => {
						return (
							<CSSTransition key={`agenda.notification.${notification.id}`} classes="notifications" timeout={{ enter: 2000, exit: 1500 }}>
								<ViewNotification notification={notification} dismiss={props.dismiss} />
							</CSSTransition>
						)
					})}
				</TransitionGroup>
			</div>
		</div>
	)
}

export default Notifications

//export default CurrentUserAccessor(NotificationListHandler(Notifications));