import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { StackedIcon } from '../../../lib/Utilities.js';

const HeaderAction = (props) => {
	const [showContent, setShowContent] = useState(false)
	const buttonRef = useRef(null)

	const toggleContent = () => {
		setShowContent(!showContent)
	}

	const hideContent = () => {
		setShowContent(false)
	}

	const getButtonRef = () => {
		return buttonRef
	}

	if (props.linkTo) {
		return <Link to={props.linkTo.pathname} state={props.linkTo.state || {}} className={"header-action-button " + (props.className || "")} title={props.title}><StackedIcon icon={props.icon} /><span className="action-name">{props.title}</span></Link>;
	} else {
		return (
			<>
				<div ref={buttonRef} onClick={props.onClick || toggleContent} className={"header-action-button " + (props.className || "")}><StackedIcon icon={props.icon} title={props.title} /><span className="action-name">{props.title}</span></div>
				{
					props.children && showContent ?
						React.Children.map(props.children, (child) => {
							return React.cloneElement(
								child,
								{ show: showContent, toggle: toggleContent, getRefPoint: getButtonRef }
							)
						}
					) : null
				}
			</>
		)
	}
}

HeaderAction.displayName = 'HeaderAction';

export default HeaderAction