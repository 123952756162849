import React from 'react';
import { Link } from 'react-router-dom';
import * as FileUtilities from '../../../lib/utilities/FileUtilities';

const AttachmentList = ({ thread }) => {
	return (
		<div className="attached-files">
			<h3>Attached Files ({thread.getAttachmentCount()})</h3>
			{thread.getAttachments().map(attachment => {
				return (
					<Link to={`/files/${attachment.file_id}`} className="email-attachment">
						{FileUtilities.fileIcon(attachment)}
						<div>
							<div className="name">{attachment.file_name}</div>
							<div className="etc">{FileUtilities.fileType(attachment)} &middot; {FileUtilities.bytesToHumanSize(attachment.size)}</div>
						</div>
					</Link>
				);
			})}
		</div>
	)
}

export default AttachmentList