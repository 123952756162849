import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { useSwipeable } from 'react-swipeable'

const HoverAction = (props) => {
	return <i className={props.control.icon} title={props.control.longTitle} onClick={(e) => { e.stopPropagation(); props.control.onClick() }} />
}

const SwipeAction = (props) => {
	return (
		<div className="swipe-action" onClick={props.control.onClick}>
			<div className="control-container">
				<i className={props.control.icon} />
				<label>{props.control.shortTitle}</label>
			</div>
		</div>
	)
}

const Control = ({ control }) => {
	const button = useRef(null)

	return (
		<React.Fragment>
			<span key="action" className="hover-action" ref={button}><HoverAction control={control}/></span>

			<React.Fragment key="payload">{typeof control.payload === "function" ? control.payload(button) : control.payload}</React.Fragment>
		</React.Fragment>
	)
}

const RecordRow = (props) => {
	const navigate = useNavigate()
	const [showControls, setShowControls] = useState(false)

	const swipeHandler = useSwipeable({
	  onSwipedLeft: (eventData) => setShowControls(true),
	  onSwipedRight: (eventData) => setShowControls(false),
	})

	const controls = () => {
		if (props.controls) {
			return (
				<div key="controls" className={"row-controls" + (props.controls.filter(control => control.persistControls).filter(Boolean).length > 0 ? " persistent" : "")}>
					<div key="control-container" className="control-container">
						{props.controls.map(control => <Control key={control.shortTitle} control={control} />)}
					</div>
				</div>
			);
		}
	}

	const rowContent = <>{props.children}{controls()}</>
	if (props.link) {
		return <div className={"block record-row " + (props.additionalClasses || "") + (showControls ? ' with-controls' : '')} onClick={() => navigate(props.link)} {...swipeHandler}>{rowContent}</div>;
	} else {
		return <div className={"block record-row " + (props.additionalClasses || "") + (showControls ? ' with-controls' : '')} {...swipeHandler}>{rowContent}</div>;
	}
}

export default RecordRow