import { useState, useEffect } from 'react';
import { useAuth } from '../../lib/useAuth'
import { useResourceLoader } from '../../lib/useResourceLoader'
import { Helmet } from 'react-helmet';
import { CurrentUserAccessor } from '../../lib/CurrentUserAccessor';
import { Form }  from '../../lib/forms/Form';
import { Submit }  from '../../lib/forms/Submit';
import Loading from '../common/Loading';

const Delete = () => {
	const [deleted, setDeleted] = useState(false)
	const [mailbox, setMailbox] = useState(null)
	const auth = useAuth()
	const resource = useResourceLoader('MailboxRepository')

	useEffect(() => {
		if (resource) {
			setMailbox(resource)
		}
	}, [resource])

	const deletionMessage = () => {
		return (
			<div className="main mini delete-account no-sidebar">
				<div className="deleted">
					<i className="fa fa-check" />
					<div className="main-content">
						<h2>We&#8217;ve deleted this mailbox</h2>
						<p className="description">
							You won&#8217;t have access to any emails or files from this mailbox, and you won&#8217;t be charged for it any further.  You can always re-connect this mailbox later.
						</p>
					</div>
				</div>
			</div>
		)
	}

	const mailboxHasOtherSubscribers = () => {
		return getOtherMailboxSubscriberCount() > 0;
	}

	const getOtherMailboxSubscriberCount = () => {
		return mailbox.subscription_count - (auth.user.isSubscribedTo(mailbox) ? 1 : 0);
	}

	const otherSubscribersWarning = () => {
		if (auth.account.multiUser() && mailboxHasOtherSubscribers()) {
			let otherSubscriberMessage = (getOtherMailboxSubscriberCount() > 1 ? `are ${getOtherMailboxSubscriberCount()} other subscribers` : 'is one othe rsubscriber');

			return <p>There {otherSubscriberMessage} to this mailbox who will lose access if you delete it.  You can unsubscribe instead to allow others to use this mailbox.</p>
		}
	}

	const form = () => {
		return (
			<>
				<div className="warning message">
					<p>You are about to delete the mailbox <em>{mailbox.email_address}</em>.</p>
				</div>
				<div className="main-content">
					<Form object={mailbox} delete={true} onDelete={() => setDeleted(true)}>
						<p>You can always re-connect the mailbox later, but any emails and files it includes will be removed from Carom.  We&#8217;ll stop billing for this mailbox right away.</p>
						{otherSubscribersWarning()}
						<p><strong>Are you sure you&#8217;d like to proceed?</strong></p>

						<div className="submit">
							<Submit button="Delete this Mailbox" class="rounded medium red" />
						</div>
					</Form>
				</div>
			</>
		)
	}

	if (deleted) {
		return deletionMessage();
	} else {
		return (
			<div className="main mini delete-account no-sidebar">
				<Helmet title={mailbox ? `Delete the mailbox ${mailbox.email_address}` : 'Delete a mailbox'} />
				<div className="subheading">
					<i className="fa fa-trash red" />
					<h2>Delete this mailbox?</h2>
				</div>
				{mailbox ? form() : <div style={{ padding: '10px' }}><Loading inline={true} /></div>}
			</div>
		)
	}
}

export default Delete