import { useState } from 'react';
import AttachmentMessage from './AttachmentMessage';

const AttachmentMessages = ({ file }) => {
	const [showAll, setShowAll] = useState(false)

	return (
		<div className={showAll ? 'all-attachments' : 'last-3-attachments'}>
			{file.getAttachments().map(attachment => {
				return <AttachmentMessage key={attachment.id} file={file} attachment={attachment} includeDownloadLink={file.attachment_count > 1} />;
			})}
			{file.attachment_count > 3
				? <span className="white action" onClick={() => { this.setState({ showAll: true }); }} style={{ marginLeft: "50px", marginTop: "10px" }}><i className="fa fa-plus" />See the other messages with this file attached</span>
				: null
			}
		</div>
	)
}

export default AttachmentMessages