import { useEffect, useContext } from 'react'
import FormContext from '../FormContext'
import useFormInput from './useFormInput'

export const ErrorMessage = (props) => {
	const context = useContext(FormContext)

	if (context.object.hasResponseError()) {
		return <div className="invalid-record">{context.object.getResponseError().message}</div>;
	}
}
