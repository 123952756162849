import Resource from '../lib/Resource';

export default class Message extends Resource {
	loadParticipantsFromThread(thread) {
		this.from = thread.getParticipantByEmailAddress(this.from_email_address);
		this.to = this.to_email_addresses.map(address => thread.getParticipantByEmailAddress(address));
	}

	hasAttachments() {
		return this.attachments && this.attachments.length > 0;
	}
}

Message.fields = [
	'id', 'sent_at', 'snippet', 'from_email_address', 'thread_id', 'to_email_addresses', 'cc_email_addresses',
	{ body: 'MessageBody' }, { thread: 'Thread' }, { attachments: ['Attachment'] }
];
Message.modelName = 'Message';