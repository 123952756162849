import AsyncFetcher from './AsyncFetcher';
import Fetcher from './Fetcher';
import Collection from './Collection';
import ApiError from './ApiError';
import { pick, snakeCase } from 'lodash';

export default class Repository {
	constructor(scopedTo) {
		this.scopedTo = scopedTo;
		this.fetcher = new Fetcher(localStorage.token);
		this.asyncFetcher = new AsyncFetcher(localStorage.token)
	}

	async asyncGetCollection(filters = {}, baseUrl = null) {
		let url = (baseUrl == null ? this.getBaseUrl() : baseUrl);

		let jsonResponse = await this.asyncFetcher.get(url, filters)

		let collection = new Collection(jsonResponse.collection, this.entityClass, this, Object.assign(this.defaultFilters(), filters));
		if (this.collectionUtilities) { return Object.assign(collection, this.collectionUtilities); }

		return collection;
	}

	async asyncGetCollection(filters = {}, baseUrl = null) {
		let url = (baseUrl == null ? this.getBaseUrl() : baseUrl);

		let json = await this.asyncFetcher.get(url, filters)
		let collection = new Collection(json.collection, this.entityClass, this, Object.assign(this.defaultFilters(), filters));
		if (this.collectionUtilities) { return Object.assign(collection, this.collectionUtilities); }

		return collection;
	}

	getCollection(filters = {}, baseUrl = null) {
		let url = (baseUrl == null ? this.getBaseUrl() : baseUrl);

		return this.fetcher.get(url, filters).then((json) => {
			let collection = new Collection(json.collection, this.entityClass, this, Object.assign(this.defaultFilters(), filters));
			if (this.collectionUtilities) { return Object.assign(collection, this.collectionUtilities); }

			return collection;
		});
	}

	defaultFilters() {
		let fields = [];
		if (this.getFilterFields.length > 0) {
			this.getFilterFields().forEach((field) => { fields[field] = undefined })
		}

		return Object.assign(
			{ sort: this.defaultSort, order: this.defaultOrder },
			fields
		);
	}

	allowedCriteria() {
		return (this.getFilterFields() ? pick(this.criteria, this.getFilterFields()) : this.criteria);
	}

	getFilterFields() {
		return this.filterFields || {}
	}

	pageTo(page) {
		this.page = page;

		return this.load();
	}

	async find(id) {
		const json = await this.asyncFetcher.get(this.getBaseUrl() + '/' + id)

		return this.entityFromJson(json)
	}

	getBaseUrl() {
		if (this.scopedTo) {
			return this.baseUrl(this.scopedTo);
		} else {
			return this.baseUrl;
		}
	}

	save(record, overrideUrl, recordJson, metaData, skipValidation) {
		if (!record.valid() && skipValidation !== true) { return Promise.reject('invalid'); }

		let url = (record.id ? this.getBaseUrl() + '/' + record.id : this.getBaseUrl());

		if (overrideUrl) {
			url = overrideUrl;
		}

		if (!recordJson) {
			recordJson = record.asJson();
		} else if (Object.keys(recordJson).length !== 1 || Object.keys(recordJson)[0] !== snakeCase(record.constructor.modelName)) {
			let oldJson = recordJson;
			recordJson = {};
			recordJson[snakeCase(record.constructor.modelName)] = oldJson;
		}

		if (Object.keys(recordJson).length === 0) {
			recordJson[snakeCase(record.constructor.modelName)] = {};
		}

		let jsonToSubmit = recordJson;
		if (metaData) {
			jsonToSubmit = Object.assign(recordJson, metaData)
		}

		return this.fetcher.process((record.id ? 'PATCH' : 'POST'), url, jsonToSubmit).then((json) => {
			if (json['error']) {
				throw new ApiError(json);
			}

			return this.entityFromJson(json);
		});
	}

	delete(record) {
		return this.fetcher.delete(`${this.getBaseUrl()}/${record.id}`)
	}

	entityFromJson(json) {
		if (json['error']) {
			return new ApiError(json);
		}

		return new this.entityClass(json[Object.keys(json)[0]]);
	}

	sliceKeys() {
		if (this.sliceFilters == null) {
			return [];
		} else if (Array.isArray(this.sliceFilters)) {
			return this.sliceFilters;
		} else {
			return Object.keys(this.sliceFilters);
		}
	}
}