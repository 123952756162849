import { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { VictoryArea } from 'victory';
import { ChartTheme } from '../../common/ChartTheme';

export const TimelineChart = (props) => {
	const [data, setData] = useState(null)

	useEffect(() => {
		if (props.messagesByMonth) { prepareData(); }
	}, [props])

	const prepareData = () => {
		let newData = []
		Object.keys(props.messagesByMonth).forEach(month => {
			newData.push({ month: month, count: props.messagesByMonth[month] });
		});

		// Add zeroes for months since the last in which a message was sent
		let today = moment();
		let lastDate = moment(Object.keys(props.messagesByMonth)[Object.keys(props.messagesByMonth).length - 1]);
		while (today > lastDate || lastDate.format('YYYY-MM') === today.format('YYYY-MM')) {
			newData.push({ month: lastDate.format('YYYY-MM'), count: 0 });
			lastDate.add(1, 'month');
		}

		newData.sort((a, b) => parseInt(a.month.replace('-', ''), 10) - parseInt(b.month.replace('-', ''), 10));

		setData(newData)
	}


	if (data) {
		return (
			<svg width={props.getContainer().offsetWidth} height="100%">
				<VictoryArea
					theme={ChartTheme}
					standalone={false}
					padding={0}
					height={props.getContainer().offsetHeight}
					width={props.getContainer().offsetWidth}
					data={data}
					x="month"
					y="count"
					style={props.style || {}}
				/>
			</svg>
		)
	}
}

const MessageTimeline = (props) => {
	const container = useRef(null)
	const [windowWidth, setWindowWidth] = useState(null)

	useEffect(() => {
		const handleResize = () => setWindowWidth(window.innerWidth)

		window.addEventListener('resize', handleResize)

		return () => window.removeEventListener('resize')
	}, [])

	if (!props.messagesByMonth) { return null; }

	return (
		<div className="contact-timeline" ref={container}>
			<TimelineChart messagesByMonth={props.messagesByMonth} style={props.style} getContainer={() => container.current} />
		</div>
	)
}

export default MessageTimeline