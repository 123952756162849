import React from 'react';

const MailboxNotification = (props) => {
	let message;
	if (props.notification.event === 'mailbox_locked') {
		message = <>The mailbox <strong>{props.notification.payload.name}</strong> ({props.notification.payload.email_address}) has been locked. You can no longer access emails or files in this mailbox.</>
	} else if (props.notification.event === 'mailbox_unlocked') {
		message = <>The mailbox <strong>{props.notification.payload.name}</strong> ({props.notification.payload.email_address}) is now connected and shared; you can subscribe to it to see all of the mailbox&#8217;s email and files.</>
	}

	return (
		<>
			<div className="icon"><i className="fal fa-inbox-in" /></div>
			<div className="body">
			<h4>{message}</h4>
			</div>
		</>
	)
}

export default MailboxNotification