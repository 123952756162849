import Plan from '../../resources/Plan';

const PlanSelection = ({ planSelector }) => {
	const solo = new Plan('solo');
	const team = new Plan('team');

	return (
		<div className="new-account-plans">
			<div className="plan" onClick={planSelector.bind(null, "solo")}>
				<i className="fa fa-user plan-icon" />
				<i className="fa fa-arrow-right select-icon" />
				<h3>
					<span className="name">{solo.get('name')}</span>
					<div>
						<div className="pricing">${solo.get('user_price').toFixed(2)} <span className="month">per month</span></div>
					</div>
				</h3>
				<p>{solo.get('description')}</p>
			</div>
			<div className="plan" onClick={planSelector.bind(null, "team")}>
				<i className="fa fa-users plan-icon" />
				<i className="fa fa-arrow-right select-icon" />
				<h3>
					<span className="name">{team.get('name')}</span>
					<div>
						<div className="pricing">${team.get('user_price').toFixed(2)} <span className="month">per user per month</span></div>
					</div>
				</h3>
				<p>{team.get('description')}</p>
			</div>
		</div>
	)
}

export default PlanSelection