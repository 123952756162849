import React from 'react';
import { useState, useEffect } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { useResourceLoader } from '../../lib/useResourceLoader'
import { Helmet } from 'react-helmet';
import { Submit }  from '../../lib/forms/Submit';
import { useHotkeys } from 'react-hotkeys-hook'
import { Loading } from '../common/Common';

const Delete = () => {
	const [organization, setOrganization] = useState(null)
	const resource = useResourceLoader('OrganizationRepository')
	const navigate = useNavigate()
	const params = useParams()
	useHotkeys('esc', () => navigate(`/organizations/${params.id}`))

	useEffect(() => {
		if (resource) setOrganization(resource)
	}, [resource])

	if (organization) {
		return (
			<div className="main mini delete-account no-sidebar">
				<Helmet title={`Delete ${organization.name}`} />
				<div className="subheading">
					<i className="fa fa-trash red" />
					<h2>Delete this organization?</h2>
				</div>
				<div className="warning message">
					<p>You are about to delete the organization <em>{organization.name}</em>.</p>
				</div>
				<div className="main-content">
					<p>This will remove the affiliation with this organization from your contacts, but it will not remove any contacts, emails, or files.</p>

					<div className="submit">
						<Submit button="Delete this Organization" class="rounded medium red" />
					</div>
				</div>
			</div>
		)
	} else { return <Loading /> }
}

export default Delete