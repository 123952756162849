import React from 'react';
import { Filterable } from '../../../lib/Filterable';
import { CurrentUserAccessor } from '../../../lib/CurrentUserAccessor';
import { TextInput } from '../../../lib/forms/TextInput';
import OrganizationRepository from '../../../repositories/OrganizationRepository';

class OrganizationSelector extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			selected: this.props.currentOrganization
		}

		this.filter = props.filter;
		this.filter.setSearchFields(['name', 'domain']);
		this.filter.setQueryableCollection(new OrganizationRepository(), true);
		this.props.onSelect(this.selectListOption);
	}

	selectListOption = org => {
		this.setState({ selected: org }, () => {
			this.props.onSelection(org);
		});
	}

	matches = () => {
		if (this.props.filterMatches.length === 0 || this.state.selected) { return null; }

		return (
			<div className="content-block white" style={{ left: "285px", width: "200px" }} onMouseDown={this.mouseDownHandler} onMouseUp={this.mouseUpHandler}>
				<ul className="table user-filter-list" style={{ width: "100%" }}>
					{this.props.filterMatches.map(organization => {
						return (
							<li key={"organization." + organization.id} onClick={this.selectListOption.bind(null, organization)}>
								<div className={"block pointer" + (this.props.active === organization ? " active" : "")}>
									<div className="user">
										<div className="name">{organization.name}</div>
									</div>
								</div>
							</li>
						);
					})}
				</ul>
			</div>
		);
	}

	chosen = () => {
		if (this.state.selected) {
			return <span>{this.state.selected.name} <i className="fa fa-pen remove-existing-organization" onClick={() => { this.props.onSelection(null); this.setState({ selected: false }) }} /></span>
		}
	}

	handleChange = (name, e) => {
		this.props.updateQuery(e);
	}

	render() {
		return (
			<span>
				{
					this.state.selected
					? this.chosen()
					: <TextInput name={`employments[${this.props.employmentLookupKey}].organization.name`} type="text" placeholder="Employer" onFocus={this.showOptions} onBlur={this.toggle} changeHandler={this.handleChange} ref="filterInput" autoFocus />
				}

				{this.matches()}
			</span>
		)
	}
}

export default CurrentUserAccessor(Filterable(OrganizationSelector));