import { useState, useEffect } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { useResourceLoader } from '../../lib/useResourceLoader'
import { useAuth } from '../../lib/useAuth'
import { useToggle } from '../../lib/useToggle'
import { useResourceViewRecorder } from '../../lib/useResourceViewRecorder'
import Loading from '../common/Loading'
import View from './View'

const ViewContainer = () => {
	const resource = useResourceLoader('OrganizationRepository')
	const recordView = useResourceViewRecorder('organizations')
	const location = useLocation()
	const navigate = useNavigate()
	const auth = useAuth()
	let match = location.pathname.match(/\/organizations\/[a-z0-9]+\/(.*)\/?/i)
	let section = match && ['emails', 'files'].includes(match[1]) ? match[1] : 'workspace'
	const [active, setActive] = useState(section)
	const [organization, setOrganization] = useState(null)
	const toggle = useToggle(resource, setOrganization)

	useEffect(() => {
		if (resource) {
			setOrganization(resource)
			recordView(resource)
		}
	}, [resource])

	const switcher = (activePanel, e) => {
		setActive(activePanel)
	}


	return (
		organization ?
		<View organization={organization} currentUser={auth.user} toggle={toggle} location={location} switcher={switcher} active={active} /> :
		<Loading />
	)
}

export default ViewContainer