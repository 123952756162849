import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import ContactResultRow from './ContactResultRow';
import ThreadResultRow from './ThreadResultRow';
import FileResultRow from './FileResultRow';
import OrganizationResultRow from './OrganizationResultRow';

const Results = ({ search }) => {
	const location = useLocation()

	const loading = () => {
		return (
			<div style={{ padding: "10px" }}>
				Enter a subject, contact name, email address, file name, or other text to search your account.
			</div>
		);
	}

	const resultRows = (collection, records) => {
		if (records.length === 0) { return null; }

		return (
			<div>
				{records.map((record) => {
					return React.createElement({
						contacts: ContactResultRow,
						files: FileResultRow,
						threads: ThreadResultRow,
						organizations: OrganizationResultRow
					}[collection], { record: record, location: location });
				})}
			</div>
		);
	}

	const collectionInfo = (collection) => {
		switch (collection) {
			case 'contacts':
				return { name: 'Contacts', icon: 'user-circle' }
			case 'organizations':
				return { name: 'Organizations', icon: 'building' }
			case 'files':
				return { name: <span dangerouslySetInnerHTML={{ __html: 'Files &amp; Documents' }} />, icon: 'file-alt' }
			case 'threads':
				return { name: 'Email Threads', icon: 'envelope' }
			default:
				return null;
		}
	}

	if (!search.loaded) { return loading(); }

	return (
		<div className="results">
			{Object.keys(search.results).map((collection) => {
				let collectionResults = search.results[collection];

				if (collectionResults['total_results'] > 0) {
					return (
						<div>
							<div className={"collection-divider" + (collectionResults['total_results'] === 0 ? " empty" : "")}>
								<i className={"fa fa-" + collectionInfo(collection)['icon']} />
								<Link to={`/${collection}/search?q=${search.query}`}>{collectionInfo(collection)['name']}</Link>
								<span className="count">{collectionResults['total_results']}</span>
							</div>
							{resultRows(collection, collectionResults['records'])}
						</div>
					);
				} else { return null; }
			})}
		</div>
	)
}

export default Results