const HeaderTabs = (props) => {
	return (
		<>
			{props.children}
		</>
	)
}

HeaderTabs.displayName = 'HeaderTabs'

export default HeaderTabs