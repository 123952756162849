import { isFunction } from 'lodash';

export default class FieldValidation {
	valid = null;

	constructor(field, spec, value = null) {
		this.field = field;
		this.if = spec['if'] ? spec['if'] : false
		this.rule = spec['test'] ? spec['test'] : spec;
		this.value = value
	}

	setValue(value, object) {
		this.value = value;
		this.object = object;
	}

	process() {
		if (!isFunction(this.if) || this.if(this.object)) {
			if (this.rule.test || 	this.rule instanceof RegExp) {
				if (!this.rule.test(this.value)) {
					return this.valid = false;
				}
			} else if (this.rule === 'present') {
				if (this.value === undefined || this.value === '') {
					return false;
				}
			} else if (isFunction(this.rule)) {
				if (!this.rule(this.object)) {
					return this.valid = false;
				}
			}
		}

		return this.valid = true;
	}

	passed() {
		return this.process();
	}

	failed() {
		return !this.process();
	}
}