import Resource from '../lib/Resource';
import ProxyCollection from '../lib/ProxyCollection';
import TaskRepository from '../repositories/TaskRepository';
import { TaskCollectionUtilities } from '../lib/utilities/TaskCollectionUtilities';

export default class Milestone extends Resource {
	taskCollection() {
		if (!this._taskCollection) {
			this._taskCollection = Object.assign(new ProxyCollection(this.tasks, new TaskRepository()), TaskCollectionUtilities);
		}

		return this._taskCollection;
	}

	progress() {
		return (this.task_count > 0 ? ((this.task_count - this.incompleteTaskCount()) / this.task_count) : 0)
	}

	incompleteTaskCount() {
		return this.taskCollection().incompleteTasks().length;
	}

	isShared() {
		return this.visibility === 'shared';
	}

	isPrivate() {
		return this.visibility === 'private';
	}

	isPublic() {
		return this.visibility === 'public';
	}

	collaborators(account) {
		return (this.collaborator_user_ids || []).map(id => {
			return account.getUserById(id);
		});
	}
}

Milestone.fields = [
	'id', 'name', 'description', 'due', 'task_count', 'pinned', 'archived', 'milestone_id',
	'completed_task_count', 'task_progress', { tasks: ['Task'] }, 'collaborator_user_ids', 'visibility'
];
Milestone.validations = {
	name: 'present'
}
Milestone.modelName = 'Milestone';