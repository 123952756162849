import { useState, useEffect, useRef } from 'react';
import Task from '../../resources/Task';
import TaskRepository from '../../repositories/TaskRepository';
import { Form } from '../../lib/forms/Form';
import { TextInput } from '../../lib/forms/TextInput';
import { DatePickerInput } from '../../lib/forms/DatePickerInput';
import OutsideClickHandler from '../common/OutsideClickHandler';
import { SegmentedSelect } from '../../lib/forms/SegmentedSelect';

const New = ({ milestone, onSave }) => {
	const [task, setTask] = useState(null)
	const [showSpecificDate, setShowSpecificDate] = useState(false)
	const [showOptions, setShowOptions] = useState(false)
	const [saving, setSaving] = useState(false)
	const [saved, setSaved] = useState(false)
	const [active, setActive] = useState(false)
	const [focused, setFocused] = useState(false)
	const [submittable, setSubmittable] = useState(false)
	const textInput = useRef()

	useEffect(() => {
		setTask(new Task({ milestone_id: milestone.id }))
	}, [])

/*
	const validityChange = (task) => {
		setValid(task.valid())
	}
*/

	const handleDueShortcutChange = (field, value) => {
		setShowSpecificDate(value === "date")
	}

	const dueDateInput = () => {
//		if (!showOptions.includes('date')) { return null; }

		let input;
		if (showSpecificDate) {
			input = (
				<div>
					<div className="inline-label">Complete this task by</div>
					<DatePickerInput name="due" className="small-input" dateFormat="MMMM d, yyyy" weekStart="0" />
				</div>
			);
		} else {
			input = (
				<SegmentedSelect name="due_shortcut" defaultValue="" changeHandler={handleDueShortcutChange}>
					<option value="">no due date</option>
					<option value="today">today</option>
					<option value="tomorrow">tomorrow</option>
					<option value="this_week">this week</option>
					<option value="next_week">next week</option>
					<option value="date">on&hellip;</option>
				</SegmentedSelect>
			);
		}

		return <div className="date-input">{input}</div>;
	}

	const saveButton = () => {
		if (saving) {
			return <span><i className="fa fa-circle-o-notch fa-spin" />Saving this task</span>;
		} else if (saved) {
			return <span className="saved"><i className="fa fa-check" />Task added</span>;
		} else {
			return <div className={submittable ? '' : 'hidden'}><input type="submit" className="medium green rounded iconless" value="Save this Task" /></div>;
		}
	}

	const markAsSaving = () => {
		setSaving(true)
	}

	const validityChange = () => {
		setSaved(false)
		setSubmittable(task.valid())
	}

	const handleFocus = () => {
		setActive(true)
	}

	const handleBlur = () => {
		setFocused(false)
	}

	const considerDeactivation = () => {
		if (!focused || (!task.assignment && !task.due_shortcut)) {
			setActive(false)
		}
	}

	const handleSave = (task) => {
		setSaving(false)
		setSaved(true)
		setShowSpecificDate(false)
		setSubmittable(false)
		setTask(new Task({ ...task, assignment: '' }))

		if (onSave) { onSave(task) }
		textInput.current.value = "";
	}

	if (task) {
		return (
			<OutsideClickHandler onOutsideClick={() => { considerDeactivation() }}>
				<Form className={["new-task", (submittable ? 'submittable' : ''), (active ? 'active' : '')].join(' ')} beforeSubmit={markAsSaving} object={task} onSave={handleSave} onValidityChange={validityChange} repository={new TaskRepository()}>
					<div className="option title">
						<TextInput ref={textInput} autoFocus name="assignment" placeholder="Add a new task" className="full" onFocus={handleFocus} onBlur={handleBlur} />
					</div>
					<div className="option with-icon">
						<i className="fal fa-calendar-alt option-icon" />
						{dueDateInput()}
					</div>
					<div className={"submit" + (saved ? ' fade-out' : '')}>
						<div className="submit-button">{saveButton()}</div>
					</div>
				</Form>
			</OutsideClickHandler>
		)
	}
}

export default New