import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import MailboxRepository from '../../repositories/MailboxRepository'
import AccountRepository from '../../repositories/AccountRepository'
import Account from '../../resources/Account'
import Plan from '../../resources/Plan'
import Mailbox from '../../resources/Mailbox'
import * as Accounts from './Accounts'
import Connection from '../mailboxes/Connection'
import { Loading } from '../common/Common'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import moment from 'moment'

const New = () => {
	const [loaded, setLoaded] = useState(false)
	const [processing, setProcessing] = useState(false)
	const [errors, setErrors] = useState({})
	const [authenticated, setAuthenticated] = useState(false)
	const [mailbox, setMailbox] = useState(null)
	const [discovering, setDiscovering] = useState(false)
	const [plan, setPlan] = useState('solo')
	const navigate = useNavigate()
	const location = useLocation()
	const queryParams = new URLSearchParams(location.search)

	useEffect(() => {
		const sendIntegrationCode = () => {
			new MailboxRepository().save(new Mailbox({ id: queryParams.get('state') }), `/mailboxes/${queryParams.get('state')}/connect`, { oauth_code: queryParams.get('code') }, {}, true).then(mailbox => {
				setLoaded(true)
				setMailbox(mailbox)
			})
		}

		sendIntegrationCode()
	}, [])

	const createAccount = (token, firstName, lastName, organization, password) => {
		let account = new Account({
			billing_token: token.id,
			organization: organization,
			owner: {
				first_name: firstName,
				last_name: lastName,
				password: password,
				mailbox_id: mailbox.id,
				configuration: {
					time_zone: moment.tz.guess()
				}
			},
		 });

		return new AccountRepository().save(account).then(account => {
			localStorage.token = account.owner.token;
			navigate('/');
		})
	}

	const form = () => {
		if (!mailbox.authorized) {
			return <Connection mailbox={mailbox} />;
		} else if (plan) {
			return (
				<Elements stripe={loadStripe('pk_test_WhQkmqjCzBrllU2Q2tQvjwZw')}>
					<Accounts.BillingForm plan={new Plan(plan)} accountCreator={createAccount} />
				</Elements>
			);
		} else {
			return <Accounts.PlanSelection planSelector={(code) => setPlan(code)} />;
		}
	}

	return (
		<div id="main" className="mini standalone headingless">
			<div className="main-content">
				{loaded ? form() : <Loading inline={true} />}
			</div>
		</div>
	)
}

export default New
