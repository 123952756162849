import React, { useState, useEffect} from 'react';
import { Helmet } from 'react-helmet';
import { Link, useLocation } from 'react-router-dom';
import { useResourceLoader } from '../../lib/useResourceLoader';
import Task from '../../resources/Task'
import { useHotkeys } from 'react-hotkeys-hook'
import Form from './Form';

const View = () => {
	const [task, setTask] = useState(null)
	const [editMode, setEditMode] = useState(false)
	const resource = useResourceLoader('TaskRepository')
	const location = useLocation()
	useHotkeys('e', (e) => { e.preventDefault(); setEditMode(true) })
	useHotkeys('esc', (e) => { e.preventDefault(); setEditMode(false) })

	useEffect(() => {
		if (resource) {
			setTask(resource)
		}
	}, [resource])

	const associatedRecordLink = () => {
		return task.contact.exists()
			? <Link to={`/contacts/${task.contact.id}`}>{task.contact.displayName()}</Link>
			: <Link to={`/files/${task.file.id}`}>{task.file.displayName()}</Link>
	}

	const description = () => {
		if (task.description) {
			return (
				<div className="main-content">
					<div className="description">
						{task.description}
					</div>
				</div>
			);
		}
	}

	const toggleCompletion = () => {
		let updatedTask = task;
		updatedTask.toggleCompletion();

		setTask(new Task(updatedTask))
	}

	const onSave = (task) => {
		setEditMode(false)
		setTask(task);

		let containerUpdater //= location.state.containerUpdater || props.containerUpdater;
		if (containerUpdater) {
			containerUpdater(task);
		}
	}

	const view = () => {
		return (
			<div className={"view-task" + (task.isComplete() ? ' complete' : '')}>
				<h2>
					<div className="checkbox" onClick={toggleCompletion}></div>
					 <i className={"fa fa-pencil edit" + (editMode ? ' active' : '')} onClick={() => setEditMode(!editMode)} />
					{task.assignment}
					 <div className="completed-at">
					 	{task.isComplete() ? <span>Completed on <strong>{task.completedAt().toFormat('LLLL d')}</strong></span> : null}
					 </div>
				</h2>
				<div className="meta">
					<div className={"due" + (!task.hasDueDate() ? ' none' : '')}>
						{task.hasDueDate() ? <span>{task.dueDate().toFormat('LLLL d')}</span> : <span>No due date</span>}
					</div>
					<div className={"milestone" + (!task.hasMilestone() ? ' none' : '')}>
						{task.hasMilestone() ? <Link to={"/milestones/" + task.milestone.id}>{task.milestone.name}</Link> : <span>No milestone</span>}
					</div>
					<div className={"record " + (task.hasAssociatedRecord() ? task.associatedRecordType() : 'none')}>
						{task.hasAssociatedRecord() ? associatedRecordLink() : <span>No associated record</span>}
					</div>
				</div>
				{description()}
			</div>
		);
	}

	if (task) {
		return (
			<div className="main small headingless">
				<Helmet title={task.assignment} />
				<div style={{ display: (editMode ? 'block' : 'none') }}>
					<Form task={new Task(task)} toggleForm={() => setEditMode(!editMode)} onSave={onSave} />
				</div>
				<div style={{ display: (editMode ? 'none' : 'block') }}>
					{view()}
				</div>
			</div>
		)
	}
}

export default View