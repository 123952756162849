import { DateTime, Interval } from 'luxon';
import moment from 'moment';

export const TaskCollectionUtilities = {
	dates: {
		today: DateTime.local(),
		tomorrow: DateTime.local().plus({ days: 1 }),
		laterThisWeek: Interval.fromDateTimes(DateTime.local().plus({ days: 2 }), DateTime.local().endOf('week')),
		thisWeek: Interval.fromDateTimes(DateTime.local().startOf('day'), DateTime.local().endOf('week')),
		nextWeek: Interval.fromDateTimes(DateTime.local().plus({ weeks: 1 }).startOf('week'), DateTime.local().plus({ weeks: 1 }).endOf('week')),
		endOfNextWeek: DateTime.local().plus({ weeks: 1 }).endOf('week')
	},

	categoryFilters: {
		overdue: (tasks, self) => { return tasks.filter(task => task.due && task.dueDate() < self.dates.today.startOf('day')) },
		today: (tasks, self) => { return tasks.filter(task => task.due && self.dates.today.hasSame(task.dueDate(), 'day') ) },
		tomorrow: (tasks, self) => { return tasks.filter(task => task.due && self.dates.tomorrow.hasSame(task.dueDate(), 'day') ) },
		laterThisWeek: (tasks, self) => { return tasks.filter(task => task.due && self.dates.laterThisWeek.contains(task.dueDate()) ) },
		thisWeek: (tasks, self) => { return tasks.filter(task => task.due && self.dates.thisWeek.contains(task.dueDate()) ) },
		nextWeek: (tasks, self) => { return tasks.filter(task => task.due && self.dates.nextWeek.contains(task.dueDate()) ) },
		later: (tasks, self) => { return tasks.filter(task => (!task.due || task.dueDate() > self.dates.endOfNextWeek) ) }
	},

	tasks() {
		return this.records || [];
	},

	tasksDue(timePeriod, show = 'all') {
		let tasks = this.categoryFilters[timePeriod](this.records, this);

		if (show === 'incomplete') {
			return tasks.filter(task => !task.isComplete())
		} else if (show === 'complete') {
			return tasks.filter(task => task.isComplete())
		} else {
			return tasks;
		}
	},

	count() {
		return this.tasks().length;
	},

	tasksSortedByCompletionDate(incompleteOnly) {
		return this.records.sort((a, b) => {
			return moment(b.completed_at) - moment(a.completed_at);
		});
	},

	completeTasks() {
		return this.records.filter(task => task.isComplete()) || [];
	},

	incompleteTasks() {
		return this.records.filter(task => !task.isComplete()) || [];
	},

	subset(method) {
		return Object.assign(new this.constructor(this[method](), this.repository), TaskCollectionUtilities);
	},

	addTask(task) {
		this.records = this.records.concat(task)
	},

	tasksSortedByDueDate(incompleteOnly) {
		let records = (incompleteOnly ? this.incompleteTasks() : this.tasks())

		return records.sort((a, b) => {
			if (a.hasDueDate() && b.hasDueDate()) {
				if (a.dueDate() - b.dueDate() === 0) {
					return a.createdAt().ts - b.createdAt().ts;
				} else {
					return a.dueDate() - b.dueDate();
				}
			} else if (a.hasDueDate()) {
				return -1;
			} else if (b.hasDueDate()) {
				return 1;
			} else {
				return b.createdAt().ts - a.createdAt().ts;
			}
		});
	},

	toggleTaskCompletion(task) {
		if (task.isComplete()) {
			task.set('incomplete', true)
			task.set('completed_at', null);
		} else {
			task.set('completed_at', DateTime.local());
		}

		task.set('complete', !task.isComplete());
		return this.repository.save(task);
	}
}