import { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { compact } from 'lodash';

const LocalTime = ({ contact }) => {
	const [counter, setCounter] = useState(0)
	const location = contact.locations[0]

	useEffect(() => {
		let timer = setInterval(() => {
			setCounter(counter + 1) // re-render
		}, (60 * 1000))

		return () => clearTimeout(timer)

	}, [])

	const city = () => {
		var parts = compact([location.city, location.state, location.country])

		if (parts.length > 0) {
			return <span className="etc normal-size">({parts.slice(0, 2).join(', ')})</span>
		} else { return null; }
	}

	if (!location || !location.time_zone) {
		return null;
	}

	var localTime = moment().tz(location.time_zone);
	var localHour = parseInt(localTime.format('k'), 10);
	var isOpen = (localHour >= 9 && localHour < 19)

	return (
		<div className={"time-zone " + (isOpen ? "open" : "closed")}>
			<span className="indicator"><i className="fa fa-circle" /></span>
			{localTime.format('h:mm a')} {city()}
		</div>
	)
}

export default LocalTime