import React from 'react';
import { Outlet, useOutletContext } from 'react-router-dom'
import TaskSidebar from './Sidebar';

const Layout = props => {
	const context = useOutletContext()

	return (
		<>
		<div id="main-sidebar">
			<div className="content">
				<div className="scope-container"></div>
				<TaskSidebar context={context} />
			</div>
		</div>

			<div className="main-content-panel">
				<Outlet context={context} />
			</div>
		</>
	);
}

export default Layout;