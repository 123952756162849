import { useEffect, useRef } from 'react';

const OutsideClickHandler = ({ onOutsideClick, children}) => {
	const wrapper = useRef(null)

	useEffect(() => {
		window.addEventListener('mousedown', handleClickOutside, false)

		return () => window.removeEventListener('mousedown', handleClickOutside)
	}, [])


	const handleClickOutside = (event) => {
		if (wrapper && !wrapper.current.contains(event.target)) {
			onOutsideClick(event)
		}
	}

	return <div ref={wrapper}>{children}</div>
}

export default OutsideClickHandler