import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../lib/hooks'

const ContactNotification = (props) => {
	const auth = useAuth()

	return (
		<>
			<div className="icon"><i className="fal fa-user" /></div>
			<div className="body">
				<h4><strong>{auth.account.getUserById(props.notification.prompting_user_id).displayName()}</strong> shared the contact <strong><Link to={`/contacts/${props.notification.payload.id}`}>{props.notification.payload.name || props.notification.payload.email_address}</Link></strong>.</h4>
			</div>
		</>
	)
}

export default ContactNotification