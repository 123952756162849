import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const HeaderSearch = (props) => {
	const [searchText, setSearchText] = useState('')
	const navigate = useNavigate()

	const handleSubmit = (e) => {
		e.preventDefault()

		navigate('/' + props.scope + '/search?q=' + searchText)
	}

	return (
		<form className="search" onSubmit={handleSubmit}>
			<input type="text" value={searchText} onChange={(e) => setSearchText(e.target.value)} placeholder={props.placeholder} />
			{searchText.length > 0 ? <i className="fa fa-arrow-circle-right" onClick={handleSubmit} /> : <i className="fa fa-search" />}
		</form>
	)
}

HeaderSearch.displayName = 'HeaderSearch'

export default HeaderSearch