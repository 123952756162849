export const ShareableRecord = (superclass) => class extends superclass {
	isShared() {
		return this.shareRecipientCount() > 0;
	}

	isSharedWith(user) {
		return this.getShareRecipientIds().includes(user.id)
	}

	isSharedBy(user) {
		return this.shares.map(share => share.sharer_id).includes(user.id)
	}

	idOfUserWhoSharedWith(user) {
		let shares = this.shares.filter(share => share.recipients.map(recipient => recipient.user_id).includes(user.id))

		return shares.length > 0 ? shares[0].sharer_id : null
	}

	shareRecipientCount() {
		return this.getShareRecipientIds().length;
	}

  getShareRecipientIds() {
  	let userIds = [];

    if (this.shares) {
      this.shares.forEach(share => {
      	share.recipients.map(recipient => userIds.push(recipient.user_id));
      })
    }

    return userIds;
  }
};
