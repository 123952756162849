import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useAuth, useCollectionLoader } from '../../lib/hooks'
import moment from 'moment';
import Pagination from '../common/Pagination';
import ThreadTable from '../threads/Table';
import Reclassify from './Reclassify';
import ThreadRepository from '../../repositories/ThreadRepository';

const View = ({ list }) => {
	const [threadCollection, setThreadCollection] = useState(null)
	const auth = useAuth()
	const threadRepository = new ThreadRepository()

	useEffect(() => {
		const loadThreads = async () => {
			const collection = await threadRepository.asyncGetCollection({}, `/contacts/${list.id}/threads`)

			setThreadCollection(collection)
		}

		loadThreads()
	}, [])

	const name = () => {
		if (list.name) {
			return <div>{list.name}<span className="inline normal-size etc">{list.email_address}</span></div>;
		} else {
			return list.email_address;
		}
	}

	const inactiveBar = () => {
		if (list.status === 'active') { return null; }

		return (
			<div className="gray sub-bar">
				It&#8217;s been longer than usual since your last email, so this mailing list may be inactive or you may no longer be subscribed.
			</div>
		)
	}

	const filter = (filters) => {
		threadRepository.getCollection(filters, `/contacts/${list.id}/threads`).then((collection) => {
			setThreadCollection(collection)
		});
	}

	const threads = () => {
		return (
			<div>
				<ThreadTable threads={threadCollection.records} />
				<Pagination collection={threadCollection} filter={filter} />
			</div>
		)
	}

	return (
		<div id="main" className="full centered">
			<Helmet title={list.displayName()} />
			<div className="clear new-header">
				<Reclassify mailingList={list} />
				<h1 className="with-breadcrumb">
					<div className="breadcrumb">Mailing List</div>
					<span className="page-icon"><i className="fa fa-newspaper" /></span>{name()}
				</h1>
			</div>
			{inactiveBar()}
			<div className={"record-stats-bar" + (list.status !== 'active' ? ' gray' : '')}>
				<div>
					<div>
						<div className="icons"><i className="fal fa-arrow-right" /></div>
						<div className="info-label">First Received</div>
						<div className="info-value file-type"><span>{moment(list.interactionHistoryForUser(auth.user).first_contact).format('MMMM YYYY')}</span></div>
					</div>
					<div>
						<div className="icons"><i className="fal fa-envelope" /></div>
						<div className="info-label">Total Threads</div>
						<div className="info-value"><span>{list.interactionHistoryForUser(auth.user).thread_count || 0}</span></div>
					</div>
					<div>
						<div className="icons"><i className="fal fa-clock" /></div>
						<div className="info-label">Frequency</div>
						<div className="info-value"><span>{list.average_frequency_in_words}</span></div>
					</div>
					<div>
						<div className="icons"><i className="fal fa-arrow-left" /></div>
						<div className="info-label">Last Received</div>
						<div className="info-value"><span>{moment(list.interactionHistoryForUser(auth.user).last_contact).format('MMMM YYYY')}</span></div>
					</div>
				</div>
			</div>
			<div id="content" className="mailing-list-threads">
				{threadCollection ? threads() : null}
			</div>
		</div>
	)
}

export default View