import React, { useState, useEffect } from 'react';
import { Outlet, useOutletContext, Routes, Route, useNavigate, useLocation } from 'react-router-dom'
import { useAuth } from '../../lib/useAuth'
import { Navigator } from '../structure/Navigator'
import { Modal } from '../structure/Modal'
import TaskRepository from '../../repositories/TaskRepository'
import FileRepository from '../../repositories/FileRepository';
import ContactRepository from '../../repositories/ContactRepository';
import OrganizationRepository from '../../repositories/OrganizationRepository';
import MailingListRepository from '../../repositories/MailingListRepository';
import ThreadRepository from '../../repositories/ThreadRepository';
import * as Dashboard from '../dashboard/Dashboard';
import * as Contacts from '../contacts/Contacts';
import * as Contact from '../contacts/Contact';
import * as Organizations from '../organizations/Organizations'
import * as Threads from '../threads/Threads';
import * as Messages from '../messages';
import * as Drafts from '../drafts/Drafts';
import * as MailingLists from '../mailing_lists/MailingLists';
import * as Tasks from '../tasks/Tasks'
import * as Milestones from '../milestones/Milestones';
import * as Duplicates from '../duplicates/Index';
import * as Settings from '../settings/Settings'
import * as Mailboxes from '../mailboxes/Mailboxes'
import * as Files from '../files/Files'
import * as Accounts from '../accounts/Accounts'
import * as Users from '../users'
import ViewInvoice from '../invoices/View'
import NotificationIndex from '../notifications/Index';
import HotkeyMap from '../hotkeys/Index'
import { useHotkeys } from 'react-hotkeys-hook'

const ProtectedPage = () => {
	const auth = useAuth()
	const navigate = useNavigate()
	const location = useLocation()
	const queryParams = new URLSearchParams(location.search)
	const [showHotkeyMap, setShowHotkeyMap] = useState(false)
	useHotkeys('shift+/ ', () => setShowHotkeyMap(!showHotkeyMap))
	useHotkeys('esc', () => setShowHotkeyMap(false))
	useHotkeys('shift+d', () => navigate('/'))
	useHotkeys('shift+c', () => navigate('/contacts'))
	useHotkeys('shift+o', () => navigate('/organizations'))
	useHotkeys('shift+e', () => navigate('/email'))
	useHotkeys('shift+f', () => navigate('/files'))
	useHotkeys('shift+t', () => navigate('/tasks'))
	useHotkeys('shift+s', () => navigate('/settings'))

	useEffect(() => {
		if (!auth.authenticated) {
			if (queryParams.get('code'), queryParams.get('state')) {
				navigate({ pathname: '/accounts/new', search: `?${queryParams}` })
				return
			}

			navigate('/sessions')
		}
	}, [])

	if (auth.authenticated) {
		if (auth.user.activated && !auth.user.initialized) {
			return <Users.Initialize />;
		}

		return (
			<>
				<Navigator />

				<Routes location={location.state?.backgroundLocation || location}>
					<Route path ="/" element={<Dashboard.Index />} />
					<Route path="/contacts" element={<Contacts.Layout />}>
						<Route index element={<Contacts.Index />} />
						<Route path="search" element={<Contacts.SearchResults />} />
						<Route path="list" element={<Contacts.List />} />
						{(new ContactRepository().sliceKeys()).map(path => <Route key={'contacts.' + path} path={path} element={<Contacts.List />} />)}
						<Route path="new" element={<Contacts.New />} />
						<Route path=":id" element={<Contacts.ViewContainer />} />
						<Route path=":id/emails" element={<Contacts.ViewContainer />} />
						<Route path=":id/files" element={<Contacts.ViewContainer />} />
						<Route path=":id/duplicates" element={<Duplicates.ContactDuplicates />} />
						<Route path=":id/edit" element={<Contacts.Edit />} />
						<Route path=":id/share" element={<Contact.Share />} />
					</Route>
					<Route path="/organizations" element={<Organizations.Layout />}>
						<Route index element={<Organizations.Index />} />
						<Route path="list" element={<Organizations.List />} />
						{(new OrganizationRepository().sliceKeys()).map(path => <Route key={'organizations.' + path} path={path} element={<Organizations.List />} />)}
						<Route path="new" element={<Organizations.New />} />
						<Route path="search" element={<Organizations.SearchResults />} />
						<Route path=":id" element={<Organizations.ViewContainer />} />
						<Route path=":id/emails" element={<Organizations.ViewContainer />} />
						<Route path=":id/files" element={<Organizations.ViewContainer />} />
						<Route path=":id/edit" element={<Organizations.Edit />} />
						<Route path=":id/delete" element={<Organizations.Delete />} />
						<Route path=":id/duplicates" element={<Duplicates.OrganizationDuplicates />} />
					</Route>
					<Route path="/email" element={<Threads.Layout />}>
						<Route index element={<Threads.List />} />
						<Route path="search" element={<Threads.SearchResults />} />
						{(new ThreadRepository().sliceKeys()).map(path => <Route key={'threads.' + path} path={path} element={<Threads.List />} />)}
						<Route path=":id" element={<Threads.ViewContainer />} />
						<Route path="messages/:messageId" element={<Messages.ViewContainer />} />
					</Route>
					<Route path="mailing-lists" element={<Threads.Layout />}>
						<Route index element={<MailingLists.List />} />
						{(new MailingListRepository().sliceKeys()).map(path => <Route key={'mailing-lists.' + path} path={path} element={<MailingLists.List />} />)}
						<Route path=":id" element={<MailingLists.ViewContainer />} />
					</Route>
					<Route path="/drafts" element={<Threads.Layout />}>
						<Route path="new" element={<Drafts.New />} />
					</Route>
					<Route path="/notifications" element={<NotificationIndex />} />
					<Route path="tasks" element={<Tasks.Layout />}>
						<Route index element={<Tasks.List />} />
						<Route path="new" element={<Tasks.New />} />
						<Route path="list" element={<Tasks.List />} />
						{(new TaskRepository().sliceKeys()).map(path => <Route key={'tasks.' + path} path={path} element={<Tasks.List />} />)}
						<Route path=":id" element={<Tasks.View />} />
					</Route>
					<Route path="/milestones" element={<Tasks.Layout />}>
						<Route index element={<Milestones.Index />} />
						<Route path="new" element={<Milestones.New />} />
						<Route path=":id/edit" element={<Milestones.Edit />} />
						<Route path=":id" element={<Milestones.ViewContainer />} />
					</Route>
					<Route path="/files" element={<Files.Layout />}>
						<Route index element={<Files.Index />} />
						<Route path="list" element={<Files.List />} />
						{(new FileRepository().sliceKeys()).map(path => <Route key={path} path={path} element={<Files.List />} />)}
						<Route path=":id" element={<Files.ViewContainer />} />
						<Route path=":id/edit" element={<Files.Edit />} />
						<Route path="search" element={<Files.SearchResults />} />
					</Route>
					<Route path="/settings">
						<Route index element={<Settings.Index />} />
						<Route path="users" element={<Settings.Index />} />
						<Route path="billing" element={<Settings.Index />} />
						<Route path="mfa" element={<Settings.MultiFactorAuthentication />} />
						<Route path="mailboxes">
							<Route index element={<Settings.Index />} />
							<Route path=":id/lock" element={<Mailboxes.Lock />} />
							<Route path=":id/unlock" element={<Mailboxes.Unlock />} />
							<Route path=":id/delete" element={<Mailboxes.Delete />} />
						</Route>
					</Route>
					<Route path="/invoices/:id" element={<ViewInvoice />} />
					<Route path="/users/:id/edit" element={<Users.Edit />} />
					<Route path="/users/:id/delete" element={<Users.Delete />} />
					<Route path="/accounts/delete" element={< Accounts.Delete />} />
				</Routes>

				{location.state?.backgroundLocation && (
					<Routes>
						<Route path="/" element={<Modal />}>
							<Route path="/tasks/new" element={<Tasks.New />} />
							<Route path="/tasks/:id" element={<Tasks.View />} />
							<Route path="/milestones/new" element={<Milestones.New />} />
							<Route path="/milestones/:id/edit" element={<Milestones.Edit />} />
							<Route path="/organizations/new" element={<Organizations.New />} />
							<Route path="/organizations/:id/edit" element={<Organizations.Edit />} />
							<Route path="/organizations/:id/delete" element={<Organizations.Delete />} />
							<Route path="/contacts/new" element={<Contacts.New />} />
							<Route path="/contacts/:id/edit" element={<Contacts.Edit />} />
							<Route path="/contacts/:id/share" element={<Contact.Share />} />
							<Route path="/contacts/:id/duplicates" element={<Duplicates.ContactDuplicates />} />
							<Route path="/files/:id/edit" element={<Files.Edit />} />
						</Route>
					</Routes>
				)}

				{showHotkeyMap ? <HotkeyMap /> : null}
			</>
		)
	}
}

export default ProtectedPage