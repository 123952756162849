import React from 'react';

const ImportStatus = ({ mailbox }) => {
	const table = () => {
		return (
			<table>
				<tbody>
					<tr>
						<td>
							<i className="fa fa-envelope" />
							<div><strong>{mailbox.thread_count ? mailbox.thread_count.toLocaleString() : String.fromCharCode(8230)}</strong> email threads</div>
						</td>
						<td>
							<i className="fa fa-file-alt" />
							<div><strong>{mailbox.file_count ? mailbox.file_count.toLocaleString() : String.fromCharCode(8230)}</strong> files</div>
						</td>
						<td>
							<i className="fa fa-users" />
							<div><strong>{mailbox.contact_count ? mailbox.contact_count.toLocaleString() : String.fromCharCode(8230)}</strong> contacts</div>
						</td>
					</tr>
				</tbody>
			</table>
		)
	}

	if (mailbox) {
		return (
			<div className="import-status complete">
				<div className="status-heading">
					<div className="icon">
						<i className="fal fa-spin fa-circle-notch" />
					</div>
					<div>
						<h3>We&#8217;re importing your emails, files, and contacts</h3>
						<p>Your data is still incomplete, but go ahead and get started. (We won&#8217;t charge you until the import is finished.)</p>
					</div>
				</div>
				{false && table()}
			</div>
		)
	}
}

export default ImportStatus