import { useState, useEffect } from 'react';
import { useAuth } from '../../lib/useAuth'
import { Helmet } from 'react-helmet';
import { useResourceLoader } from '../../lib/useResourceLoader'
import { useGatekeeper } from '../../lib/useGatekeeper'
import NotPermitted from '../common/NotPermitted';
import Fetcher from '../../lib/Fetcher';

const Delete = () => {
	const auth = useAuth()
	const [user, setUser] = useState(null)//useState(auth && (auth.user.isAdmin() && auth.user.isOwner() || !user.isOwner()))
	const [deleted, setDeleted] = useState(false)
	const [deleting, setDeleting] = useState(false)
	const resource = useResourceLoader('UserRepository')

	useEffect(() => {
		if (resource) {
			setUser(resource)
		}
	}, [resource])

	const deleteUser = () => {
		// TODO
/*
		this.setState({ deleting: true }, () => {
			this.fetcher.delete(`/users/${user.id}`).then(response => {
				if (response.ok) {
					this.setState({ deleted: true }, () => {
						this.props.loadUser();

						if (this.props.location.state.afterDelete) {
							this.props.location.state.afterDelete();
						}
					})
				}
			})
		});
*/
	}

	const cancellationNotice = () => {
		return (
			<div className="main mini delete-account no-sidebar">
				<div className="deleted">
					<i className="fa fa-check" />
					<div className="main-content">
						<h2>We&#8217;ve deleted {user.displayName()}&#8217;s account</h2>
						<p className="description">
							We&#8217;ve sent an email letting {user.first_name} know.  We&#8217;ve blocked their access to Carom and will delete their private mailboxes, threads, contacts, and files.
						</p>
					</div>
				</div>
			</div>
		)
	}

	if (!auth || !user) {
		return null;
	} else if (!(auth.user.isAdmin() && auth.user.isOwner() || !user.isOwner())) {
		return  <NotPermitted />
	} else if (deleted) {
		return cancellationNotice();
	} else {
		return (
			<div className="main mini delete-account no-sidebar">
				<Helmet title={`Delete ${user.displayName()}'s account`} />
				<div className="subheading">
					<i className="fa fa-trash red" />
					<h2>Delete {user.displayName()}&#8217;s account?</h2>
				</div>
				<div className="warning message">
					<p>You are about to permanently delete this user&#8217;s account.</p>
				</div>
				<div className="main-content">
					<p>This will remove any of their private mailboxes, emails, and files from Carom as well.</p>
					<p>Are you sure you&#8217;d like to proceed?</p>

					<div className="submit">
						{deleting
							? <i className="fa fa-spinner fa-spin" />
							: <div className="medium red button" onClick={deleteUser}>I understand&mdash;delete this user account</div>
						}
					</div>
				</div>
			</div>
		)
	}
}

export default Delete