import React from 'react';
import PropTypes from 'prop-types';

export var CurrentUserAccessor = (Component) => class extends React.Component {
	static contextTypes = {
		account: PropTypes.object,
		currentUser: PropTypes.object,
		errorHandler: PropTypes.func,
		updateCurrentUser: PropTypes.func,
		loadUser: PropTypes.func
	}

	render() {
		return <Component {...this.context} {...this.props} />
	}
};