import Resource from '../lib/Resource.js';

export default class Mailbox extends Resource {
	displayName() {
		return this.name || this.email_address;
	}

	hasConnectionSettings() {
		return !!this.configuration;
	}

	unableToAuthorize() {
		return !!this.configuration.error;
	}

	isPrimaryFor(user) {
		return this.primary && this.email_address === user.email_address;
	}
}

Mailbox.fields = [
	'id', 'name', 'email_address', 'integration', 'thread_count', 'provider', 'connect_url', 'invitation_code',
	'subscribe_creator','file_count', 'contact_count', 'shared', 'primary', 'subscription_color', 'discoverable',
	'sync_state', { configuration: 'MailboxConfiguration' }, 'subscription_count', 'authorized', 'connected', 'authorization_failed'
];
Mailbox.validations = {
	name: 'present',
	email_address: { test: /^[^@]+@[^@]+\.[^@]+$/i, if: mailbox => !(mailbox && mailbox.configuration && mailbox.configuration.oauth_code) }
}
Mailbox.colors = ['khaki', 'orange', 'peru', 'lightcoral', 'crimson', 'mediumpurple', 'steelblue', 'teal']
Mailbox.modelName = 'Mailbox';