import Resource from '../../lib/Resource';

export default class Website extends Resource {
	displayValue() {
		return this.url;
	}

	fullUrl = () => {
		if (this.url.substr(0, 4) === 'http') {
			return this.url;
		} else {
			return `http://${this.url}`;
		}
	}

}

Website.modelName = 'Website';
Website.fields = ['id', 'url', 'title', 'private'];