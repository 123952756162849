import moment from 'moment'
import Thread from '../../../resources/Thread'

const SnoozeNotice = ({ thread, updateThread }) => {
	const unsnooze = () => {
		const newThread = new Thread(thread)
		newThread.set('snooze_until', null);
		newThread.unsnooze();

		updateThread(newThread);
	}

	if (!thread.isSnoozed() || thread.resolved) { return null }

	let dateText = ''
	let unsnoozeAt = moment(thread.snooze_until)

	if (unsnoozeAt.isSame(new Date(), 'day')) {
		dateText += 'today '
	} else if (unsnoozeAt.isSame(moment(new Date()).add(1,'days'), 'day')) {
		dateText += 'tomorrow '
	} else {
		dateText += `on ${unsnoozeAt.format('dddd, MMMM Do')} `
	}

	dateText += `at ${unsnoozeAt.format('h:mma')}`

	return (
		<div className="snooze-notice sub-bar" key={thread.snooze_until}>
			<strong>You hit snooze!</strong>
			<p>We&#8217;ll bring this thread to the top of your inbox {dateText}.  <span className="unsnooze" onClick={unsnooze}>Unsnooze?</span></p>
		</div>
	)
}

export default SnoozeNotice