import React from 'react';
import { HeaderAction } from '../components/common/header';
import Fetcher from './Fetcher';

export var Enrichable = Component => class extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			enriching: false,
			contact: props.contact,
			organization: props.organization,
			enriched: false,
			unenrichable: null
		}


		this.enrichableType = (props.contact ? 'contact' : 'organization')
	}

	getEnrichableObject = () => {
		return (this.enrichableType === 'contact' ? this.state.contact : this.state.organization);
	}

	enrich = () => {
		if (!this.getEnrichableObject().enriched) {
			this.setState({ enriching: true });

			return new Fetcher(localStorage.token).process('POST', `/${this.enrichableType}s/${this.getEnrichableObject().id}/enrich`).then((json) => {
				if (json.contact.enriched) {
					let contact = this.getEnrichableObject();
					contact.loadAttributes(json.contact);

					this.setState({ contact: contact, enriching: false, enriched: true });
				}
			}).catch(() => {
				this.setState({ enriching: false, unenrichable: true });
			});

		}
	}

	getUnenrichableNotice = () => {
		if (!this.state.unenrichable) { return null; }

		return (
			<div className="sub-bar warning" style={{ textAlign: 'left' }}>
				<i className="fa fa-warning" /> <strong className="minor">We couldn&#8217;t find any external data to enrich this contact.</strong> (There&#8217;s no charge for the attempt.)
			</div>
		)
	}

	getEnrichmentIcon = () => {
		if (this.state.enriching) {
			return 'spinner fa-spin';
		} else if (this.state.unenrichable) {
			return 'warning';
		} else {
			return 'plug'
		}
	}

	getEnrichmentActionButton = () => {
		if (!this.getEnrichableObject().enriched && !this.state.enriched && !this.state.unenrichable) {
		 return <HeaderAction onClick={this.enrich} icon={this.getEnrichmentIcon()} title={`Enrich this ${this.enrichableType}`} />;
		}
	}

	render() {
		return <Component
			{...this.props}
			enriched={this.state.enriched}
			unenrichable={this.state.unenrichable}
			enriching={this.state.enriching}
			getUnenrichableNotice={this.getUnenrichableNotice}
			getEnrichmentActionButton={this.getEnrichmentActionButton}
			enrich={this.enrich}
		/>;
	}
}
