import { useState, useEffect } from 'react';
import Row from '../Row';
import { orderBy } from 'lodash';

const Section = (props) => {
	const [sortField, setSortField] = useState(null)

	useEffect(() => {
		setSortField(getDefaultSortField())
	}, [])

	const heading = () => {
		let info = {
			unread: { icon: 'envelope', title: 'Unread emails' },
			flagged: { icon: 'flag', title: 'Flagged emails' },
			other: { icon: 'envelope-open', title: 'Other unresolved emails' }
		};

		return (
			<h3>
				<i className={`fa fa-${info[props.group].icon}`} />{info[props.group].title}
				<ul className="sort">
					<li className={(sortField === 'date' ? 'active' : '')} onClick={() => setAndStoreSortField('date')}><span><i className="fa fa-sort" />Date</span></li>
					<li className={(sortField === 'priority' ? 'active' : '')} onClick={() => setAndStoreSortField('priority')}><span><i className="fa fa-sort" />Priority</span></li>
				</ul>
			</h3>
		);
	}

	const setAndStoreSortField = (field) => {
		setSortField(field)

		localStorage.setItem(`inbox.${props.group}.sort`, field);
	}

	const getDefaultSortField = () => {
		let stored = localStorage.getItem(`inbox.${props.group}.sort`);

		return stored || (props.group === 'unread' ? 'date' : 'priority');
	}

	const sortedThreads = () => {
		return orderBy(props.threads, (sortField === 'date' ? 'last_sent_at' : ['priority', 'last_sent_at']), 'desc');
	}

	if (props.threads.length === 0) { return null; }

	return (
		<div className={"section " + props.group}>
			{heading()}
			<div className="email-table" key={`inbox.${props.group}.table.${sortField}`}>
				{sortedThreads().map(thread => {
					return <Row key={thread.id} thread={thread} />;
				})}
			</div>
		</div>
	)
}

export default Section