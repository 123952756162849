import Fetcher from '../lib/Fetcher';
import Resource from '../lib/Resource';
import File from './File';
import Thread from './Thread';
import Contact from './Contact';
import Organization from './Organization';

export default class Search extends Resource {
	constructor(attrs = {}) {
		super(attrs);

		this.setResource(['threads', 'contacts', 'files', 'organizations']);
		this.set('loaded', false);
		this.fetcher = new Fetcher(localStorage.token);
	}

	setQuery(query) {
		this.set('query', query);
	}

	setResource(resource) {
		this.set('resource', (Array.isArray(resource) ? resource : [resource]));
	}

	setScope(scope) {
		this.set('scope', scope);
	}

	loadResults(json) {
		let results = json['search']['results'];
		let collection_classes = { files: File, threads: Thread, contacts: Contact, organizations: Organization }
		Object.keys(results).forEach((collection) => {
			results[collection]['records'] = results[collection]['records'].map((record) => {
				return new collection_classes[collection](record);
			})
		})

		this.set('query', json['search']['query']);
		this.set('loaded', true);
		this.set('results', results);
	}

	execute() {
		return this.fetcher.process('POST', '/search', { query: this.query, resource: this.resource, scope: this.scope, limit: this.limit }).then((json) => {
			this.loadResults(json);
			return this;
		});
	}

	noResults() {
		return this.totalResultCount() === 0;
	}

	totalResultCount() {
		let count = 0

		Object.keys(this.getResults()).forEach((collection) => {
			count += this.getResults()[collection]['total_results'];
		});

		return count;
	}

	getResults() {
		return this.results;
	}
}

Search.fields = ['query', 'scope', 'loaded', 'limit']
Search.modelName = 'Search';