import useFormInput from './useFormInput'

export const Select = (props) => {
	const formInput = useFormInput(props)

	let defaultValue;
	if (formInput.valueFor(props.name)) {
		defaultValue = formInput.valueFor(props.name);
	} else if (props.defaultValue) {
		defaultValue = props.defaultValue;
		formInput.handleChange(props.name, defaultValue);
	}

	return (
		<select
			id={props.name}
			onChange={formInput.handleChange.bind(null, props.name)}
			defaultValue={defaultValue}
			disabled={(formInput.isDefaultValue && formInput.isDefaultValue(props.name) ? true : false)}
			className={props.className ? props.className : ''}
		>
			{props.children}
		</select>
	)
}