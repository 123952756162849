import moment from 'moment';

export default class Timeline {
	constructor(objects, dateField) {
		this.objects = objects;
		this.dateField = dateField;
	}

	objectsByDate() {
		if (!this._objectsByDate) {
			this._objectsByDate = {};
			Object.keys(this.objects).forEach(key => {
				let object = this.objects[key];
				let formattedDate = this.formattedDateForObject(object);

				if (!this._objectsByDate[formattedDate]) { this._objectsByDate[formattedDate] = []; }
				this._objectsByDate[formattedDate].push(object)
			});
		}

		return this._objectsByDate;
	}

	formattedDateForObject(object) {
		let date = (typeof this.dateField === "string" ? object[this.dateField] : this.dateField(object));

		return moment(date).format('YYYY-MM-DD');
	}
}