import { useState } from 'react'
import { Helmet } from 'react-helmet'
import SearchResultForm from '../common/SearchResultForm'
import MailboxScoper from '../common/MailboxScoper'
import { useRouter } from '../../lib/hooks'

const SearchResults = () => {
	const [scope, setScope] = useState(localStorage.scope || 'subscribed')
	const router = useRouter()

/*
	componentWillReceiveProps(nextProps) {
		if (this.props.scope !== nextProps.scope) {
			this.props.onLoadCompletion();
		}
	}
*/

	return (
		<div>
			<Helmet title="Search your emails" />
			<MailboxScoper onUpdate={(newScope) => setScope(newScope)} />
			<SearchResultForm scope={scope} resource="threads" query={router.query.q} router={router} />
		</div>
	);
}

export default SearchResults
//export default AddressBookScoper(SearchResults);