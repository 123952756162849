import { useState, useEffect } from 'react';
import { useAuth } from '../../lib/useAuth'
import { useResourceLoader } from '../../lib/useResourceLoader'
import { Helmet } from 'react-helmet';
import { Form } from '../../lib/forms/Form';
import { BooleanToggle } from '../../lib/forms/BooleanToggle';
import { Label } from '../../lib/forms/Label';
import { Submit } from '../../lib/forms/Submit';
import { Loading } from '../common/Common';
import { CurrentUserAccessor } from '../../lib/CurrentUserAccessor';

const Unlock = () => {
	const [globalizeContacts, setGlobalizeContacts] = useState(false)
	const [mailbox, setMailbox] = useState(null)
	const auth = useAuth()
	const resource = useResourceLoader('MailboxRepository')

	useEffect(() => {
		if (resource) {
			setMailbox(resource)
		}
	}, [resource])

	const setContactGlobalization = (_, val) => {
		setGlobalizeContacts(val)
	}

	const updateMailboxes = (mailbox) => {
/*
		loadUser().then(() => {
			router.push({ pathname: '/settings/mailboxes' });
		});
*/
	}

	const globalizeForm = () => {
		if (!mailbox.shared) {
			return (
				<table style={{ marginBottom: "10px" }}>
					<tr>
						<td><Label for="globalize_contacts">Add mailbox contacts to your team&#8217;s global contacts</Label></td>
						<td><BooleanToggle name="globalize_contacts" changeHandler={setContactGlobalization}/></td>
					</tr>
				</table>
			)
		}
	}

	const main = () => {
		return (
			<>
				<Helmet title={`Lock ${mailbox.name}`} />
				<div className="subheading">
					<i className="fa fa-rss green" />
					<div className="combined-heading">
						<h2>Unlock this mailbox</h2>
						<h3>{mailbox.name} ({mailbox.email_address})</h3>
					</div>
				</div>
				<Form object={mailbox} saveUrl={`/mailboxes/${mailbox.id}/unlock`} onSave={updateMailboxes} metaData={{ globalize_contacts: globalizeContacts }}>
					<div style={{ margin: 0, padding: "0 20px 20px 20px" }}>
						<p>This mailbox is currently locked.  If you unlock this mailbox, other users can subscribe to it or view its emails or files.</p>
					</div>

					<div className="main-content gray bordered" style={{ padding: "10px" }}>
						{mailbox && mailbox ? globalizeForm() : null}

						<div className="submit" style={{ margin: 0 }}>
							<Submit name="send" value="Unlock this mailbox" className="rounded medium green" />
						</div>
					</div>
				</Form>
			</>
		)
	}

	return (
		<div className="main small standalone no-sidebar">
			{mailbox ? main() : <Loading inline={true} />}
		</div>
	)
}

export default Unlock