import { useState, useEffect } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { useResourceLoader } from '../../lib/useResourceLoader'
import { useAuth } from '../../lib/useAuth'
import { useToggle } from '../../lib/useToggle'
import { useResourceViewRecorder } from '../../lib/useResourceViewRecorder'
import MilestoneRepository from '../../repositories/MilestoneRepository';
import Loading from '../common/Loading'
import View from './View'

const ViewContainer = () => {
	const resource = useResourceLoader('MilestoneRepository')
	const location = useLocation()
	const [milestone, setMilestone] = useState(null)
	const [taskCollection, setTaskCollection] = useState(null)
	const [counter, setCounter] = useState(0)
	const toggle = useToggle(resource, setMilestone)

	useEffect(() => {
		if (resource) {
			setMilestone(resource)
			setTaskCollection(resource.taskCollection())
		}
	}, [resource])

	const toggleArchive = () => {
		let updatedMilestone = milestone
		updatedMilestone.set('archived', !milestone.archived);

		new MilestoneRepository().save(updatedMilestone).then(newMilestone => setMilestone(newMilestone))
	}

	const addToMilestone = task => {
		taskCollection.addTask(task)
		setTaskCollection(taskCollection)
	}

	const toggleTaskCompletion = (task) => {
		taskCollection.toggleTaskCompletion(task)

		setCounter(counter + 1)
	}

	return milestone
		? <View
				milestone={milestone}
				taskCollection={taskCollection}
				toggleArchive={toggleArchive}
				addToMilestone={addToMilestone}
				toggleTaskCompletion={toggleTaskCompletion}
				toggle={toggle}
			/>
		: <Loading />
}

export default ViewContainer