import React from 'react';
/*import ReactSwipe from 'react-swipe';*/
import Masonry from 'react-masonry-css'
import Widget from './Widget';
import pluralize from 'pluralize';

const Widgets = ({ milestones, togglePin }) => {

	const desktop = () => {
		return (
			<Masonry breakpointCols={{ default: 3, 1300: 2, 900: 1 }} className="milestone-widgets" columnClassName="milestone-widget-column">
			  {milestones.map(milestone => {
			  	return <Widget key={milestone.id} relayout={() => { this.forceUpdate() }} milestone={milestone} togglePin={togglePin} />;
			  })}
			</Masonry>
		);
	}

	const mobile = () => {
		let controls = (
			<div className="mobile-only milestone-swipe-controls">
				<div className="control"><i className="fa fa-arrow-circle-left" onClick={() => this.swipe.prev()} /></div>
				<div className="title">{pluralize('pinned milestones', milestones.length, true)}</div>
				<div className="control"><i className="fa fa-arrow-circle-right" onClick={() => this.swipe.next()} /></div>
			</div>
		);

		return (
			<div>
				{milestones.length > 1 ? controls : null}
				{milestones.map(milestone => {
					return <div><Widget key={milestone.id} milestone={milestone} togglePin={togglePin} /></div>
				})}
			</div>
		)
	}

	if (window.isMobile) {
		return mobile()
	} else {
		return desktop()
	}
}

export default Widgets