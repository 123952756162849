import ApiError from './ApiError';

export default class Fetcher {
	constructor(token) {
		this.token = token;
	}

	get(url, body = {}) {
		return this.process('GET', url + '?' + this.queryStringify(body));
	}

	post(url, body = {}) {
		return this.process('POST', url, body);
	}

	patch(url, body = {}) {
		return this.process('PATCH', url, body);
	}

	delete(url, body = {}) {
		return this.process('DELETE', url, body);
	}

	stringifyWithoutCircularReferences(obj) {
		var cache = [];
		return JSON.stringify(obj, function(key, value) {
			if (typeof value === 'object' && value !== null) {
				if (cache.indexOf(value) !== -1) {
					// Circular reference found, discard key
					return;
				}

				// Store value in our collection
				cache.push(value);
			}
			return value;
		});
	}

	process(method, url, body = {}) {
		body = (body instanceof FormData ? body : this.stringifyWithoutCircularReferences(body))
		var headers = {
			'Accept': 'application/json',
			'Auth-Token': this.token
		}
		if (!(body instanceof FormData)) {
			headers['Content-Type'] = 'application/json';
		}

		let fullUrl = (process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_HOST + url : url)
		return fetch(fullUrl, {
			method: method,
			headers: headers,
			body: (method === 'GET' ? null : body)
		})
		.then(function(response) {
			let json = response.json();

			return json.then(res => {
				if (!response.ok) {
					throw new ApiError(res.error)
				} else {
					localStorage.accountVersion = response.headers.get('X-Account-Version');

					return json;
				}
			});
		})
	}

	queryStringify(obj, prefix) {
		let str = [], p;

		for (p in obj) {
			if (obj.hasOwnProperty(p)) {
				var k = prefix ? prefix + "[" + p + "]" : p,
					v = obj[p];
				str.push((v !== null && typeof v === "object") ?
					this.queryStringify(v, k) :
					encodeURIComponent(k) + "=" + encodeURIComponent(v));
			}
		}

		return str.join("&");
	}
}