import { useRouter, useAuth } from '../../lib/hooks'
import Row from './Row';

const Table = (props) => {
	const auth = useAuth()
	const router = useRouter()

	return (
		<div className="contact-table">
			{props.contacts.map((contact) => {
				return <Row key={contact.id} contact={contact} router={router} location={router.location} currentUser={auth.user} />;
			})}
		</div>
	)
}

export default Table