import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet';
import Pagination from '../common/Pagination';
import { useDefaultLens } from '../../lib/useDefaultLens'
import { Loading, NoResults } from '../common/Common'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import ListByDate from './ListByDate';
import { Header, PageIcon, HeaderActions, HeaderAction } from '../common/header';
import useCollectionLoader from '../../lib/useCollectionLoader'
import { useHotkeys } from 'react-hotkeys-hook'

const List = (props) => {
	useDefaultLens('tasks')
	const location = useLocation()
	const navigate = useNavigate()
	const [loaded, setLoaded] = useState(false)
	const [collection, filter, loading] = useCollectionLoader('TaskRepository', {}, () => { setLoaded(true) })
	useHotkeys('n', (e) => { navigate('/tasks/new', { state: { modal: true, backgroundLocation: location } }) })

	useEffect(() => {
	}, [collection])

	const results = () => {
		if (collection.isEmpty()) {
			if (collection.isFiltered()) {
				return <NoResults message="You have no matching incomplete tasks" subMessage={<p>You can <Link to={"/tasks/list"} className="gray pseudobutton">clear filters</Link> or try a less restrictive search.</p>} />;
			} else {
				return <NoResults congrats={true} message={<span>You&#8217;ve completed all your tasks!</span>} subMessage="Great work!  Your new tasks will be right here when you create them." />;
			}
		} else {
			return <ListByDate collection={collection} location={location} />;
		}
	}

	return (
		<div className="main full" key={'tasks.' + (collection && collection.hasSlice() ? collection.currentSlice() : 'all')}>
			<Helmet title={collection && collection.hasSlice() ? collection.currentSlice().name : 'Your tasks'} />
			<Header
				collection={collection}
				breadcrumb={collection && collection.hasSlice() ? <span>Tasks &amp; To-Do&#8217;s</span> : null}
				title={collection && collection.hasSlice() ? <span dangerouslySetInnerHTML={{ __html: collection.currentSlice().name }}></span> : <span>Keep track of your tasks<span className="desktop-only"> and to-do&#8217;s</span></span>}
			>
				<PageIcon icon={collection && collection.hasSlice() ? collection.currentSlice().icon : "check"} toggleSidebarOnMobile />
				<HeaderActions>
					<HeaderAction icon="plus" title="Add a task" linkTo={{ pathname: '/tasks/new', state: { modal: true, returnTo: location.pathname, backgroundLocation: location } }} />
				</HeaderActions>
			</Header>
			<div className="main-content">
				<div id="task-list">
					{collection ? results() : <Loading inline={true} />}
				</div>
				{collection ? <Pagination collection={collection} filter={filter} location={location} /> : null}
			</div>
		</div>
	)
}

export default List;