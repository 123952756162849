const HeaderFilter = (props) => {
	if (props.collection) {
		return (
			<div className={"collapsible filter-container" + (props.visible ? "" : " collapsed")}>
				{props.children}
			</div>
		);
	}
}

HeaderFilter.displayName = 'HeaderFilter'

export default HeaderFilter