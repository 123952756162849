import Resource from '../lib/Resource';

export default class Upload extends Resource {
	serializedAttributes() {
		var data = new FormData();
		data.append('file', this.file);

		return data;
	}
}

Upload.fields = ['id'];
Upload.url = '/uploads';
Upload.belongsTo = [{ polymorphic: true, as: 'record' }];
Upload.scopedTo = 'record';