const Location = ({ location }) => {
	const line1 = () => {
		return <div key="line1">{[location.street_number, location.street].join(' ')}</div>;
	}

	const line2 = () => {
		return <div key="line2">{location.apartment}</div>;
	}

	const line3 = () => {
		return <div key="line3">{[location.city, location.state].join(', ')} {location.zip_code}</div>
	}

	const line4 = () => {
		return <div key="line4">{location.country}</div>
	}

	if (!location || Object.keys(location.getAttributes()).length === 0) { return null; }

	return (
		<div className="address" key="address">
			{[line1(), line2(), line3(), line4()]}
		</div>
	)
}

export default Location