import { useState } from 'react';
import { useAuth } from '../../../lib/useAuth'
import OutsideClickHandler from '../../common/OutsideClickHandler';
import pluralize from 'pluralize';
import { intersection } from 'lodash';

const OwnershipBar = (props) => {
	const [showMailboxSwitcher, setShowMailboxSwitcher] = useState(false)
	const auth = useAuth()

	const overview = () => {
		let otherCount = props.contact.interaction_histories.length - 1;

		return (
			<div className="overview">
				<strong>This is your personal contact</strong>
				<div className="etc normal-size">{pluralize('other people', otherCount, true)} {otherCount > 1 ? 'have' : 'has'} interacted with this contact.</div>
			</div>
		);
	}

	const setMailboxScope = mailbox => {
		if (props.scopedMailbox === mailbox) {
			setShowMailboxSwitcher(!showMailboxSwitcher)
		} else {
			setShowMailboxSwitcher(false)
			props.setMailboxScope(mailbox);
		}
	}

	const mailboxSwitcher = () => {
		if (props.contact.hasMultipleMailboxInteractions()) {

			return (
				<OutsideClickHandler onOutsideClick={() => setShowMailboxSwitcher(false)}>
					<div className={"mailbox-switcher" + (showMailboxSwitcher ? " show-all" : "")}>
						{subscribedMailboxesSwitcherRow()}
						{props.contact.interaction_histories.map(history => switcherMailboxRow(auth.user.getMailboxById(history.mailbox_id)))}
					</div>
				</OutsideClickHandler>
			);
		}
	}

	const subscribedMailboxesSwitcherRow = () => {
		let contactMailboxesSubscribedByUser = intersection(props.contact.interaction_histories.map(history => history.mailbox_id), auth.user.subscribedMailboxIds());

		if (contactMailboxesSubscribedByUser.length > 1) {
			return (
				<div className={"mailbox" + (props.scopedMailbox === null ? " active" : "")} onClick={setMailboxScope.bind(null, null)}>
					<div>
						<strong>Your subscribed mailboxes</strong>
						<div className="etc">{pluralize('subscribed mailboxes', contactMailboxesSubscribedByUser.length, true)}</div>
					</div>
					<i className="fa fa-caret-down" />
				</div>
			);
		}
	}

	const switcherMailboxRow = mailbox => {
		return (
			<div key={mailbox.id} className={"mailbox" + (props.scopedMailbox === mailbox ? " active" : "")} onClick={setMailboxScope.bind(null, mailbox)}>
				<div>
					<strong>{mailbox.name}</strong>
					<div className="etc">{mailbox.email_address}</div>
				</div>
				{props.contact.hasMultipleMailboxInteractions() ? <i className="fa fa-caret-down" /> : null}
			</div>
		);
	}

	const ownershipIndicator = () => {
		switch ('global') {
			case 'personal':
				return <i className="fa fa-lock-alt" />;
			case 'shared':
				return <i className="fal fa-rss" />;
			case 'global':
				return <i className="fal fa-globe" />;
			default:
				return null;
		}
	}

	if (props.contact.hasMultipleMailboxInteractions()) {
		return (
			<div className="contact-ownership">
				<div className={"ownership-indicator " + props.contact.type}>{ownershipIndicator()}</div>

				{overview()}

				{mailboxSwitcher()}
			</div>
		);
	}
}

export default OwnershipBar