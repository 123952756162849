import React from 'react';
import { Filterable } from '../../lib/Filterable';
import { CurrentUserAccessor } from '../../lib/CurrentUserAccessor';
import Avatar from '../contacts/contact/Avatar';

class Assigner extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showOptions: true,
			chosenUsers: props.userLookup(props.account)
		}

		this.props.filter.setSearchFields(['first_name', 'last_name', 'email_address'])
		this.props.filter.loadOptions(this.props.account.users);//.filter(user => user.id !== this.props.currentUser.id));
		this.props.onSelect(this.selectListOption);
	}

	selectListOption = (user) => {
		let chosenUsers = this.state.chosenUsers;
		chosenUsers.push(user);
		this.props.filter.removeOption(user);

		this.setState({ chosenUsers }, () => {
			this.props.setChosenUsers(chosenUsers);
		})
	}

	matches = () => {
		if (this.props.filterMatches.length === 0) { return null; }

		return (
			<div className="content-block white" onMouseDown={this.mouseDownHandler} onMouseUp={this.mouseUpHandler}>
				<ul className="table badged-users backgroundless borderless" style={{ minWidth: "150px" }}>
					{this.props.filterMatches.map(user => {
						return (
							<li key={"user." + user.id} onClick={this.selectListOption.bind(null, user)}>
								<div className={"block pointer" + (this.props.active === user ? " active" : "")}>
									<div className="user">
										<div className="avatar-container"><Avatar contact={user} inline={true} /></div>
										<div className="name">{user.displayName()}</div>
									</div>
								</div>
							</li>
						);
					})}
				</ul>
			</div>
		);
	}

	unassign = user => {
		let chosenUsers = this.state.chosenUsers.filter(chosen => chosen !== user);

		this.setState({ chosenUsers }, () => {
			this.props.setChosenUsers(chosenUsers);
			this.props.filter.addOption(user);
		})
	}

	render() {
		return (
			<div>
				<div className="chosen-users-inline">
					{this.state.chosenUsers.map(user => {
						return (
							<div className="user">
								<div className="avatar-container"><Avatar contact={user} inline={true} /></div>
								<div className="name">{user.displayName()}</div>
								<div className="unshare"><i className="fa fa-times-circle" onClick={this.unassign.bind(null, user)} /></div>
							</div>
						);
					})}
				</div>
				<div className="inline-block">
					<input type="text" placeholder={this.props.placeholder || "Choose a user"} onFocus={this.showOptions} onBlur={this.toggle} onChange={this.props.updateQuery} onKeyDown={this.props.onKeyDown} ref="filterInput" autoFocus />
					{this.matches()}
				</div>
			</div>
		);
	}
}

export default CurrentUserAccessor(Filterable(Assigner));