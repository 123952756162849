import Resource from '../lib/Resource';

export default class Organization extends Resource {
	getSortedContacts() {
		if (this.contacts && this.contacts.length > 0) {
			return this.contacts.sort((a, b) => {
				let transform = (contact) => {
					return contact.name ? `\t${contact.name.toLowerCase()}` : contact.primaryEmailAddress().toLowerCase();
				}

				return (transform(a) > transform(b) ? 1 : -1);
			})
		} else { return []; }
	}

	displayName() {
		return this.name;
	}

	hasDescription() {
		return (this.description && this.description !== '');
	}
}

Organization.fields = [
	'id', 'name', 'description', 'domain', { logo: 'Logo' }, 'organization_id', { contacts: ['Contact'] }, 'contact_count',
	'file_count', 'thread_count', 'task_count', 'pinned', 'has_duplicates', { comments: ['Comment'] }
];
Organization.editableFields = [
	'name', 'description', 'domain', 'logo', 'organization_id'
]
Organization.validations = {
	name: 'present'
}
Organization.modelName = 'Organization';