import { useState, useEffect } from 'react'
import * as Repositories from '../repositories'

const useInPlaceFilter = (parentRecord, repositoryClass, inPlaceFilters) => {
	const repository = new Repositories[repositoryClass]()
	const [activeFilters, setActiveFilters] = useState([])
	const [processingFilters, setProcessingFilters] = useState([])
	const [loading, setLoading] = useState(true)
	const [loaded, setLoaded] = useState(false)
	const [initialRecords, setInitialRecords] = useState([])
	const [collection, setCollection] = useState(null)
	const [records, setRecords] = useState([])
	const [fullCollectionLoaded, setFullCollectionLoaded] = useState(null)

	useEffect(() => {
		loadRecords().then((collection) => {
			setInitialRecords(collection.records)
			setFullCollectionLoaded(collection.isComplete())
		})
	}, [])

	useEffect(() => {
		executeFilter()
	}, [activeFilters])

	const loadRecords = (filters = {}) => {
		return repository.getCollection(filters, `${parentRecord.getPath()}${repository.baseUrl}`).then((collection) => {
			setLoading(false)
			setLoaded(true)
			setCollection(collection)
			setProcessingFilters([])

			return collection
		})
	}

	const toggleFilter = (filter, value) => {
		setActiveFilters(activeFilters.includes(filter) ? activeFilters.filter(f => f !== filter) : [...activeFilters, filter])
		setProcessingFilters(processingFilters.includes(filter) ? processingFilters.filter(f => f !== filter) : [...processingFilters, filter])
	}

	const search = query => {
		setProcessingFilters(['query'])
		loadRecords({ query: query })
		setProcessingFilters([])
	}

	const executeFilter = () => {
		if (fullCollectionLoaded) {
			filterInPlace()

			setProcessingFilters([])
		} else {
			let filters = {};
			activeFilters.forEach(filter => {
				filters[filter] = true
			})

			loadRecords(filters)
		}
	}

	const filterInPlace = () => {
		let filteredRecords = [...initialRecords]

		activeFilters.forEach(activeFilter => {
			if (inPlaceFilters[activeFilter]) {
				filteredRecords = filteredRecords.filter(record => {
					return inPlaceFilters[activeFilter](record, activeFilter, parentRecord)
				});
			}
		});

		const filteredCollection = collection
		filteredCollection.setRecords(filteredRecords)
		setCollection(filteredCollection)
	}

	return [
		collection, {
			activeFilters,
			processingFilters,
			toggleFilter,
			executeFilter,
			search
		}
	]
}

export default useInPlaceFilter
