import { useState, useEffect } from 'react'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import { useResourceLoader } from '../../lib/useResourceLoader'
import { useAuth } from '../../lib/useAuth'
import { useRouter } from '../../lib/useRouter'
import { Helmet } from 'react-helmet';
import { Loading } from '../common/Common';
import Form from './Form';
import { useHotkeys } from 'react-hotkeys-hook'

const Edit = () => {
	const [organization, setOrganization] = useState(null)
	const resource = useResourceLoader('OrganizationRepository')
	const location = useLocation()
	const auth = useAuth()
	const navigate = useNavigate()
	const router = useRouter()

	const goBack = () => {
		if (router) {
			navigate(`/organizations/${router.query.id}`)
		}
	}
	useHotkeys('esc', () => navigate(`/organizations/${router.query.id}`))

	useEffect(() => {
		if (resource) setOrganization(resource)
	}, [resource])

	return (
		<div className="main mini headingless standalone">
			<Helmet title="Add a new organization" />
			<div className="subheading gradient">
				<i className="fa fa-building" />
				<h2>Edit this organization</h2>
				{
					organization
					? <span onClick={() => navigate(`/organizations/${organization.id}`, { state: { organization: organization } })} className="close"><i className="fa fa-times" /></span>
					: null
				}
			</div>
			<div className="main-content">
				<div className="main-form gray full-width new-form standalone">
					{organization ? <Form organization={organization} router={router} /> : <Loading inline={true} />}
				</div>
			</div>
		</div>
	)
}

export default Edit