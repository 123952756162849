import useFormInput from './useFormInput'

export const HiddenField = (props) => {
	const formInput = useFormInput(props)

	let defaultValue = formInput.valueFor(props.name);
	if ((defaultValue === null || defaultValue === undefined) && props.value) {
		defaultValue = props.value;
	}

	return (
		<input type="hidden"
			{...props}
			ref={props.inputRef}
			defaultValue={defaultValue}
			id={props.name}
			onChange={formInput.handleChange.bind(null, props.name)}
			className={formInput.classes()}
		/>
	)
}