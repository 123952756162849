import { useState, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useAuth } from '../../lib/useAuth';
import { Loading } from '../common/Common';
import AccountRepository from '../../repositories/AccountRepository';
import moment from 'moment';
import { toCurrency } from '../../lib/utilities/CommonUtilities';

const View = (props) => {
	const [invoice, setInvoice] = useState(null)
	const [billingAccount, setBillingAccount] = useState(null)
	const location = useLocation()
	const params = useParams()
	const auth = useAuth()

	useEffect(() => {
		if (location.state && location.state.invoice) {
			setInvoice(location.state.invoice)
		} else {
			new AccountRepository().find(auth.account.id).then(account => {
				setBillingAccount(account.billing_account)

				setInvoice(account.billing_account.invoices.find(invoice => invoice.id == params.id))
			})
		}
	}, [props])

	const rowsFor = (section, info) => {
		if (!invoice.items[section].quantity > 1) { return null; }
		let item = invoice.items[section];

		return (
			<>
				<tr className="item-group">
					<td colSpan="4">
						<i className={`fal fa-${info.icon}`} />
						Additional {info.name}
					</td>
				</tr>
				<tr>
					<td>
						{item.proration ? <span className="proration-badge">Prorated</span> : null}
						{moment(item.period_start).format('MMMM D, YYYY')} to {moment(item.period_end).format('MMMM D, YYYY')}
					</td>
					<td>{item.quantity - 1}</td>
					<td>{item.amount ? toCurrency(item.amount) : <>&mdash;</>}</td>
					<td>{toCurrency(item.amount)}</td>
				</tr>
			</>
		);
	}

	const invoiceDetails = () => {
		let sections = { mailboxes: { name: 'Mailboxes', icon: 'inbox-in' }, users: { name: 'Users', icon: 'users' } };
		let subscription = invoice.items.subscription;

		return (
			<div className="main-content gray invoice-section">
				<table>
					<thead>
						<tr>
							<th width="50%">Description</th>
							<th width="15%">Quantity</th>
							<th width="15%">Unit $</th>
							<th width="20%">Total</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>
								Basic subscription
								<p className="etc">Includes one user and mailbox</p>
							</td>
							<td>1</td>
							<td>{subscription.unit_rate ? toCurrency(subscription.unit_rate) : <>&mdash</>}</td>
							<td>{subscription.amount ? toCurrency(subscription.amount) : <>&mdash;</>}</td>
						</tr>
						{Object.keys(sections).map(section => rowsFor(section, sections[section]))}
					</tbody>
					<tfoot>
						<tr>
							<td colSpan="3"></td>
							<td>{toCurrency(invoice.amount_due)}</td>
						</tr>
					</tfoot>
				</table>
			</div>
		);
	}

	const paymentBadge = () => {
		if (invoice.paid) {
			return <strong class="payment-badge paid">Paid</strong>;
		} else {
			return <strong class="payment-badge upcoming">Upcoming</strong>
		}
	}

	if (!invoice) { return <div className="main mini standalone center"><div className="main-content"><Loading inline={true} /></div></div> }

	return (
		<div className="main medium standalone">
			<div className="subheading">
				{paymentBadge()}
				<h2>
					<strong>Invoice</strong> for {moment(invoice.period_start).format('MMMM D, YYYY')} to {moment(invoice.period_end).format('MMMM D, YYYY')}
				</h2>
			</div>

			{invoiceDetails()}
		</div>
	)
}

export default View