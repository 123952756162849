import Row from './Row';

const Table = ({ threads }) => {
	return (
		<div className="email-table">
			{threads.map(thread => {
				return <Row key={thread.id} thread={thread} />;
			})}
		</div>
	)
}

export default Table