import React from 'react';
//var Dropzone = require('react-dropzone');

const Avatar = ({ contact, inline }) => {
	if (contact && contact.avatar && contact.avatar.exists()) {
		return (
			<div className={"avatar" + (inline ? " inline" : "")}>
				<img src={contact.avatar.source_url} alt="Contact avatar" />
			</div>
		)
	} else {
		return (
			<div className={"generic avatar" + (inline ? " inline" : "")}>
				<span className="initials-overlay">{contact.getInitials ? contact.getInitials() : ''}</span>
				<i className="fa fa-user-circle green " />
			</div>
		)
	}
}

Avatar.displayName = 'Avatar'

export default Avatar