import React, { useState, useEffect } from 'react';
import ImagePreview from './ImagePreview'
import PDFPreview from './PDFPreview'

const Preview = ({ file, download }) => {
	const [showPreview, setShowPreview] = useState(null)
	const fileExtensionMap = {
		image: ['jpg', 'jpeg', 'gif', 'bmp', 'png'],
		pdf: ['pdf']
	}
	const previewElements = {
		image: ImagePreview,
		pdf: PDFPreview
	}

	const fileTypeIsPreviewable = (extension) => {
		for (const fileType of Object.keys(fileExtensionMap)) {
			if (fileExtensionMap[fileType].includes(extension)) {
				return true
			}
		}

		return false
	}

	const previewClassForFileType = (extension) => {
		for (const fileType of Object.keys(fileExtensionMap)) {
			if (fileExtensionMap[fileType].includes(extension)) {
				return previewElements[fileType]
			}
		}
	}

	const previewWrapper = () => {
		const PreviewComponent = previewClassForFileType(file.extension)

		return (
			<div className="preview">
				<div className="info-bar">
					<strong>Preview of this file</strong>
					<a href={file.downloadUrl()} className="tiny rounded green button"><i className="fa fa-download" />Download</a>
				</div>

				<PreviewComponent file={file} />
			</div>
		);
	}

	const unpreviewableMessage = () => {
		return (
			<div className="unpreviewable">
				<i className="fa fa-times" />
				<h2>We can&#8217;t preview this file</h2>
				<p>This file type is not supported for previewing, but you can <a className="gray pseudobutton" onClick={download}>download the file</a></p>
			</div>
		)
	}

	return fileTypeIsPreviewable(file.extension) ? previewWrapper() : unpreviewableMessage()
}

export default Preview