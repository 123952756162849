import React from 'react';
import { Link } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { CurrentUserAccessor } from '../../lib/CurrentUserAccessor';
import ViewNotification from './View';
import { NotificationListHandler } from '../../lib/NotificationListHandler';
import OutsideClickHandler from '../common/OutsideClickHandler';

class SidebarPanel extends React.Component {
	constructor(props) {
		super(props);

		this.state = { notifications: this.props.user.notification_inbox.latest_notifications };
	}

	componentWillUnmount() {
		this.props.markAsRead(this.props.notifications);
	}

	dismissVisible = () => {
		this.props.dismiss(this.props.notifications);
	}

	dismiss = (dismissable) => {
		this.props.dismiss(dismissable)
	}

	dismissAll = () => {
		this.props.dismissAll();

		this.props.toggle()
	}

	render() {
		let dismissalButtons = [<span className="text-button" onClick={this.dismissAll}>clear all {this.props.notifications.length <= 10 ? null : this.props.currentUser.notification_inbox.new_count}</span>];
		if (this.props.currentUser.notification_inbox.new_count > 10) {
			dismissalButtons.push(<span className="text-button" onClick={this.dismissVisible}>clear these {this.props.notifications.length}</span>);
		}

		return (
			<OutsideClickHandler onOutsideClick={this.props.toggle}>
				<div className="pop-under-form side bottom notification-tray">
					<div className="notification-heading">
						<Link to={{ pathname: "/notifications", state: { modal: true, returnTo: '/', router: this.props.router } }}><strong>Notifications</strong></Link>
						{dismissalButtons}
					</div>
					<div className="notifications">
						<TransitionGroup>
							{this.props.notifications.map(notification => {
								<CSSTransition classNames="notifications" timeout={{ enter: 2000, exit: 1500 }}>
									<ViewNotification key={`sidebar.notification.${notification.id}`} notification={notification} dismiss={this.dismiss} />
								</CSSTransition>
							})}
						</TransitionGroup>
					</div>
				</div>
			</OutsideClickHandler>
		);
	}
}

export default CurrentUserAccessor(NotificationListHandler(SidebarPanel));