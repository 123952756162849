import { useState, useEffect } from 'react';
import SessionRepository from '../../repositories/SessionRepository'
import Session from '../../resources/Session';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../lib/useAuth';
import { Form } from '../../lib/forms/Form';
import { TextInput } from '../../lib/forms/TextInput';
import { HiddenField } from '../../lib/forms/HiddenField';
import { PasswordInput } from '../../lib/forms/PasswordInput';
import { Submit } from '../../lib/forms/Submit';

const New = () => {
	const [processing, setProcessing] = useState(false)
	const [errors, setErrors] = useState({})
	const [authError, setAuthError] = useState(false)
	const [secondFactorRequired, setSecondFactorRequired] = useState(false)
	const [secondFactorError, setSecondFactorError] = useState(false)
	const [session, setSession] = useState(new Session({}))
	const [secondFactorCode, setSecondFactorCode] = useState('')
	const auth = useAuth()
	const navigate = useNavigate()

	useEffect(() => {
		if (auth.authenticated) {
			const timer = setTimeout(() => {
			  navigate('/')
			}, 5000)

			return () => clearTimeout(timer)
		}
	}, [])

	const handleNewSession = async (session) => {
		if (session.token) {
			localStorage.setItem('token', session.token.key);

			await auth.loadUser()

			navigate('/')
		}
	}

	const handleError = (error, session) => {
		if (error.code === 'invalid_second_factor_code') {
			setAuthError(false)
			setSecondFactorError(true)
		} else if (error.code === 'second_factor_required') {
			setAuthError(false)
			setSecondFactorRequired(true)
			setSecondFactorError(false)
		} else {
			setAuthError(true)
		}
	}

	const unauthenticatedMessage = () => {
		return (
			<div className="error">
				You entered an incorrect email address and/or password. Please try again.
			</div>
		);
	}

	const invalidTokenRemovedMessage = () => {
		return (
			<div className="error">Your previous login is invalid; please sign in again.</div>
		);
	}

	const passwordForm = () => {
		return (
			<>
				<TextInput name="email_address" autoFocus className="full large" placeholder="Your email address" autoCorrect="off" autoCapitalize="off" tabIndex="1" />
				<div className="password-row">
					<PasswordInput name="password" className="full large" placeholder="Your password" tabIndex="2" />
					<Link to="/reset-password" tabIndex="3">Forgot?</Link>
				</div>
			</>
		)
	}

	const secondFactorForm = () => {
		return (
			<div>
				<label id="second_factor_code">Enter your second factor code:</label>
				<HiddenField name="email_address" />
				<HiddenField name="password" />
				<TextInput name="second_factor_code" changeHandler={(_, e) => setSecondFactorCode(e.target.value)} autoFocus maxLength={/\d/.test(secondFactorCode.charAt(0)) ? 6 : 10} className="full large"  autoCorrect="off" tabIndex="1" style={{ fontSize: '32px', color: '#86a8d6', textAlign: 'center', letterSpacing: '16px', fontWeight: 800 }} />
			</div>
		)
	}

	const invalidSecondFactorMessage = () => {
		return (
			<div className="error">Your second factor code is invalid; please try again.</div>
		);
	}

	if (auth.authenticated) {
		return (
			<div className="main mini standalone">
				<div className="subheading">
					<i className="fa fa-id-badge" />
					<h2>You are signed in as {auth.user.first_name} {auth.user.last_name}</h2>
				</div>
				<div className="main-content gray">
					<p>If you would like to sign in with another account, please <span className="text-button red" onClick={auth.signOut}>sign out</span> first.</p>

					<p>Otherwise, you will be redirected in a few seconds.</p>
				</div>
			</div>
		)
	} else {
		return (
			<div className="main mini standalone">
				<div className="subheading">
					<i className="fa fa-sign-in" />
					<h2>Sign in</h2>
					<span className="side-button"><Link to="/mailboxes/new" className="header-side-link">sign up for a new account</Link></span>
				</div>
				<div className="main-content gray">
					{/*this.props.invalidToken ? invalidTokenRemovedMessage() : null*/}
					{authError ? unauthenticatedMessage() : null}
					{secondFactorError ? invalidSecondFactorMessage() : null}
					<div className="main-form sign-in">
						<Form repository={new SessionRepository()} object={session} beforeSubmit={(session) => setSession(session)} onSave={handleNewSession} onError={handleError}>
							{secondFactorRequired ? secondFactorForm() : passwordForm()}
							<div className="submit">
								<Submit name="send" value="Sign in to your account" className="rounded medium green" />
							</div>
						</Form>
					</div>
				</div>
			</div>
		)
	}
}

export default New;