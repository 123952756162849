import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useLocation } from 'react-router-dom'
import { useCollectionLoader, useDefaultLens } from '../../lib/hooks'
import MilestoneRepository from '../../repositories/MilestoneRepository'
import { Loading } from '../common/Common'
import { NoResults } from '../common/Common'
import { Header, PageIcon, HeaderActions, HeaderAction } from '../common/header'
import MobileIndex from './Index.mobile'
import DesktopIndex from './Index.desktop'

const Index = (props) => {
	const [pinnedMilestones, setPinnedMilestones] = useState([])
	const [unpinnedMilestones, setUnpinnedMilestones] = useState([])
	const [archivedMilestones, setArchivedMilestones] = useState([])
	const [others, setOthers] = useState([])
	const location = useLocation()
	const [collection, filter] = useCollectionLoader('MilestoneRepository', {}, () => {})
	useDefaultLens('tasks')

	useEffect(() => {
		if (collection) {
			setPinnedMilestones(collection.pinnedMilestones())
			setUnpinnedMilestones(collection.unpinnedMilestones())
			setArchivedMilestones(collection.archivedMilestones())
		}
	}, [collection])

	const togglePin = (milestone) => {
		milestone.set('pinned', !milestone.pinned)
		new MilestoneRepository().save(milestone)

		if (milestone.pinned) {
			let pinned = pinnedMilestones
			pinned.push(milestone)

			setUnpinnedMilestones(unpinnedMilestones.filter(m => { return m.id !== milestone.id }))
			setPinnedMilestones(pinned)
		} else {
			let unpinned = unpinnedMilestones
			unpinned.push(milestone)

			setUnpinnedMilestones(unpinned)
			setPinnedMilestones(pinnedMilestones.filter(m => { return m.id !== milestone.id }))
		}
	}

	const loaded = () => {
		let visibleTypes = {}
		if (pinnedMilestones.length > 0) { visibleTypes.pinnedMilestones = pinnedMilestones }
		if (unpinnedMilestones.length > 0) { visibleTypes.unpinnedMilestones = pinnedMilestones }
		if (archivedMilestones.length > 0) { visibleTypes.archivedMilestones = pinnedMilestones }


		if (visibleTypes.length === 0) {
			return <NoResults
				message="You haven&#8217t created any milestones yet."
				subMessage={<span>You can <Link to={{ pathname: "/milestones/new", state: { modal: true, returnTo: this.props.location.pathname } }} className="gray pseudobutton">add a new milestone</Link> to organize and track related tasks.</span>}
			/>
		} else {
			let params = {
				visibleTypes: visibleTypes,
				togglePin: togglePin,
				unpinnedMilestones: unpinnedMilestones,
				pinnedMilestones: pinnedMilestones,
				archivedMilestones: archivedMilestones,
				...props
			}

			return window.isMobile ? <MobileIndex {...params}  /> : <DesktopIndex {...params} />
		}
	}

	return (
		<div className="main full">
			<Helmet title="Your milestones" />
			<Header
				title="Your major projects and milestones"
			>
				<PageIcon icon="map-signs" toggleSidebarOnMobile />
				<HeaderActions>
					<HeaderAction linkTo={{ pathname: "/milestones/new", state: { modal: true, returnTo: location.pathname, backgroundLocation: location } }} icon="plus" />
				</HeaderActions>
			</Header>
			<div className="main-content">
				{collection ? loaded() : <Loading inline={true} />}
			</div>
		</div>
	)
}

export default Index