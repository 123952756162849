import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import User from '../resources/User'

const AuthContext = React.createContext({
	user: null,
	account: null
});

export const AuthProvider = (props) => {
	const [user, setUser] = useState(null)
	const navigate = useNavigate()
	const hasToken = !!localStorage.token
	const [authenticating, setAuthenticating] = useState(!!hasToken)

	const authenticate = () => {
		if (hasToken) {
			return loadUser()
		}
	}

	const loadUser = async () => {
		try {
			let loadedUser = await User.loadFromToken(localStorage.token)

			setUser(loadedUser)

			return loadedUser
		} catch (e) {
			// We have an old token, probably, so delete it and the user will be prompted to sign in again
			localStorage.removeItem('token')
		}
	}

	const signOut = () => {
		setUser(null)
		localStorage.clear()
		navigate('/sessions')
	}

	useEffect(() => {
		if (hasToken) {
			async function fetchUser() {
				let user = await loadUser()

				setUser(user)
				setAuthenticating(false)
			}

			fetchUser()
		}
	}, [])

	if (!authenticating) {
		return (
			<AuthContext.Provider value={{
					authenticated: (!!user),
					user: user,
					authenticate: authenticate,
					loadUser: loadUser,
					account: (user ? user.account : null),
					setUser: setUser,
					signOut: signOut
				}}
				{...props}
			/>
		)
	}
};

export const useAuth = () => {
	const context = useContext(AuthContext);

	if (context === undefined) {
		throw new Error('useAuth must be used within an AuthProvider');
	}
	return context;
};
