import React from 'react';

const Websites = ({ contact }) => {
	if (!contact.websites) { return null; }

	return (
		<div className="websites">
			{contact.websites.map(function(website) {
				return (
					<a key={website.url} href={website.fullUrl()} target="_blank" className="website">
						{website.title
							? [website.title, <div className="etc">{website.url}</div>]
							: website.url
						}
					</a>
				);
			})}
		</div>
	)
}

export default Websites