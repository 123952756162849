import { useState } from 'react';
import Widgets from './Widgets';
import Row from './Row';
import { NoResults } from '../common/Common'

const DesktopIndex = (props) => {
	const [others, setOthers] = useState('unpinned')

	const list = () => {
		if (props.unpinnedMilestones.length + props.archivedMilestones.length > 0) {
			return (
				<div className="other-milestones">
					<ul className="sections small" style={{ float: "right", marginTop: "-2px", boxShadow: "0 0 10px #f2f2f2" }}>
						<li><span onClick={() => setOthers('unpinned')} className={"console-button" + (others === 'archived' ? '' : ' active')}><i className="far fa-sync-alt" />Active ({props.unpinnedMilestones.length})</span></li>
						<li><span onClick={() => setOthers('archived')} className={"console-button" + (others === 'archived' ? ' active' : '')}><i className="far fa-lock-alt" />Archived ({props.archivedMilestones.length})</span></li>
					</ul>
					<h2>
						Your other milestones
					</h2>
					<ul className="table milestone-table">
						{(others === 'archived' ? props.archivedMilestones : props.unpinnedMilestones).map(milestone => {
							return <Row key={milestone.id} milestone={milestone} togglePin={props.togglePin} />;
						})}
					</ul>
				</div>
			);
		}
	}

	if (props.pinnedMilestones.length + props.unpinnedMilestones.length + props.archivedMilestones.length == 0) {
		return <NoResults message={<span>You don&#8217;t have any active milestones</span>} subMessage={<span>When you do, we&#8217;ll show them here!</span>} />
	}

	return (
		<div>
			{
				props.pinnedMilestones && props.pinnedMilestones.length > 0
				? <Widgets milestones={props.pinnedMilestones} togglePin={props.togglePin} location={props.location} />
				: null
			}
			{list()}
		</div>
	)
}

export default DesktopIndex


