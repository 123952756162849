import { useState } from 'react';
import { useRouter } from '../../lib/useRouter'
import queryString from 'query-string';

const Pagination = (props) => {
	const [loading, setLoading] = useState(null)
	const router = useRouter()

/*
	componentWillReceiveProps(nextProps) {
		if (nextProps.collection && props.collection && nextProps.collection.page !== props.collection.page) {
			setState({ loading: null });
		}
	}
*/
	const previous = () => {
		goToPage(props.collection.page - 1);
	}

	const next = () => {
		goToPage(props.collection.page + 1);
	}

	const goToPage = (page) => {
		let filters = Object.assign(props.collection.getPersistentFilters(), { page: page });

		setLoading(props.collection.page > page ? 'previous' : 'next')

		props.filter(filters, () => {
			if (!props.maintainUrl) {
				props.router.push({ pathname: props.router.location.pathname, search: '?' + queryString.stringify(filters) });
			}
		});

/*
		setState({ loading: props.collection.page > page ? 'previous' : 'next' }, () => {
			props.filter(filters, () => {
				if (!props.maintainUrl) {
					props.router.push({ pathname: props.router.location.pathname, search: '?' + queryString.stringify(filters) });
				}
			});
		})
*/
	}

	const nextButton = () => {
		if (props.collection.page !== props.collection.pages) {
			return (
				<li>
					<a onClick={next}>
						{loading === 'next' ? <i className="fa fa-spinner fa-spin" /> : <div><span>Next</span><i className="fa fa-lg fa-arrow-circle-right" /></div>}
					</a>
				</li>
			);
		}
	}

	const previousButton = () => {
		if (props.collection.page !== 1) {
			return (
				<li>
					<a onClick={previous}>
						{loading === 'previous' ? <i className="fa fa-spinner fa-spin" /> : <div><i className="fa fa-lg fa-arrow-circle-left"/><span>Previous</span></div>}
					</a>
				</li>
			);
		}
	}

	if (props.collection.pages < 2) { return null; }

	return (
		<ul className="infinite-paginator" key={`pagination.${props.collection.page}`}>
			{previousButton()}
			<li><span className="blank">Page <strong>{props.collection.page}</strong> of {props.collection.pages}</span></li>
			{nextButton()}
		</ul>
	)
}

export default Pagination