import { useState } from 'react';
import Mailbox from '../../../resources/Mailbox';
import { useRouter } from '../../../lib/useRouter'
import MailboxRepository from '../../../repositories/MailboxRepository'
import { Form } from '../../../lib/forms/Form';
import { TextInput } from '../../../lib/forms/TextInput';
import { BooleanToggle } from '../../../lib/forms/BooleanToggle';
import { InvalidMessage } from '../../../lib/forms/InvalidMessage';
import { Label } from '../../../lib/forms/Label';
import { Submit } from '../../../lib/forms/Submit';

const MailboxForm = (props) => {
	const [saving, setSaving] = useState(false)
	const [showColors, setShowColors] = useState(true)
	const router = useRouter()

	const chooseColor = (color) => {
		let mailbox = props.mailbox;
		mailbox.subscription_color = color;

		props.updateMailbox(mailbox)
	}

	const colors = () => {
		return (
			<tr>
				<td width="40%">
					<Label attribute="shared">Highlight color</Label>
				</td>
				<td width="60%">
					<div className="highlight-colors">
						{Mailbox.colors.map(color => {
							return <i className={"fa fa-" + (props.mailbox.subscription_color === color ? "check-circle" : "circle")} onClick={() => chooseColor(color)} style={{ color: color }} />
						})}
					</div>
				</td>
			</tr>
		)
	}

	return (
		<div className="main-form gray new-mailbox">
			<Form object={props.mailbox} beforeSubmit={() => setSaving(true)} onSave={props.nextStep} repository={new MailboxRepository()} router={router}>
				<InvalidMessage text="Please provide a name or description and a valid email address for this mailbox." />
				<table className="clean gray">
					<tbody>
						<tr>
							<td width="40%">
								<Label attribute="name">Name or description for this mailbox</Label>
							</td>
							<td width="60%">
								<TextInput data-1p-ignore name="name" autoFocus className="full" />
							</td>
						</tr>
						<tr>
							<td width="40%">
								<Label attribute="email_address">Email address</Label>
							</td>
							<td width="60%">
								<TextInput data-1p-ignore name="email_address" className="full" />
							</td>
						</tr>
						<tr>
							<td width="40%">
								<Label attribute="shared">Is this a shared mailbox?</Label>
							</td>
							<td width="60%">
								<BooleanToggle name="shared" />
							</td>
						</tr>
						<tr>
							<td width="40%">
								<Label attribute="subscribe_creator">Subscribe to this mailbox?</Label>
							</td>
							<td width="60%">
								<BooleanToggle name="subscribe_creator" changeHandler={(_, val) => setShowColors(val)} />
							</td>
						</tr>
						{props.showColors ? colors() : null}
					</tbody>
				</table>
				<div className="submit">
					{
						props.saving
						? <i className="fa fa-spinner fa-spin" />
						: <Submit name="send" value="Create this mailbox" className="rounded medium green" />
					}
				</div>
			</Form>
		</div>
	)
}

export default MailboxForm