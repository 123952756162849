import React from 'react';
import Fetcher from '../../lib/Fetcher';

const SnoozeForm = ({ thread, onSnooze, beforeSnooze }) => {
	const snooze = (until, e) => {
		e.stopPropagation();

		if (beforeSnooze) {
			beforeSnooze();
		}

		let res = new Fetcher(localStorage.token).post(`/threads/${thread.id}/snooze`, { snooze_until: until });

		if (onSnooze) {
			res.then(res => {
				thread.snooze_until = res.thread.snooze_until
				onSnooze(new thread.constructor(thread))
			});
		}
	}

	return (
		<div key="snooze">
			<div className="body" style={{ padding: "0 20px" }}>
				<div className="table full-width equal-width">
					<div className="table-row">
						<div className="snooze cell" onClick={snooze.bind(null, 'soon')}>
							<i className="fal fa-clock" />
							soon
						</div>
						<div className="snooze cell" onClick={snooze.bind(null, 'tonight')}>
							<i className="fal fa-moon" />
							tonight
						</div>
						<div className="snooze cell" onClick={snooze.bind(null, 'morning')}>
							<i className="fal fa-coffee" />
							morning
						</div>
					</div>
					<div className="table-row">
						<div className="snooze cell" onClick={snooze.bind(null, 'weekend')}>
							<i className="fal fa-sun" />
							weekend
						</div>
						<div className="snooze cell" onClick={snooze.bind(null, 'week')}>
							<i className="fal fa-ellipsis-h" />
							next week
						</div>
						<div className="snooze cell" onClick={snooze.bind(null, 'month')}>
							<i className="fal fa-calendar" />
							next month
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default SnoozeForm