import Account from './Account';
import User from './User';
import Attachment from './Attachment';
import Task from './Task';
import Message from './Message';
import Thread from './Thread';
import Contact from './Contact';
import Organization from './Organization';
import File from './File';
import Configuration from './Configuration';
import Milestone from './Milestone';
import Mailbox from './Mailbox';
import Notification from './Notification';
import NotificationInbox from './NotificationInbox';
import BillingAccount from './BillingAccount';
import Session from './Session';
import Comment from './Comment';
import MailboxSubscription from './user/MailboxSubscription';
import Invoice from './Invoice';
import Share from './Share';
import ShareRecipient from './ShareRecipient';
import MessageBody from './MessageBody';
import Logo from './organization/Logo';
import Location from './contact/Location';
import Website from './contact/Website';
import Avatar from './contact/Avatar';
import EmailAddress from './contact/EmailAddress';
import Employment from './contact/Employment';
import EmploymentOrganization from './contact/EmploymentOrganization';
import SocialProfile from './contact/SocialProfile';
import DraftReminder from './draft/Reminder';
import MailboxConfiguration from './mailbox/Configuration';
import ImapConnectionSettings from './mailbox/ImapConnectionSettings';

const classes = {
	Account, User, Attachment, Task, Message, Milestone, Thread, Contact, Avatar, DraftReminder, Mailbox, Session, EmailAddress,
	File, Configuration, BillingAccount, Invoice, MessageBody, Location, Website, SocialProfile, MailboxConfiguration, EmploymentOrganization,
	MailboxSubscription, Share, ShareRecipient, Comment, Notification, Employment, NotificationInbox, Organization, Logo, ImapConnectionSettings
};

export default function dynamicResource(name) {
  return classes[name];
}