import Resource from '../lib/Resource';

class InvoiceItem {
	constructor(data) {
		this.data = data;
	}

	getType() {
		return this.data.type;
	}

	getQuantity() {
		return this.data.quantity;
	}

	getAmountDue() {
		return this.data.amount_due;
	}
}

export default class Invoice extends Resource {
	getItems() {
		return this.items.map(item => new InvoiceItem(item));
	}

	getBaseItem() {
		return this.getItems().find(item => item.getType() === 'base')
	}

	getEnrichmentItem() {
		return this.getItems().find(item => item.getType() === 'enrichment')
	}
}

Invoice.fields = ['id', 'items', 'amount_due', 'amount_paid', 'paid', 'period_start', 'period_end'];
Invoice.modelName = 'Invoice';