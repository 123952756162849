const HeaderSearch = (props) => {
	return (
		<div className="stats">
			{props.children}
		</div>
	)
}

HeaderSearch.displayName = 'HeaderStats'

export default HeaderSearch