import NotPermitted from '../components/common/NotPermitted';
import PermissionsEvaluator from './PermissionsEvaluator';
import { useAuth } from './useAuth'

export const useGatekeeper = (restrictions, additionalChecks) => {
	const auth = useAuth()
	const permitted = new PermissionsEvaluator().passes(restrictions, auth.user)

	if (permitted && additionalChecks) {
		permitted = additionalChecks(auth.user)
	}

	return [permitted, (permitted ? null : <NotPermitted />)]
}