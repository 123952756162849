import React from 'react';
import { TextInput } from '../../../../lib/forms/TextInput';
import { Select } from '../../../../lib/forms/Select';
import { HiddenField } from '../../../../lib/forms/HiddenField';
import PrivacyInput from './PrivacyInput';

const SocialProfileInput = (props) => {
	let main;
	if (props.social_profile.id) {
		main = (
			<div className="fields">
				<span className={"social-network " + props.social_profile.network}><i className={"fab fa-" + props.social_profile.network} /> {props.social_profile.networkName()}</span>
				<strong>{props.social_profile.handle}</strong>
			</div>
		);
	} else {
		main = (
			<div className="fields">
				<table style={{ width: "100%" }}>
					<tbody>
						<tr>
							<td width="200px">
								<div className="styled-select">
									<Select name={`social_profiles[${props.social_profile.lookupKey()}].network`}>
										<option key="" value="">Select a social network</option>
										<option key="twitter" value="twitter">Twitter</option>
										<option key="linkedin" value="linkedin">LinkedIn</option>
										<option key="angellist" value="angellist">AngelList</option>
										<option key="github" value="github">GitHub</option>
										<option key="facebook" value="facebook">Facebook</option>
									</Select>
								</div>
							</td>
							<td>
								<TextInput name={`social_profiles[${props.social_profile.lookupKey()}].handle`} className="full" placeholder="Handle, username, or link to profile page" />
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		);
	}

	return (
		<div className="field-section">
			<HiddenField name={`social_profiles[${props.social_profile.lookupKey()}].id`} />
			<HiddenField name={`social_profiles[${props.social_profile.lookupKey()}].deleted`} />
			<div className="remove">
				<i className="fal fa-times-circle" onClick={props.remove.bind(null, 'social_profiles', props.social_profile)} />
			</div>
			{main}
			<PrivacyInput contact={props.contact} field={props.social_profile} updateContact={props.updateContact} />
		</div>
	);
}

export default SocialProfileInput