import { useState } from 'react'
import { useAuth } from './useAuth'

export const useAddressBookScoper = (props) => {
	const auth = useAuth()
	const [scope, setScope] = useState(auth.user.scopeFor('contacts'))
	const [loading, setLoading] = useState(null)
	const [previous, setPrevious] = useState(null)
	const [showMailboxSelector, setShowMailboxSelector] = useState(false)

/*
	componentDidMount() {
		setState({ sidebarRef: props.getSidebar() });
	}
*/

	const updateScope = (newScope) => {
		if (newScope !== scope) {
			localStorage.setItem('addressBookScope', newScope);

			setPrevious(scope)
			setScope(newScope)
			setLoading(newScope)
		}
	}

	const onLoadCompletion = () => {
		setLoading(null)
		setPrevious(null)
	}

	const toggleMailboxSelection = () => {
		setShowMailboxSelector(!showMailboxSelector)
	}

	const scopeTabs = () => {
		if (auth.account.multiUser()) {
			return (
				<div className="scope-tab-container">
					<i className="fa fa-address-book" />
					<div className="scope-tabs">
						<span onClick={() => updateScope('personal')} className={(scope === 'personal' ? 'active' : '') + (loading === 'personal' ? ' loading' : '')}>Personal contacts</span>
						<span onClick={() => updateScope('global')} className={(scope === 'global' ? 'active' : '') + (loading === 'global' ? ' loading' : '')}>All contacts</span>
					</div>
				</div>
			);
		}
	}

	/* TODO: mobile */

	return [scope, onLoadCompletion, (auth.account.multiUser() ? scopeTabs() : null)]

/*
	if (!auth.account.multiUser()) { return <Component {...props} /> }
	if (window.isMobile) {
		if (sidebarRef) {
			return [
				ReactDOM.createPortal(scopeTabs(), ReactDOM.findDOMNode(sidebarRef).querySelector('.scope-container')),
				<Component {...props} scope={scope} onLoadCompletion={onLoadCompletion} />
			];
		} else { return null; }
	} else {
		return (
			<div>
				{scopeTabs()}

				<Component scope={scope} onLoadCompletion={onLoadCompletion} {...props} />
			</div>
		);
	}
*/

}