import { useState, useEffect } from 'react';
import { useAuth } from '../../lib/useAuth'
import { Helmet } from 'react-helmet';
import { Link, useLocation } from 'react-router-dom';
import AccountRepository from '../../repositories/AccountRepository';
import moment from 'moment';
import pluralize from 'pluralize';
import { toCurrency } from '../../lib/utilities/CommonUtilities';

const Billing = () => {
	const [includePricingDetails, setIncludePricingDetails] = useState(false)
	const [billingAccount, setBillingAccount] = useState(null)
	const auth = useAuth()
	const location = useLocation()

	useEffect(() => {
		new AccountRepository().find(auth.account.id).then(account => {
			setBillingAccount(account.billing_account)
		});
	}, [])

/*
	constructor(props) {
		super(props);
		this.state = {
			account: null,
			includePricingDetails: false
		};

		this.load(props);
	}

	componentWillReceiveProps(nextProps) {
		this.load(nextProps)
	}

	load = (props) => {
		new AccountRepository().find(props.currentUser.account.id).then(account => {
			this.setState({ account: account });
		});
	}
*/


	const invoiceTable = () => {
		if (billingAccount.invoices.length > 0) {
			return (
				<table className="invoices">
					<tbody>
						<tr key="heading">
							<th width="5%"></th>
							<th width="55%">Usage</th>
							<th width="25%" style={{ textAlign: "center" }}>Due Date</th>
							<th width="15%">Total</th>
						</tr>
						{billingAccount.invoices.map(invoice => {
							let userCount = invoice.items.users.quantity === 1 ? pluralize('user', invoice.items.users.quantity, true) : 'with prorated users';
							let mailboxCount = invoice.items.mailboxes.quantity === 1 ? pluralize('mailbox', invoice.items.mailboxes.quantity, true) : 'with prorated mailboxes';

							return (
								<tr key={invoice.id}>
									<td key="badge">
										{invoice.paid ? <strong className="payment-badge paid">Paid</strong> : <span className="payment-badge upcoming">Upcoming</span>}
									</td>
									<td key="name">
										<Link to={{ pathname: `/invoices/${invoice.id}`, state: { invoice: invoice, modal: true, returnTo: location.pathname } }} className="highlight-on-hover">
											<span><strong className="minor">Basic subscription, {userCount}, {mailboxCount}</strong></span>
										</Link>
									</td>
									<td key="period" style={{ textAlign: "center" }}><strong className="minor">{moment(invoice.period_end).format('MMMM D, YYYY')}</strong></td>
									<td key="amount">
										<strong>{toCurrency(invoice.amount_due)}</strong>
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			);
		}
	}

	const monthlyPlan = (invoice) => {
		return null;

		// eslint-disable-next-line
		let enrichments = null;
		let enrichmentItem = invoice.getEnrichmentItem();
		if (enrichmentItem) {
			enrichments = <span>{enrichmentItem.getQuantity()} enrichments for ${enrichmentItem.getAmountDue()}</span>;
		}

		return (
			<div className="strong minor">
				<span>Solo plan @ ${invoice.getBaseItem().getAmountDue()}</span>
				{enrichments ? <span> &middot; {enrichments}</span> : null}
			</div>
		);
	}

	const currentUsage = () => {
		let items = billingAccount.current_usage;
		let mailboxes = items.mailbox;
		let users = items.user;
		let subscription = items.subscription;

		if (!subscription) { return null }

		let total = subscription.rate
			+ ((users.quantity - 1) * users.rate)
			+ ((mailboxes.quantity - 1) * mailboxes.rate);

		return (
			<div className="current-billing-overview">
				<h4>
					Your current usage
					<span className={"text-button" + (includePricingDetails ? " invisible" : "")} onClick={() => setIncludePricingDetails(true)}>show pricing details</span>
				</h4>
				<p className={includePricingDetails ? "" : "hidden"}><strong className="minor">Your month-to-month bills may vary, since we prorate charges and offer credits if you add or remove users or mailboxes during the month.</strong></p>
				<ul className={includePricingDetails ? "" : "hidden"}>
					<li>We only bill for mailboxes after they are completely imported.</li>
					<li>We only bill for users after they have activated their accounts, not when they&#8217;re invited to join.</li>
				</ul>
				<div className={"current-billing-usage" + (includePricingDetails ? ' with-pricing-details' : '')}>
					<div>
						<strong>Basic</strong>
						<div className="pricing">
							<div>First user &amp; mailbox</div>
							<div>{toCurrency(subscription.rate)}/month</div>
						</div>
						<div className="label">Subscription</div>
					</div>
					<div>
						<strong>{users.quantity}</strong>
						<div className="pricing">
							<div>{toCurrency(users.rate)} after first</div>
							<div>{toCurrency((users.quantity - 1) * users.rate)}/month</div>
						</div>
						<div className="label">Users</div>
					</div>
					<div>
						<strong>{mailboxes.quantity}</strong>
						<div className="pricing">
							<div>{toCurrency(mailboxes.rate)} after first</div>
							<div>{toCurrency((mailboxes.quantity - 1) * mailboxes.rate)}/month</div>
						</div>
						<div className="label">Mailboxes</div>
					</div>
					<div>
						<strong>{toCurrency(total)}</strong>
						<div className="label">Total</div>
					</div>
				</div>
			</div>
		);
	}

	const loading = () => {
		return (
			<div className="loading">
				<i className="fa fa-spinner fa-pulse" />
				<h2>Loading your invoices&hellip;</h2>
			</div>
		)
	}

	return (
		<div>
			<Helmet title="Account billing" />
			<Link to={{ pathname: '/accounts/delete', state: { modal: true, returnTo: location.pathname } }} className="red tiny rounded button close-account">Close your account</Link>
			<h3><i className="fa fa-credit-card" />Your Carom account and billing options</h3>
			<p className="settings description">Update your credit card, view past invoices, or cancel your account.</p>

			{billingAccount ? <>{currentUsage()}{invoiceTable()}</> : loading()}
		</div>
	)
}

export default Billing