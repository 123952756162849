import { useEffect, useContext } from 'react'
import FormContext from '../FormContext'
import useFormInput from './useFormInput'
import DatePicker from 'react-datepicker';

export const DatePickerInput = (props) => {
	const context = useContext(FormContext)
	const formInput = useFormInput(props)

	return <DatePicker
			{...props}
			id={props.name}
			onChange={formInput.handleChange.bind(null, props.name)}
			selected={context.valueFor(props.name) ? new Date(context.valueFor(props.name)) : null}
			dateFormat="yyyy-MM-dd"
		/>
}
