import { useState } from 'react';
import { TextInput } from '../../lib/forms/TextInput';
import { IconToggle } from '../../lib/forms/IconToggle';
import { DatePickerInput } from '../../lib/forms/DatePickerInput';
import { FilterForm } from '../../lib/forms/FilterForm';

const FileFilterForm = ({ collection, updater }) => {
	const [loading, setLoading] = useState(false)

	return (
		<div className="file-filters filters gray" key={`files.filter.${collection.currentSlice()}`}>
			<FilterForm collection={collection} updater={updater} onSubmit={() => setLoading(!loading)} afterSubmit={() => setLoading(!loading)}>
				<div className="table narrow">
					<div className="row">
						<div className="cell">
							<label className="small standalone">File name</label>
							<div className="input-field"><TextInput name="query" className="full" placeholder="Name or part of name" /></div>
						</div>
						<div className="cell">
							<label className="small standalone">Exchanged with</label>
							<div className="input-field"><TextInput name="participant" className="full" placeholder="Email address" /></div>
						</div>
						<div className="cell">
							<label className="small standalone">Sent after</label>
							<div className="input-field"><DatePickerInput name="last_sent_after" dateFormat="MMM D, YYYY" weekStart="0" /></div>
						</div>
						<div className="cell">
							<label className="small standalone">Sent before</label>
							<div className="input-field"><DatePickerInput name="first_sent_before" dateFormat="MMM D, YYYY" weekStart="0" /></div>
						</div>
						<div className="centered cell extra-options">
							<IconToggle name="from-you" title="From you">
								<i className="fa fa-reply" />
							</IconToggle>
							<IconToggle name="to-you" title="To you">
								<i className="fa fa-share" />
							</IconToggle>
						</div>
						<div className="cell filter-submit">
							<button type="submit" className="invisible-container">
							  <i className={"fa fa-" + (loading ? 'spinner fa-spin' : 'arrow-circle-right')} />
							</button>
						</div>
					</div>
				</div>
			</FilterForm>
		</div>
	)
}

export default FileFilterForm