const Index = () => {
	return (
		<div className="layer">
			<div className="layer-shade"></div>
			<div className="main medium headingless no-sidebar">
				<div id="shortcuts" className="extra-large">
					<span className="shortcut-indicator hidden"></span>
					<div style={{ width: '800px', padding: "20px" }}>
						<strong style={{ fontSize: '16px' }}><span className="centered" style={{ color: 'forestgreen', width: '40px', display: 'inline-block' }}><i className="fa fa-keyboard-o" /></span> Keyboard Shortcuts</strong>
						<p className="etc normal-size" style={{ marginLeft: '45px' }}>Carom keyboard shortcuts make everything just a little quicker and easier.</p>
						<hr style={{ marginBottom: '20px' }} />

						<table className="wide top-aligned">
							<tr>
								<td width="50%">
									<div><strong>Navigation Shortcuts</strong></div>
									<div className="keys">
										<div>
											<div className="modifier key">shift</div> + <div className="key">d</div>
											Go to your <strong>d</strong>ashboad
										</div>
										<div>
											<div className="modifier key">shift</div> + <div className="key">c</div>
											Go to <strong>c</strong>ontacts
										</div>
										<div>
											<div className="modifier key">shift</div> + <div className="key">o</div>
											Go to <strong>o</strong>rganizations
										</div>
										<div>
											<div className="modifier key">shift</div> + <div className="key">e</div>
											Go to <strong>e</strong>mails
										</div>
										<div>
											<div className="modifier key">shift</div> + <div className="key">f</div>
											Go to documents/<strong>f</strong>iles
										</div>
										<div>
											<div className="modifier key">shift</div> + <div className="key">t</div>
											Go to <strong>t</strong>asks
										</div>
										<div>
											<div className="modifier key">shift</div> + <div className="key">s</div>
											Go to <strong>s</strong>ettings
										</div>
									</div>
								</td>
								<td width="50%"><div><strong>Record Shortcuts</strong></div>
									<div className="keys">
										<div>
											<div className="key">n</div>
											Add a <strong>c</strong>omment
										</div>
										<div>
											<div className="key">e</div>
											<strong>E</strong>dit a record
										</div>
										<div>
											<div className="key">d</div>
											<strong>D</strong>elete a record
										</div>
										<div>
											<div className="key">a</div>
											<strong>A</strong>ssign record to a user
										</div>
										<div>
											<div className="key">t</div>
											Add a <strong>t</strong>ask
										</div>
										<div>
											<div className="key">p</div>
											<strong>P</strong>in a record
										</div>
										<div>
											<div className="key">b</div>
											Edit record <strong>b</strong>ackground or <strong>b</strong>iography
										</div>
										<div>
											<div className="modifier key">1</div>, <div className="modifier key">2</div>, <div className="key">&hellip;</div>
											Toggle between display tabs
										</div>
									</div>
								</td>
							</tr>
							<tr>
								<td width="50%" style={{ paddingTop: '10px' }}>
									<div><strong>General Shortcuts</strong></div>
									<div className="keys">
										<div>
											<div className="key">s</div>
											<strong>S</strong>earch for a record
										</div>
										<div>
											<div className="key">h</div>
											Get <strong>h</strong>elp (contact support)
										</div>
										<div>
											<div className="key">?</div>
											View this list of shortcuts
										</div>
										<div>
											<div className="key">esc</div>
											Close modal windows (like this one)
										</div>
										<div>
											<div className="modifier key">shift</div> + <div className="key">backspace</div>
											Sign out of your account
										</div>
									</div>
								</td>
								<td width="50%" style={{ paddingTop: '10px' }}>
									<div><strong>List Shortcuts</strong></div>
									<div className="keys">
										<div>
											<div className="key">j</div>
											Next record in a list
										</div>
										<div>
											<div className="key">k</div>
											Previous record in a list
										</div>
										<div>
											<div className="key">enter</div>
											Go to highlighted record
										</div>
									</div>
								</td>
							</tr>
						</table>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Index;