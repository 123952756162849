import FieldValidation from './FieldValidation';

export default class RecordValidation {
	validations = {};
	invalidFields = [];

	constructor(validationFields) {
		for (let [k, v] of Object.entries(validationFields)) {
			this.validations[k] = new FieldValidation(k, v);
		}
	}

	setValues(values) {
		this.values = values;
	}

	hasValidations() {
		return Object.keys(this.validations).length > 0;
	}

	getInvalidFields(object) {
		let values = object.getAttributes();
		this.invalidFields = [];

		if (this.hasValidations()) {
			for (let [field, validation] of Object.entries(this.validations)) {
				validation.setValue(values[field], object);
				if (validation.failed()) {
					this.invalidFields = this.invalidFields.concat(field);
				} else {
					this.invalidFields.splice(this.invalidFields.indexOf(field), 1);
				}
			}
		}

		return this.invalidFields;
	}

	process(object) {
		return this.valid = this.getInvalidFields(object).length === 0;
	}

	passed() {
		if (this.valid === null) { return this.process(); }

		return this.valid;
	}

	failed() {
		if (this.valid === null) { return this.process(); }

		return !this.valid;
	}
}