import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Form from './Form';
import List from './List';
import Draft from '../../resources/Draft';

const New = () => {
	const location = useLocation()
	const [draft, setDraft] = useState(null)
	const [showDrafts, setShowDrafts] = useState(false)

	useEffect(() => {
		if (location.state && location.state.contact) {
			setDraft(new Draft({ to_recipients: [location.state.contact] }))
		} else {
			setDraft(new Draft({}))
		}
	}, [])

	const loadExisting = (existingDraft) => {
		setDraft(existingDraft)
		setShowDrafts(false)
	}

	return (
		<div className="main medium">
			<div className="subheading">
				<i className="fa fa-envelope" />
				<h2>Send a new email</h2>
				<div className={"load-drafts" + (showDrafts ? " active " : "")} onClick={() => setShowDrafts(!showDrafts)}><i className="fa fa-file-text-o" />View Drafts</div>
				<div className={showDrafts ? "" : "hidden"}><List location={location} onSelect={loadExisting} /></div>
				<Link to={location.state ? location.state.returnTo : '/'} className="close"><i className="fa fa-times" /></Link>
			</div>
			<div className="main-content">
				<div className="main-form gray full-width">
					<Form email={draft} />
				</div>
			</div>
		</div>
	)
}

export default New