import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'
import AsyncFetcher from '../../lib/AsyncFetcher';
import { NoResults, Loading } from '../common/Common'
import Section from './inbox/Section';
import Inbox from '../../resources/Inbox';

const InboxComponent = () => {
	const [inbox, setInbox] = useState(null)
	const [loading, setLoading] = useState(true)
	const [loaded, setLoaded] = useState(false)
	const location = useLocation()

	useEffect(() => {
		load();
	}, [])

	const load = async () => {
		const fetcher = new AsyncFetcher(localStorage.token)
		const json = await fetcher.get('/threads/open')

		setInbox(new Inbox(json.inbox))
		setLoading(false)
		setLoaded(true)
	}

	const loadedView = () => {
		if (inbox.isEmpty()) {
			return <NoResults congrats={true} message="Your inbox is empty" subMessage="Congratulations!  Way to stay on top of your email." />
		} else {
			return (
				<div id="inbox">
					<Section group="unread" threads={inbox.unread} location={location} />
					<Section group="flagged" threads={inbox.flagged} location={location} />
					<Section group="other" threads={inbox.other} location={location} />
				</div>
			);
		}
	}

	let stats;
	if (!loaded || inbox.isEmpty()) {
		stats = null;
	} else {
		stats = (
			<ul className="inbox-stats">
				<li className="unread"><strong>{inbox.unread.length}</strong> <span>unread</span></li>
				<li className="flagged"><strong>{inbox.flagged.length}</strong> <span>flagged</span></li>
				<li className="other"><strong>{inbox.other.length}</strong> <span>other</span></li>
			</ul>
		);
	}

	return (
		<div id="inbox-container">
			<h2 className="hide-on-mobile">
				{stats}
				<i className="fa fa-inbox" />Your <strong>Inbox</strong>
			</h2>
			{window.isMobile ? stats : null}

			{loaded ? loadedView() : <Loading inline={true} />}
		</div>
	)
}

export default InboxComponent