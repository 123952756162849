import { useState, useEffect } from 'react'
import { useAuth } from '../../lib/useAuth'

const ScopedFilterForm = ({ activeFilters, processingFilters, toggleFilter, search }) => {
	const [showSearch, setShowSearch] = useState(false)
	const auth = useAuth()

	const toggleSearch = () => {
		if (showSearch) {
			resetAfterSearch()
		} else {
			setShowSearch(true)
		}
	}

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			search(e.target.value);
		}
	}

	const resetAfterSearch = () => {
		setShowSearch(false)
//		updateRecords(initialCollection.records)
	}

	return (
		<div className="files panel">
			<div className="panel-filters">
				<div className="main-search">
					<i className="fa fa-search intro-icon" />
					<input type="text" placeholder="Search by file name" onKeyUp={handleKeyPress} />
				</div>

				<div className="spacer"></div>

				<div className="extra-options">
					<div className="filter-buttons">
						<div className="styled-select small">
							<select className="small" onChange={(e) => { toggleFilter('type', e.target.value) }}>
								<option value="">Any file type</option>
								<option value="images">Images</option>
								<option value="documents">Document</option>
								<option value="spreadsheets">Spreadsheets</option>
								<option value="presentations">Presentations</option>
								<option value="pdfs">PDFs</option>
								<option value="other">Other file types</option>
							</select>
						</div>
						<div className="styled-select small">
							<select className="small" onChange={(e) => { toggleFilter('sender', e.target.value) }}>
								<option value="">Any sender</option>
								<option value={auth.user.email_address}>From you</option>
								{false ? '<option value={contact.email_address}>From {"contact.displayShortName()"}</option>' : null}
							</select>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ScopedFilterForm