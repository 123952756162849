import useFormInput from './useFormInput'

export const TextArea = (props) => {
	const formInput = useFormInput(props)

	return (
		<div>
			<textarea
				{...props}
				id={props.name}
				onChange={formInput.handleChange.bind(null, props.name)}
				defaultValue={formInput.valueFor(props.name)}
				className={formInput.classes()}
			></textarea>
		</div>
	)
}