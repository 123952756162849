import { useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import ThreadRepository from '../../repositories/ThreadRepository'
import FirstMessage from './FirstMessage'
import Replies from './Replies'
import SnoozeNotice from './thread/SnoozeNotice'
import NewThreadMessage from './NewThreadMessage'
import { Downloadable } from '../../lib/Downloadable'

const View = ({ thread, updateThread, downloadFile }) => {
	const [showSnooze, setShowSnooze] = useState(false)
	const firstMessage = thread.getMessages()[0]

	const updateSnooze = (snoozedThread) => {
		// We don't just use this thread because it doesn't included expanded contacts
		let newThread = thread
		newThread.snooze_until = snoozedThread.snooze_until

		updateThread(newThread)
	}

	const unsnooze = () => {
		let thread = thread
		thread.set('snooze_until', null)
		new ThreadRepository().save(thread)

		updateThread(thread)
	}


	return (
		<div className="email">
			<div className="first-email">
				<div className="email-info-header">
					<div className="from">
						<span className="field">From</span>
						<strong>{firstMessage.from.displayName(firstMessage.from_email_address)}</strong>
					</div>
					<div className="date">{moment(firstMessage.sent_at).format('MMMM D, YYYY [at] h:mma')}</div>
					<div className="to comma-section">
						<span className="field">To</span>
						{firstMessage.to.map(participant => {
							return <span className="post-comma">{participant.displayName()}</span>
						})}
					</div>
					{firstMessage.hasAttachments()
						? (
							<div className="inline-attachments">
								<i className="fal fa-paperclip" />
								{firstMessage.attachments.map(attachment => {
									return <div><Link to={`/files/${attachment.file_id}`}>{attachment.file.name || attachment.file.file_name}</Link> <a onClick={() => downloadFile(attachment.file_id, attachment.attachment_id)}><i className="fa fa-download" /></a></div>
								})}
							</div>
						) : null
					}
				</div>
				<SnoozeNotice thread={thread} updateThread={updateThread} />

				<FirstMessage message={thread.messages[0]} hideInfo={true} />
			</div>
			<Replies thread={thread} downloadFile={downloadFile} />

			{false && <NewThreadMessage thread={thread} referenceThread={thread} referenceMessage={thread.messages[thread.messages.length - 1]} />}
		</div>
	)
}

export default View

//export default Downloadable(View)