import Resource from '../lib/Resource';

export default class Attachment extends Resource {
	sentByUser(user) {
		return this.from_email_address === user.email_address;
	}

	loadParticipantsFromFile(file) {
		this.from = file.getParticipantByEmailAddress(this.from_email_address);
		this.to = this.recipient_email_addresses.map(address => file.getParticipantByEmailAddress(address));
	}

	downloadUrl(parentFileId) {
		if (this.downloaded_instance && this.downloaded_instance.url) {
			return this.download_instance.url;
		} else {
			return `${(process.env.NODE_ENV === 'production' ? process.env.REACT_APP_API_HOST : 'http://localhost:2300')}/files/${parentFileId || this.file_id}/download?auth_token=${localStorage.token}&attachment_id=${this.id || this.attachment_id}`
		}
	}

	isInlineWithContent() {
		return (this.is_inline && this.content && this.content_type && this.reference_id)
	}
}

Attachment.fields = [
	'id', { thread: 'Thread' }, 'thread_id', 'attachment_id', 'file_id', 'downloaded_instance', 'size',
	'sent_at', 'message_id', 'sender_email_address', 'recipient_email_addresses', 'message', 'is_inline', 'content',
	'reference_id', 'content_type', { file: 'File' }
];
Attachment.modelName = 'Attachment';