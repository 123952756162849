import Location from './Location'

const Locations = ({ contact }) => {
	if (contact.locations && contact.locations.length > 0) {
		return (
			<div className="addresses">
				{contact.locations.map(location => <Location location={location} key={location.id} />)}
			</div>
		)
	}
}

export default Locations