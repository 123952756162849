import { useState, useEffect } from 'react';
import Task from '../../resources/Task';
import TaskRepository from '../../repositories/TaskRepository';
import { Form } from '../../lib/forms/Form';
import { TextInput } from '../../lib/forms/TextInput';
import { DatePickerInput } from '../../lib/forms/DatePickerInput';
import { SegmentedSelect } from '../../lib/forms/SegmentedSelect';

const MilestoneWidgetTaskForm = (props) => {
	const [task, setTask] = useState(null)
	const [submittable, setSubmittable] = useState(false)
	const [showSpecificDate, setShowSpecificDate] = useState(false)
	const [showOptions, setShowOptions] = useState(false)
	const [saving, setSaving] = useState(false)
	const [saved, setSaved] = useState(false)

	useEffect(() => {
		setTask(new Task({ milestone_id: props.milestone.id }))
	}, props)

/*
	const validityChange = (task) => {
		setValid(task.valid())
	}
*/

	const handleDueShortcutChange = (field, value) => {
		setShowSpecificDate(value === "date")
	}

	const dueDateInput = () => {
		let input;
		if (showSpecificDate) {
			input = (
				<div style={{ display: 'flex' }}>
					<div className="inline-label"><i className="fa fa-calendar" /></div>
					<DatePickerInput name="due" className="small-input" dateFormat="MMMM d, yyyy" weekStart="0" />
				</div>
			);
		} else {
			input = (
				<SegmentedSelect className="small" name="due_shortcut" defaultValue="" changeHandler={handleDueShortcutChange}>
					<option value="today">today</option>
					<option value="tomorrow">tomorrow</option>
					<option value="this_week">this week</option>
					<option value="next_week">next week</option>
					<option value="date">on&hellip;</option>
				</SegmentedSelect>
			);
		}

		return <div className="date-input">{input}</div>;
	}

	const saveButton = () => {
		if (saving) {
			return <i className="fa fa-spinner fa-spin" />;
		} else if (saved) {
			return <i className="fa fa-check" />;
		} else {
			return <div className={submittable ? '' : 'hidden'}><input type="submit" className="medium green rounded iconless" value="Save this Task" /></div>;
		}
	}

	const addToMilestone = (task) => {
//		setState({ saved: true, saving: false, submittable: false, task: new Task({ assignment: "", milestone_id: props.milestone.id }) });

		props.addToMilestone(task);

		props.toggleForm();
	}

	const validityChange = () => {
		setSaved(false)
		setSubmittable(task.valid())

		props.relayout();
	}

	if (task) {
		return (
			<Form className="milestone-task" beforeSubmit={() => setSaving(true)} object={task} onSave={addToMilestone} onValidityChange={validityChange} repository={new TaskRepository()}>
				<div className="title">
					<TextInput name="assignment" placeholder="Add a new task" className="full" autoFocus />
				</div>
				{dueDateInput()}
				<div className={"submit" + (saved ? ' fade-out' : '') + (submittable ? ' visible' : '')}>
					<div className="submit-button">{saveButton()}</div>
				</div>
			</Form>
		)
	}
}

export default MilestoneWidgetTaskForm