import Resource from '../lib/Resource';
import ProxyCollection from '../lib/ProxyCollection';
import ThreadRepository from '../repositories/ThreadRepository';

export default class Workspace extends Resource {
	unreadThreadCollection() {
		if (!this._threadCollection) {
			this._threadCollection = new ProxyCollection(this.unread_emails, new ThreadRepository());
		}

		return this._threadCollection;
	}
}

Workspace.fields = [{ contacts: ['Contact'] }, { files: ['File'] }, { unread_emails: ['Thread'] }];
Workspace.modelName = 'Workspace';