import React from 'react';

const Loading = (props) => {
	const inline = () => {
		return (
			<div className="loading">
				<i className="fa fa-spinner fa-pulse" />
				{text()}
			</div>
		);
	}

	const text = () => {
		if (!props.iconOnly) {
			return (
				<div>
					<h2>Loading&hellip;</h2>
					<p>Please wait just a moment while we load the information you requested.</p>
				</div>
			);
		}
	}

	const fullPage = () => {
		return (
			<div className="loading full-page">
				<i className="fa fa-spinner fa-pulse" />
			</div>
		);
	}

	if (props.fullPage) { return fullPage() }

	if (props.inline) { return inline() }

	return (
		<div className="main full">
			<div className="main-content headingless">
				{inline()}
			</div>
		</div>
	);
}

export default Loading