import Resource from '../../lib/Resource';

export default class Employment extends Resource {
	hasTitle() {
		return !!this.title;
	}

	hasOrganization() {
		return !!this.organization;
	}
}

Employment.modelName = 'Employment';
Employment.fields = ['id', 'title', 'deleted', 'organization_id', { organization: 'Organization' }, { organization: 'Organization' }];