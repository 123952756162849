import React from 'react';
import moment from 'moment';
import { useCollectionLoader } from '../../lib/hooks'

const List = ({ onSelect }) => {
	const [collection, filter] = useCollectionLoader('DraftRepository', {}, () => {})

	if (collection) {
		return (
			<div className="pop-under-form right headingless" style={{ right: "50px", top: "56px", width: "240px" }}>
				<ul className="table draft-list">
					{collection.records.map((draft) => {
						return (
							<li key={"draft." + draft.id}>
								<span to={draft.path} className="block" onClick={onSelect.bind(null, draft)}>
									<div className="subject">{draft.displaySubject()}</div>
									<div className="last-update">{moment(draft.updated_at).format('MMMM D, YYYY')}</div>
								</span>
							</li>
						);
					})}
				</ul>
			</div>
		)
	}
}

export default List