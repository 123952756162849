import { useState } from 'react';
import { Shareable } from '../../lib/Shareable';
import { useToggle } from '../../lib/useToggle';
import ParticipantList from './thread/ParticipantList';
import AttachmentList from './thread/AttachmentList';
import SnoozeNotice from './thread/SnoozeNotice'

const ViewSidebar = (thread, updateThread) => {
	const [showSnooze, setShowSnooze] = useState(false)
	const toggle = useToggle(thread, updateThread)

	const updateSnooze = (snoozedThread) => {
		// We don't just use this thread because it doesn't included expanded contacts
		let thread = thread;
		thread.snooze_until = snoozedThread.snooze_until;

		updateThread(thread);
	}

	const resolvedBadge = () => {
		return (
			<div className="centered">
				<div className="resolved-badge">
					Resolved
					<i className="fa fa-undo" onClick={() => toggle('resolution')} />
				</div>
			</div>
		);
	}

	return (
		<div>
			{thread.resolved ? this.resolvedBadge() : null}

			<SnoozeNotice thread={thread} updateThread={updateThread} />
			<ParticipantList thread={thread} />
			{thread.has_attachments ? <AttachmentList thread={thread} /> : null}
		</div>
	)
}

export default ViewSidebar