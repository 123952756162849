import { useState, useEffect } from 'react';
import { CurrentUserAccessor } from '../../../../lib/CurrentUserAccessor';
import { snakeCase } from 'lodash';
import pluralize from 'pluralize'

const PrivacyInput = (props) => {
	const [isPrivate, setIsPrivate] = useState(null)

	useEffect(() => {
		setIsPrivate(props.field.private)
	}, [props])

	const toggle = () => {
		let contact = props.contact;
		contact[`${pluralize(snakeCase(props.field.constructor.modelName))}`].find(child => {
			return child.lookupKey() === props.field.lookupKey()
		}).private = !props.field.private;

		props.updateContact(contact);
	}


	if (
		props.contact.type === 'personal' // everything is private
		|| (props.field.id && !props.field.private && !props.field.attributeChanged('private')) // once something is public, it's always public
	) { return null }

	return (
		<div className="ownership">
			<input type="hidden" name={`[${pluralize(snakeCase(props.field.constructor.modelName))}[${props.field.lookupKey()}].private`} />
			{
				props.field.private
				? <i className="fa fa-lock" title="This is a private field" onClick={toggle} />
				: <i className="fa fa-rss" title="This is visible to anyone who can view this contact" onClick={toggle} />
			}
		</div>
	)
}

export default PrivacyInput