import React from 'react';
import { NavLink, useOutletContext } from 'react-router-dom';
import useCategoryVisibilityToggle from '../../lib/useCategoryVisibilityToggle'
import { useAuth } from '../../lib/useAuth'

const ContactSidebar = () => {
	const [smartContactsVisible, smartContactsToggle] = useCategoryVisibilityToggle('contacts.smart')
	const [recentContactsVisible, recentContactsToggle] = useCategoryVisibilityToggle('contacts.smart')
	const auth = useAuth()

	const recentlyViewed = () => {
		if (auth.user.getViewHistory().getLatest('contacts').length === 0) { return null; }

		return (
			<div>
				<h3 className="section-title">
					Recently viewed
					{recentContactsToggle}
				</h3>
				<div className={"recent-contacts sub" + (recentContactsVisible ? "" : " hidden")}>
					{auth.user.getViewHistory().getLatest('contacts').map(contactView => {
						return (
							<NavLink key={"recent." + contactView.id} to={"/contacts/" + contactView.id}>
								<span className="recent-avatar">{contactView.avatar && contactView.avatar.source_url ? <img src={`${contactView.avatar.source_url}`} alt="Avatar" /> : <i className="fal fa-user-circle" />}</span>
								<span className="record-name title"><span>{contactView.name || contactView.email_address}</span></span>
							</NavLink>
						);
					})}
				</div>
			</div>
		);
	}

	return (
		<div>
			<h2><i className="fa fa-users" />Contact Directory</h2>
			<div className="sub-panel">
				<div className="main-links">
					<NavLink to="/contacts" end>
						<i className="far fa-dot-circle" /><span className="title">Overview</span>
					</NavLink>
					<NavLink to="/contacts/list">
						<i className="fa fa-bars" /><span className="title">Contact List</span>
					</NavLink>
					<NavLink to="/contacts/search">
						<i className="fa fa-search" />
						<span className="title">Search Contacts</span>
					</NavLink>
				</div>

				<h3 className="section-title">
					Smart contact filters
					{smartContactsToggle}
				</h3>
				<div className={"sub" + (smartContactsToggle ? "" : " hidden")}>
					<NavLink to="/contacts/pinned">
						<i className="fal fa-thumbtack" /><span className="title">Pinned contacts</span>
					</NavLink>
					<NavLink to="/contacts/shared">
						<i className="fal fa-rss" /><span className="title">Shared with you</span>
					</NavLink>
					<NavLink to="/contacts/tasked">
						<i className="fal fa-check-circle" /><span className="title">Contacts with tasks</span>
					</NavLink>
					<NavLink to="/contacts/latest">
						<i className="fal fa-comment" /><span className="title">Contacted this month</span>
					</NavLink>
					<NavLink to="/contacts/lapsed">
						<i className="fal fa-unlink" /><span className="title">Out-of-touch contacts</span>
					</NavLink>
					<NavLink to="/contacts/enriched">
						<i className="fal fa-plug" /><span className="title">Enriched records</span>
					</NavLink>
				</div>

				{recentlyViewed()}
			</div>
		</div>
	)
}

export default ContactSidebar