import { useState } from 'react';
import Mailbox from '../../../resources/Mailbox';
import MailboxRepository from '../../../repositories/MailboxRepository';
import MailboxForm from './Form';

const New = ({ showConnection } ) => {
	const [showForm, setShowForm] = useState(false)
	const [mailbox, setMailbox] = useState(new Mailbox({ shared: false, subscribe_creator: true }))

	const nextStep = (mailbox) => {
		if (showConnection) {
			showConnection(mailbox);
		}
	}

	if (!showForm) {
		return <div className="centered" style={{ marginBottom: "20px" }}><span className="medium green button rounded" onClick={() => setShowForm(true)}>Connect a new mailbox</span></div>;
	} else {
		return <MailboxForm nextStep={nextStep}
			mailbox={mailbox}
			updateMailbox={(mailbox) => setMailbox(mailbox)}
			MailboxRepository={new MailboxRepository({})}
			/>
	}
}

export default New