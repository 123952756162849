import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useAuth } from '../../lib/useAuth'
import moment from 'moment';
import Fetcher from '../../lib/Fetcher';

const Delete = () => {
	const [closeImmediately, setCloseImmediately] = useState(false)
	const [deleted, setDeleted] = useState(false)
	const [account, setAccount] = useState(null)
	const [closing, setClosing] = useState(false)
	const auth = useAuth()
	const fetcher = new Fetcher(localStorage.token)

	const deleteAccount = () => {
		setClosing(true)

/*
		setState({ closing: true }, () => {
			fetcher.delete(`/accounts/${props.currentUser.account.id}`, { account: { close_immediately: closeImmediately } }).then(response => {
				setState({ account: response.account })

				if (response.account.closed) { localStorage.removeItem('token') }
			})
		})
*/
	}

	const setCancellationTiming = (e) => {
		setCloseImmediately(e.target.value === "1" ? true : false)
	}

	const form = () => {
		return (
			<div>
				<div className="subheading">
					<i className="fa fa-trash red" />
					<h2>Delete your Carom account?</h2>
				</div>
				<div className="warning message">
					<p>You are about to permanently delete your Carom account.</p>
					{auth.account.multiUser() ? <p><strong className="inline-highlight warning"><span>You&#8217;re not just closing your own account&mdash;this will close the accounts for all of your account&#8217;s users.</span></strong></p> : null}
					<p>This won&#8217;t have any effect on any mailboxes you&#8217;ve connected.</p>
					<p><strong className="inline-highlight warning"><span>This cannot be undone.</span></strong></p>
				</div>
				<div className="main-content">
					<p><strong>Would you like to keep the account open through your current billing cycle?</strong> We&#8217;ll automatically close the account at that point, and you won&#8217;t be charged again either way.</p>
					<div className="timing-options">
						<div><input onClick={setCancellationTiming} checked={!closeImmediately} type="radio" id="close_end_of_cycle" name="close_immediately" value="0" /> <label htmlFor="close_end_of_cycle" className={closeImmediately ? 'inline' : 'inline selected'}>Close your account after this billing cycle</label></div>
						<div><input onClick={setCancellationTiming} checked={closeImmediately} type="radio" id="close_now" name="close_immediately" value="1" /> <label htmlFor="close_now" className={closeImmediately ? 'inline selected' : 'inline'}>Close your account immediately</label></div>
					</div>

					<div className="submit">
						{
							closing
							? <i className="fa fa-spinner fa-spin" />
							: <div className="medium red button" onClick={deleteAccount}>I understand&mdash;delete my account</div>
						}
					</div>
				</div>
			</div>
		);
	}

	const cancellationNotice = () => {
		return (
			<div className="deleted">
				<i className="fa fa-check" />
				<div className="main-content">
					<h2>You have canceled your subscription.</h2>
					<p className="description">
						{account.closed
							? 'You chose to delete the account immediately, so your data has been deleted and you will not be able to sign in again.'
							: `You will have access to the account through the end of your billing period, but we will delete your data and close the account after that.  You will not be charged again.`
						}
					</p>
				</div>
			</div>
		)
	}

	return (
		<div className="main mini delete-account no-sidebar">
			<Helmet title="Delete your account" />
			{account === null ? form() : cancellationNotice()}
		</div>
	)
}

export default Delete