import React from 'react';
import { Link } from 'react-router-dom';

const ContactResultRow = ({ record }) => {
	return (
		<Link to={"/contacts/" + record.id}>
			{record.name || record.email_address}
		</Link>
	)
}

export default ContactResultRow