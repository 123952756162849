import React from 'react';

const NotPermitted = () => {
	return (
		<div className="main medium no-navigator">
			<div className="main-content headingless">
				<div className="app-error">
					<i className="fa fa-ban" />
					<h2>You don&#8217;t have permission to do this</h2>
					<p>Please contact an administrator or account owner for access.</p>
					<p>If you are the account owner or administrator and believe this is an error, please <a href="mailto:support@carom.io?subject=Access denied">let us know</a>.</p>
				</div>
			</div>
		</div>
	)
}

export default NotPermitted