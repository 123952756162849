import React from 'react';
import { TextInput } from '../../../../lib/forms/TextInput';
import { HiddenField } from '../../../../lib/forms/HiddenField';

const EmailAddressInput = (props) => {
	return (
		<div className="field-section" key={`email_addresses.${props.email_address.lookupKey()}`}>
			<div className="remove" key="removeEmail">
				<i className="fal fa-times-circle" onClick={props.remove.bind(null, 'email_addresses', props.email_address)} />
			</div>
			<div className="fields" key="emailFields">
				<HiddenField name={`email_addresses[${props.email_address.lookupKey()}].id`} />
				<TextInput data-1p-ignore name={`email_addresses[${props.email_address.lookupKey()}].value`} placeholder="email@example.com" />
			</div>
		</div>
	)
}

export default EmailAddressInput