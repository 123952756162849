import React from 'react';
import MilestoneNotification from './MilestoneNotification';
import TaskNotification from './TaskNotification';
import FileNotification from './FileNotification';
import CommentNotification from './CommentNotification';
import ContactNotification from './ContactNotification';
import ThreadNotification from './ThreadNotification';
import MailboxNotification from './MailboxNotification';
import { useAuth } from '../../lib/useAuth';

const View = ({ notification, dismiss }) => {
	const auth = useAuth()
	const components = {
		milestone_shared: MilestoneNotification,
		task_assigned: TaskNotification,
		file_shared: FileNotification,
		contact_shared: ContactNotification,
		comment_added: CommentNotification,
		thread_shared: ThreadNotification,
		mailbox_locked: MailboxNotification,
		mailbox_unlocked: MailboxNotification
	}

	if (components[notification.event]) {
		return (
			<div key={`notification.${notification.id}`} className={"notification" + (notification.new ? ' unread' : '')}>
				<div className="controls">
					{false && <i className="fal fa-calendar-plus add" title="Add to your agenda" />}
					<i className="fal fa-times dismiss" title="Dismiss this notification" onClick={() => dismiss(notification)} />
				</div>
				{React.createElement(components[notification.event], {
					notification: notification,
					currentUser: auth.user,
					account: auth.account
				})}
			</div>
		);
	}
}

export default View