import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet';
import { Link, useLocation } from 'react-router-dom';
import { useDefaultLens } from '../../lib/useDefaultLens';
import AsyncFetcher from '../../lib/Fetcher';
import Timeline from '../../lib/Timeline';
import moment from 'moment';
import File from '../../resources/File';
import * as FileUtilities from '../../lib/utilities/FileUtilities';
import { formattedRelativeDate } from '../../lib/Utilities';
import { Header, PageIcon, HeaderSearch } from '../common/header';
import Loading from '../common/Loading';
import Row from './Row';
import { uniqBy } from 'lodash';

const TaskedFile = (props) => {
	const [toggling, setToggling] = useState(null)
	useDefaultLens('files')

	const toggleCompletion = (task) => {
		setToggling(task)

		task.toggleCompletion(task => {
			setToggling(false)
		})
	}

	return (
		<div>
			<div className="tasked-file">
				<span className={"file-icon " + FileUtilities.fileType(props.file)}>{FileUtilities.fileIcon(props.file)}</span>
				<div>
					<Link to={`/files/${props.file.id}`} className="name">{props.file.displayName()}</Link>
					<div className="etc">{FileUtilities.fileType(props.file)} &middot; {FileUtilities.bytesToHumanSize(props.file.lastFileSize())}</div>
				</div>
			</div>
			<div className="file-tasks task-list">
				{props.tasks.map(task => {
					return (
						<div className={"task-row task" + (toggling === task ? " toggling" : "") + (task.isComplete() ? " complete" : "")}>
							<div className="checkbox with-circle" onClick={() => toggleCompletion(task)}></div>
							<div className="task-assignment">{task.assignment}</div>
						</div>
					);
				})}
			</div>
		</div>
	)
}


const Index = () => {
	const [loading, setLoading] = useState(true)
	const [loaded, setLoaded] = useState(false)
	const [showTaskForm, setShowTaskForm] = useState(false)
	const [mobileView, setMobileView] = useState('snapshot')
	const [snapshot, setSnapshot] = useState({})
	const [timeline, setTimeline] = useState(null)
	const [tasks, setTasks] = useState([])
	const location = useLocation()

	const loadSnapshot = async () => {
		const fetcher = new AsyncFetcher(localStorage.token)
		let json = await fetcher.get('/files/snapshot')

		return json;
	}

	useEffect(() => {
		async function doSnapshotLoad() {
			let snapshotJson = await loadSnapshot()

			setSnapshot(['recent', 'pinned', 'tasked'].reduce((map, type) =>
				(map[type] = snapshotJson.file_snapshot[type].map(attrs => new File(attrs)), map), {})
			)
		}

		doSnapshotLoad()
	}, [])

	useEffect(() => {
		if (Object.keys(snapshot).length > 0) {
			setTimeline(new Timeline(snapshot.recent, 'last_sent_at'))
			setTasks(tasksFromFiles(snapshot.tasked))
			setLoading(false)
			setLoaded(true)
		}
	}, [snapshot])

	const tasksFromFiles = (files) => {
		let taskList = [];
		files.forEach(file => {
			let fileTasks = file.tasks;
			fileTasks.forEach(task => { task.file = file; task.file.tasks = [] });
			taskList = taskList.concat(fileTasks);
		});

		return taskList;
	}

	const taskedFiles = () => {
		if (snapshot.tasked.length === 0) { return null; }
		let files = uniqBy(tasks.map(task => task.file), 'id');

		return (
			<div>
				<h3 className="with-icon-before tasks">
					{snapshot.tasked.length > 8 ? <Link to="/files/tasked" className="see-all">see all</Link> : null}
					Files with incomplete tasks ({snapshot.tasked.length})
				</h3>
				<div className="tasked-files">
					{files.map(file => <TaskedFile file={file} tasks={tasks.filter(task => task.file.id === file.id)} />)}
				</div>
			</div>
		);
	}

	const pinnedFiles = () => {
		if (snapshot.pinned.length > 0) {
			return (
				<div className="pinned-files">
					<div className="file-table">
						{snapshot.pinned.map(file => {
							return <Row key={file.id} file={file} location={location} />;
						})}
					</div>
				</div>
			);
		} else {
			return (
				<div className="no-pinned-files">
					<i className="fal fa-circle" />
					<div>
						<h3>You don&#8217;t have any pinned files</h3>
						<p className="etc normal-size">Pin some of your important files so you always have easy access to them.</p>
					</div>
				</div>
			)
		}
	}

	const loadedView = () => {
		return (
			<div className="structure files">
				<div className={"main-panel" + (mobileView !== 'snapshot' ? ' hide-on-mobile' : '')}>
					<h3 className="with-icon-before pinned" style={{ marginTop: "0" }}>
						{snapshot.pinned.length > 8 ? <Link to="/files/pinned" className="see-all">see all</Link> : null}
						Your pinned files ({snapshot.pinned.length})
					</h3>
					{pinnedFiles()}

					{taskedFiles()}
				</div>

				<div className={"info-panel" + (mobileView !== 'latest' ? ' hide-on-mobile' : '')}>
					<div className="recent-files">
						<h3><i className="fal fa-history" />Latest files sent &amp; received</h3>
						{Object.keys(timeline.objectsByDate()).map(date => {
							return (
								<div>
									<div className="file-timeline-date">{formattedRelativeDate(date, moment(), true, true)}</div>
									{timeline.objectsByDate()[date].map(file => {
										return (
											<Link to={`/files/${file.id}`} className="email-attachment">
												<span className={"file-icon " + FileUtilities.fileType(file)}>{FileUtilities.fileIcon(file)}</span>
												<div className="name">
													<strong>{file.file_name}</strong>
													<div className="etc">{FileUtilities.fileType(file)} &middot; {FileUtilities.bytesToHumanSize(file.lastFileSize())}</div>
												</div>
											</Link>
										)
									})}
								</div>
							);
						})}
					</div>
				</div>
			</div>
		);
	}

	let search = <HeaderSearch scope="files" placeholder="Search files in your mailbox" />

	return (
		<div className="main full">
			<Helmet title="Manage your files" />
			<Header title="Manage Your Files" className="gray">
				<PageIcon icon="file-alt" toggleSidebarOnMobile />

				{window.isMobile ? null : search}
			</Header>
			<div className="subheader-search mobile-only">{window.isMobile ? search : null}</div>
			<div className="subheader-nav mobile-only">
				<ul className="sections">
					<li><span className={"console-button" + (mobileView === 'latest' ? '' : ' active')} onClick={() => setMobileView('snapshot')}><i className="fa fa-tachometer" />Snapshot</span></li>
					<li><span className={"console-button" + (mobileView === 'latest' ? ' active' : '')} onClick={() => setMobileView('latest')}><i className="fa fa-history" />Latest</span></li>
				</ul>
			</div>
			<div className="main-content">
				{loaded ? loadedView() : <Loading inline={true} />}
			</div>
		</div>
	)
}

export default Index