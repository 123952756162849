import moment from 'moment-timezone';

export default class CategorizedTimeZoneList {}

CategorizedTimeZoneList.fetch = function() {
	let allTimeZones = moment.tz.names();
	let categorized = {}

	allTimeZones.forEach(zone => {
		let pieces = zone.split('/');
		if (pieces.length === 2) {
			categorized[pieces[0]] = (categorized[pieces[0]] || []).concat(pieces[1])
//		} else {
//			categorized['Other'] = (categorized['Other'] || []).concat(zone)
		}
	});

	return categorized
}

