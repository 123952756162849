import { useState, useEffect } from 'react';
import Row from './Row';
import { useHotkeys } from 'react-hotkeys-hook'

const useListHotkeys = (records) => {
	const [selectedIndex, setSelectedIndex] = useState(null)
	const [selectedRecord, setSelectedRecord] = useState(null)
	useHotkeys('j', () => moveDown())
	useHotkeys('k', () => console.log('up'))

/*
	useEffect(() => {
		if (selectedIndex !== null) {
			setSelectedRecord(records[selectedIndex])
		}
	}, [selectedIndex])
*/

	const moveDown = () => {
//		console.log(selectedIndex, selectedIndex === null || selectedIndex === records.length - 1, Math.round(Math.random()*100))
//		if (selectedIndex === null || selectedIndex === records.length - 1) {
//			setSelectedIndex(0)
//		} else {
//			setSelectedIndex(selectedIndex + 2)
//		}

		setSelectedIndex(Math.round(Math.random()*100))
	}

	return selectedIndex
}

const Table = (props) => {
	const selectedIndex = useListHotkeys([1,2, 2])

	return (
		<div className="file-table">
			{props.files.map(file => {
				return <Row key={file.id} file={file} isSelected={false} />;
			})}
		</div>
	)
}

export default Table