import Resource from '../lib/Resource';
import ProxyCollection from '../lib/ProxyCollection';
import TaskRepository from '../repositories/TaskRepository';
import { TaskCollectionUtilities } from '../lib/utilities/TaskCollectionUtilities';
import { ShareableRecord } from '../lib/ShareableRecord';

export default class File extends ShareableRecord(Resource) {
	displayName() {
		return this.name || this.file_name || '(No file name)';
	}

	displayShortName() {
		return 'this file';
	}

	taskCollection() {
		if (!this._taskCollection) {
			this._taskCollection = Object.assign(new ProxyCollection(this.tasks || [], new TaskRepository()), TaskCollectionUtilities);
		}

		return this._taskCollection;
	}

	typeCategory() {
		if (['png', 'jpg', 'jpeg', 'gif', 'bmp', 'psd'].includes(this.extension)) {
			return 'image';
		} else if (['doc', 'docx', 'pages', 'txt', 'rtf'].includes(this.extension)) {
			return 'document';
		} else if (['xls', 'xlsx', 'csv'].includes(this.extension)) {
			return 'spreadsheet';
		} else if (['pdf'].includes(this.extension)) {
			return 'pdf';
		} else if (['ppt', 'pptx', 'pps', 'key'].includes(this.extension)) {
			return 'presentation';
		} else {
			return 'other';
		}
	}

	downloadUrl() {
		if (this.lastAttachment()) {
			return this.lastAttachment().downloadUrl(this.id);
		}
	}

	lastAttachment() {
		return this.attachments[this.attachments.length - 1];
	}

	lastFileSize() {
		if (this.lastAttachment()) {
			return this.lastAttachment().size;
		}
	}

	recipientCount() {
		return this.recipient_email_addresses.length
	}

	senderCount() {
		return this.sender_email_addresses.length
	}

	getPath() {
		return '/files/' + this.id;
	}

	getParticipantNameFromEmailAddress(emailAddress) {
		let name = emailAddress
		this.getAttachments().forEach(attachment => {
			if (attachment.thread.participant_names[emailAddress]) {
				name = attachment.thread.participant_names[emailAddress]
			}
		})

		return name
	}

	getAttachments() {
		this.attachments.map(attachment => attachment.loadParticipantsFromFile(this));

		return this.attachments;
	}

	getParticipantByEmailAddress(emailAddress) {
		return this.participants.filter(participant => {
			return participant.email_addresses.map(address => address.value).includes(emailAddress)
		})[0]
	}

	getRoleOf(user) {
		let roles = []
		if (this.sender_email_addresses.includes(user.email_address)) { roles.push('sender') }
		if (this.recipient_email_addresses.includes(user.email_address)) { roles.push('recipient') }

		return (roles.length === 2 ? 'both' : (roles[0] || 'recipient')) // the extra recipient is becasue we must be BCC'd
	}

	getParticipantCount() {
		return this.participants.length;
	}
}

File.fields = [
	'id', 'name', 'file_name', 'description', 'size', 'last_sent_at', 'first_sent_at', 'extension',
	'sender_email_addresses', 'recipient_email_addresses', { participants: ['Contact'] }, 'attachment_count',
	{ attachments: ['Attachment'] }, { tasks: ['Task'] }, 'shares',
	'mailbox_id', 'following', { comments: ['Comment'] }, 'pinned', 'download_url',
];
File.editableFields = ['name', 'description']
File.modelName = 'File';