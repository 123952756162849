import { useAuth } from './useAuth'

export const useResourceViewRecorder = (type) => {
	const auth = useAuth()

	const recordView = (record) => {
		auth.user.recordRecordView(record, type);

		window.dispatchEvent(new Event('viewHistoryUpdated'));
	}

	return recordView
}