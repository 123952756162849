import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Fetcher from '../../lib/Fetcher'
import User from '../../resources/User'
import Session from '../../resources/Session'
import SessionRepository from '../../repositories/SessionRepository'
import { Loading } from '../common/Common'
import { Link } from 'react-router-dom'
import { Submit } from '../../lib/forms/Submit'

const Reset = () => {
	const [loaded, setLoaded] = useState(false)
	const [resetRequest, setResetRequest] = useState(null)
	const [user, setUser] = useState(null)
	const [password, setPassword] = useState('')
	const [passwordIsValid, setPasswordIsValid] = useState(false)
	const validationRegex = User.prototype.validations()['password']['test']
	const fetcher = new Fetcher(null)
	const params = useParams()
	const navigate = useNavigate()

	useEffect(() => {
		const loadResetRequest = () => {
			fetcher.get(`/password_resets/${params.token}`).then(response => {
				setLoaded(true)
				setResetRequest(response.password_reset)
				setUser(response.password_reset.user)
			}).catch(() => {
				setLoaded(true)
				setResetRequest(false)
			})
		}

		loadResetRequest()
	}, [])

	const submitNewPassword = (e) => {
		e.preventDefault()

		if (passwordIsValid) {
			fetcher.patch(`/password_resets/${params.token}`, { password }).then(response => {
				new SessionRepository().save(new Session({ email_address: user.email_address, password })).then(session => {
					if (session && session.token) {
						localStorage.setItem('token', session.token.key)
//						authenticateUser() // TODO
						navigate('/')
					}
				})
			})
		}
	}

	const updatePassword = (e) => {
		setPassword(e.target.value)
		setPasswordIsValid(validationRegex.test(e.target.value))
	}

	const form = () => {
		return (
			<div>
				<p className="new-password-intro">Please choose a new password for <div className="user-info">{user.first_name} {user.last_name} ({user.email_address})</div></p>
				<div className="main-form padded">
					<form onSubmit={submitNewPassword}>
					<input onChange={updatePassword} type="password" name="password" autoFocus className="full large" placeholder="Your new password" autoCorrect="off" autoCapitalize="off" />
					<div className="submit">
						{passwordIsValid
							? <Submit name="send" value="Reset your password" className="rounded medium green" />
							: <p className="etc" style={{ color: "#777" }}>Your password must be at least 8 characters long and must include letters, numbers, and special characters.</p>
						}
					</div>
				</form>
				</div>
			</div>
		)
	}

	const content = () => {
		if (loaded) {
			if (resetRequest.valid) {
				return form()
			} else {
				return (
					<div className="inline-notice">
						<h3>Your password reset request is invalid</h3>
						<p className="etc">This may be because the request has expired or because you have already reset your password.</p>
					</div>
				)
			}
		} else {
			return <Loading />
		}
	}

	return (
		<div className="main mini standalone">
			<div className="subheading">
				<i className="fa fa-key" />
				<h2>Reset your password</h2>
				<span className="side-button"><Link to="/sessions" className="header-side-link">Sign in to your account</Link></span>
			</div>
			<div className="main-content gray">
				{content()}
			</div>
		</div>
	)
}

export default Reset