import Resource from '../../lib/Resource';

export default class EmailAddress extends Resource {
	displayValue() {
		return this.value;
	}
}

EmailAddress.modelName = 'EmailAddress';
EmailAddress.fields = [
	'id', 'value', 'primary', 'deleted'
];