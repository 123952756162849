import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet';
import UserForm from './Form'
import NotPermitted from '../common/NotPermitted';
import { useAuth } from '../../lib/useAuth'

const Edit = (props) => {
	const { id } = useParams()
	const auth = useAuth()
	const [user, setUser] = useState(auth.account.users.find(user => user.id === id))
	const [deleted, setDeleted] = useState(false)
	const [deleting, setDeleting] = useState(false)
	const [permitted, setPermitted] = useState(auth.user.isAdmin() && (auth.user.isOwner() || !user.isOwner()))

	if (!user) {
		return null;
	} else if (!permitted) {
		return  <NotPermitted />;
	} else if (deleted) {
		return this.cancellationNotice(); // todo... don't know when this would get invoked
	} else {
		return (
			<div className="main medium no-sidebar">
				<Helmet title={`Edit ${user.displayName()}'s account`} />
				<div className="subheading">
					<h2><i className="fa fa-pencil" /> Edit {user.displayName()}&#8217;s account</h2>
				</div>
				<div className="main-content settings">
					<UserForm user={user} addNewUser={() => {}} currentUser={auth.user} />
				</div>
			</div>
		)
	}
}

export default Edit