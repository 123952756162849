import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import UserRepository from '../../repositories/UserRepository';
import { Form } from '../../lib/forms/Form';
import { Select } from '../../lib/forms/Select';
import { TextInput } from '../../lib/forms/TextInput';
import { TextArea } from '../../lib/forms/TextArea';
import { HiddenField } from '../../lib/forms/HiddenField';
import { BooleanToggle } from '../../lib/forms/BooleanToggle';
import FileUploader from '../../lib/FileUploader';
import moment from 'moment-timezone';
import CategorizedTimeZoneList from '../../lib/utilities/CategorizedTimeZoneList';
import Dropzone, { useDropzone } from 'react-dropzone'
import { useAuth } from '../../lib/useAuth'

const Profile = (props) => {
	const [avatar, setAvatar] = useState(null)
	const [saving, setSaving] = useState(false)
	const [uploadInProgress, setUploadInProgress] = useState(false)
	const auth = useAuth()

	const avatarImage = () => {
		if (uploadInProgress) {
			return <i className="fal fa-spinner fa-spin" />
		} else if (auth.user.avatar && auth.user.avatar.source_url) {
			return <img src={auth.user.avatar.source_url} alt="Avatar" />
		} else {
			return <i className="fal fa-user-circle" />;
		}
	}

	const onDrop = files => {
		let user = auth.user
		user.getOrBuildAssociation('avatar')
		setUploadInProgress(true)

		new FileUploader('user-avatars', (avatar) => {
			if (avatar.status === 'complete') {
				user.avatar.loadAttributes({
					source_url: `https://s3.amazonaws.com/carom.user-avatars/${avatar.s3_key}`,
					file_name: avatar.file_name
				})

				setUploadInProgress(false)
				setAvatar(files[0])
			}
		}).upload(files[0])
	}
	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

	const dropzoneContainer = () => {
		return (
			<div className="upload-avatar" {...getRootProps()}>
				<input {...getInputProps()} />
				<div className="default-photo">
					{avatarImage()}
				</div>
				<div className="instructions-label">
					<span className="desktop-only">Drag and drop or click to upload photo</span>
					<span className="mobile-only">Click to upload photo</span>
				</div>
			</div>
		)
	}

	const timeZoneList = () => {
		let options = CategorizedTimeZoneList.fetch();

		return (
			<Select name="configuration.time_zone">
				{Object.keys(options).map(region => {
					return (
						<optgroup key={region} label={region}>
							{Object.values(options[region]).map(location => {
								return <option key={`${region}/${location}`} value={`${region}/${location}`}>{location.replace(/_/g, ' ')}</option>
							})}
						</optgroup>
					)
				})}
			</Select>
		);
	}

	const updateCurrentUser = (user) => {
		setSaving(false)

		auth.loadUser()
	}

	const mailboxSettings = () => {
		return (
			<>
				<tr>
					<td className="icon"><i className="fal fa-newspaper" /></td>
					<td className="setting-info">
						<strong>Filter mailing list emails out of your inbox</strong>
						<p className="etc normal-size">If not, they&#8217;ll still be categorized and available in the Mailing List page</p>
					</td>
					<td className="value">
						<BooleanToggle name="configuration.exclude_mailing_list_threads" />
					</td>
				</tr>
				<tr>
					<td className="icon"><i className="fal fa-file-alt" /></td>
					<td className="setting-info">
						<strong>Filter files from mailing lists out of your file repository</strong>
						<p className="etc normal-size">They will still be available as attachments to mailing list emails</p>
					</td>
					<td className="value">
						<BooleanToggle name="configuration.exclude_mailing_list_files" />
					</td>
				</tr>
				<tr>
					<td className="icon"><i className="fal fa-paperclip" /></td>
					<td className="setting-info">
						<strong>Exclude inline attachments from your file repository</strong>
						<p className="etc normal-size">Many are images in signatures and make it hard to find important files</p>
					</td>
					<td className="value">
						<BooleanToggle name="configuration.exclude_inline_attachments" />
					</td>
				</tr>
				<tr>
					<td className="icon"><i className="fal fa-inbox" /></td>
					<td className="setting-info">
						<strong>For how long should we keep emails in your Priority Inbox?</strong>
						<p className="etc normal-size">After this point, we&#8217;ll clear them so you can focus on what&#8217;s important</p>
					</td>
					<td className="value">
						<div className="styled-select small" style={{ display: 'inline-block', width: '43px', marginRight: '6px' }}>
							<Select name="configuration.priority_inbox_days">
								<option value="1">1</option>
								<option value="2">2</option>
								<option value="3">3</option>
								<option value="5">5</option>
								<option value="7">7</option>
							</Select>
						</div>
						<div className="styled-select small" style={{ display: 'inline-block', width: '100px' }}>
							<Select name="configuration.priority_inbox_days_unit">
								<option value="days">Days</option>
								<option value="weekdays">Weekdays</option>
							</Select>
						</div>
					</td>
				</tr>
			</>
		)
	}

	let currentTime;
	if (auth.user.configuration && auth.user.configuration.time_zone) {
		currentTime = <p className="etc normal-size">It is currently {moment.tz(auth.user.configuration.time_zone).format('h:mma')} in the {auth.user.configuration.time_zone.replace(/_/g, ' ')} time zone.</p>
	}

	return (
		<div>
			<Helmet title="Your configuration & settings" />
			<h3>
			<i className="fa fa-user" />Manage your account settings
			<div className="action red sign-out desktop-only" onClick={auth.signOut}><i className="fa fa-sign-out" /> Sign Out</div>
			</h3>
			<p className="settings description">We&#8217;ll configure your email queue based on your preferences, so you never forget what matters and never bother with what doesn&#8217;t.</p>
			<Form object={auth.user} repository={new UserRepository()} beforeSubmit={() => setSaving(true)} onSave={updateCurrentUser}>
				<HiddenField name="configuration.id" />
				<div className="user-form-container">
					{dropzoneContainer()}
					<div className="main-info user-form">
						<table>
							<tbody>
								<tr>
									<td width="50%">
										<label className="small">Your first name</label>
										<TextInput name="first_name" className="full" />
									</td>
									<td width="50%">
										<label className="small">Your last name</label>
										<TextInput name="last_name" className="full" />
									</td>
								</tr>
								<tr>
									<td colSpan="2">
										<label className="small">Your background or biography</label>
										<TextArea name="background" className="full" />
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<div style={{ background: "#fafafa", margin: "0 -20px", borderTop: "2px solid	#f2f2f2" }}>
					<table className="queue-settings padded">
						<tbody>
							<tr>
								<td className="icon"><i className="fal fa-key" /></td>
								<td className="setting-info">
									<strong>Enable two-factor authentication</strong>
									<p className="etc normal-size">For extra security, require a code on your phone to sign in</p>
								</td>
								<td className="value">
								{
									auth.user && auth.user.requiresTwoFactorAuthentication()
									? <BooleanToggle name="configuration.second_factor_enabled" />
									: <Link to="/settings/mfa" className="inline action button" style={{ textAlign: "center", padding: "8px 22px", fontWeight: "500", fontSize: "14px", border: "3px solid white", color: "#86a8d6" }}>Set up &rarr;</Link>
								}
								</td>
							</tr>
							<tr>
								<td className="icon"><i className="fal fa-clock" /></td>
								<td className="setting-info">
									<strong>What time zone are you in?</strong>
									{currentTime}
								</td>
								<td className="value">
									<div className="styled-select">
										{timeZoneList()}
									</div>
								</td>
							</tr>
							{auth.user && auth.user.canConnectMailbox() ? mailboxSettings() : null}
							{/*<tr>
								<td className="icon" style={{ background: "white" }}><i className="fa fa-calendar" /></td>
								<td className="setting-info" colSpan="2" style={{ background: "white" }}>
									<strong>Remove unread emails from queue after
										 <Select name="archive_after_value" style={{ marginLeft: "5px" }}>
											{[1, 2, 3, 5, 7, 10].map(function(num) {
												return <option value={num}>{num}</option>;
											})}
										</Select>
										<Select name="archive_after_units" style={{ marginLeft: "5px" }}>
											<option value="days">calendar days</option>
											<option value="days">weekdays</option>
										</Select>
									</strong>
								</td>
							</tr>*/}
						</tbody>
					</table>
				</div>
				<div style={{ marginBottom: "-10px", marginTop: "10px", textAlign: "center" }}>
					<input type="submit" className="rounded medium green button" value={(saving ? "Saving..." : "Update your settings")} disabled={uploadInProgress} />
				</div>
			</Form>
		</div>
	)
}

export default Profile